var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "menu" },
    [
      _c(
        "b-list-group",
        { staticClass: "text-left" },
        [
          _c(
            "b-list-group-item",
            {
              staticClass: "menu_button",
              attrs: { button: "" },
              on: {
                click: function($event) {
                  return _vm.link_to("/Home/Dashboard")
                }
              }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "tachometer-alt", size: "2x" }
              }),
              _c("span", { staticClass: "ml-3 menu_text" }, [
                _vm._v("ダッシュボード")
              ])
            ],
            1
          ),
          _vm.hasReportBrowsingPerm ||
          _vm.hasReportDownloadPerm ||
          _vm.hasReportEditPerm ||
          _vm.hasReportEditCompletedPerm ||
          _vm.hasReportCommentVerifiedPerm ||
          _vm.hasArchiveBrowsingPerm ||
          _vm.hasArchiveRegistPerm
            ? [
                _c(
                  "b-list-group-item",
                  {
                    staticClass: "menu_button",
                    attrs: { button: "" },
                    on: {
                      click: function($event) {
                        return _vm.link_to("/Home/ReportingData")
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "file-alt", size: "2x" }
                    }),
                    _c("span", { staticClass: "ml-3 menu_text" }, [
                      _vm._v("報告データ管理")
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "b-list-group-item",
            {
              staticClass: "menu_button",
              attrs: { button: "" },
              on: {
                click: function($event) {
                  return _vm.link_mobile()
                }
              }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "file-alt", size: "2x" }
              }),
              _c("span", { staticClass: "ml-3 menu_text" }, [
                _vm._v("発生事案登録")
              ])
            ],
            1
          ),
          _vm.hasBbcpManagerPerm || _vm.hasBcpUserPerm || _vm.hasBcpViewerPerm
            ? [
                _c(
                  "b-list-group-item",
                  {
                    staticClass: "menu_button",
                    attrs: { button: "" },
                    on: {
                      click: function($event) {
                        return _vm.link_bcp()
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "file-alt", size: "2x" }
                    }),
                    _c("span", { staticClass: "ml-3 menu_text" }, [
                      _vm._v("BCP")
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.hasWebAffiliationApprovalPerm
            ? [
                _c(
                  "b-list-group-item",
                  {
                    staticClass: "menu_button",
                    attrs: { button: "" },
                    on: {
                      click: function($event) {
                        return _vm.link_to("/Home/AffiliationApproval")
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "stamp", size: "2x" }
                    }),
                    _c("span", { staticClass: "ml-3 menu_text" }, [
                      _vm._v("所属承認")
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c(
            "b-list-group-item",
            {
              staticClass: "menu_button",
              attrs: { button: "" },
              on: {
                click: function($event) {
                  return _vm.link_to("/Home/OrganizationView")
                }
              }
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "sitemap", size: "2x" }
              }),
              _c("span", { staticClass: "ml-3 menu_text" }, [
                _vm._v("組織表示")
              ])
            ],
            1
          ),
          _vm.hasAllSysPerm
            ? [
                _c(
                  "b-list-group-item",
                  {
                    staticClass: "menu_button",
                    attrs: { button: "" },
                    on: {
                      click: function($event) {
                        return _vm.link_to("/Home/CommentHistory")
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "file-alt", size: "2x" }
                    }),
                    _c("span", { staticClass: "ml-3 menu_text" }, [
                      _vm._v("コメント履歴")
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.hasAllSysPerm ||
          _vm.hasWebOrganizationSettingPerm ||
          _vm.hasWebUsersSettingPerm ||
          _vm.hasWebManagedSettingPerm ||
          _vm.hasWebDrawingSettingPerm ||
          _vm.hasWebClassesSettingPerm ||
          _vm.hasWebInputFormSettingPerm ||
          _vm.hasWebOutputFormSettingPerm ||
          _vm.hasWebRegularReportSettingPerm ||
          _vm.hasWebNewsSettingPerm
            ? [
                _c(
                  "b-list-group-item",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle.submenu",
                        modifiers: { submenu: true }
                      }
                    ],
                    staticClass: "menu_button",
                    attrs: { button: "" },
                    on: { click: _vm.chg_info_toggle }
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "cog", size: "2x" }
                    }),
                    _c("span", { staticClass: "ml-3 menu_text" }, [
                      _vm._v("設定")
                    ]),
                    _c("b-icon", {
                      staticClass: "ml-2 mb-2",
                      attrs: {
                        icon: "caret-down-fill",
                        rotate: this.bind_data.info_toggle_rotate,
                        scale: "1.5"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-collapse",
                  { attrs: { id: "submenu" } },
                  [
                    _vm.hasAllSysPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/CompanysManage")
                                }
                              }
                            },
                            [_vm._v("会社設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasAllSysPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/AuthManage")
                                }
                              }
                            },
                            [_vm._v("権限設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebOrganizationSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/OrganizationManage")
                                }
                              }
                            },
                            [_vm._v("組織設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebUsersSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/UserManage")
                                }
                              }
                            },
                            [_vm._v("ユーザー設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebManagedSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/ManagedManage")
                                }
                              }
                            },
                            [_vm._v("管理対象設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebDrawingSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/BlueprintManage")
                                }
                              }
                            },
                            [_vm._v("図面設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebClassesSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/ClassManage")
                                }
                              }
                            },
                            [_vm._v("区分設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebInputFormSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/FormManage")
                                }
                              }
                            },
                            [_vm._v("フォーム編集")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebOutputFormSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/OutputFormRegist")
                                }
                              }
                            },
                            [_vm._v("出力帳票設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasAllSysPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to(
                                    "/Home/CompanysAccessControlManage"
                                  )
                                }
                              }
                            },
                            [_vm._v("会社アクセス制限設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebRegularReportSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/PeriodReportConfig")
                                }
                              }
                            },
                            [_vm._v("定期レポート設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasWebNewsSettingPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/NoticeManage")
                                }
                              }
                            },
                            [_vm._v("お知らせ設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasAllSysPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/LinkTimeoutManage")
                                }
                              }
                            },
                            [_vm._v("リンク有効期限設定")]
                          )
                        ]
                      : _vm._e(),
                    _vm.hasAllSysPerm
                      ? [
                          _c(
                            "b-list-group-item",
                            {
                              staticClass: "sub_menu_button",
                              attrs: { button: "" },
                              on: {
                                click: function($event) {
                                  return _vm.link_to("/Home/AccessLogDownload")
                                }
                              }
                            },
                            [_vm._v("アクセスログダウンロード")]
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }