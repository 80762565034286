var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Login" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c("Header"),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container", attrs: { align: "center" } },
        [
          _vm._m(0),
          _c(
            "b-card",
            {
              staticClass: "mt-3",
              staticStyle: { "max-width": "32rem" },
              attrs: {
                "border-variant": "primary",
                "header-text-variant": "white",
                "header-bg-variant": "primary",
                "header-tag": "h3"
              }
            },
            [
              _c("div", { staticClass: "text-left" }, [
                _c("label", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v("認証番号")
                ])
              ]),
              _c("b-form-input", {
                attrs: {
                  required: "",
                  type: "text",
                  maxlength: "6",
                  placeholder: "認証番号を入力してください"
                },
                model: {
                  value: _vm.bind_data.auth_number,
                  callback: function($$v) {
                    _vm.$set(_vm.bind_data, "auth_number", $$v)
                  },
                  expression: "bind_data.auth_number"
                }
              }),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "secondary", block: "" },
                          on: {
                            click: function($event) {
                              return _vm.back()
                            }
                          }
                        },
                        [_vm._v("ログインに戻る")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-3",
                          attrs: { variant: "primary", block: "" },
                          on: {
                            click: function($event) {
                              return _vm.auth()
                            }
                          }
                        },
                        [_vm._v("認証")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "mt-3 p-1 text-secondary",
              staticStyle: {
                "text-align": "left",
                "font-size": "0.9rem",
                "max-width": "32rem"
              }
            },
            [
              _vm._v(
                " 認証コードが届かない場合は、再度ログイン画面からやりなおしてください。 "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "p-2 mt-3",
        staticStyle: {
          border: "3px solid red",
          "text-align": "left",
          "font-size": "1.1rem",
          "margin-bottom": "2rem",
          "max-width": "32rem"
        }
      },
      [
        _vm._v(" ログインはまだ完了しておりません。"),
        _c("br"),
        _vm._v(" 認証番号を登録メールアドレスに送信しました。"),
        _c("br"),
        _vm._v(" 6桁の認証番号を入力してログインを完了してください。 ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }