import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import common from '@/lib/common.js';
import Header from '@/components/Header';
import axios from 'axios';
import CommonDataSet from '@/lib/CommonDataSet.js';

export default {
	name: 'Login', // ココを更新
	components: {
		Loading,
		Header
	},

	// 共通関数
	mixins: [common, CommonDataSet],

	props: {
		login_cmp_id: String,
		login_usr_id: String,
		login_password: String,
	},

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},

			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},

			// バインドデータ
			bind_data: {
				auth_number: "",
			},

			// ローカルデータ
			local_data: {
			},

			// 状態ストレージ
			state_data: {
			}
		}
	},

	// 既定計算
	computed: {
	},

	// インスタンス初期化後
	created(){
	},

	// インスタンス破棄後
	destroyed: function() {
	},

	// インスタンスマウント後
	async mounted(){
		console.log(this.$route.path+".vue Start");
	},

	// ローカル関数
	methods: {
		async auth() {
			if ( this.bind_data.auth_number == '' ) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = '認証番号を入力してください';

				return;
			}

			// ローディング開始
			this.loader.is_loading = true;

			// ログインを行い、ユーザー情報を取得（権限を含む）
			let url = process.env.VUE_APP_RESTAPI_MULTI_AUTH_LOGIN;
			let post_data = {
				login_cmp_id: this.login_cmp_id,
				login_usr_id: this.login_usr_id,
				login_password: this.login_password,
				auth_number: this.bind_data.auth_number,
			};

			console.log( this.$route.path + ":regist:url=" + url );

			try {
				let response = await axios.post( url, post_data );

				console.log( this.$route.path + ":response=" + JSON.stringify( response ) );

				if ( response.data.result == "OK" ) {
					// グローバル変数（store）に格納
					this.$store.dispatch( "commit_set_sid", response.data.sid );

					// ユーザー情報をグローバル変数に格納
					let user_info = response.data.user_info;

					user_info.organization_list = response.data.organization_list;
					this.$store.dispatch( "commit_set_users_info", user_info );
					this.$store.dispatch( "commit_set_system_info", response.data.system_param );

					// 区分情報を取得しグローバル変数に格納
					let result2 = await this.set_class_list();

					if ( !result2 ) {
						// ローディング終了
						this.loader.is_loading = false;

						return;
					}

					// 組織情報を取得しローカルストレージに格納
					let result3 = await this.set_org_map();

					if ( !result3 ) {
						// ローディング終了
						this.loader.is_loading = false;

						return;
					}

					// クッキーに保存(仮)
					this.$cookies.config( '72h' );
					this.$cookies.set( 'sid', response.data.sid );
					//this.$cookies.set( 'login_cmp_id', this.login_cmp_id );
					//this.$cookies.set( 'login_usr_id', this.login_usr_id );

					//画面遷移
					this.transition( "Home" );
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;

					// ローディング終了
					this.loader.is_loading = false;
				}
			} catch ( ex ) {
				console.log( this.$route.path + ":ex=" + JSON.stringify( ex ) );

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( ex );
				
				// ローディング終了
				this.loader.is_loading = false;
			}
		},

		back() {
			// 画面遷移
			this.transition( "Login" );
		}
	}
}
