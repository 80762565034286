var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Notice" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        _vm._l(_vm.bind_data.notice_lists, function(notice_list) {
          return _c(
            "div",
            { key: notice_list.id, staticClass: "border-top pt-2 mt-4" },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _vm._v(_vm._s(notice_list.nws_subject)),
                    _c("br"),
                    _c("small", {
                      domProps: {
                        innerHTML: _vm._s(
                          notice_list.nws_contents.replace(/\n/g, "<br>")
                        )
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(notice_list.nws_start_date))
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }