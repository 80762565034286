import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import dataset from '@/lib/dataset.js';
import perm from '@/lib/perm.js';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
import CommonDataSet from '@/lib/CommonDataSet.js';

export default {
  name: 'PeriodReportConfig', // ココを更新
  components: {
    Loading,
    treeselectdialogs,
  },

  // 共通関数
  mixins: [common,dataset,CommonDataSet,perm,],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        week_badge: false,
        month_badge: false,
        // 会社ID
        cmp_id_options: [
        ],
        config_cmp_id: "",
        delivery_options: [
          { text: '配信する', value: 'on' },
          { text: '配信しない', value: 'off' },
        ],
        week_per_uuid: "",
        week_delivery_selected: 'off',
        week_selected: "",
        week_options: [
          { text: '日', value: 'sun' },
          { text: '月', value: 'mon' },
          { text: '火', value: 'tue' },
          { text: '水', value: 'wed' },
          { text: '木', value: 'thu' },
          { text: '金', value: 'fri' },
          { text: '土', value: 'sat' },
        ],
        week_dpt_selected: null,
        week_dpt_selected_array: [],
        week_dpt_options: null,
        //登録済み週報の有無
        week_existing_report: false,
        
        month_per_uuid: "",
        month_delivery_selected: 'on',
        month_selected: "",
        month_options: [
          { text: '1日', value: '1' },
          { text: '6日', value: '6' },
          { text: '11日', value: '11' },
          { text: '16日', value: '16' },
          { text: '21日', value: '21' },
          { text: '26日', value: '26' },
        ],
        month_dpt_selected: null,
        month_dpt_selected_array: [],
        month_dpt_options: null,
        //登録済み月報の有無
        month_existing_report: false,
      },
      // ローカルデータ
      local_data: {     
      },
      state_data: {
        week_dpt_select_dialogs: false,
        month_dpt_select_dialogs: false,
      }
    }
  },

  // 既定計算
  computed: {

  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "定期レポート設定" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");
    await this.companys_pulldown_list();
    this.bind_data.config_cmp_id = this.$store.state.users_info.usr_cmp_id;
    await this.set_org_map("selslf",this.$store.state.users_info.usr_cmp_id,null);
    this.bind_data.week_dpt_options = this.data_get_dpt_info();
    this.bind_data.month_dpt_options = this.data_get_dpt_info();
    this.state_data.week_dpt_select_dialogs = true;
    this.state_data.month_dpt_select_dialogs = true;

    await this.get_period_report_list();
  },
  // ローカル関数
  methods: {

    get_week_dpt_info( data ){
      console.log("get_dpt_info="+JSON.stringify(data));
      this.bind_data.week_dpt_selected_array = data;
    },
    get_month_dpt_info( data ){
      console.log("get_dpt_info="+JSON.stringify(data));
      this.bind_data.month_dpt_selected_array = data;
    },

    async get_period_report_list(){

      let url = process.env.VUE_APP_RESTAPI_GET_PERIOD_REPORT_LIST+"?sid="+this.$store.state.sid
      +"&cmp_id="+this.bind_data.config_cmp_id;
      try {
        let response = await axios.get( url );
        console.log("response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          let week_set = false;
          let month_set = false;
          for( let i=0;i<response.data.period_report_list.length;i++ ){
            let item = response.data.period_report_list[i];
            if( item.per_period == 2 ){
              this.set_week( item );
              week_set = true;
            }
            if( item.per_period == 1 ){
              this.set_month( item );
              month_set = true;
            }
          }
          //既存の定期レポートが存在しない場合
          if(!week_set){
            this.bind_data.week_badge = false;
            this.bind_data.week_delivery_selected = 'off'
            this.bind_data.week_per_uuid = "";
            this.bind_data.week_selected = "";
            this.bind_data.week_dpt_selected = null;
            this.bind_data.week_dpt_selected_array = [];
          }
          if(!month_set){
            this.bind_data.month_badge = false;
            this.bind_data.month_delivery_selected = 'off'
            this.bind_data.month_per_uuid = "";
            this.bind_data.month_selected = "";
            this.bind_data.month_dpt_selected = null;
            this.bind_data.month_dpt_selected_array = [];
          }
          //配信中・未配信の表示切替
          this.bind_data.week_existing_report = week_set;
          this.bind_data.month_existing_report = month_set;
        }
      } catch (ex) {
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    set_week( item ){
      console.log("set_week item="+JSON.stringify(item));
      if(item.per_delivery_week != null && item.per_delivery_week != ""){
        this.bind_data.week_badge = true;
        this.bind_data.week_delivery_selected = 'on'
      } else {
        this.bind_data.week_badge = false;
        this.bind_data.week_delivery_selected = 'off'
      }
      this.bind_data.week_per_uuid = item.per_uuid;
      this.bind_data.week_selected = item.per_delivery_week;
      this.bind_data.week_dpt_selected = this.exclude_empty_array(item.per_delivery_orgs.split(","));
      this.bind_data.week_dpt_selected_array = this.exclude_empty_array(item.per_delivery_orgs.split(","));
    },
    set_month( item ){
      console.log("set_month item="+JSON.stringify(item));
      if(item.per_delivery_date != null && item.per_delivery_date != "" && item.per_delivery_date != '0'){
        this.bind_data.month_badge = true;
        this.bind_data.month_delivery_selected = 'on'
      } else {
        this.bind_data.month_badge = false;
        this.bind_data.month_delivery_selected = 'off'
      }
      this.bind_data.month_per_uuid = item.per_uuid;
      this.bind_data.month_selected = String(item.per_delivery_date);
      this.bind_data.month_dpt_selected = this.exclude_empty_array(item.per_delivery_orgs.split(","));
      this.bind_data.month_dpt_selected_array = this.exclude_empty_array(item.per_delivery_orgs.split(","));
    },

    //週報登録
    async registWeek(){

      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.per_cmp_uuid = this.bind_data.config_cmp_id;
      post_data.per_period_flg = '2';
      post_data.per_delivery_flg = this.bind_data.week_delivery_selected;
      if(this.bind_data.week_delivery_selected == 'on') {
        post_data.per_delivery_week = String(this.bind_data.week_selected);
        post_data.per_delivery_org = String(this.bind_data.week_dpt_selected_array.join(","));
      } else {
        post_data.per_delivery_week = "";
        post_data.per_delivery_org = [].join(",");
      }
      post_data.per_uuid = this.bind_data.week_per_uuid;
      post_data.per_delivery_date = "";
      console.log("post_data="+JSON.stringify(post_data));

      let url = process.env.VUE_APP_RESTAPI_SET_PERIOD_REPORT_LIST;
      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response));
        if (response.data.result == "OK") {
          
          this.message.is_alert = true;
          this.message.variant = "info";
          this.message.message = "成功しました。";

          await this.get_period_report_list();

          //ローディング終了
          this.loader.is_loading = false;
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch(ex){
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },

    //月報登録
    async registMonth(){

      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.per_cmp_uuid = this.bind_data.config_cmp_id;
      post_data.per_period_flg = '1';
      post_data.per_delivery_flg = this.bind_data.month_delivery_selected;
      if(this.bind_data.month_delivery_selected == 'on'){
        post_data.per_delivery_date = String(this.bind_data.month_selected);
        post_data.per_delivery_org = String(this.bind_data.month_dpt_selected_array.join(","));
      } else {
        post_data.per_delivery_date = "";
        post_data.per_delivery_org = [].join(",");
      }
      post_data.per_uuid = this.bind_data.month_per_uuid;
      post_data.per_delivery_week = "";
      console.log("post_data="+JSON.stringify(post_data));

      let url = process.env.VUE_APP_RESTAPI_SET_PERIOD_REPORT_LIST;
      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response));
        if (response.data.result == "OK") {
          
          this.message.is_alert = true;
          this.message.variant = "info";
          this.message.message = "成功しました。";

          await this.get_period_report_list();

          //ローディング終了
          this.loader.is_loading = false;
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch(ex){
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    // 会社プルダウンリスト用一覧取得
    async companys_pulldown_list(){
      let url = process.env.VUE_APP_RESTAPI_GET_COMPANY_LIST+"?sid="+this.$store.state.sid;
      try {
        let response = await axios.get( url );
        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.cmp_id_options = [];
          if (response.data.company_list.length > 0) {
            if (this.hasAllSysPerm){
              //ALLSYSの場合
              for (let i=0; i<response.data.company_list.length; i++) {
                let options = {};
                options.text = response.data.company_list[i].cmp_name;
                options.value = response.data.company_list[i].cmp_id;
                this.bind_data.cmp_id_options.push(options);
              }
            } else {
              //ALLSYS以外の場合
              for (let i=0; i<response.data.company_list.length; i++) {
                if(response.data.company_list[i].cmp_id==this.$store.state.users_info.usr_cmp_id){
                  let options = {};
                  options.text = response.data.company_list[i].cmp_name;
                  options.value = response.data.company_list[i].cmp_id;
                  this.bind_data.cmp_id_options.push(options);
                }
              }
            }
          }
        }
      } catch (ex) {
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    //会社プルダウンの変更を検知する
    async handle_cange_cmp(){
      
      // ローディング開始
      this.loader.is_loading = true;
      //コンポーネントの再描画を行う
      this.state_data.week_dpt_select_dialogs = false;
      this.state_data.month_dpt_select_dialogs = false;
      //組織選択のコンポーネントが参照する組織IDを変更する
      await this.set_org_map("selslf",this.bind_data.config_cmp_id,null);
      this.state_data.week_dpt_select_dialogs = true;
      this.state_data.month_dpt_select_dialogs = true;
      
      await this.get_period_report_list();
      
      // ローディング終了
      this.loader.is_loading = false;
    },
    //週報の配信曜日の入力チェック
    state_week(){
      if(this.bind_data.week_delivery_selected == 'off' || this.bind_data.week_selected != ""){
        return null;
      }
      return false;
    },
    //月報の配信日の入力チェック
    state_month(){
      if(this.bind_data.month_delivery_selected == 'off' || (this.bind_data.month_selected != "" && this.bind_data.month_selected != "0" )){
        return null;
      }
      return false;
    },
    //週報登録ボタンの入力可・不可切り替え
    state_regist_button_week(){
      if(this.state_week() === false){
        return true;
      }
      if(this.bind_data.week_delivery_selected == 'on' && this.bind_data.week_dpt_selected_array.length == 0){
        return true
      }
      return false;
    },
    //月報登録ボタンの入力可・不可切り替え
    state_regist_button_month(){
      if(this.state_month() === false){
        return true;
      }
      if(this.bind_data.month_delivery_selected == 'on' && this.bind_data.month_dpt_selected_array.length == 0){
        return true
      }
      return false;
    },
    //週報確認ウィンドウを表示する
    handleConfirmRegistWeek() {
      this.$refs['registReoprtWeek'].show();
    },
    //月報確認ウィンドウを表示する
    handleConfirmRegistMonth() {
      this.$refs['registReoprtMonth'].show();
    },
    //週報確認ウィンドウの表示を切り替える
    registMessageWeek(){
      if (this.bind_data.week_existing_report){
        return true;
      }
      return false;
    },
    //月報確認ウィンドウの表示を切り替える
    registMessageMonth(){
      if (this.bind_data.month_existing_report){
        return true;
      }
      return false;
    }
  }
}