var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Reportingdata text-left" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-secondary" },
                  on: {
                    click: function($event) {
                      return _vm.search_toggle()
                    }
                  }
                },
                [
                  _vm._v("検索条件 "),
                  _c("b-icon", {
                    attrs: {
                      icon: "caret-down-fill",
                      rotate: _vm.bind_data.search_toggle_rotate
                    }
                  })
                ],
                1
              ),
              _c(
                "b-card",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.bind_data.search_toggle,
                      expression: "bind_data.search_toggle"
                    }
                  ],
                  staticClass: "card-shadow border-1"
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          class: _vm.isNotMobilePhone
                            ? _vm.text_align.text_right
                            : _vm.text_align.text_left,
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "2",
                            lg: "2",
                            xl: "2"
                          }
                        },
                        [_vm._v("状態")]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "10",
                            lg: "10",
                            xl: "10"
                          }
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              multiple: true,
                              "disable-branch-nodes": true,
                              options: _vm.bind_data.search_state_options,
                              placeholder: "選択してください"
                            },
                            model: {
                              value: _vm.local_data.search_state,
                              callback: function($$v) {
                                _vm.$set(_vm.local_data, "search_state", $$v)
                              },
                              expression: "local_data.search_state"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          class: _vm.isNotMobilePhone
                            ? _vm.text_align.text_right
                            : _vm.text_align.text_left,
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "2",
                            lg: "2",
                            xl: "2"
                          }
                        },
                        [_vm._v("管理対象施設")]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "10",
                            lg: "10",
                            xl: "10"
                          }
                        },
                        [
                          _c("treeselect", {
                            attrs: {
                              multiple: true,
                              "disable-branch-nodes": true,
                              options: _vm.bind_data.search_facility_options,
                              placeholder: "選択してください"
                            },
                            model: {
                              value: _vm.local_data.search_facility,
                              callback: function($$v) {
                                _vm.$set(_vm.local_data, "search_facility", $$v)
                              },
                              expression: "local_data.search_facility"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          class: _vm.isNotMobilePhone
                            ? _vm.text_align.text_right
                            : _vm.text_align.text_left,
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "2",
                            lg: "2",
                            xl: "2"
                          }
                        },
                        [_vm._v("発生日時")]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "5",
                            lg: "5",
                            xl: "5"
                          }
                        },
                        [
                          _c(
                            "b-form-datepicker",
                            _vm._b(
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-no-date-selected":
                                    "検索範囲の開始日を選択",
                                  locale: _vm.datepicker.locale,
                                  "date-format-options": {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    weekday: "short"
                                  },
                                  state: _vm.state_data.search_date,
                                  "today-button": "",
                                  "reset-button": "",
                                  "close-button": ""
                                },
                                model: {
                                  value: _vm.local_data.search_date,
                                  callback: function($$v) {
                                    _vm.$set(_vm.local_data, "search_date", $$v)
                                  },
                                  expression: "local_data.search_date"
                                }
                              },
                              "b-form-datepicker",
                              _vm.datepicker.labels[_vm.datepicker.locale] ||
                                {},
                              false
                            )
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "5",
                            lg: "5",
                            xl: "5"
                          }
                        },
                        [
                          _c(
                            "b-form-timepicker",
                            _vm._b(
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-no-time-selected": "時刻を選択",
                                  locale: _vm.datepicker.locale,
                                  state: _vm.state_data.between_edit_start_time,
                                  "reset-button": "",
                                  "close-button": "",
                                  "now-button": ""
                                },
                                model: {
                                  value: _vm.local_data.between_edit_start_time,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data,
                                      "between_edit_start_time",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "local_data.between_edit_start_time"
                                }
                              },
                              "b-form-timepicker",
                              _vm.datepicker.labels[_vm.datepicker.locale] ||
                                {},
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-0" },
                    [
                      _c("b-col", {
                        staticClass: "text-left",
                        attrs: { xs: "12", sm: "12", md: "2", lg: "2", xl: "2" }
                      }),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "5",
                            lg: "5",
                            xl: "5"
                          }
                        },
                        [
                          _c(
                            "b-form-datepicker",
                            _vm._b(
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-no-date-selected":
                                    "検索範囲の終了日を選択",
                                  locale: _vm.datepicker.locale,
                                  "date-format-options": {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    weekday: "short"
                                  },
                                  state: _vm.state_data.search_date_end,
                                  "today-button": "",
                                  "reset-button": "",
                                  "close-button": ""
                                },
                                model: {
                                  value: _vm.local_data.search_date_end,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data,
                                      "search_date_end",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.search_date_end"
                                }
                              },
                              "b-form-datepicker",
                              _vm.datepicker.labels[_vm.datepicker.locale] ||
                                {},
                              false
                            )
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "5",
                            lg: "5",
                            xl: "5"
                          }
                        },
                        [
                          _c(
                            "b-form-timepicker",
                            _vm._b(
                              {
                                staticClass: "m-0",
                                attrs: {
                                  "label-no-time-selected": "時刻を選択",
                                  locale: _vm.datepicker.locale,
                                  state: _vm.state_data.between_edit_end_time,
                                  "reset-button": "",
                                  "close-button": "",
                                  "now-button": ""
                                },
                                model: {
                                  value: _vm.local_data.between_edit_end_time,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data,
                                      "between_edit_end_time",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.between_edit_end_time"
                                }
                              },
                              "b-form-timepicker",
                              _vm.datepicker.labels[_vm.datepicker.locale] ||
                                {},
                              false
                            )
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          class: _vm.isNotMobilePhone
                            ? _vm.text_align.text_right
                            : _vm.text_align.text_left,
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "2",
                            lg: "2",
                            xl: "2"
                          }
                        },
                        [_vm._v("件名")]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "10",
                            lg: "10",
                            xl: "10"
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder:
                                "件名を入力してください。（部分一致検索）",
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.local_data.search_text,
                              callback: function($$v) {
                                _vm.$set(_vm.local_data, "search_text", $$v)
                              },
                              expression: "local_data.search_text"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2 float-right",
                              attrs: {
                                variant: "outline-secondary",
                                size: "sm",
                                disabled: _vm.state_search_button
                              },
                              on: {
                                click: function($event) {
                                  return _vm.csv_output()
                                }
                              }
                            },
                            [_vm._v("CSV出力")]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2 float-right",
                              attrs: {
                                variant: "outline-secondary",
                                size: "sm",
                                disabled: _vm.state_search_button
                              },
                              on: {
                                click: function($event) {
                                  return _vm.search()
                                }
                              }
                            },
                            [_vm._v("検索")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", [
                    _c("h5", { staticClass: "float-right mr-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.local_data.data_count_numerator) +
                          "/" +
                          _vm._s(_vm.local_data.data_count_denominator) +
                          "件 "
                      )
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm._l(_vm.bind_data.bas_lists, function(bas_item) {
            return _c(
              "div",
              { key: bas_item.bas_uuid, staticClass: "mb-3" },
              [
                _c(
                  "b-card",
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "4",
                              md: "2",
                              lg: "2",
                              xl: "2"
                            }
                          },
                          [
                            _c(
                              "h4",
                              [
                                bas_item.bas_comp_type === "complete"
                                  ? [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "p-2 badge-width",
                                          attrs: { variant: "secondary" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(bas_item.bas_comp_type_name)
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                bas_item.bas_comp_type === "correspond"
                                  ? [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "p-2 badge-width",
                                          attrs: { variant: "primary" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(bas_item.bas_comp_type_name)
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e(),
                                bas_item.bas_comp_type === "lock"
                                  ? [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "p-2 badge-width",
                                          attrs: { variant: "success" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(bas_item.bas_comp_type_name)
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "text-left",
                            attrs: {
                              xs: "12",
                              sm: "8",
                              md: "6",
                              lg: "6",
                              xl: "6"
                            }
                          },
                          [
                            _c(
                              "b-card-title",
                              [
                                bas_item.bas_rpt_type === "emergency"
                                  ? _c(
                                      "b-badge",
                                      {
                                        staticClass: "p-2",
                                        attrs: { variant: "danger" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(bas_item.bas_rpt_type_name)
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(bas_item.bas_subject) + " ")
                              ],
                              1
                            ),
                            _c(
                              "b-card-text",
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    " 事案番号 : " +
                                      _vm._s(bas_item.bas_case_number) +
                                      " "
                                  ),
                                  _c("br")
                                ]),
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(
                                    " 発生日時 : " +
                                      _vm._s(bas_item.bas_occur_date_time) +
                                      " "
                                  ),
                                  _c("br")
                                ]),
                                _vm._l(bas_item.bas_mng_list, function(
                                  bas_mng_info
                                ) {
                                  return _c(
                                    "span",
                                    {
                                      key: bas_mng_info.bas_mng_uuid,
                                      staticClass: "mr-2"
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            bas_mng_info.bas_mng_uuid_name
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                }),
                                bas_item.bas_comp_type === "lock"
                                  ? [
                                      _c("br"),
                                      _vm._v(
                                        "処置完了日時 : " +
                                          _vm._s(bas_item.bas_lock_date_time) +
                                          " "
                                      )
                                    ]
                                  : _vm._e(),
                                bas_item.bas_comp_type === "complete"
                                  ? [
                                      _c("br"),
                                      _vm._v(
                                        "処置完了日時 : " +
                                          _vm._s(bas_item.bas_lock_date_time) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "報告完了日時 : " +
                                          _vm._s(
                                            bas_item.bas_complete_date_time
                                          ) +
                                          " "
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "text-right",
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "4",
                              lg: "4",
                              xl: "4"
                            }
                          },
                          [
                            _vm.hasReportEditPerm
                              ? [
                                  bas_item.bas_comp_type !== "complete"
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "m-1",
                                          attrs: {
                                            variant: "outline-secondary",
                                            size: "sm"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.edit(bas_item)
                                            }
                                          }
                                        },
                                        [_vm._v("編集")]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm.hasReportBrowsingPerm
                              ? [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "m-1",
                                      attrs: {
                                        variant: "outline-secondary",
                                        size: "sm"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.details(bas_item)
                                        }
                                      }
                                    },
                                    [_vm._v("閲覧")]
                                  )
                                ]
                              : _vm._e(),
                            _vm.hasArchiveBrowsingPerm
                              ? [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "m-1",
                                      attrs: {
                                        variant: "outline-secondary",
                                        size: "sm"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.cabinet(bas_item)
                                        }
                                      }
                                    },
                                    [_vm._v("書庫")]
                                  )
                                ]
                              : _vm._e(),
                            _vm.hasReportDownloadPerm
                              ? [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "m-1",
                                      attrs: {
                                        variant: "outline-secondary",
                                        size: "sm"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.download(bas_item)
                                        }
                                      }
                                    },
                                    [_vm._v("ダウンロード")]
                                  )
                                ]
                              : _vm._e(),
                            _vm.hasReportEditPerm
                              ? [
                                  bas_item.bas_comp_type !== "complete" &&
                                  bas_item.bas_comp_type !== "lock"
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "m-1",
                                          attrs: {
                                            variant: "outline-primary",
                                            size: "sm"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleConfirmBreakingNews(
                                                bas_item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("速報データ作成")]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm.hasReportEditCompletedPerm
                              ? [
                                  bas_item.bas_comp_type === "complete"
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "m-1",
                                          attrs: {
                                            variant: "outline-danger",
                                            size: "sm"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleConfirmRelease(
                                                bas_item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("完了解除")]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm.hasReportEditCompletedPerm
                              ? [
                                  bas_item.bas_comp_type === "lock"
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "m-1",
                                          attrs: {
                                            variant: "outline-danger",
                                            size: "sm"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleConfirmCompelete(
                                                bas_item
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("報告完了")]
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _vm.hasAllSysPerm
                              ? [
                                  _c("br"),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "m-1",
                                      attrs: { variant: "danger", size: "sm" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleConfirmDelete(
                                            bas_item
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("削除")]
                                  )
                                ]
                              : _vm._e(),
                            bas_item.consult_state.indexOf("consult_receive") >
                            -1
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary small" },
                                    [
                                      _vm._v(
                                        "相談依頼された報告データがあります。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "［編集］で次画面に遷移し確認してください。"
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            bas_item.consult_state.indexOf("consult_send") > -1
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary small" },
                                    [_vm._v("相談中の報告データがあります。")]
                                  )
                                ]
                              : _vm._e(),
                            bas_item.consult_state.indexOf("answer_receive") >
                            -1
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary small" },
                                    [
                                      _vm._v(
                                        "回答あり（未配信）の報告データがあります。"
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "［編集］で次画面に遷移し確認してください。"
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            bas_item.consult_state.indexOf("answer_send") > -1
                              ? [
                                  _c(
                                    "div",
                                    { staticClass: "text-primary small" },
                                    [
                                      _vm._v(
                                        "回答済み（未配信）の報告データがあります。"
                                      )
                                    ]
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "confirmBreakingNews",
          attrs: { title: "速報データ作成" },
          on: {
            ok: function($event) {
              return _vm.handleBreakingNews()
            }
          }
        },
        [_vm._v("速報データを作成します。よろしいですか？")]
      ),
      _c(
        "b-modal",
        {
          ref: "confirmRelease",
          attrs: { title: "報告完了解除" },
          on: {
            ok: function($event) {
              return _vm.handleRelease()
            }
          }
        },
        [_vm._v("報告書の完了を解除します。よろしいですか？")]
      ),
      _c(
        "b-modal",
        {
          ref: "confirmComplete",
          attrs: { title: "報告編集完了" },
          on: {
            ok: function($event) {
              return _vm.handleComplete()
            }
          }
        },
        [_vm._v("報告書の編集を完了にします。よろしいですか？")]
      ),
      _c(
        "b-modal",
        {
          ref: "confirmFormSelect",
          attrs: {
            size: "lg",
            title: "フォーム選択",
            "ok-disabled": !_vm.isFormSelected
          },
          on: {
            ok: function($event) {
              return _vm.handleSelectForm()
            }
          }
        },
        [
          _vm._v(" 出力対象の報告データに対して複数のフォームが存在します。"),
          _c("br"),
          _vm._v(" 一覧より出力するフォームを選択してから出力してください。 "),
          _c("div", { staticClass: "mt-3 font-weight-bold" }, [
            _vm._v("フォーム一覧")
          ]),
          _c("b-table", {
            attrs: {
              hover: "",
              items: _vm.local_data.form_info,
              fields: _vm.local_data.form_info_fields
            },
            scopedSlots: _vm._u([
              {
                key: "cell(app_cmd)",
                fn: function(row) {
                  return [
                    _c("b-form-radio", {
                      attrs: { name: "select_form", value: row.item.frm_id },
                      model: {
                        value: _vm.bind_data.selected_form,
                        callback: function($$v) {
                          _vm.$set(_vm.bind_data, "selected_form", $$v)
                        },
                        expression: "bind_data.selected_form"
                      }
                    })
                  ]
                }
              },
              {
                key: "table-colgroup",
                fn: function(scope) {
                  return _vm._l(scope.fields, function(field) {
                    return _c("col", {
                      key: field.key,
                      style: {
                        width:
                          field.key == "app_cmd"
                            ? "50px"
                            : field.key == "frm_upd_date_time"
                            ? "190px"
                            : ""
                      }
                    })
                  })
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "confirmDelete",
          attrs: {
            title: "事案削除",
            "ok-variant": "danger",
            "ok-title": "削除"
          },
          on: {
            ok: function($event) {
              return _vm.handleConfirmDelete2()
            }
          }
        },
        [
          _vm._v(" 事案を削除します。よろしいですか？"),
          _c("br"),
          _vm._v(" 以下の情報が一緒に削除されます。"),
          _c("br"),
          _vm._v(" ・タイムライン"),
          _c("br"),
          _vm._v(" ・トークルーム"),
          _c("br"),
          _vm._v(" ・報告データ"),
          _c("br"),
          _vm._v(" ・書庫 ")
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "confirmDelete2",
          attrs: {
            title: "事案削除",
            "ok-variant": "danger",
            "ok-title": "削除"
          },
          on: {
            ok: function($event) {
              return _vm.handleDelete()
            }
          }
        },
        [_vm._v("削除したデータは復元できません。よろしいですか？")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }