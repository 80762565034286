var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CaseDetails mb-3" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _vm.bind_data.fbs_info
        ? [
            _c(
              "div",
              { staticClass: "container text-left" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "m-2",
                    attrs: { variant: "outline-secondary" },
                    on: {
                      click: function($event) {
                        return _vm.back()
                      }
                    }
                  },
                  [_vm._v("戻る")]
                ),
                _vm.isConsult()
                  ? [
                      _vm.isShowConsult
                        ? [
                            _c(
                              "b-card",
                              {
                                staticClass: "mx-2 mb-2",
                                attrs: { "header-bg-variant": "warning" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c(
                                            "h5",
                                            { staticClass: "mb-0 float-left" },
                                            [_vm._v("相談内容")]
                                          ),
                                          _vm.local_data.consultList.length > 1
                                            ? _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.collapse-1",
                                                      modifiers: {
                                                        "collapse-1": true
                                                      }
                                                    }
                                                  ],
                                                  staticClass: "float-right",
                                                  attrs: { size: "sm" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.consultListCallaoseBtnTitle
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  4021452815
                                )
                              },
                              _vm._l(_vm.local_data.consultList, function(
                                consult,
                                index
                              ) {
                                return _c(
                                  "div",
                                  [
                                    index <
                                    _vm.local_data.consultList.length - 1
                                      ? _c(
                                          "b-collapse",
                                          {
                                            attrs: { id: "collapse-1" },
                                            model: {
                                              value:
                                                _vm.state_data
                                                  .consultListVisible,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.state_data,
                                                  "consultListVisible",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "state_data.consultListVisible"
                                            }
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticStyle: { width: "100%" }
                                              },
                                              [
                                                consult.cnm_consult_content !=
                                                ""
                                                  ? [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("相談日時")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                consult.cnm_consult_date_time
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("相談内容")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.cnm_consult_content
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  : _vm._e(),
                                                consult.cnm_status == "answer"
                                                  ? [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("回答日時")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                consult.cnm_answer_date_time
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      consult.contact_user_name !=
                                                      ""
                                                        ? _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  width: "1%",
                                                                  "white-space":
                                                                    "nowrap"
                                                                },
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [_vm._v("回答者")]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  width: "1%",
                                                                  "white-space":
                                                                    "nowrap"
                                                                },
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [_vm._v("：")]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.contact_user_name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("回答内容")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.cnm_answer_content
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            consult.cnm_status == "cancel"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-2 text-center",
                                                    staticStyle: {
                                                      color: "red"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " この相談はキャンセルされました。 "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("hr")
                                          ]
                                        )
                                      : [
                                          _c(
                                            "table",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              consult.cnm_consult_content != ""
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("相談日時")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              consult.cnm_consult_date_time
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("相談内容")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "pre-wrap"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.cnm_consult_content
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e(),
                                              consult.cnm_status == "answer"
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("回答日時")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              consult.cnm_answer_date_time
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    consult.contact_user_name !=
                                                    ""
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "1%",
                                                                "white-space":
                                                                  "nowrap"
                                                              },
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [_vm._v("回答者")]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "1%",
                                                                "white-space":
                                                                  "nowrap"
                                                              },
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [_vm._v("：")]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.contact_user_name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("回答内容")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "pre-wrap"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.cnm_answer_content
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          ),
                                          consult.cnm_status == "cancel"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mt-2 text-center",
                                                  staticStyle: { color: "red" }
                                                },
                                                [
                                                  _vm._v(
                                                    " この相談はキャンセルされました。 "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mx-2 mb-4",
                            attrs: { "header-bg-variant": "light" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function() {
                                    return [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "m-0 p-0 font-weight-bold"
                                        },
                                        [_vm._v("番号")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              922230809
                            )
                          },
                          [
                            _vm.bind_data.fbs_info.fbs_version_number
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.bind_data.fbs_info.fbs_version_number
                                    )
                                  )
                                ])
                              : _c("div", [_vm._v("-")])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mx-2 mb-4",
                            attrs: { "header-bg-variant": "light" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function() {
                                    return [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "m-0 p-0 font-weight-bold"
                                        },
                                        [_vm._v("コピー元")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              621386716
                            )
                          },
                          [
                            _vm.bind_data.fbs_info.fbs_original_number
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.bind_data.fbs_info.fbs_original_number
                                    )
                                  )
                                ])
                              : _c("div", [_vm._v("-")])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "m-0 p-0 font-weight-bold" },
                                [_vm._v("報告書名称")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1878607194
                    )
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.bind_data.fbs_info.fbs_report_title) +
                        " "
                    )
                  ]
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("件名")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1781290544
                    )
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "m-0",
                      attrs: { maxlength: "100" },
                      model: {
                        value: _vm.bind_data.fbs_info.bas_subject,
                        callback: function($$v) {
                          _vm.$set(_vm.bind_data.fbs_info, "bas_subject", $$v)
                        },
                        expression: "bind_data.fbs_info.bas_subject"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "m-0 p-0 font-weight-bold" },
                                [_vm._v("事案番号")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1329550554
                    )
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.bind_data.fbs_info.bas_case_number) + " "
                    )
                  ]
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("発生日時")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3739352553
                    )
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "6",
                              lg: "6",
                              xl: "6"
                            }
                          },
                          [
                            _c("b-form-datepicker", {
                              staticClass: "m-0",
                              attrs: {
                                locale: "ja",
                                "label-no-date-selected":
                                  "日付を選択してください",
                                "date-format-options": {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                  weekday: "short"
                                }
                              },
                              model: {
                                value: _vm.bind_data.bas_occur_date,
                                callback: function($$v) {
                                  _vm.$set(_vm.bind_data, "bas_occur_date", $$v)
                                },
                                expression: "bind_data.bas_occur_date"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "6",
                              lg: "6",
                              xl: "6"
                            }
                          },
                          [
                            _c("b-form-timepicker", {
                              staticClass: "m-0",
                              attrs: {
                                locale: "ja",
                                "label-no-time-selected":
                                  "時刻を選択してください"
                              },
                              model: {
                                value: _vm.bind_data.bas_occur_time,
                                callback: function($$v) {
                                  _vm.$set(_vm.bind_data, "bas_occur_time", $$v)
                                },
                                expression: "bind_data.bas_occur_time"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("管理対象")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1756511699
                    )
                  },
                  [
                    _c("treeselect", {
                      attrs: {
                        multiple: true,
                        "disable-branch-nodes": true,
                        options: _vm.local_data.mng_name_options,
                        placeholder: "選択してください"
                      },
                      model: {
                        value: _vm.local_data.mng_uuid,
                        callback: function($$v) {
                          _vm.$set(_vm.local_data, "mng_uuid", $$v)
                        },
                        expression: "local_data.mng_uuid"
                      }
                    })
                  ],
                  1
                ),
                _c("FormEditUtils", {
                  attrs: {
                    form_info: _vm.bind_data.form_info,
                    form_data: _vm.bind_data.form_data
                  },
                  on: {
                    save: function($event) {
                      return _vm.handleFormEdit($event)
                    }
                  }
                }),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("図面")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      705054266
                    )
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2 mt-2 float-right",
                        attrs: { variant: "outline-secondary" },
                        on: {
                          click: function($event) {
                            return _vm.toggleViewBlueprint()
                          }
                        }
                      },
                      [
                        _vm._v("図面を表示 "),
                        _c("b-icon", {
                          attrs: {
                            icon: "caret-down-fill",
                            rotate: _vm.isViewBlueprint ? 180 : 0
                          }
                        })
                      ],
                      1
                    ),
                    _vm.isViewBlueprint
                      ? _c(
                          "div",
                          {
                            staticClass: "pt-1",
                            staticStyle: { clear: "both" }
                          },
                          [
                            _vm._l(_vm.local_data.drawImage, function(draw2) {
                              return [
                                _c(
                                  "b-card-group",
                                  { staticClass: "mt-3", attrs: { deck: "" } },
                                  [
                                    _vm._l(draw2, function(draw) {
                                      return [
                                        _c(
                                          "b-card",
                                          {
                                            staticClass: "text-center",
                                            attrs: { header: draw.name }
                                          },
                                          [
                                            draw.name != ""
                                              ? [
                                                  draw.image != ""
                                                    ? [
                                                        _c(
                                                          "b-card-text",
                                                          [
                                                            _c("b-img", {
                                                              staticClass:
                                                                "w-100",
                                                              attrs: {
                                                                src: draw.image
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    : [
                                                        _c("p", [
                                                          _vm._v(
                                                            "図面データが存在しません"
                                                          )
                                                        ])
                                                      ],
                                                  _c("hr"),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "float-right",
                                                      attrs: {
                                                        variant:
                                                          "outline-secondary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleConfirmDeleteBlueprint(
                                                            draw.file_name
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("図面削除")]
                                                  )
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      ]
                                    })
                                  ],
                                  2
                                )
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.hasDrawingRegistPerm
                      ? _c(
                          "div",
                          {
                            staticClass: "pt-2",
                            staticStyle: { clear: "both" }
                          },
                          [
                            _c("hr"),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "図面（画像）",
                                  "label-for": "add_blurprint_input",
                                  "label-cols": "2"
                                }
                              },
                              [
                                _vm.state_isSmartPhone
                                  ? [
                                      _c("p", [
                                        _vm._v("ファイルを選択してください。")
                                      ])
                                    ]
                                  : _vm._e(),
                                !_vm.state_isSmartPhone
                                  ? [
                                      _c("p", [
                                        _vm._v(
                                          "ファイルを選択するか、ファイルをドロップしてください。"
                                        )
                                      ])
                                    ]
                                  : _vm._e(),
                                _c("b-form-file", {
                                  ref: "add_blurprint_input",
                                  attrs: {
                                    id: "add_blurprint_input",
                                    placeholder: "ファイルを選択する。",
                                    state: _vm.state_data.addBlueprintFile,
                                    "drop-placeholder":
                                      "ここにファイルをドロップしてください。",
                                    "browse-text": "参照",
                                    accept: "image/jpeg, image/png"
                                  },
                                  model: {
                                    value: _vm.local_data.addBlueprintFile,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data,
                                        "addBlueprintFile",
                                        $$v
                                      )
                                    },
                                    expression: "local_data.addBlueprintFile"
                                  }
                                })
                              ],
                              2
                            ),
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: "図面名",
                                  "label-for": "add_blurprint_name",
                                  "label-cols": "2"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  ref: "add_blurprint_name",
                                  attrs: {
                                    id: "add_blurprint_name",
                                    placeholder: "図面名を入力してください。",
                                    state: _vm.state_data.addBlueprintName,
                                    maxlength: "50"
                                  },
                                  model: {
                                    value: _vm.local_data.addBlueprintName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data,
                                        "addBlueprintName",
                                        $$v
                                      )
                                    },
                                    expression: "local_data.addBlueprintName"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-2 ml-2 float-right",
                                attrs: { variant: "success" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleAddBlueprintFile()
                                  }
                                }
                              },
                              [_vm._v("追加")]
                            ),
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-2 float-right",
                                on: {
                                  click: function($event) {
                                    return _vm.handleClearBlueprintFile()
                                  }
                                }
                              },
                              [_vm._v("クリア")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.local_data.addBlueprintFiles.length > 0
                      ? _c(
                          "div",
                          {
                            staticClass: "pt-2",
                            staticStyle: { clear: "both" }
                          },
                          [
                            _c("hr"),
                            _vm._l(_vm.local_data.addBlueprintFiles, function(
                              fileInfo,
                              index
                            ) {
                              return _c(
                                "b-row",
                                { key: index },
                                [
                                  _c("b-col", { attrs: { cols: "1" } }),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left ml-1 mr-1 pb-1",
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid rgba( 0, 0, 0, 0.2 )",
                                        "padding-top": "15px"
                                      },
                                      attrs: { cols: "3" }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(fileInfo.name))
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left ml-1 mr-1 pb-1",
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid rgba( 0, 0, 0, 0.2 )",
                                        "padding-top": "15px"
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(fileInfo.file.name))
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right ml-1 pt-2 pb-1",
                                      staticStyle: {
                                        "border-bottom":
                                          "1px solid rgba( 0, 0, 0, 0.2 )"
                                      },
                                      attrs: { cols: "1" }
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          on: {
                                            click: function($event) {
                                              return _vm.handleRemoveBlueprintFile(
                                                fileInfo.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("削除")]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-col", { attrs: { cols: "1" } })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _vm.isViewTimeline
                  ? _vm._l(_vm.bind_data.fbs_info.form_timeline_list, function(
                      tml_item
                    ) {
                      return _c(
                        "div",
                        { key: tml_item.ftl_uuid, staticClass: "pt-2 mb-4" },
                        [
                          _c("div", { staticClass: "ml-2 font-weight-bold" }, [
                            _vm._v("タイムライン")
                          ]),
                          _c(
                            "b-card",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        xs: "12",
                                        sm: "12",
                                        md: "6",
                                        lg: "6",
                                        xl: "6"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-card",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            "header-bg-variant": "light"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "mb-0 font-weight-bold"
                                                      },
                                                      [_vm._v("日時")]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    xs: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6",
                                                    xl: "6"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-form-datepicker",
                                                    _vm._b(
                                                      {
                                                        staticClass: "m-0",
                                                        attrs: {
                                                          "label-no-date-selected":
                                                            "日付を選択",
                                                          locale:
                                                            _vm.datepicker
                                                              .locale,
                                                          "date-format-options": {
                                                            year: "numeric",
                                                            month: "2-digit",
                                                            day: "2-digit",
                                                            weekday: "short"
                                                          }
                                                        },
                                                        model: {
                                                          value:
                                                            tml_item.tml_rpt_date,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              tml_item,
                                                              "tml_rpt_date",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tml_item.tml_rpt_date"
                                                        }
                                                      },
                                                      "b-form-datepicker",
                                                      _vm.datepicker.labels[
                                                        _vm.datepicker.locale
                                                      ] || {},
                                                      false
                                                    )
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    xs: "12",
                                                    sm: "12",
                                                    md: "6",
                                                    lg: "6",
                                                    xl: "6"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-form-timepicker",
                                                    _vm._b(
                                                      {
                                                        staticClass: "m-0",
                                                        attrs: {
                                                          "label-no-time-selected":
                                                            "時刻を選択",
                                                          locale:
                                                            _vm.datepicker
                                                              .locale
                                                        },
                                                        model: {
                                                          value:
                                                            tml_item.tml_rpt_time,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              tml_item,
                                                              "tml_rpt_time",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "tml_item.tml_rpt_time"
                                                        }
                                                      },
                                                      "b-form-timepicker",
                                                      _vm.datepicker.labels[
                                                        _vm.datepicker.locale
                                                      ] || {},
                                                      false
                                                    )
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      attrs: {
                                        xs: "12",
                                        sm: "12",
                                        md: "6",
                                        lg: "6",
                                        xl: "6"
                                      }
                                    },
                                    [
                                      _c(
                                        "b-card",
                                        {
                                          staticClass: "mb-2",
                                          attrs: {
                                            "header-bg-variant": "light"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "header",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "b-badge",
                                                      {
                                                        staticClass:
                                                          "float-left mr-2",
                                                        attrs: {
                                                          pill: "",
                                                          variant: "danger"
                                                        }
                                                      },
                                                      [_vm._v("必須")]
                                                    ),
                                                    _c(
                                                      "h4",
                                                      {
                                                        staticClass:
                                                          "mb-0 font-weight-bold"
                                                      },
                                                      [_vm._v("報告者")]
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c("b-form-input", {
                                            staticClass: "m-0",
                                            attrs: {
                                              state:
                                                _vm.state_data
                                                  .tml_reporter_name,
                                              maxlength: "100"
                                            },
                                            model: {
                                              value: tml_item.tml_reporter_name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  tml_item,
                                                  "tml_reporter_name",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "tml_item.tml_reporter_name"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-card",
                                {
                                  staticClass: "mb-2",
                                  attrs: { "header-bg-variant": "light" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header",
                                        fn: function() {
                                          return [
                                            _c(
                                              "h4",
                                              {
                                                staticClass:
                                                  "mb-0 font-weight-bold"
                                              },
                                              [_vm._v("状況（説明）")]
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("b-form-textarea", {
                                    staticClass: "m-0",
                                    attrs: { maxlength: "500" },
                                    model: {
                                      value: tml_item.tml_status_explain,
                                      callback: function($$v) {
                                        _vm.$set(
                                          tml_item,
                                          "tml_status_explain",
                                          $$v
                                        )
                                      },
                                      expression: "tml_item.tml_status_explain"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.isShowAttacheFileList
                                ? [
                                    tml_item.tml_media_type === "image"
                                      ? _vm._l(tml_item.mediaList, function(
                                          media,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            { staticStyle: { clear: "both" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "float-right" },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "ml-2 mt-2",
                                                      attrs: {
                                                        variant:
                                                          "outline-danger"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleConfirmDeleteImageAndVideo(
                                                            tml_item,
                                                            index,
                                                            media.attachedIndex
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("画像を削除")]
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "ml-2 mt-2",
                                                      attrs: {
                                                        variant:
                                                          "outline-secondary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleToggleRotate(
                                                            media.fileUuid,
                                                            tml_item.tml_media_type,
                                                            media.dataUrl
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" 画像を表示 "),
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon:
                                                            "caret-down-fill",
                                                          rotate: _vm.rotate(
                                                            media.fileUuid
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.rotate(media.fileUuid) === 180
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        clear: "both"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            float: "right"
                                                          }
                                                        },
                                                        [
                                                          _c("b-img", {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              src:
                                                                _vm.local_data
                                                                  .imageSrc,
                                                              fluid: "",
                                                              "max-width": "800"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        })
                                      : _vm._e(),
                                    tml_item.tml_media_type === "video"
                                      ? _vm._l(tml_item.mediaList, function(
                                          media,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            { staticStyle: { clear: "both" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "float-right" },
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "ml-2 mt-2",
                                                      attrs: {
                                                        variant:
                                                          "outline-danger"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleConfirmDeleteImageAndVideo(
                                                            tml_item,
                                                            index,
                                                            media.attachedIndex
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("動画を削除")]
                                                  ),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "ml-2 mt-2",
                                                      attrs: {
                                                        variant:
                                                          "outline-secondary"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleToggleRotate(
                                                            media.fileUuid,
                                                            tml_item.tml_media_type,
                                                            media.dataUrl
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(" 動画を表示 "),
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon:
                                                            "caret-down-fill",
                                                          rotate: _vm.rotate(
                                                            media.fileUuid
                                                          )
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              ),
                                              _vm.rotate(media.fileUuid) === 180
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        clear: "both"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            float: "right"
                                                          }
                                                        },
                                                        [
                                                          _c("video-player", {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              options:
                                                                _vm.local_data
                                                                  .video_options
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        })
                                      : _vm._e()
                                  ]
                                : _vm._e(),
                              _vm.isFileAttachField
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "pt-1",
                                      staticStyle: { clear: "both" }
                                    },
                                    [
                                      _vm.attacheFileNum(tml_item) > 0
                                        ? _c("hr")
                                        : _vm._e(),
                                      _vm._l(
                                        tml_item.timelineAttachFileList,
                                        function(attacheFile, index) {
                                          return _c(
                                            "div",
                                            { staticStyle: { clear: "both" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mb-2",
                                                  staticStyle: {
                                                    float: "right"
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        attacheFile.fileName
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "ml-1",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleClearSelectedFile(
                                                            tml_item,
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("クリア")]
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        }
                                      ),
                                      _vm.isPossibleFileAttache(tml_item)
                                        ? [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "mt-2",
                                                staticStyle: { clear: "both" }
                                              },
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      "label-for":
                                                        tml_item.ftl_uuid
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "large"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.mediaAttacheLabel(
                                                                        tml_item
                                                                      ).label
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "small"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.mediaAttacheLabel(
                                                                        tml_item
                                                                      ).note
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  },
                                                  [
                                                    _vm.state_isSmartPhone
                                                      ? [
                                                          _c("p", [
                                                            _vm._v(
                                                              "ファイルを選択してください。"
                                                            )
                                                          ])
                                                        ]
                                                      : _vm._e(),
                                                    !_vm.state_isSmartPhone
                                                      ? [
                                                          _c("p", [
                                                            _vm._v(
                                                              "ファイルを選択するか、ファイルをドロップしてください。"
                                                            )
                                                          ])
                                                        ]
                                                      : _vm._e(),
                                                    _c("b-form-file", {
                                                      ref: tml_item.ftl_uuid,
                                                      refInFor: true,
                                                      attrs: {
                                                        id: tml_item.ftl_uuid,
                                                        placeholder:
                                                          "ファイルを選択する。",
                                                        "drop-placeholder":
                                                          "ここにファイルをドロップしてください。",
                                                        "browse-text": "参照",
                                                        accept: _vm.attacheFileAccept(
                                                          tml_item.tml_media_type
                                                        )
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleChangeSelectAttacheFile(
                                                            $event,
                                                            tml_item
                                                          )
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          tml_item.selectedFile,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            tml_item,
                                                            "selectedFile",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "tml_item.selectedFile"
                                                      }
                                                    })
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        : _vm._e()
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticStyle: { clear: "both" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { variant: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleConfirmDeleteTimeline(
                                            tml_item
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("削除")]
                                  )
                                ],
                                1
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    })
                  : _vm._e(),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mt-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "b-badge",
                                {
                                  staticClass: "float-left mr-2",
                                  attrs: { pill: "", variant: "danger" }
                                },
                                [_vm._v("必須")]
                              ),
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("編集理由")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1407258063
                    )
                  },
                  [
                    _c("b-form-textarea", {
                      staticClass: "m-0",
                      attrs: {
                        state: _vm.state_data.fbs_chg_reason,
                        maxlength: "500"
                      },
                      model: {
                        value: _vm.bind_data.fbs_info.fbs_chg_reason,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.bind_data.fbs_info,
                            "fbs_chg_reason",
                            $$v
                          )
                        },
                        expression: "bind_data.fbs_info.fbs_chg_reason"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "b-badge",
                                {
                                  staticClass: "float-left mr-2",
                                  attrs: { pill: "", variant: "danger" }
                                },
                                [_vm._v("必須")]
                              ),
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("報告者")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2450696840
                    )
                  },
                  [
                    _c("b-form-input", {
                      staticClass: "m-0",
                      attrs: {
                        state: _vm.state_data.fbs_reporter,
                        maxlength: "100"
                      },
                      model: {
                        value: _vm.bind_data.fbs_info.fbs_reporter,
                        callback: function($$v) {
                          _vm.$set(_vm.bind_data.fbs_info, "fbs_reporter", $$v)
                        },
                        expression: "bind_data.fbs_info.fbs_reporter"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("共有先")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1083516091
                    )
                  },
                  [
                    _vm.state_data.treeselectdialogs
                      ? [
                          _c("treeselectdialogs", {
                            attrs: {
                              dpt_selected_array: _vm.bind_data.dpt_selected,
                              multiple: true,
                              branch_nodes: false
                            },
                            on: {
                              save: function($event) {
                                return _vm.get_dpt_info($event)
                              }
                            }
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "mx-2 mb-4" },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("div", { staticClass: "text-right" }, [
                            _vm._v(
                              "最終更新者：" +
                                _vm._s(
                                  _vm.bind_data.fbs_info.fbs_upd_usr_uuid_name
                                )
                            )
                          ])
                        ]),
                        _c("b-col", [
                          _c("div", [
                            _vm._v(
                              "最終更新日時：" +
                                _vm._s(_vm.bind_data.fbs_info.fbs_upd_date_time)
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.bind_data.fbs_info.fbs_uuid &&
                _vm.bind_data.fbs_info.fbs_form_comp_type !== "initial" &&
                _vm.hasConsultPerm
                  ? [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "mx-2",
                          attrs: {
                            value: "accepted",
                            "unchecked-value": "not_accepted"
                          },
                          model: {
                            value: _vm.local_data.status,
                            callback: function($$v) {
                              _vm.$set(_vm.local_data, "status", $$v)
                            },
                            expression: "local_data.status"
                          }
                        },
                        [_vm._v("相談相手を選択する")]
                      )
                    ]
                  : _vm._e(),
                _vm.local_data.status === "accepted"
                  ? _c(
                      "b-card",
                      {
                        staticClass: "mx-2 mb-4 p-0",
                        attrs: { "header-bg-variant": "light" }
                      },
                      [
                        _c(
                          "b-form-group",
                          { staticClass: "mb-0" },
                          [
                            _c(
                              "b-form-radio-group",
                              {
                                staticClass: "consult_list mb-3",
                                model: {
                                  value:
                                    _vm.bind_data.fbs_info.fbs_consult_usr_uuid,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bind_data.fbs_info,
                                      "fbs_consult_usr_uuid",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "bind_data.fbs_info.fbs_consult_usr_uuid"
                                }
                              },
                              _vm._l(_vm.local_data.person_options, function(
                                user
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: user.value,
                                    staticClass: "ml-2 mb-2 text-left"
                                  },
                                  [
                                    _c(
                                      "b-form-radio",
                                      { attrs: { value: user.value } },
                                      [_vm._v(_vm._s(user.text))]
                                    ),
                                    _c("div", { staticClass: "small ml-4" }, [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(user.org) +
                                            " " +
                                            _vm._s(user.dept)
                                        )
                                      ])
                                    ])
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _vm._t("label", [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "float-left mr-2",
                                          staticStyle: { "margin-top": "2px" },
                                          attrs: { pill: "", variant: "danger" }
                                        },
                                        [_vm._v("必須")]
                                      ),
                                      _c("span", [_vm._v("相談内容")])
                                    ],
                                    1
                                  )
                                ]),
                                _c("b-form-textarea", {
                                  staticClass: "consult_content",
                                  attrs: {
                                    rows: "4",
                                    "max-rows": "8",
                                    maxlength: "500"
                                  },
                                  model: {
                                    value: _vm.bind_data.consult_content,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.bind_data,
                                        "consult_content",
                                        $$v
                                      )
                                    },
                                    expression: "bind_data.consult_content"
                                  }
                                })
                              ],
                              2
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.local_data.status !== "accepted"
                  ? _c(
                      "div",
                      { staticClass: "text-right mx-2" },
                      [
                        _vm.bind_data.fbs_info.fbs_uuid &&
                        _vm.bind_data.fbs_info.fbs_form_comp_type !== "initial"
                          ? [
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-3",
                                  attrs: { variant: "outline-secondary" },
                                  on: { click: _vm.save_rewrite_report }
                                },
                                [_vm._v("上書き保存")]
                              )
                            ]
                          : _vm._e(),
                        _c(
                          "b-button",
                          {
                            staticClass: "ml-3",
                            attrs: { variant: "outline-secondary" },
                            on: { click: _vm.save_named_report }
                          },
                          [_vm._v("名前を付けて保存")]
                        ),
                        _vm.bind_data.fbs_info.fbs_uuid &&
                        _vm.bind_data.fbs_info.fbs_form_comp_type !== "initial"
                          ? [
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-3",
                                  attrs: { variant: "outline-secondary" },
                                  on: { click: _vm.save_confirmed_report }
                                },
                                [_vm._v("編集内容を確定する")]
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e(),
                _vm.local_data.status === "accepted"
                  ? _c(
                      "div",
                      { staticClass: "text-right mx-2" },
                      [
                        _vm.bind_data.fbs_info.fbs_uuid &&
                        _vm.bind_data.fbs_info.fbs_form_comp_type !== "initial"
                          ? [
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-3",
                                  attrs: { variant: "outline-secondary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.save_consult_confirm_report()
                                    }
                                  }
                                },
                                [_vm._v("相談して保存")]
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  : _vm._e()
              ],
              2
            ),
            _c(
              "b-modal",
              {
                ref: "named_report",
                attrs: { title: "名前を付けて保存" },
                on: {
                  ok: function($event) {
                    return _vm.handleSaveAs()
                  }
                }
              },
              [
                _vm._v("名称を入力してください "),
                _c("b-form-input", {
                  staticClass: "m-0",
                  attrs: { required: "", maxlength: "100" },
                  model: {
                    value: _vm.bind_data.fbs_info.fbs_report_title,
                    callback: function($$v) {
                      _vm.$set(_vm.bind_data.fbs_info, "fbs_report_title", $$v)
                    },
                    expression: "bind_data.fbs_info.fbs_report_title"
                  }
                })
              ],
              1
            ),
            _c(
              "b-modal",
              {
                ref: "rewrite_report",
                attrs: { title: "上書き保存" },
                on: {
                  ok: function($event) {
                    return _vm.handleOverWrite()
                  }
                }
              },
              [_vm._v("上書きされて保存されます。よろしいですか？")]
            ),
            _c(
              "b-modal",
              {
                ref: "consult_confirm_report",
                attrs: { title: "相談して保存" },
                on: {
                  ok: function($event) {
                    return _vm.handleSaveAsConsultConfirm()
                  }
                }
              },
              [_vm._v("相談して保存されます。よろしいですか？")]
            ),
            _c(
              "b-modal",
              {
                ref: "confirmed_report",
                attrs: { title: "確認済みにする" },
                on: {
                  ok: function($event) {
                    return _vm.handleSaveConfirmedReport()
                  }
                }
              },
              [_vm._v("確認済みにします。よろしいですか？")]
            ),
            _c(
              "b-modal",
              {
                ref: "deleteTimeline",
                attrs: { title: "タイムライン削除" },
                on: {
                  ok: function($event) {
                    return _vm.handleDeleteTimeline()
                  }
                }
              },
              [_vm._v("選択したタイムラインを削除します。よろしいですか？")]
            ),
            _c(
              "b-modal",
              {
                ref: "deletePicture",
                attrs: { title: "画像削除" },
                on: {
                  ok: function($event) {
                    return _vm.handleDeleteImageAndVideo()
                  }
                }
              },
              [_vm._v("選択した画像を削除します。よろしいですか？")]
            ),
            _c(
              "b-modal",
              {
                ref: "deleteMovie",
                attrs: { title: "動画削除" },
                on: {
                  ok: function($event) {
                    return _vm.handleDeleteImageAndVideo()
                  }
                }
              },
              [_vm._v("選択した動画を削除します。よろしいですか？")]
            ),
            _c(
              "b-modal",
              {
                ref: "deleteBlueprint",
                attrs: { title: "図面削除" },
                on: {
                  ok: function($event) {
                    return _vm.handleDeleteBlueprint()
                  }
                }
              },
              [_vm._v("選択した図面を削除します。よろしいですか？")]
            )
          ]
        : _vm._e(),
      _c("canvas", { ref: "canvas", staticStyle: { display: "none" } })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }