// ----------------------------------------------------------------
// 共通関数 
// CommonDataSet.js Ver 1.0.1
// 共通的な情報をAPIから取得し、ストアする
// ----------------------------------------------------------------
import axios from 'axios';

export default {

  methods: {

    //
    // システムパラメータを取得しグローバル変数に格納
    //
    async set_system_info(){

      let url = process.env.VUE_APP_RESTAPI_GET_SYSTEM_PARM_LIST+"?sid="+this.$store.state.sid;
      console.log(this.$route.path+":regist:url="+url);

      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));

        if( response.data.result == "OK" ){

          // システム情報をグローバル変数に格納
          let info = response.data;
          delete info.result;
          delete info.message;
          this.$store.dispatch("commit_set_system_info",info);

          return true;
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
          return false;
        }
  
      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        return false;
      }
    },

    //
    // 区分情報を取得しグローバル変数に格納
    //
    async set_class_list(){

      let url = process.env.VUE_APP_RESTAPI_GET_CLASS_LIST+"?sid="+this.$store.state.sid;
      console.log(this.$route.path+":regist:url="+url);

      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));

        if( response.data.result == "OK" ){

          // システム情報をグローバル変数に格納
          let info = response.data.classes_list;
          delete info.result;
          delete info.message;
          this.$store.dispatch("commit_set_class_list",info);

          return true;
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
          return false;
        }
  
      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        return false;
      }
    },
    async get_class_list(){
		if ( this.$store.state.class_list == null ) {
			await this.set_class_list();
		}

      let list = [];
      for( let i=0;i<this.$store.state.class_list.length;i++ ){
        let push_flag = true;
        for( let j=0;j<list.length;j++ ){
          if( this.$store.state.class_list[i].cls_class_key == list[j].cls_class_key ){
            push_flag = false;
          }
        }
        if( this.$store.state.class_list[i].cls_name && push_flag ){
          list.push(this.$store.state.class_list[i]);
        }
      }
      return list;
    },
    async get_item_list( class_key ){
		if ( this.$store.state.class_list == null ) {
			await this.set_class_list();
		}

      let list = [];
      for( let i=0;i<this.$store.state.class_list.length;i++ ){
        if( this.$store.state.class_list[i].cls_class_key == class_key ){
          list.push(this.$store.state.class_list[i]);
        }
      }
      return list;
    },
    async get_item_name( class_key, item_key ){
		if ( this.$store.state.class_list == null ) {
			await this.set_class_list();
		}

      for( let i=0;i<this.$store.state.class_list.length;i++ ){
        //console.log("class_list="+this.$store.state.class_list[i].cls_class_key+":"+this.$store.state.class_list[i].itm_item_key)
        if( this.$store.state.class_list[i].cls_class_key == class_key && this.$store.state.class_list[i].itm_item_key == item_key ){
          return this.$store.state.class_list[i].itm_name;
        }
      }
      return "";
    },

    //
    // 組織情報を取得し、localstorageに格納
    //
    async set_org_map(get_type,cmp_uuid,mng_uuid){

      let url = process.env.VUE_APP_RESTAPI_GET_ORG_MAP+"?sid="+this.$store.state.sid;
      if(get_type != null || get_type == "") {
        url = url+"&get_type="+get_type;
      }
      if(cmp_uuid != null || cmp_uuid == "") {
        url = url+"&cmp_uuid="+cmp_uuid;
      }
      if(mng_uuid != null || mng_uuid == "") {
        url = url+"&mng_uuid="+mng_uuid;
      }
      console.log(this.$route.path+":regist:url="+url);

      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response.data));

        if( response.data.result == "OK" ){

          let organization_info_json = JSON.stringify(response.data.organization_list);
          localStorage.setItem('organization_info', organization_info_json);

          return true;
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
          return false;
        }
  
      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        return false;
      }
    },

    //
    // 組織情報を取得し、localstorageに格納２
    //
    async set_org_map2(get_type,cmp_uuid,mng_uuid){

      let url = process.env.VUE_APP_RESTAPI_GET_ORG_MAP+"?sid="+this.$store.state.sid;
      if(get_type != null || get_type == "") {
        url = url+"&get_type="+get_type;
      }
      if(cmp_uuid != null || cmp_uuid == "") {
        url = url+"&cmp_uuid="+cmp_uuid;
      }
      if(mng_uuid != null || mng_uuid == "") {
        url = url+"&mng_uuid="+mng_uuid;
      }
      console.log(this.$route.path+":regist:url="+url);

      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response.data));

        if( response.data.result == "OK" ){

          let organization_info_json = JSON.stringify(response.data.organization_list);
          localStorage.setItem('organization_info2', organization_info_json);

          return true;
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
          return false;
        }
  
      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        return false;
      }
    },
  }
}