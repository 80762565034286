import moment from 'moment-timezone';
import validator from 'validator';

export default {
  name: "FormEditUtils",
  props: {
    form_info: {
      type: Object,
      default() {
        return {};
      }
    },
    form_data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      bind_data: {
        form_info: null,
        form_data: null,
        output_data: null,
      },
      state_data: {
      }
    }
  },
  mounted: function() {
    this.bind_data.form_info = this.form_info;
    this.bind_data.form_data = this.form_data;
    for( let i=0;i<this.bind_data.form_info.edit_item_data.length;i++ ){
      this.bind_data.form_info.edit_item_data[i].value = ["","","",""];  // <-- ここに入力されるデータを格納する
      this.bind_data.form_info.edit_item_data[i].state_info = null;  // <-- ここに入力されるデータを格納する
      if( this.bind_data.form_info.edit_item_data[i].edit_item_type == "now" ){
        this.bind_data.form_info.edit_item_data[i].value[0] = moment().tz("Azia/Tokyo").format("YYYY-MM-DD");
        this.bind_data.form_info.edit_item_data[i].value[1] = moment().tz("Azia/Tokyo").format("HH:mm");
      } else if( this.bind_data.form_info.edit_item_data[i].edit_item_type == "datetime" ){
        let datetime = this.get_content_text(this.bind_data.form_info.edit_item_data[i].edit_item_no).split(" ");
        this.bind_data.form_info.edit_item_data[i].value[0] = datetime[0];
        this.bind_data.form_info.edit_item_data[i].value[1] = datetime[1];
      } else {
        this.bind_data.form_info.edit_item_data[i].value[0] = this.get_content_text(this.bind_data.form_info.edit_item_data[i].edit_item_no);
      }
    }
  },
  methods: {
    get_refs( item ){
      return "item"+item.edit_item_no;
    },

    get_numeric_placeholder( item ){
      return "単位："+item.edit_item_option.unit
      +"（"
      +"上限値："+item.edit_item_option.upper_limit
      +"　下限値："+item.edit_item_option.lower_limit
      +"）";
    },

    get_text_placeholder( item ){
      return "最大"+String(item.edit_item_option.max_length)+"文字";
    },

    input_form_data(){

      this.bind_data.output_data = {};
      this.bind_data.output_data.version = this.bind_data.form_info.version;
      this.bind_data.output_data.form_name = this.bind_data.form_info.edit_form_name;
      this.bind_data.output_data.item_data = [];
      for( let i=0;i<this.bind_data.form_info.edit_item_data.length;i++ ){
        let item_data = {};
        item_data.item_no = this.bind_data.form_info.edit_item_data[i].edit_item_no;
        item_data.item_type = this.bind_data.form_info.edit_item_data[i].edit_item_type;

        if( item_data.item_type == "text" || item_data.item_type == "texts" || item_data.item_type == "date" ||
          item_data.item_type == "time" || item_data.item_type == "radio" || item_data.item_type == "pulldown" ||
          item_data.item_type == "toggle" || item_data.item_type == "mail_address" || item_data.item_type == "numeric" ){
          item_data.item_content = this.bind_data.form_info.edit_item_data[i].value[0];
        } else if( item_data.item_type == "datetime" ){
          if( this.bind_data.form_info.edit_item_data[i].value[0] != "" && this.bind_data.form_info.edit_item_data[i].value[1] != "" ){
            item_data.item_content = this.bind_data.form_info.edit_item_data[i].value[0]+" "+this.bind_data.form_info.edit_item_data[i].value[1];
          } else {
            item_data.item_content = "";
          }
        } else {
          item_data.item_content = "";
        }

        // 必須チェック
        if( this.bind_data.form_info.edit_item_data[i].edit_item_must == "on" ){
          if( item_data.item_content === "" ){
            this.bind_data.form_info.edit_item_data[i].state_info = false;
          } else {
            this.bind_data.form_info.edit_item_data[i].state_info = true;
          }
        }

        //数値の範囲チェック
        if( item_data.item_type == "numeric" ){
          if( this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit !== undefined 
              && this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit != "" 
              && validator.isNumeric(this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit) 
              && item_data.item_content != "" ){
                if( Number(item_data.item_content) > Number(this.bind_data.form_info.edit_item_data[i].edit_item_option.upper_limit) ){
                  this.bind_data.form_info.edit_item_data[i].state_info = false;
                }
          }
          if( this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit !== undefined 
              && this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit != "" 
              && validator.isNumeric(this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit) 
              && item_data.item_content != "" ){
                if( Number(item_data.item_content) < Number(this.bind_data.form_info.edit_item_data[i].edit_item_option.lower_limit) ){
                  this.bind_data.form_info.edit_item_data[i].state_info = false;
                }
          }
        }

        //メールアドレスチェック
        if( item_data.item_type == "mail_address" ){
          if( item_data.item_content != "" ){
            if( !validator.isEmail(item_data.item_content) ){
              this.bind_data.form_info.edit_item_data[i].state_info = false;
            }
          }
        }

        this.bind_data.output_data.item_data.push(item_data);
      }

      this.state_data.form_info = false;
      this.$emit("save",this.bind_data.output_data);
      this.$nextTick( function() {
        this.state_data.form_info = true;
      });
      
    },

    is_edit_item_type( item, types ){
      let type = types.split(",");
      let item_type = item.edit_item_type;
      for( let i=0;i<type.length;i++ ){
        if( item_type == type[i] ){
          return true;
        }
      }
      return false;
    },

    get_content_text( item_no ){
      for( let i=0;i<this.bind_data.form_data.item_data.length;i++ ){
        if( this.bind_data.form_data.item_data[i].item_no == item_no ){
          return this.bind_data.form_data.item_data[i].item_content;
        }
      }
      return item_no;
    },
  }
}
