import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import EventHub from './lib/EventHub'
import VueCookies from 'vue-cookies'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome'
import Treeselect from '@riophae/vue-treeselect'
//import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import uuid from 'vue-uuid';

import axios from 'axios';

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(EventHub)
Vue.use(VueCookies)
Vue.use(uuid);
Vue.component('treeselect', Treeselect)

library.add(fas,far)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

Vue.config.productionTip = false

Vue.prototype.$http = axios;

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
