import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';

export default {
	name: 'FormManage', // ココを更新
	components: {
		Loading,
	},

	// 共通関数
	mixins: [common,CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},

			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			
			// バインドデータ
			bind_data: {
				row_data: [],
			},
			
			// ローカルデータ
			local_data: {
				param_list: [],
			},
			
			// 状態データ
			state_data: {
				template_table: false,
			}
		}
	},

	// 既定計算
	computed: {
		/**
		 * 値チェック
		 * 
		 * @returns 
		 */
		checkValue() {
			return ( item ) => {
				if ( item.prm_value < 1 || item.prm_value > 999 ) {
					item.state = false;
				} else {
					item.state = true;
				}
	
				return item.state;
			}
		},

		/**
		 * フォーム入力チェック
		 * 
		 * @returns 
		 */
		isFormInputError() {
			for( const elem of this.bind_data.row_data ) {
				if ( elem.state == false ) {
					return true;
				}
			}

			return false;
		},
	},

	// インスタンス初期化後
	async created(){
		// タイトル更新イベント
		this.$eventHub.$emit( "navbar_title", "リンク有効期限設定" );

		this.state_data.template_table = false;
	},

	// インスタンス破棄後
	destroyed: function() {},

	// インスタンスマウント後
	async mounted(){
		console.log(this.$route.path+".vue Start");

		// データの読み込み
		this.local_data.param_list = await this.loadData();

		// リスト生成
		this.make_list();

		this.state_data.template_table = true;
	},

	// ローカル関数
	methods: {
		/**
		 * 数値フォーマッター
		 * 
		 * @param {*} value 
		 * @returns 
		 */
		numberFormatter: function( value ) {
			console.log( value );

			// 数字以外除去、先頭0づめを除去
			value = value.replaceAll( /[^0-9]/g, '' ).replaceAll( /^0*/g, '' );

			// 桁数チェック
			if ( value.length > 3 ) {
				// 3桁を超えたら3桁までにする

				value = value.substring( 0, 3 );
			}

			return value;
		},

		/**
		 * 読み込み
		 */
		loadData: async function() {
			// ローディング開始
			this.loader.is_loading = true;

			let url = process.env.VUE_APP_RESTAPI_GET_SHARED_URL_TIMELIMIT + "?sid=" + this.$store.state.sid;
			let paramList = [];

			try {
				let response = await axios.get( url );

				console.log( this.$route.path + ":response=" + JSON.stringify( response ) );

				if ( response.data.result == "OK" ) {
					paramList = response.data.param_list;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				// ローディング終了
				this.loader.is_loading = false;
			} catch ( ex ) {
				console.log( this.$route.path + ":ex=" + JSON.stringify( ex ) );
				
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( ex );
				
				// ローディング終了
				this.loader.is_loading = false;
			}

			console.log( this.$route.path + ":param_list=" + JSON.stringify( paramList ) );

			return paramList;
		},

		/**
		 * 表の生成
		 */
		make_list: function() {
			for( const elem of this.local_data.param_list ) {
				let prmName = elem.prm_name.split( ':' );
				let paramInfo = {
					'index': prmName[0],
					'prm_key': elem.prm_key,
					'prm_name': prmName[1],
					'prm_value': elem.prm_value,
					'state': true,
				}

				this.bind_data.row_data.push( paramInfo );
			}
		},

		update_confirm(){
			this.$refs['confirm-modal'].show();
		},

		update_cancel(){
			this.$refs['confirm-modal'].hide();
		},

		async update(){
			// ローディング開始
			this.loader.is_loading = true;

			let url = process.env.VUE_APP_RESTAPI_SET_SHARED_URL_TIMELIMIT
			let post_data = {
				'sid': this.$store.state.sid,
			};

			for ( const elem of this.bind_data.row_data ) {
				console.log( elem );

				let paramKey = elem.prm_key.replaceAll( 'sid_time_limit_', '' );

				post_data[paramKey] = elem.prm_value;
			}

			post_data.sid = this.$store.state.sid;

			console.log( post_data );

			try {
				let response = await axios.put( url, post_data );
				
				console.log( this.$route.path + ":add_response=" + JSON.stringify( response ) );

				if ( response.data.result == "OK" ) {
					//ローディング終了

					this.loader.is_loading = false;

					this.$bvToast.toast( '有効期限を更新しました。', {
						title: "更新完了",
						autoHideDelay: 3000,
						appendToast: true,
						variant: "success",
						solid: true
					} );
				} else {
					// ローディング終了
					
					this.loader.is_loading = false;
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch( ex ) {
				// ローディング終了

				this.loader.is_loading = false;

				console.log( this.$route.path + ":ex=" + JSON.stringify( ex ) );

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( ex );
			} finally {
				this.$refs['confirm-modal'].hide();
			}
		},
	}
}