var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container" },
    [
      _vm.bind_data.form_info && _vm.bind_data.form_data
        ? _vm._l(_vm.bind_data.form_info.edit_item_data, function(item) {
            return _c(
              "div",
              { key: item.edit_item_no, staticClass: "mb-4 text-left" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v(_vm._s(item.edit_item_name))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm.is_edit_item_type(
                      item,
                      "text,texts,name,numeric,date,time,datetime,now,mail_address,radio,pulldown,toggle"
                    )
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(_vm.get_content_text(item.edit_item_no)) +
                              " "
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }