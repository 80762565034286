// ----------------------------------------------------------------
// イメージ共通関数 
// common.js Ver 1.0.1
// ----------------------------------------------------------------
export default {
    methods: {
      /**
       * イメージをFullHD(1920px)にリサイズする
       * 長い方を1920pxにし、縦横比固定でリサイズする
       * 1920pxより小さい場合は、サイズ変更は行わない
       */
      async resizeImage(imageAsDataUrl) {
        const self = this;
        return new Promise((resolve) => {
            const image = new Image();
            image.onload = () => resolve(image);
            image.src = imageAsDataUrl;
        })
        .then((image) => {
            const canvas = self.$refs.canvas;
            const ctx = canvas.getContext('2d');
            const maxSize = parseInt(process.env.VUE_APP_PICTURE_MAX_WIDTH);

            if (image.width < maxSize && image.height < maxSize) {
                [canvas.width, canvas.height] = [image.width, image.height];
                ctx.drawImage(image, 0, 0);
            }
            else {
                let distWidth;
                let distHeight;
                if (image.width > image.height) {
                    distWidth = maxSize;
                    distHeight = (image.height * maxSize) / image.width;
                }
                else {
                    distHeight = maxSize;
                    distWidth = (image.width * maxSize) / image.height;
                }
                canvas.width = distWidth;
                canvas.height = distHeight;

                ctx.clearRect(0, 0, distWidth, distHeight);
                ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, distWidth, distHeight);
            }

            const base64 = canvas.toDataURL('image/jpeg');

            const blob = self.base64ToBlobImage(base64, 'image/jpeg');

            return Promise.resolve(blob);
        });
      },
      /**
       * イメージファイルをBase64で読み込む
       */
      readImageAsBase64(picture) {
        if (!picture) {
            return;
        }

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = e => resolve(e.target.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(picture);
        })
      },
        /**
         * base64形式のドキュメントをBlobに変換する
         * @param contentBase64 base64形式のドキュメント
         */
        base64ToBlobImage(contentBase64, mime) {
            const bin = atob(contentBase64.replace(/^.*,/, ''));
            const buffer = new Uint8Array(bin.length);

            for (let i=0 ; i<bin.length ; i++) {
            buffer[i] = bin.charCodeAt(i);
            }

            let blob;
            try {
                const options = {};
                if (mime) {
                    options.type = mime;
                }
                blob = new Blob([buffer.buffer], options);
            }
            catch(e) {
                return null;
            }

            return blob;
        },
  },
}
