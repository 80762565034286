var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "home" } },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _vm.$store.state.startup
        ? [
            _c("nabvar", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.bind_data.navbar_show,
                  expression: "bind_data.navbar_show"
                }
              ],
              ref: "nabvar_method",
              staticClass: "header_fixed"
            })
          ]
        : _vm._e(),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        {
          staticClass: "container-fulid content_top pt-1",
          class: { content_top: _vm.$store.state.startup }
        },
        [
          _c("router-view"),
          _c(
            "b-sidebar",
            {
              attrs: {
                id: "sidebar-menu",
                title: "MENU",
                shadow: "",
                backdrop: "",
                "bg-variant": "dark",
                "text-variant": "light"
              }
            },
            [_c("div", [_c("SidebarMenu")], 1)]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                },
                {
                  name: "b-toggle",
                  rawName: "v-b-toggle.sidebar-menu",
                  modifiers: { "sidebar-menu": true }
                }
              ],
              ref: "sidebar_menu_show_button"
            },
            [_vm._v("メニュー")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }