var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "container" }, [
    _c("video", {
      ref: "videoPlayer",
      staticClass: "video-js",
      attrs: { preload: "auto", width: "640", height: "380" }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }