import FormDataUtils from '@/components/FormDataUtils/FormDataUtils.vue';
import axios from 'axios';
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
import 'video.js/dist/video-js.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import image from '@/lib/image.js';
import { mapState } from 'vuex';

export default {
  name: "BaseDataUtils",

  components: {
    FormDataUtils,
    VideoPlayer,
    Loading,
  },

  mixins: [
    image,
  ],

  props: {
    bas_info: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      bind_data: {
        bas_info: null,
        form_info: null,
        form_data: null,
      },
      state_data: {
        bas_info: false,
		isView: false,
      },
      local_data: {
        drawRotate: 0,
        drawImage: [],
        toggleRotate: null,
        rotate: 0,
        imageSrc: null,
        video_options: {
          width: 640, // 幅
          height: 360, // 高さ
          autoplay: true,
          controls: true,
          sources: [
            {
              src: "",
              type: "video/mp4"
            }
          ]
        },
      }
    }
  },
  watch: {
    'bind_data.bas_info': function(){
      if( this.bind_data.bas_info != null ){
        this.state_data.bas_info = true;
      } 
    },
  },

  computed: {
	...mapState( [
		'system_info',
	] ),

	timelinePictureCount() {
		return this.system_info.timeline_picture_count;
	},

	timelineMovieCount() {
		return this.system_info.timeline_movie_count;
	},

    statusExplains: function () {
      return function(orgStatusExplain) {
        return orgStatusExplain.split('\n');
      }
    },

	rotate: function() {
		return function( fileUuid ) {
			if ( this.local_data.toggleRotate === fileUuid ) {
				return this.local_data.rotate;
			} else {
				return 0;
			}
		}
	},

    _rotate: function() {
      return function(tml) {
        if (this.local_data.toggleRotate === tml.tml_uuid) {
          return this.local_data.rotate;
        }
        else {
          return 0;
        }  
      }
    },
  },

  async created() {
  },

  async mounted() {
	this.loader.is_loading = true;
	this.state_data.isView = false;

    this.bind_data.bas_info = this.bas_info;
    this.bind_data.form_info = this.$store.state.form_info;
    this.bind_data.form_data = JSON.parse(this.bas_info.bas_element);

	for ( let i = 0; i < this.bind_data.bas_info.timeline_list.length; i++ ) {
		const tml = this.bind_data.bas_info.timeline_list[i];
		const tmlRptDatetime = tml.tml_rpt_date_time.split( ' ' );

		tml.tml_rpt_date = tmlRptDatetime[0];
		tml.tml_rpt_time = tmlRptDatetime[1];
		tml.selectedFile = null;
		tml.orgIdx = i;

		let tmlSaveIdJson = JSON.parse( tml.tml_save_id );
		console.log( tmlSaveIdJson );

		let mediaList = Array();

		// 複数添付未対応への対応
		if ( tml.tml_web_view_url && !tml.tml_web_view_url.startsWith( '{' ) ) {
			// 複数添付未対応のバージョンで登録されたデータの場合

			console.log( tml.tml_web_view_url );

			let dataUrl = await this.getS3Media( tml.tml_web_view_url );
			let media = {
				fileUuid: this.getFileUuid( tml.tml_web_view_url ),
				blob: null,
				dataUrl: dataUrl,
				width: '',
				height: '',
				isEditMode: false,
				isAttached: true,
				attachedIndex: 0,
			};

			mediaList.push( media );
	} else {
			let webViewUrlInfo = JSON.parse( tml.tml_web_view_url );

			if ( webViewUrlInfo && webViewUrlInfo.url_list ) {
				for ( let idx in webViewUrlInfo.url_list ) {
					let webViewUrl = webViewUrlInfo.url_list[idx];
					let dataUrl = await this.getS3Media( webViewUrl );
					let media = {
						fileUuid: this.getFileUuid( webViewUrl ),
						blob: null,
						dataUrl: dataUrl,
						width: '',
						height: '',
						isEditMode: false,
						isAttached: true,
						attachedIndex: idx,
					};
	
					mediaList.push( media );
				}
			}
		}

		tml.mediaList = mediaList;
		this.bind_data.bas_info.timeline_list[i] = tml;
	}

	this.state_data.isView = true;
	this.loader.is_loading = false;
},

  // ローカル関数
  methods: {
	getFileUuid: function( webViewUrl ) {
		const urls = webViewUrl.split( '?' );
		const params = urls[1].split( '&' );
		const fileName = params.find( p => p.substr( 0, 10 ) === 'file_name=' );

		return fileName.replace( /^.*=/, '' ).replace( /\..*$/, '' );
	},

	getS3Media: async function( webViewUrl ) {
		try {
			console.log( "process.env.VUE_APP_GET_LIMIT_URL = " + process.env.VUE_APP_GET_LIMIT_URL );
			console.log( "process.env.VUE_APP_API_KEY = " + process.env.VUE_APP_API_KEY );

			const urls = webViewUrl.split( '?' );
			const params = urls[1].split( '&' );
			const basUuid = params.find( p => p.substr( 0, 9 ) === 'bas_uuid=' );
			const tmlUuid = params.find( p => p.substr( 0, 9 ) === 'tml_uuid=' );
			const fileName = params.find( p => p.substr( 0, 10 ) === 'file_name=' );
			const url = `${process.env.VUE_APP_GET_LIMIT_URL}?sid=${this.$store.state.sid}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
			const config = {
				headers: {
					'x-api-key': process.env.VUE_APP_API_KEY,
				},
			};
			
			const res = await this.$http.get( url, config );

			if (res.data.result === 'OK') {
				return res.data.url
			} else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;

				return "";
			}
		} catch(e) {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = "エラーが発生しました。" + JSON.stringify( e );

			return "";
		}
	},

	async handleToggleRotate( ftlUuid, tmlMediaType, dataUrl ) {
		if ( this.local_data.toggleRotate === ftlUuid ) {
			this.local_data.rotate = ( this.local_data.rotate === 0 ) ? 180 : 0;
		} else {
			if ( tmlMediaType === 'image' ) {
				this.local_data.imageSrc = dataUrl;
			} else if ( tmlMediaType === 'video' ) {
				this.local_data.video_options.sources[0].src = dataUrl;
			}

			this.local_data.toggleRotate = ftlUuid;
			this.local_data.rotate = 180;
		}
	},

    /**
     * タイムラインの画像、動画を取得する
     * @param {*} tml 
     */
    async _handleToggleRotate(tml) {
      if (this.local_data.toggleRotate === tml.tml_uuid) {
        this.local_data.rotate = (this.local_data.rotate === 0) ? 180 : 0;
      }
      else {
        this.loader.is_loading = true;
        await this.$nextTick();

        const urls = tml.tml_web_view_url.split('?');
        const params = urls[1].split('&');
        const basUuid = params.find(p => p.substr(0, 9) === 'bas_uuid=');
        const tmlUuid = params.find(p => p.substr(0, 9) === 'tml_uuid=');
        const fileName = params.find(p => p.substr(0, 10) === 'file_name=');

        const url = `${process.env.VUE_APP_GET_LIMIT_URL}?sid=${this.$store.state.sid}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
        const config = {
          headers: {
            'x-api-key': process.env.VUE_APP_API_KEY,
          },
        };

        try {
          const res = await axios.get(url, config);
          if (res.data.result === 'OK') {
            if (tml.tml_media_type === 'image') {
              this.local_data.imageSrc = res.data.url;
            }
            else if (tml.tml_media_type === 'video') {
              this.local_data.video_options.sources[0].src = res.data.url;
            }
            this.local_data.toggleRotate = tml.tml_uuid;
            this.local_data.rotate = 180;
          }
          else {
            this.message.is_alert = true;
            this.message.variant = "danger";
            this.message.message = res.data.message;  
          }
        }
        catch(e) {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = "エラーが発生しました。"+JSON.stringify(e);
        }
        this.loader.is_loading = false;
      }
    },

    /**
     * 図面を表示する
     * @returns 
     */
    async handleToggleDrawRotate() {
      this.local_data.drawRotate = (this.local_data.drawRotate === 0) ? 180 : 0;
      if (this.local_data.drawRotate === 0) {
        return;
      }
      
      this.loader.is_loading = true;
      await this.$nextTick();

      try {
        const draw = JSON.parse(this.bind_data.bas_info.bas_draw_info);

        const drawInfoList = [];
        if (Array.isArray(draw)) {
          drawInfoList.push(...draw);
        }
        else {
          drawInfoList.push(draw);
        }

        this.local_data.drawImage = [];
        let drawImage = [];
        for (let i=0 ; i<drawInfoList.length ; i++) {
          const config = {
            params: {
              sid: this.$store.state.sid,
              bucket_name: drawInfoList[i].bucket_name,
              bucket_key: drawInfoList[i].bucket_key,
              file_name: drawInfoList[i].file_name,
            },
          };
  
          const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_S3_MEDIA_FILE, config);
          if (res.data.result === 'OK') {
            drawImage.push({
              name: drawInfoList[i].drw_name,
              image: `data:${drawInfoList[i].content_type};base64,${res.data.media_file}`,
            });
            this.$store.dispatch("commit_set_sid",res.data.sid);

            if ( drawImage.length == 2 ) {
              this.local_data.drawImage.push( drawImage );
              drawImage = [];
            }
          }
          else {
            this.$emit('error', res.data.message);
          }
        }
        //画像の数が奇数の時にスペースを追加
        if ( drawImage.length > 0 ) {
          if ( drawImage.length == 1 ) {
            drawImage.push( {
              name: "",
              file_name: "",
              image: "",
            } );
          }
  
          this.local_data.drawImage.push( drawImage );
        }
      }
      catch(e) {
        this.$emit('error', "エラーが発生しました。"+JSON.stringify(e));
      }
      this.loader.is_loading = false;
    },

    /**
     * タイムラインに登録されている動画をダウンロードする
     */
    async handleDownloadVideo( tmlSaveId, index ) {
      if (!tmlSaveId) {
        return;
      }

      this.loader.is_loading = true;
      await this.$nextTick();

      try {
        let saveIdJson = JSON.parse(tmlSaveId);
		let imageList = saveIdJson.image_list;
		let contentType = '';
		let fileName = '';

		if ( imageList ) {
			contentType = imageList[index].content_type;
			fileName = imageList[index].file_name;
		} else {
			contentType = saveIdJson.content_type;
			fileName = saveIdJson.file_name;
		}



        const config = {
          params: {
            sid: this.$store.state.sid,
            bucket_name: saveIdJson.download_bucket_name,
            bucket_key: saveIdJson.bucket_key,
            file_name: fileName,
          },
        };
        const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_S3_MEDIA_FILE, config);
        if (res.data.result === 'OK') {
          const blob = this.base64ToBlobImage(res.data.media_file, contentType);
          const url = window.URL.createObjectURL(blob);
            const link = this.$refs.videoDownload;
          link.href = url;
          link.download = fileName;
          link.click();
        }
        else if (res.data.result === 'NG') {
          this.$emit('message', {
            variant: 'danger',
            message: res.data.message,
          });
        }
        else if (res.data.result === 'ERR') {
          this.$emit('message', {
            variant: 'danger',
            message: '既に動画は削除されています',
          });
        }
      }
      catch(e) {
        this.$emit('message', {
          variant: 'danger',
          message: `エラーが発生しました: ${JSON.stringify(e)}`,
        });
      }

      this.loader.is_loading = false;
    },
  },
}
