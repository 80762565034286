import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import validator from 'validator';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';

export default {
  name: 'AffiliationRequest', // ココを更新
  components: {
    Loading,
    treeselectdialogs,
  },

  // 共通関数
  mixins: [common,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        usr_applying_org_uuid: "",   // 移動先組織 
        transfer_date: "",  // 移動適用日
        org_name: "", 
        usr_apply_status: "",
        applying_flg: true,
        cancel_flg: true,
      },
      // ローカルデータ
      local_data: {
      }
    }
  },

  // 既定計算
  computed: {
    state_request_button(){
      if (this.bind_data.usr_applying_org_uuid != null
        && !validator.isEmpty(this.bind_data.transfer_date)
        && !this.bind_data.applying_flg){
        return false;
      } else {
        return true;
      }
    },
    state_cancel_button(){
      return this.bind_data.cancel_flg;
    }
  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "所属申請" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");
    await this.set_org_map("slf",null,null);
    await this.affiliation_list();
  },
  // ローカル関数
  methods: {

    // 所属申請情報取得
    async affiliation_list(){
      this.loader.is_loading = true;
      let url = process.env.VUE_APP_RESTAPI_GET_AFFI_REQ+"?sid="+this.$store.state.sid
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response) );
        if (response.data.result == "OK") {
          this.bind_data.access_ok = true;//画面を有効化する
          if( response.data.usr_list.length > 0 ) {

            let row = response.data.usr_list[0];
            this.bind_data.usr_applying_org_uuid = row.usr_applying_org_uuid;
            this.bind_data.transfer_date = row.usr_transfer_date;
            this.bind_data.org_name = row.org_name;
            console.log(this.$route.path+":usr_applying_org_uuid="+this.bind_data.usr_applying_org_uuid);

            // treeselectdialogsリロード
            this.bind_data.applying_flg = true;
            this.$nextTick( function() {
              this.bind_data.applying_flg = false;
            });
            // 初期状態では取り消しボタン使用不可
            this.bind_data.cancel_flg = true; 

            if (row.usr_apply_status == "applying") {
              this.message.is_alert = true;
              this.message.variant = "info";
              this.message.message = "所属申請中です";
              this.bind_data.cancel_flg = false;  //取り消しボタン使用可能
            } else if (row.usr_apply_status == "moving") {
              this.message.is_alert = true;
              this.message.variant = "info";
              this.message.message = "所属申請が承認されました。";
              this.$nextTick( function() {
                this.bind_data.applying_flg = true; // 申請ボタン使用不可
              });
            } else if (row.usr_apply_status == "reject") {
              this.message.is_alert = true;
              this.message.variant = "danger";
              this.message.message = "所属申請が差し戻されました。";
            }
          } else {
            this.message.is_alert = true;
            this.message.variant = "danger";
            this.message.message = "ユーザー情報の取得に失敗しました。"; 
          }
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;           
        }
        this.loader.is_loading = false;
      } catch (ex) {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        // ローディング終了
        this.loader.is_loading = false;
      }
    },

    async request(){
      this.bind_data.usr_apply_status = "applying";
      await this.update();
    },

    async cancel(){
      this.bind_data.usr_apply_status = "cancel";
      await this.update();
    },

    // 申請実行
    async update(){
      this.loader.is_loading = true;
      this.message.is_alert = false;
      let url = process.env.VUE_APP_RESTAPI_SET_AFFI_REQ;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.usr_uuid = this.$store.state.users_info.usr_uuid; // 
      post_data.usr_applying_org_uuid = this.bind_data.usr_applying_org_uuid;
      post_data.usr_apply_status = this.bind_data.usr_apply_status;
      post_data.usr_transfer_date = this.bind_data.transfer_date;
      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){
          // リロード
          await this.affiliation_list();
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      this.loader.is_loading = false;
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    get_org_info( data ){
      console.log("get_org_info="+JSON.stringify(data));
      this.bind_data.usr_applying_org_uuid = data;
    },

  }
}