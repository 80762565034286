<template>
<div id="home">
	<!-- ローディング -->
	<loading :active.sync="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
	
	<!-- ヘッダー アプリから起動された時は、ヘッダーを表示しない -->
	<template v-if="$store.state.startup">
		<nabvar ref="nabvar_method" v-show="bind_data.navbar_show" class="header_fixed"></nabvar>
	</template>

	<!-- メッセージ -->
	<b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>

	<div class="container-fulid content_top pt-1" v-bind:class="{ content_top: $store.state.startup }">
		<!-- メインコンテンツ -->
		<router-view />
		
		<!-- サイドメニューバー -->
		<b-sidebar id="sidebar-menu" title="MENU" shadow backdrop bg-variant="dark" text-variant="light" >
			<div><SidebarMenu></SidebarMenu></div>
		</b-sidebar>
		<!-- ハンバーガーメニューボタン（隠しボタン） -->
		<b-button ref="sidebar_menu_show_button" v-show="false" v-b-toggle.sidebar-menu >メニュー</b-button>
	</div>
</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import SidebarMenu from '@/components/SidebarMenu';
import nabvar from '@/components/Navbar';
import common from '@/lib/common.js';
import { mapActions } from 'vuex';

export default {
	name: 'Home',
	components: {
		Loading,
		SidebarMenu,
		nabvar,
	},

	// 共通関数
	mixins: [common],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				sidebar_menu_show: false,
				navbar_show: true,
			},
			// ローカルデータ
			local_data: {
			}
		}
	},

	// 既定計算
	computed: {
	},
	// インスタンス初期化後
	created(){
	// タイトル更新イベント
		//this.$eventHub.$emit( "navbar_title", "データセット編集" );
	},
	// インスタンス破棄後
	destroyed: function() {
	},
	// インスタンスマウント後
	async mounted(){
		console.log(this.$route.path+".vue Start");

		// ローディング開始
		this.loader.is_loading = true;

		this.$eventHub.$on('sidebar_menu_click', this.sidebar_menu_click );

		// ローディング終了
		this.loader.is_loading = false;

		if( this.$store.state.mode == "reporting" ){
			// グローバル変数（store）に格納
			this.$store.dispatch("commit_set_startup",false);//ハンバーガーメニューを非表示にする
			this.$router.push({ path:'/Home/ReportingData' });
		} else if( this.$store.state.mode == "report_list" ){
			//報告データ管理画面に遷移
			this.reporting_data();
		} else if( this.$store.state.mode == "report_data" ){
			//報告データ選択画面に遷移
			this.case_details_select();
		} else if( this.$store.state.mode == "report_data_edit" ){
			//報告データ編集画面に遷移
			this.case_details_edit();
		} else if( this.$store.state.mode == "report_data_edit_confirm" ){
			//報告データ編集画面に遷移
			this.case_details_edit_confirm();
		} else if( this.$store.state.mode == "report_data_view" ){
			//報告データ閲覧画面に遷移
			this.case_details_view();
		} else {
			this.$router.push({ path:'/Home/Dashboard' });
		}
	},
	// ローカル関数
	methods: {
		...mapActions( 'api', {
			getForm: 'getForm',
		} ),

		//サイドメニュークリック
		sidebar_menu_click: function(){
			let button = this.$refs.sidebar_menu_show_button;
			button.click();
		},

		//報告データ管理の画面に遷移する
		reporting_data: function(){
			console.log("bas_uuid="+this.$store.state.bas_uuid);
			console.log("fbs_uuid="+this.$store.state.fbs_uuid);
			//ここで画面遷移する
			this.$router.push({ path:'/Home/ReportingData' });
		},

		//報告データ選択の画面に遷移する
		case_details_select: function(){
			// 使用したmodeを削除
			this.$store.dispatch("commit_set_mode","");
			console.log("bas_uuid="+this.$store.state.bas_uuid);
			console.log("fbs_uuid="+this.$store.state.fbs_uuid);
			//事案の情報を取得し、ストアにセットする

			let selected_bas_info = {
				bas_uuid:this.$store.state.bas_uuid
			}
			this.$store.dispatch("commit_set_selected_bas_info",selected_bas_info);// グローバル変数（store）に格納
			//ここで画面遷移する
			this.$router.push({ path:'/Home/CaseDetailsSelect' });
		},

		//報告データ編集画面に遷移する
		async case_details_edit(){
			// 使用したmodeを削除
			this.$store.dispatch("commit_set_mode","");
			console.log("bas_uuid="+this.$store.state.bas_uuid);
			console.log("fbs_uuid="+this.$store.state.fbs_uuid);

			let selected_bas_info = {
				bas_uuid:this.$store.state.bas_uuid
			}
			this.$store.dispatch("commit_set_selected_bas_info",selected_bas_info);// グローバル変数（store）に格納

			const ret = await this.getForm( {
				_this: this,
				basUuid: this.$store.state.bas_uuid,
			} );

			if (ret.result === 'OK') {
				this.$store.dispatch('commit_set_form_info', JSON.parse(ret.form_json_data));
				this.$store.dispatch("commit_set_sid",ret.sid);
			}

			const config = {
				params: {
					sid: this.$store.state.sid,
					fbs_bas_uuid: this.$store.state.bas_uuid,
				},
			};
			//報告データのリストを取得する
			const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_FORM_BASICS_LIST, config);
			if (res.data.result === 'OK') {
				this.bind_data.fbs_lists = res.data.form_basics_list;
			
				for (let i=0 ; i<this.bind_data.fbs_lists.length ; i++) {
					const fbs = this.bind_data.fbs_lists[i];
					//表示したい報告データとfbs_uuidが一致するデータをストアにセットする
					if(fbs.fbs_uuid == this.$store.state.fbs_uuid){
						this.$store.dispatch("commit_set_selected_fbs_info",fbs);// グローバル変数（store）に格納
					}
				}

				this.$store.dispatch("commit_set_sid",res.data.sid);
				//画面遷移
			this.$router.push({ path:'/Home/CaseDetailsEdit' });
			}
			else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		},

		//報告データ編集確認画面に遷移する
		async case_details_edit_confirm(){
			// 使用したmodeを削除
			this.$store.dispatch("commit_set_mode","");
			console.log("bas_uuid="+this.$store.state.bas_uuid);
			console.log("fbs_uuid="+this.$store.state.fbs_uuid);

			let selected_bas_info = {
				bas_uuid:this.$store.state.bas_uuid
			}
			this.$store.dispatch("commit_set_selected_bas_info",selected_bas_info);// グローバル変数（store）に格納

			const ret = await this.getForm( {
				_this: this,
				basUuid: this.$store.state.bas_uuid,
			} );

			if (ret.result === 'OK') {
				this.$store.dispatch('commit_set_form_info', JSON.parse(ret.form_json_data));
				this.$store.dispatch("commit_set_sid",ret.sid);
			}

			const config = {
				params: {
					sid: this.$store.state.sid,
					fbs_bas_uuid: this.$store.state.bas_uuid,
				},
			};
			//報告データのリストを取得する
			const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_FORM_BASICS_LIST, config);
			if (res.data.result === 'OK') {
				this.bind_data.fbs_lists = res.data.form_basics_list;
			
				for (let i=0 ; i<this.bind_data.fbs_lists.length ; i++) {
					const fbs = this.bind_data.fbs_lists[i];
					//表示したい報告データとfbs_uuidが一致するデータをストアにセットする
					if(fbs.fbs_uuid == this.$store.state.fbs_uuid){
						this.$store.dispatch("commit_set_selected_fbs_info",fbs);// グローバル変数（store）に格納
					}
				}

				this.$store.dispatch("commit_set_sid",res.data.sid);
				//画面遷移
				this.$router.push({ path:'/Home/CaseDetailsEditConfirm' });
			}
			else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		},

		//報告データ閲覧画面に遷移する
		async case_details_view(){
			// 使用したmodeを削除
			this.$store.dispatch("commit_set_mode","");
			console.log("bas_uuid="+this.$store.state.bas_uuid);
			console.log("fbs_uuid="+this.$store.state.fbs_uuid);

			let selected_bas_info = {
				bas_uuid:this.$store.state.bas_uuid
			}
			this.$store.dispatch("commit_set_selected_bas_info",selected_bas_info);// グローバル変数（store）に格納

			const ret = await this.getForm( {
				_this: this,
				basUuid: this.$store.state.bas_uuid,
			} );
			
			if (ret.result === 'OK') {
				this.$store.dispatch('commit_set_form_info', JSON.parse(ret.form_json_data));
				this.$store.dispatch("commit_set_sid",ret.sid);
			}

			const config = {
				params: {
					sid: this.$store.state.sid,
					fbs_bas_uuid: this.$store.state.bas_uuid,
				},
			};
			//報告データのリストを取得する
			const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_FORM_BASICS_LIST, config);
			if (res.data.result === 'OK') {
				this.bind_data.fbs_lists = res.data.form_basics_list;
			
				for (let i=0 ; i<this.bind_data.fbs_lists.length ; i++) {
					const fbs = this.bind_data.fbs_lists[i];
					//表示したい報告データとfbs_uuidが一致するデータをストアにセットする
					if(fbs.fbs_uuid == this.$store.state.fbs_uuid){
						this.$store.dispatch("commit_set_selected_fbs_info",fbs);// グローバル変数（store）に格納
					}
				}

			this.$store.dispatch("commit_set_sid",res.data.sid);
			
			//戻り先に閲覧データ選択画面を指定
			this.$store.dispatch("commit_set_prev_screen_name","/Home/CaseDetailsViewSelect")
			//画面遷移
			this.$router.push({ path:'/Home/CaseDetailsView' });
			}
			else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		},
	}
}
</script>

<style scoped lang="scss">
.header_fixed {
	top: 0;
	margin: 0;
	z-index: 100;
	width: 100%;
	position:fixed;
}
.content_top {
	margin-top: 68px;
}
</style>