
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import image from '@/lib/image.js';
import perm from '@/lib/perm.js';
import dataset from '@/lib/dataset.js';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
import treeselectdialogs2 from '@/components/TreeSelectDialogs2.vue';

export default {
  name: 'CabinetManage', // ココを更新
  components: {
    Loading,
    treeselectdialogs,
    treeselectdialogs2,
  },

  // 共通関数
  mixins: [
    common,
    dataset,
    CommonDataSet,
    perm,
    image,
  ],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        dpt_selected: [],
        dpt_options: null,
        dpt_selected_document: [],

        document_placeholder:"選択可能なファイルの容量は"+process.env.VUE_APP_CABINET_UPLOAD_FILE_SIZE_LIMIT+"MB以内です。"
      },
      // ローカルデータ
      local_data: {
        document_data: null,
        bas_info: null,
        mng_uuid_set: "",
        result: {
          list: [],
          fields: [
            { key: "app_cmd", label: "", class:"text-nowrap"},
            { key: "acv_document_name", label: "ドキュメント名" },
            { key: "acv_regist_date_time_show", label: "登録日時", class:"text-nowrap" },
            { key: "share_cmd", label: "", class:"text-nowrap"},
          ]
        },
        deleteArchive: null,
        targetArchive: null,
        share_data: {
          text: "",
          url: "",
        }
      },
      state_data: {
        treeselectdialogs: true,
        treeselectdialogs2: true,
      },
    }
  },

  // 既定計算
  computed: {
    registState: function () {
      if (this.local_data.document_data && this.bind_data.dpt_selected.length !== 0) {
        return false;
      }
      return true;
    },
  },

  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "書庫" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    this.loader.is_loading = true;
    await this.$nextTick();

    this.local_data.bas_info = this.$store.state.selected_bas_info;

    await this.get_dpt();

    this.bind_data.dpt_options = JSON.parse(localStorage.getItem('organization_info'));

    await this.getDocumentList();

    this.loader.is_loading = false;
  },
  // ローカル関数
  methods: {
    back(){
      //画面遷移
      this.transition("/Home/ReportingData");
    },

    get_dpt_info(data) {
      this.bind_data.dpt_selected = data;
    },

    /**
     * 共有先の組織を取得する
     */
     async get_dpt(){

      // ローディング開始
      this.loader.is_loading = true;

      this.state_data.treeselectdialogs = false;

      this.local_data.bas_info.bas_mng_list;
      console.log(this.local_data.bas_info.bas_mng_list[0].bas_mng_uuid);

      for (let i=0 ; i<this.local_data.bas_info.bas_mng_list.length ; i++) {
        if(i == 0) {
          this.local_data.mng_uuid_set = this.local_data.bas_info.bas_mng_list[i].bas_mng_uuid;
        }else{
          this.local_data.mng_uuid_set = this.local_data.mng_uuid_set + "," + this.local_data.bas_info.bas_mng_list[i].bas_mng_uuid;
        }
      }
  
      await this.set_org_map("allmng",null,this.local_data.mng_uuid_set);
      
      this.state_data.treeselectdialogs = true;

      // ローディング終了
      this.loader.is_loading = false;
    },  

    /**
     * 資料情報を取得する
     */
    async getDocumentList() {
      try {
        const config = {
          params: {
            sid: this.$store.state.sid,
            bas_uuid: this.local_data.bas_info.bas_uuid,
          },
        };
  
        const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_CABINET_DOCUMENT_LIST, config);
        if (res.data.result === 'OK') {
          this.local_data.result.list = res.data.cabinet_document_list;

          for (let i=0 ; i<this.local_data.result.list.length ; i++) {
            const document = this.local_data.result.list[i];
            document.acv_regist_date_time_show = document.acv_regist_date_time.substr(0, 16);
            document.dpt_selected = [];
            if (document.acv_share_dest_array) {
              document.dpt_selected = document.acv_share_dest_array.split(',');
            }
          }

          this.$store.dispatch("commit_set_sid",res.data.sid);
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }
      }
      catch(e) {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }
    },

    /**
     * 資料を登録する
     */
    async handleRegist() {
      this.loader.is_loading = true;
      await this.$nextTick();

      try {
        const formData = new FormData();
        formData.append('sid', this.$store.state.sid);
        formData.append('acv_bas_uuid', this.local_data.bas_info.bas_uuid);
        formData.append('acv_document_name', this.local_data.document_data.name);
        formData.append('acv_share_dest', this.bind_data.dpt_selected.join(','));
        formData.append('acv_cabinet_file', this.local_data.document_data, this.local_data.document_data.name);

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
            timeout: 0,
        };

        const res = await axios.post(process.env.VUE_APP_RESTAPI_SET_CABINET_DOCUMENT_DATA, formData, config);
        if (res.data.result === 'OK') {
          this.$refs['selectDocument'].reset();
          this.bind_data.dpt_selected = [];
          await this.getDocumentList();
          this.$store.dispatch("commit_set_sid",res.data.sid);
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }
      }
      catch(e) {
        console.error(e);
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }

      this.loader.is_loading = false;
    },

    /**
     * ドキュメントの削除を確認する
     */
    handleConfirmDeleteArchive(item) {
      this.local_data.deleteArchive = item;

      this.$refs['deleteArchive'].show();
    },

    /**
     * ドキュメントを削除する
     */
    async handleDeleteArchive() {
      if (!this.local_data.deleteArchive) {
        return;
      }

      try {
        const data = {
          sid: this.$store.state.sid,
          acv_uuid: this.local_data.deleteArchive.acv_uuid,
        };

        const res = await axios.put(process.env.VUE_APP_RESTAPI_DELETE_CABINET_DOCUMENT, data);
        if (res.data.result === 'OK') {
          await this.getDocumentList();
          this.$store.dispatch("commit_set_sid",res.data.sid);
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }
      }
      catch(e) {
        console.error(e);
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }
    },

    /**
     * ドキュメントをダウンロードする
     */
    async handleDownload(item) {
      try {
        const config = {
          params: {
            sid: this.$store.state.sid,
            acv_uuid: item.acv_uuid,
          },
        };

        const res = await axios.get(process.env.VUE_APP_RESTAPI_DOWNLOAD_CABINET_DOCUMENT_DATA, config);
        if (res.data.result === 'OK') {
          this.$store.dispatch("commit_set_sid",res.data.sid);

          // const blob = this.base64ToBlobImage(res.data.media_file, 'application/vnd.openxmlformats-officedocument.presentationml.presentation');
          const blob = this.base64ToBlobImage(res.data.media_file, null);
          const url = window.URL.createObjectURL(blob);
  
          const link = this.$refs.documentDownload;
          link.href = url;
          link.download = `${item.acv_document_name}`;
          link.click();
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }
      }
      catch(e) {
        console.error(e);
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }
    },

    /**
     * 共有先を変更する
     * @param {*} data 
     * @param {*} item 
     */
    async get_dpt_info_document() {
      this.local_data.targetArchive.dpt_selected = this.bind_data.dpt_selected_document;

      try {
        const data = {
          sid: this.$store.state.sid,
          acv_uuid: this.local_data.targetArchive.acv_uuid,
          acv_share_dest: this.local_data.targetArchive.dpt_selected.join(','),
        };

        const res = await axios.put(process.env.VUE_APP_RESTAPI_SET_CABINET_UPDATE_SHARE, data);
        if (res.data.result === 'OK') {
          await this.getDocumentList();
          this.$store.dispatch("commit_set_sid",res.data.sid);
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }
      }
      catch(e) {
        console.error(e);
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }
    },

    /**
     * 共有先を変更する
     */
    handleChangeShare(item) {
      console.log("item");
      console.log(item);
      this.local_data.targetArchive = item;
      if (item.acv_share_dest_array) {
        this.bind_data.dpt_selected_document = item.acv_share_dest_array.split(',');
      }
      else {
        this.bind_data.dpt_selected_document = [];
      }
      this.$refs['treeselectDialog'].show();
    },

    async handleShare(item){

      const config = {
        params: {
          sid: this.$store.state.sid,
          prm_key: "webview_download_url",
        },
      };
      const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_SYSTEM_PARM_LIST, config);
      if (res.data.result === 'OK') {

        let url = res.data.params_list[0].prm_value+res.data.sid;
        url = url.replace("%mode%","cabinet").replace("%acv_uuid%",item.acv_uuid);
        let text1 = "URLをクリックし、ドキュメントをダウンロードしてください。";
        let text2 = "URLをコピーして、配信してください。";

        console.log(url);

        if (navigator.share) {
          const shareData = {
            title: item.acv_document_name,
            text: text1,
            url: url,
          }
          try {
            await navigator.share(shareData);
          } catch(err) {
            console.log(err);
          }
        } else {
          this.local_data.share_data.url = url;
          this.local_data.share_data.text = text2;
          this.$refs['share'].show();
        }
      }
    },
    //選択したファイル容量が上限を超えていないかチェックする
    chsck_document(){
      if(this.local_data.document_data != null){
        let limitSizeByte = Number( process.env.VUE_APP_CABINET_UPLOAD_FILE_SIZE_LIMIT ) * 1048576;
        if(this.local_data.document_data.size>limitSizeByte){
          this.local_data.document_data = null;
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = this.bind_data.document_placeholder;
        }
      }
    }
  },
}
