<template>
    <div class="menu">
      <b-list-group class="text-left">
        <b-list-group-item button v-on:click="link_to('/Home/Dashboard')" class="menu_button">
          <font-awesome-icon icon="tachometer-alt" size="2x"></font-awesome-icon>
          <span class="ml-3 menu_text">ダッシュボード</span>
        </b-list-group-item>
        <template v-if="hasReportBrowsingPerm||hasReportDownloadPerm||hasReportEditPerm||hasReportEditCompletedPerm||hasReportCommentVerifiedPerm||hasArchiveBrowsingPerm||hasArchiveRegistPerm">
          <b-list-group-item button v-on:click="link_to('/Home/ReportingData')" class="menu_button">
            <font-awesome-icon icon="file-alt" size="2x"></font-awesome-icon>
            <span class="ml-3 menu_text">報告データ管理</span>
          </b-list-group-item>
        </template>
        <!-- <template v-if=""> -->
          <b-list-group-item button v-on:click="link_mobile()" class="menu_button">
            <font-awesome-icon icon="file-alt" size="2x"></font-awesome-icon>
            <span class="ml-3 menu_text">発生事案登録</span>
          </b-list-group-item>
        <!-- </template> -->
        <template v-if="hasBbcpManagerPerm||hasBcpUserPerm||hasBcpViewerPerm">
          <b-list-group-item button v-on:click="link_bcp()" class="menu_button">
            <font-awesome-icon icon="file-alt" size="2x"></font-awesome-icon>
            <span class="ml-3 menu_text">BCP</span>
          </b-list-group-item>
        </template>
        <template v-if="hasWebAffiliationApprovalPerm">
          <b-list-group-item button v-on:click="link_to('/Home/AffiliationApproval')" class="menu_button">
            <font-awesome-icon icon="stamp" size="2x"></font-awesome-icon>
            <span class="ml-3 menu_text">所属承認</span>
          </b-list-group-item>
        </template>
        <b-list-group-item button v-on:click="link_to('/Home/OrganizationView')" class="menu_button">
          <font-awesome-icon icon="sitemap" size="2x"></font-awesome-icon>
          <span class="ml-3 menu_text">組織表示</span>
        </b-list-group-item>
        <template v-if="hasAllSysPerm">
          <b-list-group-item button v-on:click="link_to('/Home/CommentHistory')" class="menu_button">
            <font-awesome-icon icon="file-alt" size="2x"></font-awesome-icon>
            <span class="ml-3 menu_text">コメント履歴</span>
          </b-list-group-item>
        </template>

        <!-- ここからアコーディオン -->
        <template v-if="hasAllSysPerm||hasWebOrganizationSettingPerm||hasWebUsersSettingPerm||hasWebManagedSettingPerm||hasWebDrawingSettingPerm||hasWebClassesSettingPerm||hasWebInputFormSettingPerm||hasWebOutputFormSettingPerm||hasWebRegularReportSettingPerm||hasWebNewsSettingPerm">
          <b-list-group-item button v-b-toggle.submenu class="menu_button" v-on:click="chg_info_toggle">
            <font-awesome-icon icon="cog" size="2x"></font-awesome-icon><span class="ml-3 menu_text">設定</span>
            <b-icon icon="caret-down-fill" :rotate=this.bind_data.info_toggle_rotate scale="1.5" class="ml-2 mb-2"></b-icon>
          </b-list-group-item>
          <b-collapse id="submenu">
            <template v-if="hasAllSysPerm">
              <b-list-group-item button v-on:click="link_to('/Home/CompanysManage')" class="sub_menu_button">会社設定</b-list-group-item>
            </template>
            <template v-if="hasAllSysPerm">
              <b-list-group-item button v-on:click="link_to('/Home/AuthManage')" class="sub_menu_button" >権限設定</b-list-group-item>
            </template>
            <template v-if="hasWebOrganizationSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/OrganizationManage')" class="sub_menu_button">組織設定</b-list-group-item>
            </template>
            <template v-if="hasWebUsersSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/UserManage')" class="sub_menu_button">ユーザー設定</b-list-group-item>
            </template>
            <template v-if="hasWebManagedSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/ManagedManage')" class="sub_menu_button">管理対象設定</b-list-group-item>
            </template>
            <template v-if="hasWebDrawingSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/BlueprintManage')" class="sub_menu_button">図面設定</b-list-group-item>
            </template>
            <template v-if="hasWebClassesSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/ClassManage')" class="sub_menu_button">区分設定</b-list-group-item>
            </template>
            <template v-if="hasWebInputFormSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/FormManage')" class="sub_menu_button">フォーム編集</b-list-group-item>
            </template>
            <template v-if="hasWebOutputFormSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/OutputFormRegist')" class="sub_menu_button">出力帳票設定</b-list-group-item>
            </template>
            <template v-if="hasAllSysPerm">
              <b-list-group-item button v-on:click="link_to('/Home/CompanysAccessControlManage')" class="sub_menu_button">会社アクセス制限設定</b-list-group-item>
            </template>
            <template v-if="hasWebRegularReportSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/PeriodReportConfig')" class="sub_menu_button">定期レポート設定</b-list-group-item>
            </template>
            <template v-if="hasWebNewsSettingPerm">
              <b-list-group-item button v-on:click="link_to('/Home/NoticeManage')" class="sub_menu_button">お知らせ設定</b-list-group-item>
            </template>
            <template v-if="hasAllSysPerm">
              <b-list-group-item button v-on:click="link_to('/Home/LinkTimeoutManage')" class="sub_menu_button">リンク有効期限設定</b-list-group-item>
            </template>
            <template v-if="hasAllSysPerm">
              <b-list-group-item button v-on:click="link_to('/Home/AccessLogDownload')" class="sub_menu_button">アクセスログダウンロード</b-list-group-item>
            </template>
          </b-collapse>
        </template>
      </b-list-group>
    </div>
</template>
<script>
import auth from '@/lib/auth.js';
import perm from '@/lib/perm.js';
import common from '@/lib/common.js';

export default {
  name: 'SidebarMenu',

  // 共通関数
  mixins: [auth,perm,common],

  // ローカルデータ変数
  data() {
    return {
      // バインドデータ
      bind_data: {
        info_toggle: false,
        info_toggle_rotate: "0",
      },
      // エラーメッセージ
      errors: [],
      error_message: {
      },
    }
  },

  // 既定計算
  computed: {
    
  },

  // インスタンス初期化後
  created: function(){
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  mounted: function(){
  },

  // ローカル関数
  methods: {
    chg_info_toggle() {
      if (this.bind_data.info_toggle) {
        this.bind_data.info_toggle_rotate ="0";
      } else {
        this.bind_data.info_toggle_rotate ="180";
      }
      this.bind_data.info_toggle = !this.bind_data.info_toggle;
    },

    link_to: function( to ){

      //console.log("link_to="+this.$route.path);
      if( this.$route.path != to ){
        // 画面遷移
        this.$router.push({ path: to });
      }
    },

    logout: function(){
      
      // クッキーを削除
      this.$cookies.remove("sid");

      // // ストアの初期化
      this.$store.dispatch("commit_reset_state");
      // 画面遷移
      window.location = process.env.VUE_APP_LOGIN_URL;
    },

    link_mobile: function(){
      window.open(process.env.VUE_APP_MOBILE_URL+"?sid="+this.$store.state.sid, '_blank');
      this.$eventHub.$emit('sidebar_menu_click');
    },
    link_bcp: function(){
      if(common.bcpWindow && !common.bcpWindow.closed){
        common.bcpWindow.close();
      }
      common.bcpWindow = window.open(process.env.VUE_APP_BCP_URL+"?sid="+this.$store.state.sid+"&mode=dashboard", '_blank');

      this.$eventHub.$emit('sidebar_menu_click');
    }
  }
};
</script>

<style scoped lang="scss">
.side-menu {
  top: 58px;
  margin: 0;
  padding: 0;
  text-align: left;

  & li a {
    color: white;
    //font-weight: bold;
    text-decoration: none;
  }
  & li a.disabled {
    color: grey;
    //font-weight: bold;
    text-decoration: none;
  }
  & li {
    padding: 8px 0 8px 16px;
    list-style: none;
  }
  & li:hover {
    background-color: silver;
  }
}
.menu {
  margin: 0;
  padding: 0;
}
i {
  position: relative;
  top: 4px;
}
.menu_button {
  text-align: left;
  background-color: #343a40;/* $gray-800 */
  color: white;
}
.sub_menu_button { 
  text-align: left;
  background-color: #343a40;/* $gray-800 */
  color: white;
  padding-left: 2.25rem;
}
.menu_text {
  vertical-align: 20%;
}
</style>