var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Login" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c("Header"),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container", attrs: { align: "center" } },
        [
          _c(
            "b-card",
            {
              staticClass: "mt-3",
              staticStyle: { "max-width": "30rem" },
              attrs: {
                "border-variant": "primary",
                "header-text-variant": "white",
                "header-bg-variant": "primary",
                "header-tag": "h3"
              }
            },
            [
              _c("div", { staticClass: "text-left" }, [
                _c("label", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v("会社ID")
                ])
              ]),
              _c("b-form-input", {
                attrs: {
                  type: "text",
                  maxlength: "32",
                  placeholder: "会社IDを入力してください"
                },
                model: {
                  value: _vm.bind_data.login_cmp_id,
                  callback: function($$v) {
                    _vm.$set(_vm.bind_data, "login_cmp_id", $$v)
                  },
                  expression: "bind_data.login_cmp_id"
                }
              }),
              _c("div", { staticClass: "text-left mt-3" }, [
                _c("label", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v("ログインID")
                ])
              ]),
              _c("b-form-input", {
                attrs: {
                  type: "text",
                  maxlength: "32",
                  placeholder: "ログインIDを入力してください"
                },
                model: {
                  value: _vm.bind_data.login_usr_id,
                  callback: function($$v) {
                    _vm.$set(_vm.bind_data, "login_usr_id", $$v)
                  },
                  expression: "bind_data.login_usr_id"
                }
              }),
              _c("div", { staticClass: "text-left mt-3" }, [
                _c("label", { staticClass: "mb-0 font-weight-bold" }, [
                  _vm._v("パスワード")
                ])
              ]),
              _c("b-form-input", {
                attrs: {
                  type: _vm.state_data.password_type,
                  maxlength: "32",
                  placeholder: "パスワードを入力してください"
                },
                model: {
                  value: _vm.bind_data.login_password,
                  callback: function($$v) {
                    _vm.$set(_vm.bind_data, "login_password", $$v)
                  },
                  expression: "bind_data.login_password"
                }
              }),
              _c(
                "b-form-checkbox",
                {
                  staticClass: "mt-2",
                  attrs: { value: "text", "unchecked-value": "password" },
                  model: {
                    value: _vm.state_data.password_type,
                    callback: function($$v) {
                      _vm.$set(_vm.state_data, "password_type", $$v)
                    },
                    expression: "state_data.password_type"
                  }
                },
                [_vm._v("パスワードを表示する")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "primary", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.login()
                    }
                  }
                },
                [_vm._v("ログイン")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }