import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import validator from 'validator';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
//import dataset from '@/lib/dataset.js';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';

export default {
  name: 'ManagedManage', // ココを更新
  components: {
    Loading,
    treeselectdialogs,
  },

  // 共通関数
  mixins: [common,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        access_ok: false,
        dpt_selected: null,
        search_name: "",
        dl_flg: false,

        // 検索結果
        result: {
          list: [],
        },
        // 会社UUID
        org_cmp_uuid_options: [

        ],
        options: {
          label: "",
          value: "",
          id: "",
        },
      },
      // ローカルデータ
      local_data: {

        // 検索条件
        search_mng_name:"",

        // 選択行
        row: null,
        // 既存データ選択状態
        selected: false,

        add_data: {
            // 新規ボタン押下状態
            selected: false,
            mng_uuid: "",
            mng_cmp_uuid: "",
            mng_name: "",
            mng_org_name: "",
            mng_chg_org_uuid: [],
            mng_info: "",
            mng_draw_info: "",
        },
        edit_data: {
            app_cmd: "",
            mng_uuid: "",
            mng_cmp_uuid: "",
            mng_cmp_uuid_name: "",
            mng_name: "",
            mng_org_name: "",
            mng_chg_org_uuid: [],
            mng_info: "",
            mng_draw_info: "",
        },
        fields: [
          { key: "mng_name", label: "場所施設名", sortable: true, class:"text-nowrap" },
          { key: "mng_org_name", label: "担当組織", sortable: true },
          { key: "app_cmd", label:"", class:"text-nowrap" },
        ],
      },
      state_data: {
        treeselectdialogs: true,
      },
    }
  },

  // 既定計算
  computed: {
    state_mng_uuid(){
      return !validator.isEmpty(this.local_data.add_data.mng_uuid);
    },
    state_mng_name(){
      return !validator.isEmpty(this.local_data.add_data.mng_name);
    },
    state_add_new_button(){
        if (this.state_mng_name) {
            return false;
        } else {
            return true;
        }
    },
    state_edit_mng_uuid(){
      return !validator.isEmpty(this.local_data.edit_data.mng_uuid);
    },
    state_edit_mng_name(){
      return !validator.isEmpty(this.local_data.edit_data.mng_name);
    },
    state_edit_update_button(){
        if (this.state_edit_mng_name) {
            return false;
        } else {
            return true;
        }
    },

    state_upload(){
      return this.bind_data.csv_file==null;
    },
  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "管理対象設定" );

  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");
    this.state_data.treeselectdialogs = false;
    await this.set_org_map("all",null,null);
    this.state_data.treeselectdialogs = true;
    await this.companys_list();
    await this.managed_list();
  },
  // ローカル関数
  methods: {
    treeselect_dialog_show(){
      this.$refs['treeselect-dialog'].show();
    },
    // 会社リスト用一覧取得
    async companys_list(){
      let url = process.env.VUE_APP_RESTAPI_GET_MANAGE_COMPANY_PULLDOWN_LIST+"?sid="+this.$store.state.sid;
      try {
        let response = await axios.get( url );
        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.org_cmp_uuid_options = [];
          if (response.data.company_list.length > 0) {
            for (let i=0; i<response.data.company_list.length; i++) {
              let options = {};
              options.text = response.data.company_list[i].cmp_name;
              options.value = response.data.company_list[i].cmp_id;
              this.bind_data.org_cmp_uuid_options.push(options);

              if (i==0) {
                // 初期値セット
                this.local_data.add_data.mng_cmp_uuid = response.data.company_list[i].cmp_id;
               }
            }
          }
        }
      } catch (ex) {
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    // 検索
    async seach_managed_list(){
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;
      let url = process.env.VUE_APP_RESTAPI_GET_MANAGED_PULLDOWN_LIST+"?sid="+this.$store.state.sid+"&mng_name="+encodeURIComponent(this.bind_data.search_name);
      console.log("search_name" + this.bind_data.search_name);
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.result.list = [];
          if( response.data.managed_list.length > 0 ) {
            for ( let i=0; i<response.data.managed_list.length; i++ ) {
              let row = response.data.managed_list[i];
              let org_list = row.mng_chg_org_list;
              let org_name_list = "";
              for (let k=0; k<org_list.length; k++) {
                let org_info = org_list[k];
                if (org_name_list != "") {
                  org_name_list = org_name_list + "," + org_info.org_name;
                } else {
                  org_name_list = org_info.org_name;
                }
              }
              row.mng_org_name = org_name_list;
              console.log("row="+JSON.stringify(row));
              this.bind_data.result.list.push(row);
              // ダウンロードボタン表示
              this.bind_data.dl_flg = true;
            }
          } else {
            this.bind_data.result.list = [];
            this.message.is_alert = true;
            this.message.variant = "info";
            this.message.message = "該当する管理対象が存在しません。";               
          }
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;      
        }
        // ローディング終了
        this.loader.is_loading = false;
      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        // ローディング終了
        this.loader.is_loading = false;
      }
    } ,
    // 一覧取得
    async managed_list(){
      // ローディング開始
      this.loader.is_loading = true;
      let url = process.env.VUE_APP_RESTAPI_GET_MANAGED_PULLDOWN_LIST+"?sid="+this.$store.state.sid;
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.result.list = [];
          if( response.data.managed_list.length > 0 ) {
            for ( let i=0; i<response.data.managed_list.length; i++ ) {
              let row = response.data.managed_list[i];
              let org_list = row.mng_chg_org_list;
              let org_name_list = "";
              for (let k=0; k<org_list.length; k++) {
                let org_info = org_list[k];
                if (org_name_list != "") {
                  org_name_list = org_name_list + "," + org_info.org_name;
                } else {
                  org_name_list = org_info.org_name;
                }
              }
              row.mng_org_name = org_name_list;
              console.log("row="+JSON.stringify(row));
              this.bind_data.result.list.push(row);
            }
          } else {
            this.bind_data.result.list = [];
            this.message.is_alert = true;
            this.message.variant = "info";
            this.message.message = "登録済の管理対象が存在しません。";               
          }
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;      
        }
        // ダウンロードボタン非表示
        this.bind_data.dl_flg = false;
        // ローディング終了
        this.loader.is_loading = false;
      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        // ローディング終了
        this.loader.is_loading = false;
      }
    } ,
    // 新規登録表示
    add_row(){
      this.local_data.add_data.selected = true;
      if( this.local_data.selected ){
        this.local_data.row.toggleDetails();
        this.local_data.selected = false;
      }
      // 画面初期化
      this.local_data.add_data.mng_name = "";
    },
    add_cancel(){
      this.local_data.add_data.selected = false;
    },
    edit_row(row){
      if( this.local_data.row != null ){
        if( this.local_data.selected ){
          this.local_data.row.toggleDetails();
        }
      }
      this.local_data.edit_data.mng_cmp_uuid = row.item.mng_cmp_uuid;
      this.local_data.edit_data.mng_cmp_uuid_name = row.item.mng_cmp_uuid_name;
      this.local_data.edit_data.mng_uuid = row.item.mng_uuid;
      this.local_data.edit_data.mng_name = row.item.mng_name;
      this.local_data.edit_data.mng_chg_org_uuid = [];
      let org_list = row.item.mng_chg_org_list;
      console.log("org_list="+org_list);
      for (let i=0; i<org_list.length; i++) {
        console.log("org_uuid="+org_list[i].org_uuid);
        this.local_data.edit_data.mng_chg_org_uuid.push(org_list[i].org_uuid);
      }

      this.local_data.add_data.selected = false;
      this.local_data.selected = true;
      row.toggleDetails();
      this.local_data.row = row;
    },
    cancel_row( row ){
      this.message.is_alert = false;
      this.message.variant = "";
      this.message.message = "";   
      this.local_data.selected = false;
      row.toggleDetails();
    },
    async add_new(){
      this.local_data.add_data.selected = false;
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;
      let url = process.env.VUE_APP_RESTAPI_SET_MANAGED_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.mng_cmp_uuid = this.local_data.add_data.mng_cmp_uuid;
      post_data.mng_name = this.local_data.add_data.mng_name;
      post_data.mng_chg_org_uuid = this.local_data.add_data.mng_chg_org_uuid.join(",");
      try {
        let response = await axios.post( url, post_data );
        console.log(this.$route.path+":add_response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          //選択状態解除
          this.local_data.add_data.selected = false;
          // リロード
          await this.managed_list();
          //ローディング終了
          this.loader.is_loading = false;
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch(ex){
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);        
      }
    },
    // 更新実行
    async update_row(){

      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;
      let url = process.env.VUE_APP_RESTAPI_SET_MANAGED_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.mng_uuid = this.local_data.edit_data.mng_uuid;
      post_data.mng_cmp_uuid = this.local_data.edit_data.mng_cmp_uuid;
      post_data.mng_name = this.local_data.edit_data.mng_name;
      post_data.mng_chg_org_uuid = this.local_data.edit_data.mng_chg_org_uuid.join(",");
      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response));
        if (response.data.result == "OK") {
          //選択状態解除
          this.local_data.selected = false;
          this.local_data.row.toggleDetails();
          // リロード
          await this.managed_list();
          //ローディング終了
          this.loader.is_loading = false;
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch(ex){
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
      this.local_data.selected = false;
      this.local_data.row.toggleDetails();
    },
    delete_confirm(){
      this.$refs['confirm-modal'].show();
    },    
    delete_cancel(){
      this.$refs['confirm-modal'].hide();
    },
    // 削除実行
    async delete_row(){
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;
      let url = process.env.VUE_APP_RESTAPI_SET_MANAGED_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.mng_uuid = this.local_data.edit_data.mng_uuid;
      try {
        let response = await axios.delete( url, {data: post_data} );
        console.log(this.$route.path+":del_response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          //選択状態解除
          this.local_data.selected = false;
          this.local_data.row.toggleDetails();
          // リロード
          await this.managed_list();
          //ローディング終了
          this.loader.is_loading = false;
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;          
        }
        this.$refs['confirm-modal'].hide();
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);

        this.$refs['confirm-modal'].hide();
      }

      this.local_data.selected = false;
      this.local_data.row.toggleDetails();
    },
    get_dpt_info_add( data ){
      console.log("get_dpt_info="+JSON.stringify(data));
      this.local_data.add_data.mng_chg_org_uuid = data;
    },
    get_dpt_info_edit( data ){
      console.log("get_dpt_info="+JSON.stringify(data));
      this.local_data.edit_data.mng_chg_org_uuid = data;
    },
    async download(){
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;
      let UTF_8_BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
      let url = process.env.VUE_APP_RESTAPI_GET_MANAGED_CSV+"?sid="+this.$store.state.sid
      +"&mng_name="+this.bind_data.search_name;
      console.log(this.$route.path+":url="+url);
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));
        if ( response.data.result == "OK" ) {
          let rows = [];
          for( let i=0;i<response.data.managed_list.length;i++ ){
            let cols = response.data.managed_list[i];
            rows.push('"'+cols.join('","')+'"');
          }
          let link = document.createElement('a');
          link.download = "managed_list.csv";
          let blob = new Blob([UTF_8_BOM, rows.join('\n')], { type: 'text/csv;charset=utf-8' } );
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;           
        }
        // ローディング終了
        this.loader.is_loading = false;
      } catch ( ex ) {
        // ローディング終了
        this.loader.is_loading = false;
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);        
      }
    },
    async csv_upload(){
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;

      let url = process.env.VUE_APP_RESTAPI_SET_MANAGED_CSV;
      let post_data = new FormData();
      post_data.append("sid",this.$store.state.sid);
      post_data.append("csv_file",this.bind_data.csv_file);
      try {
  
        let response = await axios.post( url, post_data);
        console.log(this.$route.path+":response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.message.is_alert = true;
          this.message.variant = "info";
          this.message.message = response.data.message;            
        } else {
          
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message =  response.data.message;    
        }
        // リロード
        await this.managed_list();       
        // ローディング終了
        this.loader.is_loading = false;
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);     
      }
    },

  }
}