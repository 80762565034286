var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "FormManage" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _vm.state_data.template_table
            ? _c(
                "div",
                { staticClass: "mt-4" },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "table",
                          { staticClass: "table table-striped table-bordered" },
                          [
                            _c("tbody", [
                              _c("tr", [
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "background-color": "#343a40",
                                      color: "white",
                                      width: "120px"
                                    }
                                  },
                                  [_c("span", [_vm._v("付記情報")])]
                                ),
                                _c("td", [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.local_data.frm_explanation)
                                    )
                                  ])
                                ]),
                                _c(
                                  "th",
                                  {
                                    staticStyle: {
                                      "background-color": "#343a40",
                                      color: "white",
                                      width: "120px"
                                    }
                                  },
                                  [_c("span", [_vm._v("更新日時")])]
                                ),
                                _c("td", { staticStyle: { width: "180px" } }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.local_data.frm_upd_date_time)
                                    )
                                  ])
                                ])
                              ])
                            ])
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "table",
                          { staticClass: "table table-striped table-bordered" },
                          [
                            _c("thead", [
                              _c("tr", { staticClass: "thead-dark" }, [
                                _c("th", { attrs: { width: "5%" } }),
                                _c(
                                  "th",
                                  { attrs: { width: "5%", nowrap: "" } },
                                  [_vm._v("No")]
                                ),
                                _c("th", [_vm._v("項目名")]),
                                _c("th", { attrs: { nowrap: "" } }, [
                                  _vm._v("説明")
                                ]),
                                _c("th", { attrs: { nowrap: "" } }, [
                                  _vm._v("必須")
                                ]),
                                _c("th", [_vm._v("入力方法")]),
                                _c("th", { attrs: { width: "5%" } })
                              ])
                            ]),
                            _c(
                              "draggable",
                              _vm._b(
                                {
                                  tag: "tbody",
                                  attrs: { tag: "tbody" },
                                  on: {
                                    start: function($event) {
                                      _vm.drag = true
                                    },
                                    end: function($event) {
                                      _vm.drag = false
                                      _vm.id_reset()
                                    }
                                  },
                                  model: {
                                    value: _vm.bind_data.row_data,
                                    callback: function($$v) {
                                      _vm.$set(_vm.bind_data, "row_data", $$v)
                                    },
                                    expression: "bind_data.row_data"
                                  }
                                },
                                "tbody",
                                _vm.dragOptions,
                                false
                              ),
                              _vm._l(_vm.bind_data.row_data, function(item) {
                                return _c("tr", { key: item.id }, [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-danger",
                                            size: "sm"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.remove(item)
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: { icon: "trash" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("td", { attrs: { nowrap: "" } }, [
                                    _vm._v(_vm._s(item.id))
                                  ]),
                                  _c("td", [
                                    _vm._v(" " + _vm._s(item.item_title) + " ")
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.item_examine))]),
                                  _c("td", { attrs: { nowrap: "" } }, [
                                    _vm._v(
                                      " " + _vm._s(item.item_must_name) + " "
                                    )
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(item.item_type_name)),
                                    _c("br"),
                                    _vm._v(
                                      _vm._s(item.item_type_option_examine)
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    { attrs: { nowrap: "" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "outline-secondary",
                                            size: "sm"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.row_edit(item)
                                            }
                                          }
                                        },
                                        [_vm._v("編集")]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: "new_item_card",
                              expression: "'new_item_card'"
                            }
                          ],
                          staticClass: "m-1",
                          attrs: { variant: "outline-secondary" }
                        },
                        [_vm._v("項目を登録する")]
                      ),
                      _c(
                        "div",
                        { staticClass: "float-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "m-1",
                              attrs: {
                                variant: "outline-secondary",
                                disabled: _vm.state_regist_template_button
                              },
                              on: {
                                click: function($event) {
                                  return _vm.regist_confirm()
                                }
                              }
                            },
                            [_vm._v("フォームを更新する")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-collapse",
            {
              staticClass: "mb-3",
              attrs: { id: "new_item_card" },
              on: {
                shown: function($event) {
                  return _vm.newItemCardShow()
                }
              },
              model: {
                value: _vm.local_data.new_item_card_visivle,
                callback: function($$v) {
                  _vm.$set(_vm.local_data, "new_item_card_visivle", $$v)
                },
                expression: "local_data.new_item_card_visivle"
              }
            },
            [
              _c(
                "b-card",
                { staticClass: "mt-2" },
                [
                  _c(
                    "div",
                    { staticClass: "mt-1" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-0 mb-0" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { cols: "2" } },
                            [_vm._v("項目名")]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "10" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: "項目名を入力してください",
                                  state: _vm.state_item_title,
                                  maxlength: "100"
                                },
                                model: {
                                  value: _vm.local_data.item_title,
                                  callback: function($$v) {
                                    _vm.$set(_vm.local_data, "item_title", $$v)
                                  },
                                  expression: "local_data.item_title"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { cols: "2" } },
                            [_vm._v("説明")]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "10" } },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  placeholder: "項目説明を入力してください",
                                  maxlength: "500"
                                },
                                model: {
                                  value: _vm.local_data.item_examine,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data,
                                      "item_examine",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.item_examine"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1 mb-0" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { cols: "2" } },
                            [_vm._v("必須")]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "10" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    value: "on",
                                    "unchecked-value": "off"
                                  },
                                  model: {
                                    value: _vm.local_data.item_must,
                                    callback: function($$v) {
                                      _vm.$set(_vm.local_data, "item_must", $$v)
                                    },
                                    expression: "local_data.item_must"
                                  }
                                },
                                [_vm._v("必須")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1" },
                        [
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { cols: "2" } },
                            [_vm._v("入力方法")]
                          ),
                          _c(
                            "b-col",
                            { staticClass: "text-left", attrs: { cols: "10" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("b-form-radio-group", {
                                    attrs: {
                                      options: _vm.local_data.item_type_options,
                                      "button-variant": "outline-primary"
                                    },
                                    model: {
                                      value: _vm.local_data.item_type,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.local_data,
                                          "item_type",
                                          $$v
                                        )
                                      },
                                      expression: "local_data.item_type"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.local_data.item_type == "numeric"
                    ? [
                        _c(
                          "b-card",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "2" }
                                  },
                                  [_vm._v("数値オプション")]
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "単位を入力してください"
                                      },
                                      model: {
                                        value: _vm.local_data.item_numeric_unit,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data,
                                            "item_numeric_unit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.item_numeric_unit"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "上限値を入力してください"
                                      },
                                      model: {
                                        value:
                                          _vm.local_data.item_numeric_upper,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data,
                                            "item_numeric_upper",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.item_numeric_upper"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "下限値を入力してください"
                                      },
                                      model: {
                                        value:
                                          _vm.local_data.item_numeric_lower,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data,
                                            "item_numeric_lower",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.item_numeric_lower"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.local_data.item_type == "radio"
                    ? [
                        _c(
                          "b-card",
                          _vm._l(
                            _vm.local_data.item_radio_options.length,
                            function(n) {
                              return _c(
                                "b-row",
                                { key: n, staticClass: "mt-1" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { cols: "2" }
                                    },
                                    [_vm._v("選択項目")]
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder:
                                                "選択項目を入力してください"
                                            },
                                            model: {
                                              value:
                                                _vm.local_data
                                                  .item_radio_options[n - 1],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.local_data
                                                    .item_radio_options,
                                                  n - 1,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "local_data.item_radio_options[n-1]"
                                            }
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _vm.radio_options_plus_state(n)
                                                ? [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "success"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.add_radio_item(
                                                              n - 1
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "plus"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _vm.radio_options_trash_state(n)
                                                ? [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "warning"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.remove_radio_item(
                                                              n - 1
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "trash"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          ),
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.local_data.item_type == "checkbox"
                    ? [
                        _c(
                          "b-card",
                          _vm._l(
                            _vm.local_data.item_checkbox_options.length,
                            function(n) {
                              return _c(
                                "b-row",
                                { key: n, staticClass: "mt-1" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-right",
                                      attrs: { cols: "2" }
                                    },
                                    [_vm._v("選択項目")]
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              placeholder:
                                                "選択項目を入力してください"
                                            },
                                            model: {
                                              value:
                                                _vm.local_data
                                                  .item_checkbox_options[n - 1],
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.local_data
                                                    .item_checkbox_options,
                                                  n - 1,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "local_data.item_checkbox_options[n-1]"
                                            }
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            [
                                              _vm.checkbox_options_plus_state(n)
                                                ? [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "success"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.add_checkbox_item(
                                                              n - 1
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "plus"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e(),
                                              _vm.checkbox_options_trash_state(
                                                n
                                              )
                                                ? [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant: "warning"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.remove_checkbox_item(
                                                              n - 1
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "trash"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            }
                          ),
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.local_data.item_type == "pulldown"
                    ? [
                        _c(
                          "b-card",
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: { cols: "2" }
                                  },
                                  [_vm._v("区分")]
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c("b-form-select", {
                                      attrs: {
                                        options: _vm.bind_data.class_options
                                      },
                                      model: {
                                        value: _vm.bind_data.class_selected,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.bind_data,
                                            "class_selected",
                                            $$v
                                          )
                                        },
                                        expression: "bind_data.class_selected"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                variant: "success",
                                disabled: _vm.state_item_add_button
                              },
                              on: {
                                click: function($event) {
                                  return _vm.add("true")
                                }
                              }
                            },
                            [_vm._v("登録")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "item-edit-modal",
              attrs: { title: "編集" },
              on: {
                ok: function($event) {
                  return _vm.title_edit_ok()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-center" },
                [
                  _c("b-form-input", {
                    attrs: { placeholder: "項目名を入力してください" },
                    model: {
                      value: _vm.local_data.item_title_edit,
                      callback: function($$v) {
                        _vm.$set(_vm.local_data, "item_title_edit", $$v)
                      },
                      expression: "local_data.item_title_edit"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "must-edit-modal",
              attrs: { title: "編集" },
              on: {
                ok: function($event) {
                  return _vm.must_edit_ok()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-center" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { value: "on", "unchecked-value": "off" },
                      model: {
                        value: _vm.local_data.item_must_edit,
                        callback: function($$v) {
                          _vm.$set(_vm.local_data, "item_must_edit", $$v)
                        },
                        expression: "local_data.item_must_edit"
                      }
                    },
                    [_vm._v("必須")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "row-edit-modal",
              attrs: { title: "編集" },
              on: {
                ok: function($event) {
                  return _vm.row_edit_ok()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "2" } },
                        [_vm._v("項目名")]
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder: "項目名を入力してください",
                              maxlength: "100"
                            },
                            model: {
                              value: _vm.local_data.item_title_edit,
                              callback: function($$v) {
                                _vm.$set(_vm.local_data, "item_title_edit", $$v)
                              },
                              expression: "local_data.item_title_edit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "2" } },
                        [_vm._v("説明")]
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder: "説明を入力してください",
                              maxlength: "500"
                            },
                            model: {
                              value: _vm.local_data.item_examine_edit,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.local_data,
                                  "item_examine_edit",
                                  $$v
                                )
                              },
                              expression: "local_data.item_examine_edit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "2" } },
                        [_vm._v("必須")]
                      ),
                      _c(
                        "b-col",
                        [
                          _c("b-form-checkbox", {
                            attrs: { value: "on", "unchecked-value": "off" },
                            model: {
                              value: _vm.local_data.item_must_edit,
                              callback: function($$v) {
                                _vm.$set(_vm.local_data, "item_must_edit", $$v)
                              },
                              expression: "local_data.item_must_edit"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "b-modal",
            {
              ref: "confirm-modal",
              attrs: { title: "フォームを更新する", size: "lg" },
              scopedSlots: _vm._u([
                {
                  key: "modal-footer",
                  fn: function() {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.regist_cancel()
                            }
                          }
                        },
                        [_vm._v("キャンセル")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { variant: "danger" },
                          on: {
                            click: function($event) {
                              return _vm.regist_template()
                            }
                          }
                        },
                        [_vm._v("更新")]
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _c("span", { staticStyle: { "word-break": "keep-all" } }, [
                _vm._v("フォームを更新します。よろしいですか?"),
                _c("br"),
                _vm._v(
                  " フォームを更新した場合以下に影響が出ますのでご確認ください。"
                ),
                _c("br"),
                _c("span", { staticClass: "ml-3" }, [
                  _vm._v("・事案登録の入力項目")
                ]),
                _c("br"),
                _c("span", { staticClass: "ml-3" }, [
                  _vm._v("・出力帳票の出力項目")
                ]),
                _c("br")
              ]),
              _c("hr"),
              _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right pr-2",
                          attrs: { cols: "3" }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "vertical-align": "-webkit-baseline-middle"
                              }
                            },
                            [_vm._v("付記情報")]
                          ),
                          _c(
                            "b-badge",
                            {
                              staticClass: "ml-2",
                              staticStyle: {
                                "vertical-align": "-webkit-baseline-middle"
                              },
                              attrs: { pill: "", variant: "danger" }
                            },
                            [_vm._v("必須")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "pl-2" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              state: _vm.state_data.frm_explanation,
                              placeholder:
                                "フォームの説明などを入力してください（30文字以内）",
                              maxlength: "30"
                            },
                            model: {
                              value: _vm.bind_data.frm_explanation,
                              callback: function($$v) {
                                _vm.$set(_vm.bind_data, "frm_explanation", $$v)
                              },
                              expression: "bind_data.frm_explanation"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }