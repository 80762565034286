var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AffiliationApproval" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c("b-table", {
            staticClass: "mt-4",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.bind_data.result.list,
              fields: _vm.local_data.result.fields
            },
            scopedSlots: _vm._u([
              {
                key: "cell(approval_cmd)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "ml-2",
                        attrs: {
                          variant: "outline-secondary float-right",
                          size: "sm"
                        },
                        on: {
                          click: function($event) {
                            return _vm.normal(row)
                          }
                        }
                      },
                      [_vm._v("承認")]
                    ),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          variant: "outline-secondary float-right",
                          size: "sm"
                        },
                        on: {
                          click: function($event) {
                            return _vm.reject(row)
                          }
                        }
                      },
                      [_vm._v("差し戻し")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }