var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CaseDetailsView" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _vm.bind_data.fbs_info
        ? [
            _c(
              "div",
              { staticClass: "container text-left" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "m-2",
                    attrs: { variant: "outline-secondary" },
                    on: {
                      click: function($event) {
                        return _vm.back()
                      }
                    }
                  },
                  [_vm._v("戻る")]
                ),
                _c("div", { staticClass: "float-right" }),
                _vm.isOpenCommented()
                  ? [
                      _vm.isShowConsult
                        ? [
                            _c(
                              "b-card",
                              {
                                staticClass: "mx-2 mb-2",
                                attrs: { "header-bg-variant": "warning" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c(
                                            "h5",
                                            { staticClass: "mb-0 float-left" },
                                            [_vm._v("相談内容")]
                                          ),
                                          _vm.local_data.consultList.length > 1
                                            ? _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.collapse-1",
                                                      modifiers: {
                                                        "collapse-1": true
                                                      }
                                                    }
                                                  ],
                                                  staticClass: "float-right",
                                                  attrs: { size: "sm" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.consultListCallaoseBtnTitle
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  4021452815
                                )
                              },
                              _vm._l(_vm.local_data.consultList, function(
                                consult,
                                index
                              ) {
                                return _c(
                                  "div",
                                  [
                                    index <
                                    _vm.local_data.consultList.length - 1
                                      ? _c(
                                          "b-collapse",
                                          {
                                            attrs: { id: "collapse-1" },
                                            model: {
                                              value:
                                                _vm.state_data
                                                  .consultListVisible,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.state_data,
                                                  "consultListVisible",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "state_data.consultListVisible"
                                            }
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticStyle: { width: "100%" }
                                              },
                                              [
                                                consult.cnm_consult_content !=
                                                ""
                                                  ? [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("相談日時")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                consult.cnm_consult_date_time
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("相談内容")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.cnm_consult_content
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  : _vm._e(),
                                                consult.cnm_status == "answer"
                                                  ? [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("回答日時")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                consult.cnm_answer_date_time
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      consult.contact_user_name !=
                                                      ""
                                                        ? _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  width: "1%",
                                                                  "white-space":
                                                                    "nowrap"
                                                                },
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [_vm._v("回答者")]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  width: "1%",
                                                                  "white-space":
                                                                    "nowrap"
                                                                },
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [_vm._v("：")]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.contact_user_name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("回答内容")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.cnm_answer_content
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            consult.cnm_status == "cancel"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-2 text-center",
                                                    staticStyle: {
                                                      color: "red"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " この相談はキャンセルされました。 "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("hr")
                                          ]
                                        )
                                      : [
                                          _c(
                                            "table",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              consult.cnm_consult_content != ""
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("相談日時")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              consult.cnm_consult_date_time
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("相談内容")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "pre-wrap"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.cnm_consult_content
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e(),
                                              consult.cnm_status == "answer"
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("回答日時")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              consult.cnm_answer_date_time
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    consult.contact_user_name !=
                                                    ""
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "1%",
                                                                "white-space":
                                                                  "nowrap"
                                                              },
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [_vm._v("回答者")]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "1%",
                                                                "white-space":
                                                                  "nowrap"
                                                              },
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [_vm._v("：")]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.contact_user_name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("回答内容")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "pre-wrap"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.cnm_answer_content
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          ),
                                          consult.cnm_status == "cancel"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mt-2 text-center",
                                                  staticStyle: { color: "red" }
                                                },
                                                [
                                                  _vm._v(
                                                    " この相談はキャンセルされました。 "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mx-2 mb-4",
                            attrs: { "header-bg-variant": "light" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function() {
                                    return [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "m-0 p-0 font-weight-bold"
                                        },
                                        [_vm._v("番号")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              922230809
                            )
                          },
                          [
                            _vm.bind_data.fbs_info.fbs_version_number
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.bind_data.fbs_info.fbs_version_number
                                    )
                                  )
                                ])
                              : _c("div", [_vm._v("-")])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-card",
                          {
                            staticClass: "mx-2 mb-4",
                            attrs: { "header-bg-variant": "light" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "header",
                                  fn: function() {
                                    return [
                                      _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "m-0 p-0 font-weight-bold"
                                        },
                                        [_vm._v("コピー元")]
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              621386716
                            )
                          },
                          [
                            _vm.bind_data.fbs_info.fbs_original_number
                              ? _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.bind_data.fbs_info.fbs_original_number
                                    )
                                  )
                                ])
                              : _c("div", [_vm._v("-")])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "m-0 p-0 font-weight-bold" },
                                [_vm._v("報告書名称")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1878607194
                    )
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.bind_data.fbs_info.fbs_report_title) +
                        " "
                    )
                  ]
                ),
                _c("BaseDataUtils", {
                  attrs: { bas_info: _vm.bind_data.fbs_info }
                }),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mt-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("編集理由")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      2328381138
                    )
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.bind_data.fbs_info.fbs_chg_reason) + " "
                    )
                  ]
                ),
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2 mb-4",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v("報告者")]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1127441045
                    )
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.bind_data.fbs_info.fbs_reporter) + " "
                    )
                  ]
                ),
                _vm.bind_data.fbs_info.fbs_form_comp_type !== "complete"
                  ? [
                      _c(
                        "b-card",
                        {
                          staticClass: "mx-2 mb-4",
                          attrs: { "header-bg-variant": "light" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function() {
                                  return [
                                    _c(
                                      "h4",
                                      { staticClass: "mb-0 font-weight-bold" },
                                      [_vm._v("共有先")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1083516091
                          )
                        },
                        [
                          _vm._l(
                            _vm.bind_data.fbs_info.fbs_share_dest_list,
                            function(share) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(share.fbs_share_dest_uuid_name) +
                                    " "
                                )
                              ]
                            }
                          )
                        ],
                        2
                      )
                    ]
                  : _vm._e(),
                _vm.isCommented()
                  ? [
                      _vm.isShowConsult
                        ? [
                            _c(
                              "b-card",
                              {
                                staticClass: "mx-2 mb-2",
                                attrs: { "header-bg-variant": "warning" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c(
                                            "h5",
                                            { staticClass: "mb-0 float-left" },
                                            [_vm._v("相談内容")]
                                          ),
                                          _vm.local_data.consultList.length > 1
                                            ? _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-toggle",
                                                      rawName:
                                                        "v-b-toggle.collapse-1",
                                                      modifiers: {
                                                        "collapse-1": true
                                                      }
                                                    }
                                                  ],
                                                  staticClass: "float-right",
                                                  attrs: { size: "sm" }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.consultListCallaoseBtnTitle
                                                    )
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  false,
                                  4021452815
                                )
                              },
                              _vm._l(_vm.local_data.consultList, function(
                                consult,
                                index
                              ) {
                                return _c(
                                  "div",
                                  [
                                    index <
                                    _vm.local_data.consultList.length - 1
                                      ? _c(
                                          "b-collapse",
                                          {
                                            attrs: { id: "collapse-1" },
                                            model: {
                                              value:
                                                _vm.state_data
                                                  .consultListVisible,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.state_data,
                                                  "consultListVisible",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "state_data.consultListVisible"
                                            }
                                          },
                                          [
                                            _c(
                                              "table",
                                              {
                                                staticStyle: { width: "100%" }
                                              },
                                              [
                                                consult.cnm_consult_content !=
                                                ""
                                                  ? [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("相談日時")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                consult.cnm_consult_date_time
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("相談内容")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.cnm_consult_content
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  : _vm._e(),
                                                consult.cnm_status == "answer"
                                                  ? [
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("回答日時")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                consult.cnm_answer_date_time
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      consult.contact_user_name !=
                                                      ""
                                                        ? _c("tr", [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  width: "1%",
                                                                  "white-space":
                                                                    "nowrap"
                                                                },
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [_vm._v("回答者")]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticStyle: {
                                                                  width: "1%",
                                                                  "white-space":
                                                                    "nowrap"
                                                                },
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [_vm._v("：")]
                                                            ),
                                                            _c(
                                                              "td",
                                                              {
                                                                attrs: {
                                                                  valign: "top"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.contact_user_name
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ])
                                                        : _vm._e(),
                                                      _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("回答内容")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            staticStyle: {
                                                              width: "1%",
                                                              "white-space":
                                                                "nowrap"
                                                            },
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [_vm._v("：")]
                                                        ),
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              valign: "top"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "pre-wrap"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    consult.cnm_answer_content
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  : _vm._e()
                                              ],
                                              2
                                            ),
                                            consult.cnm_status == "cancel"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mt-2 text-center",
                                                    staticStyle: {
                                                      color: "red"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " この相談はキャンセルされました。 "
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c("hr")
                                          ]
                                        )
                                      : [
                                          _c(
                                            "table",
                                            { staticStyle: { width: "100%" } },
                                            [
                                              consult.cnm_consult_content != ""
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("相談日時")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              consult.cnm_consult_date_time
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("相談内容")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "pre-wrap"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.cnm_consult_content
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e(),
                                              consult.cnm_status == "answer"
                                                ? [
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("回答日時")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              consult.cnm_answer_date_time
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]),
                                                    consult.contact_user_name !=
                                                    ""
                                                      ? _c("tr", [
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "1%",
                                                                "white-space":
                                                                  "nowrap"
                                                              },
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [_vm._v("回答者")]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticStyle: {
                                                                width: "1%",
                                                                "white-space":
                                                                  "nowrap"
                                                              },
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [_vm._v("：")]
                                                          ),
                                                          _c(
                                                            "td",
                                                            {
                                                              attrs: {
                                                                valign: "top"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.contact_user_name
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    _c("tr", [
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("回答内容")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            width: "1%",
                                                            "white-space":
                                                              "nowrap"
                                                          },
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [_vm._v("：")]
                                                      ),
                                                      _c(
                                                        "td",
                                                        {
                                                          attrs: {
                                                            valign: "top"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "white-space":
                                                                  "pre-wrap"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  consult.cnm_answer_content
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ])
                                                  ]
                                                : _vm._e()
                                            ],
                                            2
                                          ),
                                          consult.cnm_status == "cancel"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "mt-2 text-center",
                                                  staticStyle: { color: "red" }
                                                },
                                                [
                                                  _vm._v(
                                                    " この相談はキャンセルされました。 "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                )
                              }),
                              0
                            )
                          ]
                        : _vm._e()
                    ]
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "mx-2 mb-4" },
                  [
                    _c(
                      "b-row",
                      [
                        _c("b-col", [
                          _c("div", { staticClass: "text-right" }, [
                            _vm._v(
                              "最終更新者：" +
                                _vm._s(
                                  _vm.bind_data.fbs_info.fbs_upd_usr_uuid_name
                                )
                            )
                          ])
                        ]),
                        _c("b-col", [
                          _c("div", [
                            _vm._v(
                              "最終更新日時：" +
                                _vm._s(_vm.bind_data.fbs_info.fbs_upd_date_time)
                            )
                          ])
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              2
            ),
            _vm.isCancelFormButton()
              ? _c(
                  "div",
                  { staticClass: "container text-right" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "mb-2 mr-2",
                        attrs: { variant: "outline-secondary" },
                        on: {
                          click: function($event) {
                            return _vm.handleCancelForm()
                          }
                        }
                      },
                      [_vm._v("相談をキャンセルする")]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "cancelForm",
          attrs: { title: "相談をキャンセルする" },
          on: {
            ok: function($event) {
              return _vm.cancelForm()
            }
          }
        },
        [_vm._v("相談をキャンセルします。よろしいですか？")]
      ),
      _c("br"),
      _c("br"),
      _c("br")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }