var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserRegist" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "div",
            { staticClass: "mt-3" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-secondary" },
                  on: {
                    click: function($event) {
                      _vm.add_user()
                      _vm.add_cmp_change()
                    }
                  }
                },
                [_vm._v("新規")]
              )
            ],
            1
          ),
          _vm.local_data.add_data.selected
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    { staticClass: "card-shadow border-1 mt-2" },
                    [
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c("b-col", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(_vm.local_data.user_count) + " ")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("会社名")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  options: _vm.bind_data.org_cmp_uuid_options,
                                  placeholder: "選択してください"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.add_cmp_change()
                                  }
                                },
                                model: {
                                  value: _vm.local_data.add_data.usr_cmp_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data.add_data,
                                      "usr_cmp_id",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.add_data.usr_cmp_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("ログインID")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder:
                                    "半角英小文字,数字で32文字以下で入力してください",
                                  state: _vm.state_usr_login_id,
                                  type: "text",
                                  maxlength: "32"
                                },
                                on: { input: _vm.change_usr_login_id },
                                model: {
                                  value: _vm.local_data.add_data.usr_login_id,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data.add_data,
                                      "usr_login_id",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.add_data.usr_login_id"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("パスワード")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder:
                                    "半角英大小文字,数字,記号(./?-@)を含む8文字以上32文字以下で入力してください",
                                  state: _vm.state_usr_password,
                                  type: "text",
                                  maxlength: "32"
                                },
                                model: {
                                  value: _vm.local_data.add_data.usr_password,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data.add_data,
                                      "usr_password",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.add_data.usr_password"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("ユーザー名")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder:
                                    "ユーザー名を32文字以下で入力してください",
                                  state: _vm.state_usr_name,
                                  type: "text",
                                  maxlength: "32"
                                },
                                model: {
                                  value: _vm.local_data.add_data.usr_name,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data.add_data,
                                      "usr_name",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.add_data.usr_name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("プロフィール画像")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c(
                                "b-input-group-append",
                                [
                                  _c("b-form-file", {
                                    attrs: {
                                      placeholder:
                                        "ファイルを選択するか、ここにファイルをドロップしてください。",
                                      "drop-placeholder":
                                        "ここにファイルをドロップしてください。",
                                      accept: ".png,.jpg,.JPG,.PNG",
                                      "browse-text": "参照"
                                    },
                                    on: { input: _vm.addFormatNames },
                                    model: {
                                      value:
                                        _vm.local_data.add_data
                                          .usr_profile_img_file,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.local_data.add_data,
                                          "usr_profile_img_file",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "local_data.add_data.usr_profile_img_file"
                                    }
                                  }),
                                  !_vm.state_add_usr_profile_img
                                    ? [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              variant: "outline-secondary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.img_delete_add()
                                              }
                                            }
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "trash" }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              id: "add_usr_profile_img",
                                              variant: "outline-secondary"
                                            }
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "image" }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              target: "add_usr_profile_img",
                                              placement: "topleft"
                                            }
                                          },
                                          [
                                            _c("b-img", {
                                              attrs: {
                                                src:
                                                  _vm.local_data.add_data
                                                    .usr_profile_img_src
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v(" 所属部署")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _vm.state_data.treeselectdialogs
                                ? [
                                    _c("treeselectdialogs", {
                                      attrs: {
                                        dpt_info: _vm.bind_data.dpt_options,
                                        dpt_selected_array:
                                          _vm.bind_data.dummy_array,
                                        dpt_selected_string:
                                          _vm.bind_data.dpt_selected,
                                        multiple: false,
                                        branch_nodes: false
                                      },
                                      on: { save: _vm.get_dpt_info_add }
                                    })
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("役職名")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  maxlength: "32",
                                  placeholder:
                                    "役職名を32文字以下で入力してください"
                                },
                                model: {
                                  value: _vm.local_data.add_data.usr_post,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data.add_data,
                                      "usr_post",
                                      $$v
                                    )
                                  },
                                  expression: "local_data.add_data.usr_post"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("メールアドレス")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    "メールアドレスを入力してください（カンマ区切りで複数登録可）",
                                  state: _vm.state_usr_mail_address,
                                  maxlength: "256"
                                },
                                model: {
                                  value:
                                    _vm.local_data.add_data.usr_mail_address,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data.add_data,
                                      "usr_mail_address",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "local_data.add_data.usr_mail_address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("表示順位")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  state: _vm.state_add_usr_sort_order,
                                  maxlength: "8",
                                  placeholder:
                                    "表示順位を8文字以下で入力してください"
                                },
                                model: {
                                  value: _vm.local_data.add_data.usr_sort_order,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.local_data.add_data,
                                      "usr_sort_order",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "local_data.add_data.usr_sort_order"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "4",
                                md: "4",
                                lg: "3",
                                xl: "2"
                              }
                            },
                            [_vm._v("多要素認証")]
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                xs: "12",
                                sm: "8",
                                md: "8",
                                lg: "9",
                                xl: "10"
                              }
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    value: "on",
                                    "unchecked-value": "off"
                                  },
                                  model: {
                                    value:
                                      _vm.local_data.add_data
                                        .usr_multi_auth_invalid,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.add_data,
                                        "usr_multi_auth_invalid",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "local_data.add_data.usr_multi_auth_invalid"
                                  }
                                },
                                [_vm._v("無効化する")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "ml-2 float-right",
                                  attrs: {
                                    variant: "outline-secondary",
                                    disabled: _vm.state_add_new_button
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.add_new()
                                    }
                                  }
                                },
                                [_vm._v("登録")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "float-right",
                                  attrs: { variant: "outline-secondary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.add_cancel()
                                    }
                                  }
                                },
                                [_vm._v("キャンセル")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { sm: "4", lg: "4" } },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.bind_data.org_cmp_uuid_options,
                      placeholder: "選択してください"
                    },
                    model: {
                      value: _vm.bind_data.search_cmp_id,
                      callback: function($$v) {
                        _vm.$set(_vm.bind_data, "search_cmp_id", $$v)
                      },
                      expression: "bind_data.search_cmp_id"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "6", lg: "6" } },
                [
                  _c(
                    "b-input-group",
                    { staticClass: "mb-2" },
                    [
                      _c(
                        "b-input-group-prepend",
                        { attrs: { "is-text": "" } },
                        [_c("b-icon", { attrs: { icon: "search" } })],
                        1
                      ),
                      _c("b-form-input", {
                        attrs: {
                          placeholder:
                            "ユーザー名（部分一致）を入力して検索してください",
                          maxlength: "32"
                        },
                        model: {
                          value: _vm.bind_data.search_name,
                          callback: function($$v) {
                            _vm.$set(_vm.bind_data, "search_name", $$v)
                          },
                          expression: "bind_data.search_name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "2", lg: "2" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        block: "",
                        variant: "outline-secondary",
                        disabled: false
                      },
                      on: {
                        click: function($event) {
                          _vm.seach_users_list()
                          _vm.users_count()
                        }
                      }
                    },
                    [_vm._v("検索")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                {
                  attrs: {
                    "offset-sm": "10",
                    sm: "2",
                    "offset-lg": "10",
                    lg: "2"
                  }
                },
                [
                  _vm.bind_data.dl_flg
                    ? _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: { block: "", variant: "outline-secondary" },
                          on: {
                            click: function($event) {
                              return _vm.download()
                            }
                          }
                        },
                        [_vm._v("ダウンロード")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                { attrs: { sm: "10", lg: "10" } },
                [
                  _c("b-form-file", {
                    attrs: {
                      placeholder:
                        "ファイルを選択するか、ここにファイルをドロップしてください。",
                      accept: ".csv",
                      "browse-text": "参照"
                    },
                    model: {
                      value: _vm.bind_data.csv_file,
                      callback: function($$v) {
                        _vm.$set(_vm.bind_data, "csv_file", $$v)
                      },
                      expression: "bind_data.csv_file"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "2", lg: "2" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: {
                        block: "",
                        variant: "outline-secondary",
                        disabled: _vm.state_upload
                      },
                      on: {
                        click: function($event) {
                          return _vm.csv_upload()
                        }
                      }
                    },
                    [_vm._v("アップロード")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-3 ml-2" },
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c("b-col", { staticClass: "text-right" }, [
                    _vm._v(_vm._s(_vm.local_data.user_count))
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("b-table", {
            staticClass: "mt-1",
            attrs: {
              fixed: "",
              responsive: "",
              striped: "",
              hover: "",
              items: _vm.bind_data.result.list,
              fields: _vm.local_data.fields
            },
            scopedSlots: _vm._u([
              {
                key: "table-colgroup",
                fn: function(scope) {
                  return _vm._l(scope.fields, function(field) {
                    return _c("col", {
                      key: field.key,
                      style: { width: field.width }
                    })
                  })
                }
              },
              {
                key: "cell(app_cmd)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: { variant: "outline-secondary", size: "sm" },
                        on: {
                          click: function($event) {
                            _vm.edit_row(row)
                            _vm.edit_cmp_change()
                          }
                        }
                      },
                      [_vm._v("編集")]
                    )
                  ]
                }
              },
              {
                key: "row-details",
                fn: function(row) {
                  return [
                    _c(
                      "b-card",
                      { staticClass: "card-shadow border-1 mt-2 ml-2" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("会社名")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.local_data.edit_data.cmp_name)
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("ログインID")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "半角英小文字,数字でを32文字以下で入力してください",
                                    state: _vm.state_edit_usr_login_id,
                                    maxlength: "32"
                                  },
                                  model: {
                                    value:
                                      _vm.local_data.edit_data.usr_login_id,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.edit_data,
                                        "usr_login_id",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "local_data.edit_data.usr_login_id"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("パスワード")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "空白の場合は更新されません。半角英大小文字,数字,記号(./?-@)を含む8文字以上32文字以下で入力してください",
                                    state: _vm.state_edit_usr_password,
                                    maxlength: "32"
                                  },
                                  model: {
                                    value:
                                      _vm.local_data.edit_data.usr_password,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.edit_data,
                                        "usr_password",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "local_data.edit_data.usr_password"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("ユーザー名")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "ユーザー名を32文字以下で入力してください",
                                    state: _vm.state_edit_usr_name,
                                    maxlength: "32"
                                  },
                                  model: {
                                    value: _vm.local_data.edit_data.usr_name,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.edit_data,
                                        "usr_name",
                                        $$v
                                      )
                                    },
                                    expression: "local_data.edit_data.usr_name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("プロフィール画像")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                !_vm.state_edit_usr_profile_img
                                  ? [
                                      _c("div", [
                                        _c("img", {
                                          attrs: {
                                            src:
                                              _vm.local_data.edit_data
                                                .usr_profile_img_src,
                                            width: "100",
                                            height: "100"
                                          }
                                        })
                                      ])
                                    ]
                                  : _vm._e(),
                                _c(
                                  "b-input-group-append",
                                  [
                                    _c("b-form-file", {
                                      attrs: {
                                        placeholder:
                                          "ファイルを選択するか、ここにファイルをドロップしてください。",
                                        "drop-placeholder":
                                          "ここにファイルをドロップしてください。",
                                        accept: ".png,.jpg,.JPG,.PNG",
                                        "browse-text": "参照"
                                      },
                                      on: { input: _vm.editFormatNames },
                                      model: {
                                        value:
                                          _vm.local_data.edit_data
                                            .usr_profile_img_file,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data.edit_data,
                                            "usr_profile_img_file",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.edit_data.usr_profile_img_file"
                                      }
                                    }),
                                    !_vm.state_edit_usr_profile_img
                                      ? [
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                variant: "outline-secondary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.img_delete_edit()
                                                }
                                              }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "trash" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              attrs: {
                                                id: "edit_usr_profile_img",
                                                variant: "outline-secondary"
                                              }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: { icon: "image" }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target: "edit_usr_profile_img",
                                                placement: "topleft"
                                              }
                                            },
                                            [
                                              _c("b-img", {
                                                attrs: {
                                                  src:
                                                    _vm.local_data.edit_data
                                                      .usr_profile_img_src
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v(" 所属部署")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _vm.state_data.treeselectdialogs
                                  ? [
                                      _c("treeselectdialogs", {
                                        attrs: {
                                          dpt_info: _vm.bind_data.dpt_options,
                                          dpt_selected_array:
                                            _vm.bind_data.dummy_array,
                                          dpt_selected_string:
                                            _vm.local_data.edit_data
                                              .usr_org_uuid,
                                          multiple: false,
                                          branch_nodes: false
                                        },
                                        on: { save: _vm.get_dpt_info_edit }
                                      })
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("役職名")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "役職名を32文字以下で入力してください",
                                    maxlength: "32"
                                  },
                                  model: {
                                    value: _vm.local_data.edit_data.usr_post,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.edit_data,
                                        "usr_post",
                                        $$v
                                      )
                                    },
                                    expression: "local_data.edit_data.usr_post"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("メールアドレス")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    placeholder:
                                      "メールアドレスを入力してください（カンマ区切りで複数登録可）",
                                    state: _vm.state_edit_usr_mail_address,
                                    maxlength: "256"
                                  },
                                  model: {
                                    value:
                                      _vm.local_data.edit_data.usr_mail_address,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.edit_data,
                                        "usr_mail_address",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "local_data.edit_data.usr_mail_address"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("表示順位")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    state: _vm.state_edit_usr_sort_order,
                                    maxlength: "8",
                                    placeholder:
                                      "表示順位を8文字以下で入力してください"
                                  },
                                  model: {
                                    value:
                                      _vm.local_data.edit_data.usr_sort_order,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.edit_data,
                                        "usr_sort_order",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "local_data.edit_data.usr_sort_order"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("多要素認証")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    attrs: {
                                      value: "on",
                                      "unchecked-value": "off"
                                    },
                                    model: {
                                      value:
                                        _vm.local_data.edit_data
                                          .usr_multi_auth_invalid,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.local_data.edit_data,
                                          "usr_multi_auth_invalid",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "local_data.edit_data.usr_multi_auth_invalid"
                                    }
                                  },
                                  [_vm._v("無効化する")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-left",
                                    attrs: { variant: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.delete_confirm()
                                      }
                                    }
                                  },
                                  [_vm._v("削除")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "ml-2 float-right",
                                    attrs: {
                                      variant: "outline-secondary",
                                      disabled: _vm.state_edit_update_button
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.update_row()
                                      }
                                    }
                                  },
                                  [_vm._v("更新")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-right",
                                    attrs: { variant: "outline-secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.cancel_row(row)
                                      }
                                    }
                                  },
                                  [_vm._v("キャンセル")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "confirm-modal",
          attrs: { title: "削除" },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function($event) {
                          return _vm.delete_cancel()
                        }
                      }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.delete_row()
                        }
                      }
                    },
                    [_vm._v("削除")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("p", [
            _vm._v(
              "ユーザ名：" +
                _vm._s(_vm.local_data.edit_data.usr_name) +
                " を削除します。よろしいですか?"
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }