var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CaseDetailsDiff" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-button",
            {
              staticClass: "m-2",
              attrs: { variant: "outline-secondary" },
              on: {
                click: function($event) {
                  return _vm.back()
                }
              }
            },
            [_vm._v("戻る")]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("div", { staticClass: "mx-2 font-weight-bold" }, [
                    _vm._v("編集前")
                  ]),
                  _vm.bind_data.fbs_info_initial
                    ? [
                        _c("BaseDataUtils", {
                          attrs: { bas_info: _vm.bind_data.fbs_info_initial }
                        })
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "b-col",
                [
                  _c("div", { staticClass: "mx-2 font-weight-bold" }, [
                    _vm._v("完了後")
                  ]),
                  _vm.bind_data.fbs_info_complete
                    ? [
                        _c("BaseDataUtils", {
                          attrs: { bas_info: _vm.bind_data.fbs_info_complete }
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("br"),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }