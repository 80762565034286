<template>
  <div ref="container">
    <template v-if="state_data.dpt_info">
      <b-row><b-col xs="12" sm="4" md="4" lg="3" xl="2">
        <b-button block @click="treeselect_dialog_show()" variant="outline-secondary" class="float-left" size="sm">{{ local_data.button_name }}</b-button>
      </b-col><b-col>
        <div v-if="local_data.multiple">
          <span v-for="dpt_selected in bind_data.dpt_selected" :key="dpt_selected" class="mr-2 selected_data" >
            {{ get_dpt_name(dpt_selected,bind_data.dpt_info) }}
          </span>
        </div>
        <div v-if="!local_data.multiple">
          {{ get_dpt_name(bind_data.dpt_selected,bind_data.dpt_info) }}
        </div>
      </b-col></b-row>
    </template>

    <b-modal scrollable ref="treeselect-dialog" title="組織を選択" @ok="handleOk" @hidden="handleHidden">
      <treeselect v-model="bind_data.dpt_selected" :multiple="local_data.multiple" :disable-branch-nodes="local_data.branch_nodes" :options="bind_data.dpt_info" :always-open="local_data.always_open" placeholder="選択してください" noOptionsText="選択するものがありません" noResultsText="結果は見つかりませんでした" />
      <br><br><br><br><br><br><br><br><br><br><br><br><br>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "TreeSelectDialogs2",
  props: {
    // dpt_info: {
    //   type: Array,
    //   default() {
    //     return {};
    //   }
    // },
    dpt_selected_array: {
      type: Array,
      default() {
        return [];
      }
    },
    dpt_selected_string: {
      type: String,
      default() {
        return "";
      }
    },
    multiple: {
      type: Boolean,
      default() {
        return false;
      }
    },
    branch_nodes: {
      type: Boolean,
      default() {
        return false;
      }
    },
    button_name: {
      type: String,
      default() {
        return "";
      }
    },
  },
  data() {
    return {
      dpt_info: null,
      bind_data: {
        dpt_info: null,
        dpt_selected: null,
      },
      state_data: {
        dpt_info: false,
      },
      local_data: {
        always_open: true,
        multiple: true,
        branch_nodes: true,
        button_name: "選択",
      }
    }
  },
  watch: {
    'bind_data.dpt_info': function(){
      if( this.bind_data.dpt_info != null ){
        this.bind_data.dpt_info = this.dpt_info;
        this.state_data.dpt_info = true;
      }
    },
    dpt_selected_array: function () {
      this.bind_data.dpt_selected = this.dpt_selected_array;
    },
  },
  created(){
  },  
  mounted() {
    this.dpt_info = JSON.parse(localStorage.getItem('organization_info2'));
  
    if( this.multiple == true ){
      this.local_data.multiple = true;
      if( this.dpt_selected_array != null ){
        this.bind_data.dpt_selected = this.dpt_selected_array;
      } 
    } else {
      this.local_data.multiple = false;
      if( this.dpt_selected_string !== "" && this.dpt_selected_string !== null ){
        this.bind_data.dpt_selected = this.dpt_selected_string;
      } else{
        this.dpt_selected_string = "";
      } 
    }
    if( this.branch_nodes == true ){
      this.local_data.branch_nodes = false;
    } else {
      this.local_data.branch_nodes = true;
    }
    this.bind_data.dpt_info = this.dpt_info;
    if( this.button_name !== "" && this.button_name !== null ){
      this.local_data.button_name = this.button_name;
    }
  },

  // ローカル関数
  methods: {
    treeselect_dialog_show(){
      this.$refs['treeselect-dialog'].show();
    },
    handleOk(){
      this.$emit("save",this.bind_data.dpt_selected);
      if(this.bind_data.dpt_selected !== null && !Array.isArray(this.bind_data.dpt_selected)){
        this.dpt_selected_string = this.bind_data.dpt_selected;
      } else{
        this.dpt_selected_string = "";
      }
    },
    handleHidden(){
      //変更前の値を設定して、再描画させる（OKボタンを押下した場合でも、一旦は元に戻す）
      if( this.multiple == true ){
        if( this.dpt_selected_array != null ){
          this.bind_data.dpt_selected = this.dpt_selected_array;
        } 
      } else {
        if( this.dpt_selected_string !== null ){
          this.bind_data.dpt_selected = this.dpt_selected_string;
        }
      }
    },
    get_node_by_id( id, node ){
      var reduce = [].reduce;
      function runner(result, node){
        if(result || !node) return result;
        return node.id === id && node || //is this the proper node?
          runner(null, node.children) || //process this nodes children
          reduce.call(Object(node), runner, result);  //maybe this is some ArrayLike Structure
      }
      return runner(null, node);
    },
    get_dpt_name( id, node) {
      if( id == null || id == "" ) return "";
      let result = this.get_node_by_id( id, node );
      console.log("get_dpt_name="+JSON.stringify(result));
      if( result == null ) return "";
      return result.label;
    }
  }
}
</script>

<style scoped>
.selected_data {
  color: gray;
  font-size: 0.9em;
}
.treeselect-dialog-body {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
}
</style>
