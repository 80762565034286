import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import BaseDataUtils from '@/components/BaseDataUtils/BaseDataUtils.vue';

export default {
  name: 'CaseDetails', // ココを更新
  components: {
    Loading,
    BaseDataUtils,
  },

  // 共通関数
  mixins: [common],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        bas_info: null,
        fbs_bas_info: null,
      },
      // ローカルデータ
      local_data: {
      },
      // ローカルデータ
      state_data: {
        form_data: false,
        fbs_form_data: false,
      }
    }
  },

  // 既定計算
  computed: {
  },
  // インスタンス初期化後
  created(){
    this.$eventHub.$emit( "navbar_title", "報告データ詳細" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){

    
    // 選択された事案UUIDで、事案の情報を取得する
    let bas_info = this.$store.state.selected_bas_info
    this.bind_data.bas_info = bas_info;

    //報告完了時は、帳票事案を表示
    console.log("bas_comp_type="+this.bind_data.bas_info.bas_comp_type);
    if( this.bind_data.bas_info.bas_comp_type === 'complete' ){

      try {
        const config = {
          params: {
            sid: this.$store.state.sid,
            fbs_bas_uuid: bas_info.bas_uuid,
          },
        };
        
        const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_FORM_BASICS_LIST, config);
        if (res.data.result === 'OK') {
          console.log(JSON.stringify(res.data));
          this.bind_data.fbs_bas_info = res.data.form_basics_list[0];
          if (this.bind_data.fbs_bas_info) {
            this.bind_data.fbs_bas_info.timeline_list = this.bind_data.fbs_bas_info.form_timeline_list;
          }
          this.$store.dispatch("commit_set_sid",res.data.sid);
          this.state_data.fbs_form_data = true;
        }
        else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = res.data.message;
        }
      }
      catch(e) {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(e);
      }
    }
    // 対応中の時は、そのまま事案を表示
    else {
      this.state_data.form_data = true;
    }
  },
  // ローカル関数
  methods: {

    back: function(){
      // 画面遷移
      this.trans_back();
    },

    show_before: function(){

      // 画面遷移
      this.transition("/Home/CaseDetailsDiff");
    },

    /**
     * メッセージ表示
     */
    handleMessage(message) {
      this.message.is_alert = true;
      this.message.variant = message.variant;
      this.message.message = message.message;
    },
  }
}