import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
import moment from 'moment-timezone';
import common from '@/lib/common.js';
import image from '@/lib/image.js';

export default {
	name: 'DownLoadSelect', // ココを更新
	components: {
		Loading,
	},

	// 共通関数
	mixins: [
		common,
		image,
	],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				fbs_info: null,
				fbs_selected: "",
				fbs_lists: [],
				format_selected: "",
				format_lists: [],
			},
			// ローカルデータ
			local_data: {
				fbs_fields: [
					{ key: "app_cmd", label: ""},
					{ key: "fbs_save_date_time_show", label: "保存日付"},
					{ key: "fbs_report_title", label: "名称", class:"text-nowrap" },
					{ key: "fbs_form_comp_name", label: "状態", class:"text-nowrap" },
					{ key: "fbs_reporter", label: "報告者", class:"text-nowrap" },
					{ key: "fbs_confirmer_uuid_name", label: "確定者", class:"text-nowrap" },
				],
				format_fields: [
					{ key: "app_cmd", label: ""},
					{ key: "ofm_save_date_time_show", label: "保存日付"},
					{ key: "ofm_name", label: "名称", class:"text-nowrap" },
				],
				download_params: null,
			},
			// 状態
			state_data: {
				bas_info: null,
				force_download: false,
			}
		}
	},

	// 既定計算
	computed: {
		downloadState: function() {
			if (this.bind_data.fbs_selected && this.bind_data.format_selected) {
				return false;
			}

			return true;
		},
	},
	// インスタンス初期化後
	created(){
		// タイトル更新イベント
		this.$eventHub.$emit( "navbar_title", "報告データダウンロード" );
	},
	// インスタンス破棄後
	destroyed: function() {
	},
	// インスタンスマウント後
	async mounted(){
		this.loader.is_loading = true;
		await this.$nextTick();

		this.bind_data.bas_info = this.$store.state.selected_bas_info;

		try {
			const config = {
				params: {
					sid: this.$store.state.sid,
					fbs_bas_uuid: this.bind_data.bas_info.bas_uuid,
				},
			};

			const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_FORM_BASICS_DOWNLOAD_LIST, config);
			if (res.data.result === 'OK') {
				this.bind_data.fbs_lists = res.data.form_basics_list;
				this.bind_data.format_lists = res.data.output_form_list;
			
				for (let i=0 ; i<this.bind_data.fbs_lists.length ; i++) {
					const fbs = this.bind_data.fbs_lists[i];
					fbs.fbs_save_date_time_show = fbs.fbs_save_date_time.substr(0, 16);
				}
				for (let i=0 ; i<this.bind_data.format_lists.length ; i++) {
					const format = this.bind_data.format_lists[i];
					format.ofm_save_date_time_show = format.ofm_save_date_time.substr(0, 16);
				}

				if (this.bind_data.fbs_lists.length !== 0) {
					this.bind_data.fbs_selected = this.bind_data.fbs_lists[0].fbs_uuid;
				}
				if (this.bind_data.format_lists.length !== 0) {
					this.bind_data.format_selected = this.bind_data.format_lists[0].ofm_uuid;
				}
				this.$store.dispatch("commit_set_sid", res.data.sid);
			}
			else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		}
		catch(e) {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = JSON.stringify(e);
		}

		for( let i=0;i<this.bind_data.fbs_lists.length;i++ ){
			this.bind_data.fbs_lists[i].bas_info = this.$store.state.selected_bas_info;
		}

		this.bind_data.bas_info = this.$store.state.selected_bas_info;

		// 画面表示
		this.state_data.bas_info = true;

		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {

		back(){
			//画面遷移
			this.transition("/Home/ReportingData");
		},

		/**
		 * ダウンロード実行
		 */
		async handleDownload( type ) {
			try {
				let params = null;

				if ( this.state_data.force_download && this.local_data.download_params ) {
					params = this.local_data.download_params;
					params.force_download = this.state_data.force_download;
				} else {
					params = {
						sid: this.$store.state.sid,
						fbs_uuid: this.bind_data.fbs_selected,
						ofm_uuid: this.bind_data.format_selected,
						ofm_type: type,
					};
				}

				const res = await axios.get( process.env.VUE_APP_RESTAPI_DOWNLOAD_FORM_BASICS_DATA, {params} );

				this.state_data.force_download = false;
				this.local_data.download_params = null;

				if (res.data.result === 'OK') {
					this.$store.dispatch("commit_set_sid",res.data.sid);

					const blob = this.base64ToBlobImage(res.data.media_file, res.data.mime_type);
					const url = window.URL.createObjectURL(blob);
	
					const link = this.$refs.reportDownload;
					link.href = url;
					const fbs = this.bind_data.fbs_lists.find(f => f.fbs_uuid === this.bind_data.fbs_selected);
					const format = this.bind_data.format_lists.find(f => f.ofm_uuid === this.bind_data.format_selected);
					const now = moment().tz("Asia/Tokyo").format("YYYYMMDDHHmm");
					link.download = `【報告書】${this.bind_data.bas_info.bas_subject}-${fbs.fbs_report_title}-${format.ofm_name}-${now}.${res.data.ofm_type}`;
					link.click();
				} else {
					if ( res.data.message == 'no_image' ) {
						this.local_data.download_params = params;
						this.$refs['confirmForceDownload'].show();
					} else {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				}
			}
			catch(e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
		},

		async handleForceDownload() {
			this.state_data.force_download = true;

			await this.handleDownload();
		},
	}
}