import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
//import validator from 'validator';
//import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import Vue2OrgTree from 'vue2-org-tree'
import 'vue2-org-tree/dist/style.css'

export default {
  name: 'OrganizationRegist', // ココを更新
  components: {
    Loading,
    Vue2OrgTree
  },

  // 共通関数
  mixins: [common,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        dpt_info_list: null,
      },
      // ローカルデータ
      local_data: {
      },
      state_data: {
        tree: false,
      }
    }
  },

  // 既定計算
  computed: {
    
  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "組織表示" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");

    // ローディング開始
    this.loader.is_loading = true;

    await this.set_org_map("allslf",null,null);

    this.bind_data.dpt_info_list = JSON.parse(localStorage.getItem('organization_info'));
    //this.bind_data.dpt_info_list = array[0];
    //console.log(JSON.stringify(this.bind_data.dpt_info));

    this.state_data.tree = true;

    // ローディング終了
    this.loader.is_loading = false;
  },
  // ローカル関数
  methods: {
    labelClass () {
      return "bg_node";
    },
    renderContent (h, data) {
      return data.label;
    },
    onExpand (e, data) {
      console.log('on-expand', e, data);
      if ('expand' in data) {
        data.expand = !data.expand
        if (!data.expand && data.children) {
          this.collapse(data.children)
        }
      } else {
        this.$set(data, 'expand', true)
      }
    },
    collapse (nodes) {
      nodes.forEach(node => {
        if (node.expand) {
          node.expand = false
        }

        node.children && this.collapse(node.children)
      })
    },
    onNodeClick (e, data) {
      console.log('CLICK', e, data);
      this.$set(data, 'selectedKey', !data['selectedKey']);
    },
    onNodeMouseOver(e, data) {
      console.log('MOUSE OVER', e, data);
    },
    onNodeMouseOut(e, data) {
      console.log('MOUSE OUT', e, data);
    }
  }
}
