import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import dataset from '@/lib/dataset.js';
import perm from '@/lib/perm.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import image from '@/lib/image.js';
import FormEditUtils from '@/components/FormEditUtils/FormEditUtils.vue';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
import 'video.js/dist/video-js.css';
import { mapMutations, mapState } from 'vuex';

export default {
	name: 'CaseDetailsEditConfirm',
	components: {
		Loading,
		treeselectdialogs,
		FormEditUtils,
		VideoPlayer,
	},

	// 共通関数
	mixins: [
		common,
		dataset,
		CommonDataSet,
		image,
		perm,
	],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				fbs_info: null,
				form_info: null,
				form_data: null,
				dpt_selected: [],
				dpt_options: this.data_get_dpt_info(),
				bas_occur_date: null,
				bas_occur_time: null,
			},
			// ローカルデータ
			local_data: {
				status: "not_accepted",
				// 管理対象
				mng_uuid: [],
				mng_name_options: [],
				mng_uuid_set: "",
				// 相談先リスト
				person_selected: "",
				person_options: [],
				// 名前を付けて保存
				named_report_name: "",
				deleteTml: -1,
				toggleRotate: null,
				rotate: 0,
				imageSrc: null,
				video_options: {
					width: 640, // 幅
					height: 360, // 高さ
					autoplay: true,
					controls: true,
					sources: [
						{
							src: "",
							type: "video/mp4"
						}
					]
				},
				drawImage: [],		// 画面に表示する図面の配列（2列表示のため、2次元配列）
				bas_draw_info: "",	// 図面情報のJSON文字列
				drawInfoList: [],	// 図面情報JSONの配列
				deleteDraw: -1,		// 削除する図面の配列インデックス
				deleteDrawInfoList: [],		// 削除する図面情報の配列
				addBlueprintName: "",		// 追加する図面の図面名
				addBlueprintFile: null,		// 追加する図面（画像）ファイル
				addBlueprintFiles: [],		// 追加する図面（画像）ファイルの配列
				deleteAttachTml: null,		// 添付を削除するタイムライン
				deleteAttachFtlOrgIdxList: [],		// 添付を削除したタイムラインのftl_uuid
				fbs_comment: "",//コメント
				consultList: [],
			},
			// ステート
			state_data: {
				treeselectdialogs: true,
				fbs_chg_reason: null,
				fbs_reporter: null,
				tml_reporter_name: null,
				fbs_comment: null,
				addBlueprintName: null,
				addBlueprintFile: null,
				consultListVisible: false,
			},
			isViewBlueprint: false,
			isViewTimeline: false,
			isShowAttacheFileList: true,
			isFileAttachField: true,
		}
	},

	computed: {
		...mapState( [
			'system_info',
		] ),

		isShowConsult: function() {
			return (this.local_data.consultList && this.local_data.consultList.length > 0);
		},

		consultListCallaoseBtnTitle: function() {
			if ( this.state_data.consultListVisible ) {
				return '履歴を閉じる▲';
			} else {
				return '履歴を開く▼';
			}
		},

		attacheLimitNum: function() {
			return function( tmlItem ) {
				let limitNum = 0;

				if ( tmlItem.tml_media_type == 'image' ) {
					limitNum = this.system_info.timeline_picture_count;
				} else if ( tmlItem.tml_media_type == 'video' ) {
					limitNum = this.system_info.timeline_movie_count;
				} else {
					if ( this.system_info.timeline_picture_count > this.system_info.timeline_movie_count ) {
						limitNum = this.system_info.timeline_picture_count;
					} else {
						limitNum = this.system_info.timeline_movie_count;
					}
				}

				return limitNum;
			}
		},

		attacheFileNum: function() {
			return function( tmlItem ) {
				return tmlItem.mediaList.length + tmlItem.timelineAttachFileList.length;
			}
		},

		isPossibleFileAttache: function() {
			return function( tmlItem ) {
				return ( this.attacheFileNum( tmlItem ) < this.attacheLimitNum( tmlItem ) );
			}
		},

		mediaAttacheLabel: function() {
			return function ( tmlItem ) {
				let remainNum = Number( this.attacheLimitNum( tmlItem ) ) - Number( this.attacheFileNum( tmlItem ) );
				let retJson = {
					label: '',
					note: '',
				}
				

				if ( tmlItem.tml_media_type == 'image' ) {
					retJson.label = '画像';
					retJson.note = '（あと' + remainNum + 'つ添付することができます）';
				} else if ( tmlItem.tml_media_type == 'video' ) {
					retJson.label = '動画';
					retJson.note = '（あと' + remainNum + 'つ添付することができます）';
				} else {
					if ( this.system_info.timeline_picture_count > 0 && this.system_info.timeline_movie_count > 0 ) {
						retJson.label = '画像または動画';
						retJson.note = '（画像：あと' + this.system_info.timeline_picture_count 
							+ 'つ、動画：あと' + this.system_info.timeline_movie_count + 'つ添付することができます）';
					} else if ( this.system_info.timeline_picture_count > 0 ) {
						retJson.label = '画像';
						retJson.note = '（あと' + remainNum + 'つ添付することができます）';
					} else if ( this.system_info.timeline_movie_count > 0 ) {
						retJson.label = '動画';
						retJson.note = '（あと' + remainNum + 'つ添付することができます）';
					}
				}

				return retJson;
			}
		},

		attacheFileAccept: function() {
			return function ( type ) {
				if ( type == 'image' ) {
					return 'image/jpeg, image/png';
				} else if ( type == 'video' ) {
					return 'video/mp4, video/quicktime';
				} else {
					return 'image/jpeg, image/png, video/mp4, video/quicktime';
				}
			}
		},
		rotate: function() {
			return function( fileUuid ) {
				if ( this.local_data.toggleRotate === fileUuid ) {
					return this.local_data.rotate;
				} else {
					return 0;
				}
			}
		}
	},

	// インスタンス初期化後
	created() {
		this.$eventHub.$emit( "navbar_title", "報告データ編集確認" );
	},

	// インスタンス破棄後
	destroyed: function() {},

	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;
		this.isViewTimeline = false;

		await this.$nextTick();

		this.bind_data.fbs_info = this.$store.state.selected_fbs_info;
		this.bind_data.form_info = this.$store.state.form_info;
		this.bind_data.form_data = JSON.parse(this.bind_data.fbs_info.bas_element);

		const occurDatetime = this.bind_data.fbs_info.bas_occur_date_time.split(' ');
		this.bind_data.bas_occur_date = occurDatetime[0];
		this.bind_data.bas_occur_time = occurDatetime[1];

		for ( let i = 0; i < this.bind_data.fbs_info.form_timeline_list.length; i++ ) {
			const tml = this.bind_data.fbs_info.form_timeline_list[i];
			const tmlRptDatetime = tml.tml_rpt_date_time.split( ' ' );

			tml.tml_rpt_date = tmlRptDatetime[0];
			tml.tml_rpt_time = tmlRptDatetime[1];
			tml.selectedFile = null;
			tml.orgIdx = i;

			let tmlSaveIdJson = JSON.parse( tml.tml_save_id );
			console.log( tmlSaveIdJson );

			let mediaList = Array();

			// 複数添付未対応への対応
			if ( tml.tml_web_view_url && !tml.tml_web_view_url.startsWith( '{' ) ) {
				// 複数添付未対応のバージョンで登録されたデータの場合

				console.log( tml.tml_web_view_url );

				let dataUrl = await this.getS3Media( tml.tml_web_view_url );
				let media = {
					fileUuid: this.getFileUuid( tml.tml_web_view_url ),
					blob: null,
					dataUrl: dataUrl,
					width: '',
					height: '',
					isEditMode: false,
					isAttached: true,
					attachedIndex: 0,
				};

				mediaList.push( media );
			} else {
				let webViewUrlInfo = JSON.parse( tml.tml_web_view_url );

				if ( webViewUrlInfo && webViewUrlInfo.url_list ) {
					for ( let idx in webViewUrlInfo.url_list ) {
						let webViewUrl = webViewUrlInfo.url_list[idx];
						let dataUrl = await this.getS3Media( webViewUrl );
						let media = {
							fileUuid: this.getFileUuid( webViewUrl ),
							blob: null,
							dataUrl: dataUrl,
							width: '',
							height: '',
							isEditMode: false,
							isAttached: true,
							attachedIndex: idx,
						};
		
						mediaList.push( media );
					}
				}
			}

			tml.mediaList = mediaList;
			tml.timelineAttachFileList = [];
			this.bind_data.fbs_info.form_timeline_list[i] = tml;
		}

		this.local_data.mng_uuid = [];
		for ( let i=0 ; i<this.bind_data.fbs_info.bas_mng_list.length ; i++ ) {
			this.local_data.mng_uuid.push(this.bind_data.fbs_info.bas_mng_list[i].bas_mng_uuid);
		}

		this.bind_data.dpt_selected = [];
		for ( let i=0 ; i<this.bind_data.fbs_info.fbs_share_dest_list.length ; i++ ) {
			this.bind_data.dpt_selected.push(this.bind_data.fbs_info.fbs_share_dest_list[i].fbs_share_dest_uuid);
		}

		// if ( this.bind_data.fbs_info.fbs_consult_usr_uuid) {
		//	 this.local_data.status = 'accepted';
		// }
		await this.get_dpt();

		await this.getManaged();

		await this.getConsultations();

		if ( this.bind_data.fbs_info.bas_draw_info ) {
			this.local_data.bas_draw_info = this.bind_data.fbs_info.bas_draw_info;
			this.local_data.drawInfoList = [];

			const drawInfo = JSON.parse( this.local_data.bas_draw_info );
			
			if ( Array.isArray( drawInfo ) ) {
				this.local_data.drawInfoList.push( ...drawInfo );
			} else {
				this.local_data.drawInfoList.push( drawInfo );
			}

			await this.getDrawInfo( true );
		}

		let ret = await this.getConsultList( {
			fbsUuid: this.bind_data.fbs_info.fbs_uuid,
		} );

		if ( ret.result === 'OK' ) {
			console.log( ret );

			this.local_data.consultList = ret.consult_list;
		} else {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = ret.message;

			// if ( ret.result === 'ERR' ) {
			// 	this.logout( {
			// 		_this: this,
			// 	} );
			// }
		}

		if ( this.bind_data.fbs_info.fbs_comment != null && this.bind_data.fbs_info.fbs_comment != '' ) {
			console.log( this.bind_data.fbs_info.fbs_comment );
			this.local_data.consultList.unshift(
				{
					consult_user_name: '',
					contact_user_name: '',
					cnm_status: 'answer',
					cnm_consult_content: '',
					cnm_consult_date_time: '',
					cnm_answer_content: this.bind_data.fbs_info.fbs_comment,
					cnm_answer_date_time: this.bind_data.fbs_info.fbs_comment_date_time,
				}
			);
		}
	
		this.isViewTimeline = true;
		this.loader.is_loading = false;
	},

	// ローカル関数
	methods: {
		...mapMutations( [
			'set_sid',
		] ),

		toggleViewBlueprint: async function() {
			this.isViewBlueprint = !this.isViewBlueprint;
		
			await this.$nextTick();
		},

		async getDrawInfo( init = false ) {
			let drawImage = [];
			this.local_data.drawImage = [];
			
			for ( let i = 0 ; i < this.local_data.drawInfoList.length; i++ ) {
				if ( init ) {
					this.local_data.drawInfoList[i].orgIdx = i;
				}

				const param = {
					params: {
						sid: this.$store.state.sid,
						bucket_name: this.local_data.drawInfoList[i].bucket_name,
						bucket_key: this.local_data.drawInfoList[i].bucket_key,
						file_name: this.local_data.drawInfoList[i].file_name,
					},
				};
				
				try {
					const res = await axios.get( process.env.VUE_APP_RESTAPI_GET_S3_MEDIA_FILE, param );
					
					if ( res.data.result === 'OK' ) {
						this.set_sid( res.data.sid );
						
						let image = "";
						
						if ( this.local_data.drawInfoList[i].content_type !="" && res.data.media_file != "" ) {
							image = `data:${this.local_data.drawInfoList[i].content_type};base64,${res.data.media_file}`;
						}
						
						drawImage.push( {
							name: this.local_data.drawInfoList[i].drw_name,
							file_name: this.local_data.drawInfoList[i].file_name,
							image: image,
						} );
						
						if ( drawImage.length == 2 ) {
							this.local_data.drawImage.push( drawImage );
							drawImage = [];
						}
					} else {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				} catch ( e ) {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = JSON.stringify( e );
				}
			}
			
			if ( drawImage.length > 0 ) {
				if ( drawImage.length == 1 ) {
					drawImage.push( {
						name: "",
						file_name: "",
						image: "",
					} );
				}

				this.local_data.drawImage.push( drawImage );
			}
		},

		// isConsult() {
		//	 console.log(this.bind_data.fbs_info.fbs_form_comp_type);
		//	 console.log(this.bind_data.fbs_info.fbs_open_comment);
		//	 console.log(this.bind_data.fbs_info.fbs_request_usr_uuid);

		//	 console.log(JSON.stringify(this.bind_data.fbs_info));
		//	 if ( this.bind_data.fbs_info.fbs_form_comp_type == "fixpending" ) {
		//		 if ( this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid ) {
		//			 return true;
		//		 }
		//	 }
		//	 return false;
		// },

		back: function() {
			this.transition('/Home/CaseDetailsSelect');
		},

		save_named_report: function() {
			if ( !this.valid()) {
				return;
			}

			this.$refs['named_report'].show();
		},
		
		save_rewrite_report: function() {
			if ( !this.valid()) {
				return;
			}

			this.$refs['rewrite_report'].show();
		},

		save_consult_confirm_report() {
			if ( !this.valid()) {
				return;
			}

			this.$refs['consult_confirm_report'].show();
		},

		save_confirmed_report() {
			if ( !this.valid()) {
				return;
			}

			this.$refs['confirmed_report'].show();
		},

		media_file_state: function( tml_item ) {
			tml_item.tml_media_file_state = !tml_item.tml_media_file_state;
		},

		get_dpt_info( data ) {
			this.bind_data.dpt_selected = data;
		},

		/**
		 * 共有先の組織を取得する
		 */
		async get_dpt() {

			// ローディング開始
			this.loader.is_loading = true;

			this.state_data.treeselectdialogs = false;

			for ( let i=0 ; i<this.local_data.mng_uuid.length ; i++ ) {
				if ( i == 0) {
					this.local_data.mng_uuid_set = this.local_data.mng_uuid[i];
				} else {
					this.local_data.mng_uuid_set = this.local_data.mng_uuid_set + "," + this.local_data.mng_uuid[i];
				}
			}
	
			await this.set_org_map("allmng",null,this.local_data.mng_uuid_set);
			
			this.state_data.treeselectdialogs = true;

			// ローディング終了
			this.loader.is_loading = false;
		},	

		/**
		 * 管理対象を取得する
		 */
		async getManaged() {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_MANAGED_LIST, config);
				if ( res.data.result === 'OK') {
					const managed = res.data.managed_list;

					this.local_data.mng_name_options = [];
					
					for ( let i=0 ; i<managed.length ; i++ ) {
						this.local_data.mng_name_options.push({
							label: managed[i].mng_name,
							value: managed[i].mng_uuid,
							id: managed[i].mng_uuid,
						});
					}
					this.set_sid( res.data.sid );
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch ( ex ) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( ex );
			}
		},

		/**
		 * 相談先リストを取得する
		 */
		async getConsultations() {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_CONSULTATION_LIST, config);
				if ( res.data.result === 'OK') {
					const consultations = res.data.consultation_list;
					this.local_data.person_options = [];
					for ( let i=0 ; i<consultations.length ; i++ ) {
						this.local_data.person_options.push({
							text: consultations[i].usr_name,
							value: consultations[i].usr_uuid,
							dept: consultations[i].usr_post,
							org: consultations[i].usr_org_long_name,
						});
					}
					this.set_sid( res.data.sid );
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch ( ex ) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( ex );
			}
		},

		/**
		 * 入力データをチェックする
		 */
		valid() {
			let ret = true;
			this.state_data.fbs_chg_reason = null;
			this.state_data.fbs_reporter = null;
			this.state_data.fbs_comment = null;
			this.state_data.tml_reporter_name = null;

			if ( !this.bind_data.fbs_info.fbs_chg_reason) {
				this.state_data.fbs_chg_reason = false;
				ret = false;
			}
			if ( !this.bind_data.fbs_info.fbs_reporter) {
				this.state_data.fbs_reporter = false;
				ret = false;
			}
			if ( !this.local_data.fbs_comment) {
				this.state_data.fbs_comment = false;
				ret = false;
			}

			this.bind_data.fbs_info.bas_occur_date_time = `${this.bind_data.bas_occur_date} ${this.bind_data.bas_occur_time}`;
			this.bind_data.fbs_info.bas_mng_uuid = this.local_data.mng_uuid.join(',');
			this.bind_data.fbs_info.fbs_share_dest = this.bind_data.dpt_selected.join(',');
			for ( let i=0 ; i<this.bind_data.fbs_info.form_timeline_list.length ; i++ ) {
				const tml = this.bind_data.fbs_info.form_timeline_list[i];
				tml.tml_rpt_date_time = `${tml.tml_rpt_date} ${tml.tml_rpt_time}`;
				if ( !tml.tml_reporter_name) {
					this.state_data.tml_reporter_name = false;
					ret = false;
				}
			}

			return ret;
		},

		/**
		 * FormEditデータの変更
		 */
		handleFormEdit(data) {
			this.bind_data.fbs_info.bas_element = JSON.stringify(data);
		},

		/**
		 * 名前を付けて保存
		 */
		async handleSaveAs() {
			await this.save('naming');
		},

		/**
		 * 上書き保存
		 */
		async handleOverWrite() {
			await this.save('overwrite');
		},

		/**
		 * 相談して保存
		 */
		async handleSaveAsConsultConfirm() {
			await this.save('consulconfirm');
		},

		async handleSaveConfirmedReport() {
			await this.save('commentconfirm');
		},

		/**
		 * 報告データ保存
		 */
		async save( saveMode ) {
			this.loader.is_loading = true;
			await this.$nextTick();
			
			try {
				this.setMediaType();
				
				const tmls = [];
				for ( let idx = 0; idx < this.bind_data.fbs_info.form_timeline_list.length; idx++ ) {
					const tml = this.bind_data.fbs_info.form_timeline_list[idx];
					
					tmls.push( {
						ftl_uuid: tml.ftl_uuid,
						tml_uuid: tml.tml_uuid,
						tml_bas_uuid: this.bind_data.fbs_info.bas_uuid,
						tml_rpt_date_time: tml.tml_rpt_date_time,
						tml_reporter: tml.tml_reporter,
						tml_reporter_name: tml.tml_reporter_name,
						tml_status_explain: tml.tml_status_explain,
						tml_media_type: tml.tml_media_type,
						timelineAttachFileList: tml.timelineAttachFileList,
						deleteAttacheFileIndex: tml.deleteAttacheFileIndex,
					} );
				}
				
				const data = {
					sid: this.$store.state.sid,
					save_mode: saveMode,
					fbs_uuid: this.bind_data.fbs_info.fbs_uuid,
					before_upd_date_time: String(this.bind_data.fbs_info.fbs_upd_date_time),
					fbs_report_title: this.bind_data.fbs_info.fbs_report_title,
					fbs_chg_reason: this.bind_data.fbs_info.fbs_chg_reason,
					fbs_reporter: this.bind_data.fbs_info.fbs_reporter,
					fbs_comment: this.local_data.fbs_comment,
					bas_uuid: this.bind_data.fbs_info.bas_uuid,
					bas_rpt_type: this.bind_data.fbs_info.bas_rpt_type,
					bas_mng_uuid: this.bind_data.fbs_info.bas_mng_uuid,
					bas_org_uuid: this.bind_data.fbs_info.bas_org_uuid,
					bas_subject: this.bind_data.fbs_info.bas_subject,
					bas_occur_date_time: this.bind_data.fbs_info.bas_occur_date_time,
					bas_element: this.bind_data.fbs_info.bas_element,
					bas_dlv_list: this.bind_data.fbs_info.bas_dlv_list,
					bas_case_number: this.bind_data.fbs_info.bas_case_number,
					form_timeline_list: tmls,
				};

				if ( this.bind_data.fbs_info.fbs_consult_usr_uuid ) {
					data.fbs_consult_usr_uuid = this.bind_data.fbs_info.fbs_consult_usr_uuid;
				}

				if ( this.bind_data.fbs_info.fbs_share_dest ) {
					data.fbs_share_dest = this.bind_data.fbs_info.fbs_share_dest;
				}
				
				console.log( "data", data );
				
				const res = await axios.put(process.env.VUE_APP_RESTAPI_SET_FORM_BASICS_INFO, data);
				
				console.log( "res", res );
				
				if (res.data.result === 'OK') {
					this.set_sid( res.data.sid );
					await this.updateBlueprint( res.data.s3_fbs_info );
					await this.updateImageAndVideo( res.data.s3_ftl_list, tmls );
					this.back();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch(e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(e);
			}
			
			this.loader.is_loading = false;
		},

		async _save(saveMode) {
			this.loader.is_loading = true;
			await this.$nextTick();

			try {
				this.setMediaType();

				const tmls = [];
				for ( let i=0 ; i<this.bind_data.fbs_info.form_timeline_list.length ; i++ ) {
					const tml = this.bind_data.fbs_info.form_timeline_list[i];

					tmls.push({
						ftl_uuid: tml.ftl_uuid,
						tml_uuid: tml.tml_uuid,
						tml_bas_uuid: this.bind_data.fbs_info.bas_uuid,
						tml_rpt_date_time: tml.tml_rpt_date_time,
						tml_reporter: tml.tml_reporter,
						tml_reporter_name: tml.tml_reporter_name,
						tml_status_explain: tml.tml_status_explain,
						tml_media_type: tml.tml_media_type,
					});
				}

				const data = {
					sid: this.$store.state.sid,
					save_mode: saveMode,
					fbs_uuid: this.bind_data.fbs_info.fbs_uuid,
					before_upd_date_time: String(this.bind_data.fbs_info.fbs_upd_date_time),
					fbs_report_title: this.bind_data.fbs_info.fbs_report_title,
					fbs_chg_reason: this.bind_data.fbs_info.fbs_chg_reason,
					fbs_reporter: this.bind_data.fbs_info.fbs_reporter,
					fbs_comment: this.local_data.fbs_comment,
					bas_uuid: this.bind_data.fbs_info.bas_uuid,
					bas_rpt_type: this.bind_data.fbs_info.bas_rpt_type,
					bas_mng_uuid: this.bind_data.fbs_info.bas_mng_uuid,
					bas_org_uuid: this.bind_data.fbs_info.bas_org_uuid,
					bas_subject: this.bind_data.fbs_info.bas_subject,
					bas_occur_date_time: this.bind_data.fbs_info.bas_occur_date_time,
					bas_element: this.bind_data.fbs_info.bas_element,
					bas_dlv_list: this.bind_data.fbs_info.bas_dlv_list,
					bas_case_number: this.bind_data.fbs_info.bas_case_number,
					form_timeline_list: tmls,
				};
				if ( this.bind_data.fbs_info.fbs_consult_usr_uuid) {
					data.fbs_consult_usr_uuid = this.bind_data.fbs_info.fbs_consult_usr_uuid;
				}
				if ( this.bind_data.fbs_info.fbs_share_dest) {
					data.fbs_share_dest = this.bind_data.fbs_info.fbs_share_dest;
				}

				console.log( "data", data );
				
				const res = await axios.put(process.env.VUE_APP_RESTAPI_SET_FORM_BASICS_INFO, data);
				
				console.log( "res", res );
				
				if (res.data.result === 'OK') {
					this.set_sid( res.data.sid );
					await this.updateBlueprint( res.data.s3_fbs_info );
					await this.updateImageAndVideo( res.data.s3_ftl_list, res.data.org_s3_ftl_list );
					this.back();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch ( ex ) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( ex );
			}

			this.loader.is_loading = false;
		},

		/**
		 * タイムラインのメディアタイプを設定する
		 * ファイルが選択されているところだけのメディアタイプを設定する
		 */
		setMediaType() {
			for ( let i=0 ; i<this.bind_data.fbs_info.form_timeline_list.length ; i++ ) {
				const tml = this.bind_data.fbs_info.form_timeline_list[i];
				if ( tml.selectedFile instanceof File) {
					if ( tml.selectedFile.type === 'image/jpeg' || tml.selectedFile.type === 'image/png' ) {
						tml.tml_media_type = 'image';
					} else {
						tml.tml_media_type = 'video';
					}
				}
			}
		},

		/**
		 * タイムラインの画像、動画をS3にアップロードする
		 */
		updateImageAndVideo: async function( ftlList, tmlList ) {
			console.log( "ftlList", ftlList );
			console.log( "tmlList", tmlList );

			const formData = new FormData();

			formData.append( 'ftl_list', JSON.stringify( ftlList ) );

			let newTmlList = Array();

			for ( let idx = 0; idx < tmlList.length; idx++ ) {
				let tml = tmlList[idx];
				let newTml = {
					ftl_uuid: tml.ftl_uuid,
					deleteAttacheFileIndex: tml.deleteAttacheFileIndex,
				};

				let newAttacheList = Array();

				for ( let idx2 = 0; idx2 < tml.timelineAttachFileList.length; idx2++ ) {
					let attacheFile = tml.timelineAttachFileList[idx2];
					
					newAttacheList.push( {
						fileType: attacheFile.type,
						fileUuid: attacheFile.fileUuid,
						fileName: attacheFile.fileName,
					} );

					let ext = '';

					if ( attacheFile.type !== 'video/quicktime' ) {
						ext = attacheFile.type.substring( attacheFile.type.indexOf( '/' ) + 1 );
					} else {
						ext = 'mov';
					}

					let fileName = attacheFile.fileUuid + '.' + ext;

					formData.append( 'media_file', attacheFile.blob, fileName );
				}
				
				newTml.timelineAttachFileList = newAttacheList;

				newTmlList.push( newTml );
			}

			console.log( "newTmlList", JSON.stringify( newTmlList ) );

			formData.append( 'tml_list', JSON.stringify( newTmlList ) );
			formData.append('sid', this.$store.state.sid);

			try {
				const config = {
					headers: {
						'content-type': 'multipart/form-data',
					},
					timeout: 0,
				};
				
				const res = await axios.post(process.env.VUE_APP_RESTAPI_SET_S3_FORM_MEDIA_FILE2, formData, config);
	
				console.log( res );

				if ( res.data.result != 'OK' ) {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch ( ex ) {
				console.error( ex );

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( ex );
			}
		},

		_updateImageAndVideo: async function( ftlList, ftlListOrg ) {
			console.log( "ftlList", ftlList );

			let addedFtluuid = [];

			// 追加
			for ( let idx = 0; idx < this.bind_data.fbs_info.form_timeline_list.length; idx++ ) {
				const tml = this.bind_data.fbs_info.form_timeline_list[idx];

				if ( tml.selectedFile instanceof File ) {
					if ( tml.tml_media_type === 'image' ) {
						const dataUrl = await this.readImageAsBase64( tml.selectedFile );
						tml.selectedFile = await this.resizeImage( dataUrl );
					}

					const ftl = ftlList[idx];

					console.log( "ftl", ftl );

					let s3Info = JSON.parse( ftl.tml_save_id );

					const bucketName = s3Info.upload_bucket_name;
					const bucketKey = s3Info.bucket_key;

					console.log( bucketName, bucketKey );

					try {
						const formData = new FormData();
						formData.append('sid', this.$store.state.sid);
						formData.append('ftl_uuid', ftl.ftl_uuid);
						formData.append('bucket_name', bucketName);
						formData.append('bucket_key', bucketKey);
						let ext;
						
						if (tml.selectedFile.type !== 'video/quicktime') {
							ext = tml.selectedFile.type.substring(tml.selectedFile.type.indexOf('/') + 1);
						} else {
							ext = 'mov';
						}
						formData.append('media_file', tml.selectedFile, `${this.$uuid.v4()}.${ext}`);
						
						const config = {
							headers: {
								'content-type': 'multipart/form-data',
							},
							timeout: 0,
						};
						
						const res = await axios.post(process.env.VUE_APP_RESTAPI_SET_S3_FORM_MEDIA_FILE, formData, config);

						if (res.data.result == 'OK') {
							addedFtluuid.push( ftl.ftl_uuid );
						} else {
							this.message.is_alert = true;
							this.message.variant = "danger";
							this.message.message = res.data.message;
						}
					} catch(e) {
						console.error(e);
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = JSON.stringify(e);
					}
				}
			}

			// 削除
			for ( let idx = 0; idx < this.local_data.deleteAttachFtlOrgIdxList.length; idx++ ) {
				const ftl = ftlListOrg[this.local_data.deleteAttachFtlOrgIdxList[idx]];
				
				console.log( "ftl", ftl );

				let s3Info = JSON.parse( ftl.tml_save_id );

				const bucketName = s3Info.upload_bucket_name;
				const bucketKey = s3Info.bucket_key;

				console.log( bucketName, bucketKey );

				let postData = {
					"sid": this.$store.state.sid,
					"mode": addedFtluuid.includes( ftl.ftl_uuid ) ? "update" : "delete",
					"ftl_uuid": ftl.ftl_uuid,
					"upload_bucket_name": s3Info.upload_bucket_name,
					"download_bucket_name": s3Info.download_bucket_name,
					"bucket_key": s3Info.download_bucket_name,
					"file_name": s3Info.download_bucket_name,
				}

				try {
					const res = await axios.post( process.env.VUE_APP_RESTAPI_DELETE_S3_FORM_MEDIA_FILE, postData );

					if ( res.data.result !== 'OK' ) {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;
					}
				} catch ( ex ) {
					console.error( ex );
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = JSON.stringify( ex );
				}
			}
		},

		/**
		 * タイムライン削除を確認する
		 */
		handleConfirmDeleteTimeline(tml) {
			this.local_data.deleteTml = this.bind_data.fbs_info.form_timeline_list.findIndex(t => t.ftl_uuid === tml.ftl_uuid);
			if ( this.local_data.deleteTml !== (-1)) {
				this.$refs.deleteTimeline.show();
			}
		},

		/**
		 * タイムラインを削除する
		 */
		handleDeleteTimeline() {
			if ( this.local_data.deleteTml !== (-1)) {
				let delTml = this.bind_data.fbs_info.form_timeline_list.splice(this.local_data.deleteTml, 1);

				this.local_data.deleteAttachFtlOrgIdxList.push( delTml[0].orgIdx );
				this.local_data.deleteTml = (-1);
			}
		},

		getFileUuid: function( webViewUrl ) {
			const urls = webViewUrl.split( '?' );
			const params = urls[1].split( '&' );
			const fileName = params.find( p => p.substr( 0, 10 ) === 'file_name=' );

			return fileName.replace( /^.*=/, '' ).replace( /\..*$/, '' );
		},

		getS3Media: async function( webViewUrl ) {
			try {
				console.log( "process.env.VUE_APP_GET_LIMIT_URL = " + process.env.VUE_APP_GET_LIMIT_URL );
				console.log( "process.env.VUE_APP_API_KEY = " + process.env.VUE_APP_API_KEY );

				const urls = webViewUrl.split( '?' );
				const params = urls[1].split( '&' );
				const basUuid = params.find( p => p.substr( 0, 9 ) === 'bas_uuid=' );
				const tmlUuid = params.find( p => p.substr( 0, 9 ) === 'tml_uuid=' );
				const fileName = params.find( p => p.substr( 0, 10 ) === 'file_name=' );
				const url = `${process.env.VUE_APP_GET_LIMIT_URL}?sid=${this.$store.state.sid}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
				const config = {
					headers: {
						'x-api-key': process.env.VUE_APP_API_KEY,
					},
				};
				
				const res = await this.$http.get( url, config );

				if (res.data.result === 'OK') {
					return res.data.url
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;

					return "";
				}
			} catch(e) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "エラーが発生しました。" + JSON.stringify( e );

				return "";
			}
		},

		async handleToggleRotate( ftlUuid, tmlMediaType, dataUrl ) {
			if ( this.local_data.toggleRotate === ftlUuid ) {
				this.local_data.rotate = ( this.local_data.rotate === 0 ) ? 180 : 0;
			} else {
				if ( tmlMediaType === 'image' ) {
					this.local_data.imageSrc = dataUrl;
				} else if ( tmlMediaType === 'video' ) {
					this.local_data.video_options.sources[0].src = dataUrl;
				}

				this.local_data.toggleRotate = ftlUuid;
				this.local_data.rotate = 180;
			}
		},

				/**
		 * ファイルの追加
		 * 
		 * @param {*} event 
		 * @param {*} tmlItem 
		 * @returns 
		 */
		handleChangeSelectAttacheFile: async function( event, tmlItem ) {
			console.log( event, tmlItem );

			let timelineAttachFileList = [];
			
			if ( tmlItem.timelineAttachFileList ) {
				timelineAttachFileList = tmlItem.timelineAttachFileList;
			}

			if ( event.target.files.length > 0 ) {
				let fileUuid = this.$uuid.v4();
				let file = event.target.files[0];
				let fileName = file.name;

				let dataUrl = null;
				let blob = null;

				dataUrl = await this.readImageAsBase64( file );

				if ( file.type.startsWith( 'image' ) ) {
					if ( file.size > process.env.VUE_APP_PICTURE_FILE_SIZE_LIMIT * 1048576 ) {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = "選択可能なファイルの容量は" + process.env.VUE_APP_PICTURE_FILE_SIZE_LIMIT + "MB以内です。";
		
						this.$refs[tmlItem.ftl_uuid][0].reset();

						return;
					}

					tmlItem.tml_media_type = 'image';
					blob = await this.resizeImage( dataUrl );
				} else {
					if ( file.size > process.env.VUE_APP_MOVIE_FILE_SIZE_LIMIT * 1048576 ) {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = "選択可能なファイルの容量は" + process.env.VUE_APP_MOVIE_FILE_SIZE_LIMIT + "MB以内です。";
		
						this.$refs[tmlItem.ftl_uuid][0].reset();

						return;
					}

					tmlItem.tml_media_type = 'video';
					blob = await fetch( dataUrl ).then( r => r.blob() );
				}

				console.log( fileName, fileUuid, blob );

				timelineAttachFileList.push( {
					fileUuid: fileUuid,
					file: file,
					type: file.type,
					fileName: fileName,
					dataUrl: dataUrl,
					blob: blob,
				} );
			}

			this.$refs[tmlItem.ftl_uuid][0].reset();

			this.isFileAttachField = false;
			this.$set( tmlItem, 'timelineAttachFileList', timelineAttachFileList );
			this.isFileAttachField = true;
		},

		async _handleToggleRotate(tml) {
			if ( this.local_data.toggleRotate === tml.ftl_uuid) {
				this.local_data.rotate = (this.local_data.rotate === 0) ? 180 : 0;
			} else {
				this.loader.is_loading = true;

				try {
					const urls = tml.tml_web_view_url.split('?');
					const params = urls[1].split('&');
					const basUuid = params.find(p => p.substr(0, 9) === 'bas_uuid=');
					const tmlUuid = params.find(p => p.substr(0, 9) === 'tml_uuid=');
					const fileName = params.find(p => p.substr(0, 10) === 'file_name=');

					const url = `${process.env.VUE_APP_GET_LIMIT_URL}?sid=${this.$store.state.sid}&mode=download&${basUuid}&${tmlUuid}&${fileName}`;
					const config = {
						headers: {
							'x-api-key': process.env.VUE_APP_API_KEY,
						},
					};

					const res = await axios.get(url, config);
					if ( res.data.result === 'OK') {
						if ( tml.tml_media_type === 'image') {
							this.local_data.imageSrc = res.data.url;
						} else if ( tml.tml_media_type === 'video') {
							this.local_data.video_options.sources[0].src = res.data.url;
						}
						this.local_data.toggleRotate = tml.ftl_uuid;
						this.local_data.rotate = 180;
					} else {
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = res.data.message;	
					}
				} catch ( ex ) {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = "エラーが発生しました。"+JSON.stringify( ex );
				}
				this.loader.is_loading = false;
			}
		},

		/**
		 * 選択されている画像、動画をリセットする
		 * @param {*} tml 
		 */
		handleClearSelectedFile( tml, index ) {
			console.log( tml.timelineAttachFileList[index] );

			this.isFileAttachField = false;
			tml.timelineAttachFileList.splice( index, 1 );

			if ( this.attacheFileNum( tml ) == 0 ) {
				tml.tml_media_type = 'none';
			}

			this.isFileAttachField = true;
		},

		_handleClearSelectedFile(tml) {
			this.$refs[tml.ftl_uuid][0].reset();
		},

		/**
		 * 画像または動画を削除する（確認）
		 */
		handleConfirmDeleteImageAndVideo( tml, index, attachedIndex ) {
			this.local_data.deleteAttachTml = {
				tml: tml,
				index: index,
				attachedIndex: attachedIndex,
			};

			if ( tml.tml_media_type == "image" ) {
				this.$refs.deletePicture.show();
			} else {
				this.$refs.deleteMovie.show();
			}
		},

		/**
		 * 画像または動画を削除する（実行）
		 */
		handleDeleteImageAndVideo() {
			let tml = this.local_data.deleteAttachTml.tml;
			let index = this.local_data.deleteAttachTml.index;
			let attachedIndex = this.local_data.deleteAttachTml.attachedIndex;

			console.log( tml, index );

			tml.mediaList.splice( index, 1 );

			if ( this.attacheFileNum( tml ) == 0 ) {
				tml.tml_media_type = 'none';
			}

			if ( !tml.deleteAttacheFileIndex ) {
				tml.deleteAttacheFileIndex = Array();
			}

			tml.deleteAttacheFileIndex.push( attachedIndex );

			this.isShowAttacheFileList = false;
			this.$set( this.local_data.deleteAttachTml, 'tml', tml );
			this.isShowAttacheFileList = true;
		},

		/**
		 * 選択されている図面（画像）をリセットする
		 */
		handleClearBlueprintFile: function() {
			this.local_data.addBlueprintName = "";
			this.$refs.add_blurprint_input.reset();

			this.state_data.addBlueprintName = null;
			this.state_data.addBlueprintFile = null;
		},

		/**
		 * 図面を追加する
		 */
		handleAddBlueprintFile: function() {
			if ( this.local_data.addBlueprintName == "" || !this.local_data.addBlueprintFile ) {
				this.state_data.addBlueprintName = null;
				this.state_data.addBlueprintFile = null;
				
				if ( this.local_data.addBlueprintName == "" ) {
					this.state_data.addBlueprintName = false;
				}

				if ( !this.local_data.addBlueprintFile ) {
					this.state_data.addBlueprintFile = false;
				}

				return;
			}

			let limitSizeByte = Number( process.env.VUE_APP_BLUEPRINT_FILE_SIZE_LIMIT ) * 1048576;

			if ( this.local_data.addBlueprintFile.size > limitSizeByte ) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "選択可能なファイルの容量は" + process.env.VUE_APP_BLUEPRINT_FILE_SIZE_LIMIT + "MB以内です。";

				return;
			}

			let addFileInfo = {
				id: new Date().getTime(),
				name: this.local_data.addBlueprintName,
				file: this.local_data.addBlueprintFile,
			}

			this.local_data.addBlueprintFiles.push( addFileInfo );

			this.handleClearBlueprintFile();
		},

		/**
		 * 追加した図面を除去する
		 * 
		 * @param {*} id 
		 */
		handleRemoveBlueprintFile: function( id ) {
			let idx = this.local_data.addBlueprintFiles.findIndex( t => t.id === id );

			this.local_data.addBlueprintFiles.splice( idx, 1 );
		},

		/**
		 * 登録済みの図面を削除する（確認）
		 * 
		 * @param {*} filename 
		 */
		handleConfirmDeleteBlueprint: function( filename ) {
			this.local_data.deleteDraw = this.local_data.drawInfoList.findIndex( t => t.file_name == filename );

			if ( this.local_data.deleteDraw != -1 ) {
				this.$refs.deleteBlueprint.show();
			}
		},

		/**
		 * 登録済みの図面を削除する（実行）
		 */
		handleDeleteBlueprint: async function() {
			this.isViewBlueprint = false;
		
			await this.$nextTick();

			if ( this.local_data.deleteDraw != -1 ) {
				this.local_data.deleteDrawInfoList = this.local_data.deleteDrawInfoList.concat( this.local_data.drawInfoList.splice( this.local_data.deleteDraw, 1 ) );
				this.local_data.deleteDraw = -1;
			}

			await this.getDrawInfo();

			this.isViewBlueprint = true;
		},

		/**
		 * S3の図面を更新する
		 */
		updateBlueprint: async function( s3Info ) {
			// 削除する図面の有無をチェック
			if ( this.local_data.deleteDrawInfoList.length > 0 ) {
				for ( let idx = 0; idx < this.local_data.deleteDrawInfoList.length; idx++ ) {
					let orgIdx = this.local_data.deleteDrawInfoList[idx].orgIdx;
					let drawInfo = JSON.parse( s3Info.bas_draw_info );

					let postData = {
						"sid": this.$store.state.sid,
						"mode": "delete",
						"fbs_uuid": s3Info.fbs_uuid,
						"bucket_name": drawInfo[orgIdx].bucket_name,
						"bucket_key": drawInfo[orgIdx].bucket_key,
						"file_name": drawInfo[orgIdx].file_name,
					}

					try {
						const res = await axios.post( process.env.VUE_APP_RESTAPI_DELETE_S3_FORM_MEDIA_FILE, postData );

						if ( res.data.result !== 'OK' ) {
							this.message.is_alert = true;
							this.message.variant = "danger";
							this.message.message = res.data.message;
						}
					} catch ( ex ) {
						console.error( ex );
						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = JSON.stringify( ex );
					}
				}
			}

			// 追加する図面の有無をチェック
			if ( this.local_data.addBlueprintFiles.length > 0 ) {
				for ( let idx = 0; idx < this.local_data.addBlueprintFiles.length; idx++ ) {
					let fileInfo = this.local_data.addBlueprintFiles[idx];

					if ( fileInfo.file instanceof File ) {
						const dataUrl = await this.readImageAsBase64( fileInfo.file );
						fileInfo.file = await this.resizeImage( dataUrl );
						
						try {
							let ext = fileInfo.file.type.substring( fileInfo.file.type.indexOf( '/' ) + 1 );
							let formData = new FormData();

							formData.append( 'sid', this.$store.state.sid );
							formData.append( 'fbs_uuid', s3Info.fbs_uuid );
							formData.append( 'drw_name', fileInfo.name );
							formData.append( 'bucket_name', process.env.VUE_APP_BLUEPRINT_BAS_BUCKET_NAME );
							formData.append( 'bucket_key', process.env.VUE_APP_BLUEPRINT_BAS_BUCKET_KEY );
							formData.append( 'media_file', fileInfo.file, `${this.$uuid.v4()}.${ext}` );
							
							const config = {
								headers: {
									'content-type': 'multipart/form-data',
								},
								timeout: 0,
							};
							
							const res = await axios.post( process.env.VUE_APP_RESTAPI_SET_S3_FORM_MEDIA_FILE, formData, config );

							if ( res.data.result !== 'OK' ) {
								this.message.is_alert = true;
								this.message.variant = "danger";
								this.message.message = res.data.message;
							}
						} catch( ex ) {
							console.error( ex );
							this.message.is_alert = true;
							this.message.variant = "danger";
							this.message.message = JSON.stringify( ex );
						}
					}
				}
			}
		},

		//<!-- start #100 対応 -->
		// 相談されたものだけがコメントできる
		isConsulted: function() {
			
			// 相談中の時
			if ( this.bind_data.fbs_info.fbs_form_comp_type == "confirmpending" ) {
				// 自分が相談者（確定者）の時
				if ( this.bind_data.fbs_info.fbs_consult_usr_uuid === this.$store.state.users_info.usr_uuid ) {
					return true;
				}
			}
			return false;
		},
		// 公開コメント表示
		isOpenCommented: function() {
			
			// 確認済みの時
			if ( this.bind_data.fbs_info.fbs_form_comp_type == "complete" ) {
				// 公開の時
				if ( this.bind_data.fbs_info.fbs_open_comment == "on" ) {
					return true;
				}
			}
			return false;
		},
		// 非公開コメント表示
		isCommented: function() {
			
			// 相談中の時 // 確認済みの時
			if ( this.bind_data.fbs_info.fbs_form_comp_type == "confirmpending" ) {
				// 公開の時
				if ( this.bind_data.fbs_info.fbs_open_comment === "off" ) {
					// 自分が相談した者（依頼者）の時
					if ( this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid ) {
						if ( this.bind_data.fbs_info.fbs_comment !== "" ) {
							return true;
						}
					}
				}
			}
			if ( this.bind_data.fbs_info.fbs_form_comp_type == "complete" ) {
				// 公開の時
				if ( this.bind_data.fbs_info.fbs_open_comment === "off" ) {
					// 自分が相談者（確定者）の時
					if ( this.bind_data.fbs_info.fbs_consult_usr_uuid === this.$store.state.users_info.usr_uuid ) {
						return true;
					}
					// 自分が相談した者（依頼者）の時
					if ( this.bind_data.fbs_info.fbs_request_usr_uuid === this.$store.state.users_info.usr_uuid ) {
						return true;
					}
				}
			}
			return false;
		},

		/**
		 * コメントするを確認する
		 */
		handleConfirmComment() {
			if ( !this.valid()) {
				return;
			}

			this.$refs['confirmComment'].show();
		},

		/**
		 * コメントする
		 */
		async handleComment() {
			await this.save('comment');
		},

		/**
		 * コメントして確認するを確認する
		 */
		handleConfirmCommentAndConfirm() {
			if ( !this.valid()) {
				return;
			}

			this.$refs['confirmCommentAndConfirm'].show();
		},

		/**
		 * コメントして確認する
		 */
		async handleCommentAndConfirm() {
			await this.save('commentconfirm');
		},

		/**
		 * 確認済みにするを確認する
		 */
		handleConfirmConfirm() {
			if ( !this.valid()) {
				return;
			}
			this.$refs['confirmConfirm'].show();
		},

		/**
		 * 確認済みにする
		 */
		async handleConfirm() {
			await this.save('confirm');
		},
		//<!-- end #100 対応 -->

		async getConsultList( {mode = 'mine', fbsUuid = '', status = ''} ) {
			let value;
			let param = {
				params: {
					sid: this.$store.state.sid,
					mode: mode,
					fbs_uuid: fbsUuid,
					status: status,
				},
			};

			console.log( process.env.VUE_APP_RESTAPI_GET_CONSULT_LIST, param );

			try {
				let res = await axios.get( process.env.VUE_APP_RESTAPI_GET_CONSULT_LIST, param );

				if ( res.data.result === 'OK' ) {
					this.set_sid( res.data.sid );
				}
		
				value = res.data;
			} catch( err ) {
				value = {
					result: 'NG',
					message: 'サーバから情報取得できませんでした。もう一度操作してください。',
				};
			}

			return value;
		},
	},
}