var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container" },
    [
      _vm.bind_data.form_info && _vm.bind_data.form_data
        ? _vm._l(_vm.bind_data.form_info.edit_item_data, function(item) {
            return _c(
              "div",
              { key: item.edit_item_no, staticClass: "mb-4 text-left" },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mx-2",
                    attrs: { "header-bg-variant": "light" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "header",
                          fn: function() {
                            return [
                              item.edit_item_must == "on"
                                ? [
                                    _c(
                                      "b-badge",
                                      {
                                        staticClass: "float-left mr-2",
                                        attrs: { pill: "", variant: "danger" }
                                      },
                                      [_vm._v("必須")]
                                    )
                                  ]
                                : _vm._e(),
                              _c(
                                "h4",
                                { staticClass: "mb-0 font-weight-bold" },
                                [_vm._v(_vm._s(item.edit_item_name))]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    [
                      _c("div", { staticClass: "mb-2" }, [
                        _vm._v(_vm._s(item.edit_item_desc))
                      ])
                    ],
                    item.edit_item_type == "text"
                      ? [
                          _c("b-form-input", {
                            staticClass: "m-0",
                            attrs: {
                              placeholder: _vm.get_text_placeholder(item),
                              state: item.state_info,
                              maxlength: "50"
                            },
                            on: { change: _vm.input_form_data },
                            model: {
                              value: item.value[0],
                              callback: function($$v) {
                                _vm.$set(item.value, 0, $$v)
                              },
                              expression: "item.value[0]"
                            }
                          })
                        ]
                      : _vm._e(),
                    item.edit_item_type == "texts"
                      ? [
                          _c("b-form-textarea", {
                            staticClass: "m-0",
                            attrs: {
                              placeholder: _vm.get_text_placeholder(item),
                              state: item.state_info,
                              maxlength: "500"
                            },
                            on: { change: _vm.input_form_data },
                            model: {
                              value: item.value[0],
                              callback: function($$v) {
                                _vm.$set(item.value, 0, $$v)
                              },
                              expression: "item.value[0]"
                            }
                          })
                        ]
                      : _vm._e(),
                    item.edit_item_type == "numeric"
                      ? [
                          _c("b-form-input", {
                            staticClass: "m-0",
                            attrs: {
                              placeholder: _vm.get_numeric_placeholder(item),
                              state: item.state_info
                            },
                            on: { change: _vm.input_form_data },
                            model: {
                              value: item.value[0],
                              callback: function($$v) {
                                _vm.$set(item.value, 0, $$v)
                              },
                              expression: "item.value[0]"
                            }
                          })
                        ]
                      : _vm._e(),
                    item.edit_item_type == "date"
                      ? [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-form-datepicker", {
                                    staticClass: "m-0",
                                    attrs: {
                                      locale: "ja",
                                      "label-no-date-selected":
                                        "日付を選択してください",
                                      "date-format-options": {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        weekday: "short"
                                      },
                                      state: item.state_info
                                    },
                                    on: { change: _vm.input_form_data },
                                    model: {
                                      value: item.value[0],
                                      callback: function($$v) {
                                        _vm.$set(item.value, 0, $$v)
                                      },
                                      expression: "item.value[0]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    item.edit_item_type == "time"
                      ? [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-form-timepicker", {
                                    staticClass: "m-0",
                                    attrs: {
                                      locale: "ja",
                                      "label-no-time-selected":
                                        "時刻を選択してください",
                                      state: item.state_info
                                    },
                                    on: { change: _vm.input_form_data },
                                    model: {
                                      value: item.value[0],
                                      callback: function($$v) {
                                        _vm.$set(item.value, 0, $$v)
                                      },
                                      expression: "item.value[0]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    item.edit_item_type == "datetime"
                      ? [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c("b-form-datepicker", {
                                    staticClass: "m-0",
                                    attrs: {
                                      locale: "ja",
                                      "label-no-date-selected":
                                        "日付を選択してください",
                                      "date-format-options": {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                        weekday: "short"
                                      },
                                      state: item.state_info
                                    },
                                    on: { change: _vm.input_form_data },
                                    model: {
                                      value: item.value[0],
                                      callback: function($$v) {
                                        _vm.$set(item.value, 0, $$v)
                                      },
                                      expression: "item.value[0]"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c("b-form-timepicker", {
                                    staticClass: "m-0",
                                    attrs: {
                                      locale: "ja",
                                      "label-no-time-selected":
                                        "時刻を選択してください",
                                      state: item.state_info
                                    },
                                    on: { change: _vm.input_form_data },
                                    model: {
                                      value: item.value[1],
                                      callback: function($$v) {
                                        _vm.$set(item.value, 1, $$v)
                                      },
                                      expression: "item.value[1]"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    item.edit_item_type == "now"
                      ? [
                          _vm._v(
                            " " +
                              _vm._s(item.value[0] + " " + item.value[1]) +
                              " "
                          )
                        ]
                      : _vm._e(),
                    item.edit_item_type == "radio"
                      ? [
                          _c(
                            "b-form-group",
                            { staticClass: "m-0" },
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  options: item.edit_item_option.select_option,
                                  state: item.state_info
                                },
                                on: { change: _vm.input_form_data },
                                model: {
                                  value: item.value[0],
                                  callback: function($$v) {
                                    _vm.$set(item.value, 0, $$v)
                                  },
                                  expression: "item.value[0]"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e(),
                    item.edit_item_type == "pulldown"
                      ? [
                          _c("b-form-select", {
                            staticClass: "m-0",
                            attrs: {
                              options: item.edit_item_option.select_option,
                              state: item.state_info
                            },
                            on: { change: _vm.input_form_data },
                            model: {
                              value: item.value[0],
                              callback: function($$v) {
                                _vm.$set(item.value, 0, $$v)
                              },
                              expression: "item.value[0]"
                            }
                          })
                        ]
                      : _vm._e(),
                    item.edit_item_type == "toggle"
                      ? [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "m-0",
                              attrs: { value: "該当", "unchecked-value": "" },
                              on: { change: _vm.input_form_data },
                              model: {
                                value: item.value[0],
                                callback: function($$v) {
                                  _vm.$set(item.value, 0, $$v)
                                },
                                expression: "item.value[0]"
                              }
                            },
                            [_vm._v(_vm._s(item.edit_item_name))]
                          )
                        ]
                      : _vm._e(),
                    item.edit_item_type == "mail_address"
                      ? [
                          _c("b-form-input", {
                            staticClass: "m-0",
                            attrs: {
                              placeholder: "例）xxxxxxx@xxxxxx.co.jp",
                              state: item.state_info
                            },
                            on: { change: _vm.input_form_data },
                            model: {
                              value: item.value[0],
                              callback: function($$v) {
                                _vm.$set(item.value, 0, $$v)
                              },
                              expression: "item.value[0]"
                            }
                          })
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ],
              1
            )
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }