var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "OrganizationView" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _vm.state_data.tree ? _c("div") : _vm._e(),
          _vm._l(_vm.bind_data.dpt_info_list, function(item) {
            return _c(
              "div",
              { key: item.id },
              [
                _c("vue2-org-tree", {
                  attrs: {
                    data: item,
                    collapsable: false,
                    horizontal: "",
                    "label-width": 180,
                    "label-class-name": _vm.labelClass,
                    "render-content": _vm.renderContent,
                    "selected-class-name": "selected-node",
                    "selected-key": "selectedKey"
                  },
                  on: {
                    "on-expand": _vm.onExpand,
                    "on-node-click": _vm.onNodeClick,
                    "on-node-mouseover": _vm.onNodeMouseOver,
                    "on-node-mouseout": _vm.onNodeMouseOut
                  }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }