import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import BaseDataUtils from '@/components/BaseDataUtils/BaseDataUtils.vue';

export default {
  name: 'CaseDetailsDiff', // ココを更新
  components: {
    Loading,
    BaseDataUtils,
  },

  // 共通関数
  mixins: [common],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        fbs_info_initial: null,
        fbs_info_complete: null,
        form_info: null,
      },
      // ローカルデータ
      local_data: {
      },
      // ローカルデータ
      state_data: {
      }
    }
  },

  // 既定計算
  computed: {
  },
  // インスタンス初期化後
  created(){
    this.$eventHub.$emit( "navbar_title", "報告データ比較" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    this.loader.is_loading = true;
    await this.$nextTick();

    const bas_info = this.$store.state.selected_bas_info;
    this.bind_data.form_info = this.$store.state.form_info;

    try {
      const config = {
        params: {
          sid: this.$store.state.sid,
          fbs_bas_uuid: bas_info.bas_uuid,
        },
      };

      const res = await axios.get(process.env.VUE_APP_RESTAPI_GET_FORM_BASICS_LIST, config);
      if (res.data.result === 'OK') {
        this.bind_data.fbs_info_initial = res.data.form_basics_list.find(b => b.fbs_form_comp_type === 'initial');
        if (this.bind_data.fbs_info_initial) {
          this.bind_data.fbs_info_initial.timeline_list = this.bind_data.fbs_info_initial.form_timeline_list;
        }
        this.bind_data.fbs_info_complete = res.data.form_basics_list.find(b => b.fbs_form_comp_type === 'complete');
        if (this.bind_data.fbs_info_complete) {
          this.bind_data.fbs_info_complete.timeline_list = this.bind_data.fbs_info_complete.form_timeline_list;
        }
        this.$store.dispatch("commit_set_sid",res.data.sid);
      }
      else {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = res.data.message;
      }
    }
    catch(e) {
      this.message.is_alert = true;
      this.message.variant = "danger";
      this.message.message = JSON.stringify(e);
    }

    this.loader.is_loading = false;
  },
  // ローカル関数
  methods: {
    
    back: function(){
      // 画面遷移
      this.transition("/Home/CaseDetails");
    },
  }
}