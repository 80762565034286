import { render, staticRenderFns } from "./OrganizationView.html?vue&type=template&id=821759f6&scoped=true&"
import script from "./OrganizationView.js?vue&type=script&lang=javascript&"
export * from "./OrganizationView.js?vue&type=script&lang=javascript&"
import style0 from "./OrganizationView.scss?vue&type=style&index=0&id=821759f6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "821759f6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\work\\btalert_eol_prod\\IN_SBP_OneRepo\\BT-Alert-VUE\\bt-alert-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('821759f6')) {
      api.createRecord('821759f6', component.options)
    } else {
      api.reload('821759f6', component.options)
    }
    module.hot.accept("./OrganizationView.html?vue&type=template&id=821759f6&scoped=true&", function () {
      api.rerender('821759f6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/OrganizationView/OrganizationView.vue"
export default component.exports