var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CompanysManage" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c("div", { staticClass: "container text-left" }, [
        _vm.bind_data.access_ok
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "mt-3 ml-2" },
                  [
                    _c(
                      "b-row",
                      { staticClass: "mt-2" },
                      [
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "2",
                              md: "2",
                              lg: "2",
                              xl: "2"
                            }
                          },
                          [
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-secondary" },
                                on: {
                                  click: function($event) {
                                    return _vm.add_row()
                                  }
                                }
                              },
                              [_vm._v("新規")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          {
                            staticClass: "text-right",
                            attrs: {
                              xs: "12",
                              sm: "10",
                              md: "10",
                              lg: "10",
                              xl: "10"
                            }
                          },
                          [_vm._v(_vm._s(_vm.local_data.user_sum) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.local_data.add_data.selected
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-card",
                          { staticClass: "card-shadow border-1 mt-2 ml-2" },
                          [
                            _c(
                              "b-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: {
                                      xs: "12",
                                      sm: "4",
                                      md: "4",
                                      lg: "3",
                                      xl: "2"
                                    }
                                  },
                                  [_vm._v("会社ID")]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      xs: "12",
                                      sm: "8",
                                      md: "8",
                                      lg: "9",
                                      xl: "10"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "text",
                                        maxlength: "12",
                                        placeholder:
                                          "会社IDを半角英小文字12文字以下で入力してください",
                                        state: _vm.state_add_cmp_id
                                      },
                                      model: {
                                        value: _vm.local_data.add_data.cmp_id,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data.add_data,
                                            "cmp_id",
                                            $$v
                                          )
                                        },
                                        expression: "local_data.add_data.cmp_id"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: {
                                      xs: "12",
                                      sm: "4",
                                      md: "4",
                                      lg: "3",
                                      xl: "2"
                                    }
                                  },
                                  [_vm._v("会社名")]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      xs: "12",
                                      sm: "8",
                                      md: "8",
                                      lg: "9",
                                      xl: "10"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        maxlength: "32",
                                        placeholder:
                                          "会社名を32文字以下で入力してください",
                                        state: _vm.state_add_cmp_name
                                      },
                                      model: {
                                        value: _vm.local_data.add_data.cmp_name,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data.add_data,
                                            "cmp_name",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.add_data.cmp_name"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: {
                                      xs: "12",
                                      sm: "4",
                                      md: "4",
                                      lg: "3",
                                      xl: "2"
                                    }
                                  },
                                  [_vm._v("会社種別")]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      xs: "12",
                                      sm: "8",
                                      md: "8",
                                      lg: "9",
                                      xl: "10"
                                    }
                                  },
                                  [
                                    _c("b-form-radio-group", {
                                      attrs: {
                                        options: _vm.local_data.type_options,
                                        state: _vm.state_add_cmp_type
                                      },
                                      model: {
                                        value: _vm.local_data.add_data.cmp_type,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data.add_data,
                                            "cmp_type",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.add_data.cmp_type"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: {
                                      xs: "12",
                                      sm: "4",
                                      md: "4",
                                      lg: "3",
                                      xl: "2"
                                    }
                                  },
                                  [_vm._v("ユーザー上限数")]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      xs: "12",
                                      sm: "8",
                                      md: "8",
                                      lg: "9",
                                      xl: "10"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        type: "number",
                                        placeholder:
                                          "割当可能数以下で入力してください",
                                        state: _vm.state_add_cmp_max_users
                                      },
                                      model: {
                                        value:
                                          _vm.local_data.add_data.cmp_max_users,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data.add_data,
                                            "cmp_max_users",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.add_data.cmp_max_users"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-right",
                                    attrs: {
                                      xs: "12",
                                      sm: "4",
                                      md: "4",
                                      lg: "3",
                                      xl: "2"
                                    }
                                  },
                                  [_vm._v("表示順位")]
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      xs: "12",
                                      sm: "8",
                                      md: "8",
                                      lg: "9",
                                      xl: "10"
                                    }
                                  },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        maxlength: "8",
                                        placeholder:
                                          "表示順位を8文字以下で入力してください",
                                        state: _vm.state_add_cmp_sort_order
                                      },
                                      model: {
                                        value:
                                          _vm.local_data.add_data
                                            .cmp_sort_order,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.local_data.add_data,
                                            "cmp_sort_order",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "local_data.add_data.cmp_sort_order"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "ml-2 float-right",
                                        attrs: {
                                          variant: "outline-secondary",
                                          disabled: _vm.state_add_new_button
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.add_new()
                                          }
                                        }
                                      },
                                      [_vm._v("登録")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "float-right",
                                        attrs: { variant: "outline-secondary" },
                                        on: {
                                          click: function($event) {
                                            return _vm.add_cancel()
                                          }
                                        }
                                      },
                                      [_vm._v("キャンセル")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { attrs: { sm: "4", lg: "4" } },
                      [
                        _c("b-form-select", {
                          attrs: {
                            options: _vm.bind_data.cmp_id_options,
                            placeholder: "会社ID"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "first",
                                fn: function() {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null } },
                                      [_vm._v("すべて（会社）")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1526336765
                          ),
                          model: {
                            value: _vm.bind_data.search_cmp_id,
                            callback: function($$v) {
                              _vm.$set(_vm.bind_data, "search_cmp_id", $$v)
                            },
                            expression: "bind_data.search_cmp_id"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "3", lg: "3" } },
                      [
                        _c("b-form-select", {
                          attrs: {
                            options: _vm.bind_data.cmp_type_options,
                            placeholder: "会社種別"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "first",
                                fn: function() {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null } },
                                      [_vm._v("すべて（会社種別）")]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            45447158
                          ),
                          model: {
                            value: _vm.bind_data.search_cmp_type,
                            callback: function($$v) {
                              _vm.$set(_vm.bind_data, "search_cmp_type", $$v)
                            },
                            expression: "bind_data.search_cmp_type"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "3", lg: "3" } },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "mb-2" },
                          [
                            _c(
                              "b-input-group-prepend",
                              { attrs: { "is-text": "" } },
                              [_c("b-icon", { attrs: { icon: "search" } })],
                              1
                            ),
                            _c("b-form-input", {
                              attrs: {
                                placeholder: "会社名（部分一致）",
                                maxlength: "100"
                              },
                              model: {
                                value: _vm.bind_data.search_name,
                                callback: function($$v) {
                                  _vm.$set(_vm.bind_data, "search_name", $$v)
                                },
                                expression: "bind_data.search_name"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { attrs: { sm: "2", lg: "2" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              block: "",
                              variant: "outline-secondary",
                              disabled: false
                            },
                            on: {
                              click: function($event) {
                                _vm.search_companys_list()
                                _vm.users_sum()
                              }
                            }
                          },
                          [_vm._v("検索")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("b-table", {
                  staticClass: "mt-3",
                  attrs: {
                    striped: "",
                    hover: "",
                    items: _vm.bind_data.result.list,
                    fields: _vm.local_data.fields
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(app_cmd)",
                        fn: function(row) {
                          return [
                            _c(
                              "b-button",
                              {
                                staticClass: "float-right",
                                attrs: {
                                  variant: "outline-secondary",
                                  size: "sm"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.edit_row(row)
                                  }
                                }
                              },
                              [_vm._v("編集")]
                            )
                          ]
                        }
                      },
                      {
                        key: "row-details",
                        fn: function(row) {
                          return [
                            _c(
                              "b-card",
                              { staticClass: "border-1 mt-2 ml-2" },
                              [
                                _c(
                                  "b-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: {
                                          xs: "12",
                                          sm: "4",
                                          md: "4",
                                          lg: "3",
                                          xl: "2"
                                        }
                                      },
                                      [_vm._v("会社ID")]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          xs: "12",
                                          sm: "8",
                                          md: "8",
                                          lg: "9",
                                          xl: "10"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: "会社ID",
                                            disabled: ""
                                          },
                                          model: {
                                            value:
                                              _vm.local_data.edit_data.cmp_id,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.local_data.edit_data,
                                                "cmp_id",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "local_data.edit_data.cmp_id"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: {
                                          xs: "12",
                                          sm: "4",
                                          md: "4",
                                          lg: "3",
                                          xl: "2"
                                        }
                                      },
                                      [_vm._v("会社名")]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          xs: "12",
                                          sm: "8",
                                          md: "8",
                                          lg: "9",
                                          xl: "10"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            maxlength: "32",
                                            placeholder:
                                              "会社名を32文字以下で入力してください",
                                            state: _vm.state_edit_cmp_name
                                          },
                                          model: {
                                            value:
                                              _vm.local_data.edit_data.cmp_name,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.local_data.edit_data,
                                                "cmp_name",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "local_data.edit_data.cmp_name"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: {
                                          xs: "12",
                                          sm: "4",
                                          md: "4",
                                          lg: "3",
                                          xl: "2"
                                        }
                                      },
                                      [_vm._v("会社種別")]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          xs: "12",
                                          sm: "8",
                                          md: "8",
                                          lg: "9",
                                          xl: "10"
                                        }
                                      },
                                      [
                                        _c("b-form-radio-group", {
                                          attrs: {
                                            options: _vm.local_data.type_options
                                          },
                                          model: {
                                            value:
                                              _vm.local_data.edit_data.cmp_type,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.local_data.edit_data,
                                                "cmp_type",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "local_data.edit_data.cmp_type"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: {
                                          xs: "12",
                                          sm: "4",
                                          md: "4",
                                          lg: "3",
                                          xl: "2"
                                        }
                                      },
                                      [_vm._v("ユーザー上限数")]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          xs: "12",
                                          sm: "8",
                                          md: "8",
                                          lg: "9",
                                          xl: "10"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "number",
                                            placeholder:
                                              "割当可能数以下で入力してください",
                                            state: _vm.state_edit_cmp_max_users
                                          },
                                          model: {
                                            value:
                                              _vm.local_data.edit_data
                                                .cmp_max_users,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.local_data.edit_data,
                                                "cmp_max_users",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "local_data.edit_data.cmp_max_users"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "text-right",
                                        attrs: {
                                          xs: "12",
                                          sm: "4",
                                          md: "4",
                                          lg: "3",
                                          xl: "2"
                                        }
                                      },
                                      [_vm._v("表示順位")]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: {
                                          xs: "12",
                                          sm: "8",
                                          md: "8",
                                          lg: "9",
                                          xl: "10"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            maxlength: "8",
                                            placeholder:
                                              "表示順位を8文字以下で入力してください",
                                            state: _vm.state_edit_cmp_sort_order
                                          },
                                          model: {
                                            value:
                                              _vm.local_data.edit_data
                                                .cmp_sort_order,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.local_data.edit_data,
                                                "cmp_sort_order",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "local_data.edit_data.cmp_sort_order"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-left",
                                            attrs: { variant: "danger" },
                                            on: {
                                              click: function($event) {
                                                return _vm.delete_confirm()
                                              }
                                            }
                                          },
                                          [_vm._v("削除")]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "ml-2 float-right",
                                            attrs: {
                                              variant: "outline-secondary",
                                              disabled:
                                                _vm.state_edit_update_button
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.update_row()
                                              }
                                            }
                                          },
                                          [_vm._v("更新")]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right",
                                            attrs: {
                                              variant: "outline-secondary"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.cancel_row(row)
                                              }
                                            }
                                          },
                                          [_vm._v("キャンセル")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    368056277
                  )
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _c(
        "b-modal",
        {
          ref: "confirm-modal",
          attrs: { title: "削除" },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function($event) {
                          return _vm.delete_cancel()
                        }
                      }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { variant: "danger" },
                      on: {
                        click: function($event) {
                          return _vm.delete_row()
                        }
                      }
                    },
                    [_vm._v("削除")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("p", [
            _vm._v(
              "会社ID：" +
                _vm._s(_vm.local_data.edit_data.cmp_id) +
                " を削除します。よろしいですか?"
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }