import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import api from './modules/api.module';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// ログイン情報
		sid : null,
		mode: "",
		users_info: null,
		//システムパラメータ
		system_info: null,
		//区分パラメータ
		class_list: null,

		//起動元がスマホか？スマホアプリの時：false 通常：true
		startup: true,

		//ダッシュボードから報告データ管理へのデータ連携
		dashboard_to_report_data: null,

		//フォーム情報
		form_info: null,
		//選択された事案
		selected_bas_info: null,
		//選択された帳票用事案
		selected_fbs_info: null,
		//遷移元の画面
		prev_screen_name: null,
		//ショートカット
		bas_uuid: "",
		fbs_uuid: "",
	},
	mutations: {
		// ログイン情報
		set_sid( state, sid ){
			state.sid = sid;
		},
		set_mode( state, mode ){
			state.mode = mode;
		},
		set_users_info( state, info ){
			state.users_info = info;
		},
		//システムパラメータ
		set_system_info( state, info ){
			state.system_info = info;
		},
		//区分パラメータ
		set_class_list( state, info ){
			state.class_list = info;
		},

		//起動元がスマホか？スマホアプリの時：false 通常：true
		set_startup( state, info ){
			state.startup = info;
		},
		//ダッシュボードから報告データ管理へのデータ連携
		dashboard_to_report_data( state, info ){
			state.dashboard_to_report_data = info;
		},

		//フォーム情報
		set_form_info( state, form_info ){
			state.form_info = form_info;
		},
		//選択された事案
		set_selected_bas_info( state, bas_info ){
			state.selected_bas_info = bas_info;
		},
		//選択された帳票用事案
		set_selected_fbs_info( state, fbs_info ){
			state.selected_fbs_info = fbs_info;
		},
		//遷移元の画面
		set_prev_screen_name( state, screen_name ){
			state.prev_screen_name = screen_name;
		},
		//ショートカット
		set_bas_uuid( state, bas_uuid ){
			state.bas_uuid = bas_uuid;
		},
		set_fbs_uuid( state, fbs_uuid ){
			state.fbs_uuid = fbs_uuid;
		},
		// ステートの初期化
		reset_state(state) {
			for (let key in state) {
				state[key] = null;
			}
		},
	},
	actions: {
		// ログイン情報
		commit_set_sid( store, sid ){
			store.commit('set_sid', sid);
		},
		commit_set_mode( store, mode ){
			store.commit('set_mode', mode);
		},
		commit_set_users_info( store, users_info ){
			store.commit('set_users_info', users_info);
		},
		//システムパラメータ
		commit_set_system_info( store, info ){
			store.commit('set_system_info', info);
		},
		//区分パラメータ
		commit_set_class_list( store, info ){
			store.commit('set_class_list', info);
		},

		//起動元がスマホか？スマホアプリの時：false 通常：true
		commit_set_startup( store, info ){
			store.commit('set_startup', info);
		},
		//ダッシュボードから報告データ管理へのデータ連携
		commit_dashboard_to_report_data( store, info ){
			store.commit('dashboard_to_report_data', info);
		},

		//フォーム情報
		commit_set_form_info( store, form_info ){
			store.commit('set_form_info', form_info);
		},
		//選択された事案
		commit_set_selected_bas_info( store, bas_info ){
			store.commit('set_selected_bas_info', bas_info);
		},
		//選択された帳票用事案
		commit_set_selected_fbs_info( store, fbs_info ){
			store.commit('set_selected_fbs_info', fbs_info);
		},
		//遷移元の画面
		commit_set_prev_screen_name( store, screen_name ){
			store.commit('set_prev_screen_name', screen_name);
		},
		//ショートカット
		commit_set_bas_uuid( store, bas_uuid ){
			store.commit('set_bas_uuid', bas_uuid);
		},
		commit_set_fbs_uuid( store, fbs_uuid ){
			store.commit('set_fbs_uuid', fbs_uuid);
		},
		// ステートの初期化
		commit_reset_state( store ){
			store.commit('reset_state');
		},
	},
	modules: {
		api,
	},
	plugins: [createPersistedState(
		{ // ストレージのキーを指定。デフォルトではvuex
			key: 'btalert',

			// 管理対象のステートを指定。pathsを書かない時は`modules`に書いたモジュールに含まれるステート全て。`[]`の時はどれも保存されない
			paths: [
				'sid',
				'users_info',
				'system_info',
			],

			// ストレージの種類を指定する。デフォルトではローカルストレージ
			storage: window.sessionStorage
		}		
	)],
})
