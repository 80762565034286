var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CompanysAccessControlManage" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c(
                "b-card",
                { staticClass: "border-1 mt-2" },
                [
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "4",
                            md: "4",
                            lg: "3",
                            xl: "2"
                          }
                        },
                        [_vm._v("IPアドレス制限機能")]
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "6",
                            md: "6",
                            lg: "7",
                            xl: "8"
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  options: _vm.bind_data.switch_options
                                },
                                model: {
                                  value: _vm.bind_data.switch_selected,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.bind_data,
                                      "switch_selected",
                                      $$v
                                    )
                                  },
                                  expression: "bind_data.switch_selected"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        {
                          attrs: {
                            xs: "12",
                            sm: "2",
                            md: "2",
                            lg: "2",
                            xl: "2"
                          }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2 float-right",
                              attrs: { variant: "outline-secondary" },
                              on: {
                                click: function($event) {
                                  return _vm.confirmHandleUpdateIpControl()
                                }
                              }
                            },
                            [_vm._v("更新")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "confirmUpdateIpControl",
              attrs: { title: "IPアドレス制限", "cancel-title": "キャンセル" },
              on: {
                ok: function($event) {
                  return _vm.handleUpdateIpControl()
                }
              }
            },
            [_vm._v("有効・無効の設定を変更します。よろしいですか？")]
          ),
          _c("b-table", {
            staticClass: "mt-3",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.bind_data.result.list,
              fields: _vm.local_data.fields
            },
            scopedSlots: _vm._u([
              {
                key: "cell(app_cmd)",
                fn: function(row) {
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "float-right",
                        attrs: { variant: "outline-secondary", size: "sm" },
                        on: {
                          click: function($event) {
                            return _vm.edit_row(row)
                          }
                        }
                      },
                      [_vm._v("編集")]
                    )
                  ]
                }
              },
              {
                key: "row-details",
                fn: function(row) {
                  return [
                    _c(
                      "b-card",
                      { staticClass: "border-1 mt-2 ml-2" },
                      [
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              {
                                staticClass: "text-right",
                                attrs: {
                                  xs: "12",
                                  sm: "4",
                                  md: "4",
                                  lg: "3",
                                  xl: "2"
                                }
                              },
                              [_vm._v("アクセス制御")]
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: {
                                  xs: "12",
                                  sm: "8",
                                  md: "8",
                                  lg: "9",
                                  xl: "10"
                                }
                              },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    placeholder:
                                      "1行ごとにIPアドレスを入力してください。例：192.168.1.1",
                                    state: "state_edit_cmp_access_control",
                                    rows: "3",
                                    "max-rows": "20"
                                  },
                                  model: {
                                    value:
                                      _vm.local_data.edit_data
                                        .cmp_access_control,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.local_data.edit_data,
                                        "cmp_access_control",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "local_data.edit_data.cmp_access_control"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-row",
                          { staticClass: "mt-2" },
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "ml-2 float-right",
                                    attrs: {
                                      variant: "outline-secondary",
                                      disabled: _vm.state_update_row_button
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.update_row()
                                      }
                                    }
                                  },
                                  [_vm._v("更新")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-right",
                                    attrs: { variant: "outline-secondary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.cancel_row(row)
                                      }
                                    }
                                  },
                                  [_vm._v("キャンセル")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }