// ----------------------------------------------------------------
// 共通関数 
// common.js Ver 1.0.1
// ----------------------------------------------------------------
import axios from 'axios';
import moment from 'moment-timezone';

export default {

  data() {
    return {
      datepicker: {
        locale: "ja-JP",
        labels: {
          "ja-JP": {
            labelNoDateSelected: "日付を選択してください",
            labelNoTimeSelected: "時間を選択してください",
            labelHelp: "",
            labelTodayButton: "今日",
            labelNowButton: "現在時刻",
            labelCloseButton: "閉じる",
            labelResetButton: "消去",
          }
        }
      },
      text_align: {
        text_left: "text-left",
        text_right: "text-right",
      }
    }
  },

  computed: {
    isNotMobilePhone(){
      if( navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0 && navigator.userAgent.indexOf('Mobile') > 0 ){
        return false;
      } else {
        return true;
      }
    }
  },

  async mounted(){

    // 操作ログを送信する
    let header = {
      "x-api-key":process.env.VUE_APP_API_KEY,
    }
    let url = process.env.VUE_APP_BTALERT_OPERATION_LOGGER;
    let post_data = {};
    post_data.path = this.$route.path;
    post_data.date = moment().tz('Asia/Tokyo').format("YYYY/MM/DD HH:mm:ss.SSS");
    post_data.hostname = window.location.href
    post_data.usr_info = {};
    post_data.usr_info.usr_uuid = this.$store.state.users_info.usr_uuid;
    post_data.usr_info.usr_login_id = this.$store.state.users_info.usr_login_id;
    post_data.usr_info.usr_name = this.$store.state.users_info.usr_name;
    post_data.usr_info.usr_cmp_id = this.$store.state.users_info.usr_cmp_id;
    post_data.usr_info.usr_cmp_name = this.$store.state.users_info.cmp_name;
    post_data.usr_info.usr_org_uuid = this.$store.state.users_info.usr_org_uuid;
    post_data.usr_info.usr_org_name = this.$store.state.users_info.org_name;
    //console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );
			
    try {
		await axios.post( url, post_data, { headers: header } );
		// let response = await axios.post( url, post_data, { headers: header } );
		//console.log(this.$route.path+":response="+JSON.stringify(response) );
    } catch (ex) {
      console.log(this.$route.path+":ex="+JSON.stringify(ex) );
    }
  },

  methods: {

    exclude_empty_array( array ){
      if( array.length == 1 ){
        if( array[0] == "" ){
          return [];
        }
      }
      return array;
    },

    base64decode( data ){
      if( data == null || data == "" ) return "";
      return Buffer.from(data,'base64').toString();
    },

    base64encode( data ){
      if( data == null || data == "" ) return "";
      return new Buffer.from(data).toString('base64');
    },

    // 画面遷移
    transition: function( name, params = {} ){
      if( this.$route.path != name ){
        this.$router.push({
          name: name,
          params: params,
        });
      }
    },
    
    trans_forward: function( name, params = {}){
      if( this.$route.path != name ){
        // 遷移元をストアに保管する
        this.$store.dispatch("commit_set_prev_screen_name",this.$route.path);// グローバル変数（store）に格納
        this.$router.push({
          name: name,
          params: params,
        });
      }
    },
    trans_back: function(){
      this.$router.push(this.$store.state.prev_screen_name, () => {});
    },

    // エラー出力用
    printStackTrace: function(e) {
      if (e.stack) {
        // 出力方法は、使いやすいように修正する。
        console.log(e.stack);
       // alert(e.stack);
      } else {
        // stackがない場合には、そのままエラー情報を出す。
        console.log(e.message, e);
      }
    },
  },

  bcpWindow: null,
}

