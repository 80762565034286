var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CaseDetails" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-button",
            {
              staticClass: "m-2",
              attrs: { variant: "outline-secondary" },
              on: {
                click: function($event) {
                  return _vm.back()
                }
              }
            },
            [_vm._v("戻る")]
          ),
          _vm.state_data.form_data || _vm.state_data.fbs_form_data
            ? _c(
                "div",
                { staticClass: "float-right" },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.bind_data.bas_info.bas_comp_type === "complete",
                          expression:
                            "bind_data.bas_info.bas_comp_type==='complete'"
                        }
                      ],
                      staticClass: "m-2",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function($event) {
                          return _vm.show_before()
                        }
                      }
                    },
                    [_vm._v("編集前を表示")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.state_data.form_data
            ? _c("BaseDataUtils", {
                attrs: { bas_info: _vm.bind_data.bas_info },
                on: {
                  message: function($event) {
                    return _vm.handleMessage($event)
                  }
                }
              })
            : _vm._e(),
          _vm.state_data.fbs_form_data
            ? _c("BaseDataUtils", {
                attrs: { bas_info: _vm.bind_data.fbs_bas_info },
                on: {
                  message: function($event) {
                    return _vm.handleMessage($event)
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("br"),
      _c("br")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }