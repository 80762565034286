var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AuthManage" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-row",
            { staticClass: "mt-3" },
            [
              _c(
                "b-col",
                {
                  staticClass: "text-right",
                  attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "2" }
                },
                [_vm._v("会社名")]
              ),
              _c(
                "b-col",
                { attrs: { xs: "12", sm: "8", md: "8", lg: "9", xl: "10" } },
                [
                  _c("b-form-select", {
                    staticClass: "mb-3",
                    attrs: { options: _vm.bind_data.cmp_uuid_options },
                    on: { change: _vm.set_checkbox },
                    model: {
                      value: _vm.local_data.cmp_uuid,
                      callback: function($$v) {
                        _vm.$set(_vm.local_data, "cmp_uuid", $$v)
                      },
                      expression: "local_data.cmp_uuid"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm.state_data.auth_table
            ? [
                _c(
                  "table",
                  { staticClass: "table table-striped table-bordered mt-3" },
                  [
                    _vm._m(0),
                    _c(
                      "tbody",
                      {
                        attrs: { tag: "tbody" },
                        model: {
                          value: _vm.bind_data.list,
                          callback: function($$v) {
                            _vm.$set(_vm.bind_data, "list", $$v)
                          },
                          expression: "bind_data.list"
                        }
                      },
                      _vm._l(_vm.bind_data.list, function(item) {
                        return _c("tr", { key: item.ath_id }, [
                          _c(
                            "td",
                            [
                              _c("b-form-checkbox", {
                                attrs: {
                                  value: "accepted",
                                  "unchecked-value": "not_accepted"
                                },
                                model: {
                                  value: item.status,
                                  callback: function($$v) {
                                    _vm.$set(item, "status", $$v)
                                  },
                                  expression: "item.status"
                                }
                              })
                            ],
                            1
                          ),
                          _c("td", { attrs: { nowrap: "" } }, [
                            _vm._v(_vm._s(item.ath_id))
                          ]),
                          _c("td", { attrs: { nowrap: "" } }, [
                            _vm._v(_vm._s(item.ath_name))
                          ]),
                          _c("td", [_vm._v(_vm._s(item.ath_desc))])
                        ])
                      }),
                      0
                    )
                  ]
                )
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-secondary" },
                  on: {
                    click: function($event) {
                      return _vm.regist()
                    }
                  }
                },
                [_vm._v("登録")]
              )
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c("br")
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("権限ID")]),
        _c("th", [_vm._v("権限名")]),
        _c("th", [_vm._v("説明")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }