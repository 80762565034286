import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';

export default {
	name: 'Dashboard', // ココを更新
	components: {
		Loading,
		treeselectdialogs,
	},

	// 共通関数
	mixins: [
		common,
		CommonDataSet,
	],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				// 検索条件表示トグル
				search_toggle: false,
				search_toggle_rotate:"0",
				state_class: null,
				state_options: [],
				report_class: null,
				report_options: [],
				summary_method: "owner",
				owner_selected: null,
				owner_options: [],
				// dpt_options: [],
				dpt_selected: [],
				//現在日付
				between_date_from: moment().add(-1,'day').format('YYYY/MM/DD HH:mm:ss'),
				between_date_to: moment().format('YYYY/MM/DD HH:mm:ss'),
				between_init: true,
				between_edit_start_date: moment().add(-1,'day').format('YYYY-MM-DD'),
				between_edit_start_time: moment().add(-1,'day').format('HH:mm:00'),
				between_edit_end_date: moment().format('YYYY-MM-DD'),
				between_edit_end_time: moment().format('HH:mm:59'),
				// 事案件数
				lists: [],
			},
			// ローカルデータ
			local_data: {
				fields: [
					{ key: "cmp_name", label: "オーナー", class:"text-nowrap text-left"},
					{ key: "total", label: "総報告件数", class:"text-nowrap" },
					{ key: "emergency", label: "緊急報告数", class:"text-nowrap" },
					{ key: "correspond", label: "緊急未完了数", class:"text-nowrap" },
					{ key: "normal", label: "通常報告数", class:"text-nowrap"},
				],
				state_between_date_init: true,
			},
			state_data: {
				treeselectdialogs: true,
			},
		}
	},

	// 既定計算
	computed: {
		state_owner_selected() {
			return ( this.bind_data.summary_method == "owner" ) ? false : true ;
		},

		search_datetime_from() {
			return ( this.bind_data.between_date_from.substr(0, 16).replace( /-/g, "/" ) );
		},
		
		search_datetime_to() {
			return ( this.bind_data.between_date_to.substr(0, 16).replace( /-/g, "/" ) );
		},
	},

	// インスタンス初期化後
	created() {
		// タイトル更新イベント
		this.$eventHub.$emit( "navbar_title", "ダッシュボード" );
	},

	// インスタンス破棄後
	destroyed: function() {},

	// インスタンスマウント後
	async mounted() {
		this.loader.is_loading = true;

		await this.data_set( this.bind_data.between_date_from, this.bind_data.between_date_to, this.bind_data.summary_method );

		try {
			const config = {
				params: {
					sid: this.$store.state.sid,
					cmp_type: "owner",
				},
			};
			const res = await axios.get( process.env.VUE_APP_RESTAPI_GET_COMPANY_LIST, config );
			
			if ( res.data.result === 'OK' ) {
				for ( let i = 0; i < res.data.company_list.length; i++ ) {
					this.bind_data.owner_options.push( {
						text: res.data.company_list[i].cmp_name,
						value: res.data.company_list[i].cmp_id,
					} );
				}

				this.$store.dispatch( "commit_set_sid", res.data.sid );
			} else {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = res.data.message;
			}
		} catch( e ) {
			this.message.is_alert = true;
			this.message.variant = "danger";
			this.message.message = JSON.stringify( e );
		}

		this.state_data.treeselectdialogs = false;
		await this.set_org_map( "allslf", null, null );
		this.state_data.treeselectdialogs = true;

		// const ret = await localStorage.getItem('organization_info');
		// const orgs = JSON.parse(ret);
		// for (let i=0 ; i<orgs.length ; i++) {
		//	this.bind_data.dpt_options.push({
		//		id: orgs[i].org_uuid,
		//		value: orgs[i].org_uuid,
		//		label: orgs[i].org_name,
		//	});
		// }

		const states = await this.get_item_list( 'dashboardstate' );

		for ( let i = 0; i < states.length; i++ ) {
			this.bind_data.state_options.push( {
				text: states[i].itm_name,
				value: states[i].itm_item_key,
			} );
		}

		const reports = await this.get_item_list( 'dashboardreport' );
		
		for ( let i = 0; i < reports.length; i++ ) {
			this.bind_data.report_options.push( {
				text: reports[i].itm_name,
				value: reports[i].itm_item_key,
			} );
		}

		this.loader.is_loading = false;
	},

	// ローカル関数
	methods: {
		// 検索条件トグル
		search_toggle() {
			if ( this.bind_data.search_toggle ) {
				this.bind_data.search_toggle_rotate = "0";
			} else {
				this.bind_data.search_toggle_rotate = "180";
			}

			this.bind_data.search_toggle = !this.bind_data.search_toggle;
		},

		toggle_between_date_init() {
			this.$refs['between_date_edit'].show();
		},

		async between_date_ok() {
			this.loader.is_loading = true;

			const startDatetime = `${this.bind_data.between_edit_start_date} ${this.bind_data.between_edit_start_time}`;
			const endDatetime = `${this.bind_data.between_edit_end_date} ${this.bind_data.between_edit_end_time}`;

			await this.data_set( startDatetime, endDatetime );

			this.loader.is_loading = false;
		},

		// APIで取得したデータをセットする部分（仮）
		async data_set( startDatetime = null, endDatetime = null, aggregationMethod = 'owner', compType = null, rptType = null, orgUuid = null, ownerUuid = null ) {
			try {
				const config = {
					params: {
						sid: this.$store.state.sid,
					},
				};

				if ( startDatetime ) {
					this.bind_data.between_date_from = startDatetime
					config.params.bas_occur_start_date_time = startDatetime;
				}

				if ( endDatetime ) {
					this.bind_data.between_date_to = endDatetime
					config.params.bas_occur_end_date_time = endDatetime;
				}

				if ( aggregationMethod ) {
					config.params.aggregation_method = aggregationMethod;
				}

				if ( compType ) {
					config.params.bas_comp_type = compType;
				}

				if ( rptType ) {
					config.params.bas_rpt_type = rptType;
				}

				if ( orgUuid ) {
					config.params.bas_org_uuid = orgUuid;
				}

				if ( ownerUuid ) {
					config.params.cmp_id = ownerUuid;
				}

				const res = await axios.get( process.env.VUE_APP_RESTAPI_GET_DASHBOARD_LIST, config );

				if ( res.data.result === 'OK' ) {
					if ( aggregationMethod == "owner" ) {
						this.local_data.fields[0].key = "cmp_name";
						this.local_data.fields[0].label = "オーナー";
					} else {
						this.local_data.fields[0].key = "mng_name";
						this.local_data.fields[0].label = "施設名";
					}

					this.bind_data.lists = res.data.dashboard_list;
					console.log( JSON.stringify( this.bind_data.lists ) );
					this.$store.dispatch( "commit_set_sid", res.data.sid );
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = res.data.message;
				}
			} catch( e ) {
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify( e );
			}
		},

		get_dpt_info_add( selected ) {
			this.bind_data.dpt_selected = selected;
		},

		async search() {
			this.loader.is_loading = true;

			//const startDatetime = `${this.bind_data.between_edit_start_date} ${this.bind_data.between_edit_start_time}`;
			//const endDatetime = `${this.bind_data.between_edit_end_date} ${this.bind_data.between_edit_end_time}`;
		
			//開始日時，終了日時が入力されていないは、デフォルト値を代入する
			let startYYYYMMDD = `${this.bind_data.between_edit_start_date}`;
			let startHHmmss = `${this.bind_data.between_edit_start_time}`;
			let endYYYYMMDD = `${this.bind_data.between_edit_end_date}`;
			let endHHmmss = `${this.bind_data.between_edit_end_time}`;
			if(!startHHmmss || startHHmmss.length < 8){
				startHHmmss = '00:00:00';
			}
			if(!endHHmmss || endHHmmss.length < 8){
				endHHmmss = '23:59:59';
			}
			if(!startYYYYMMDD || startYYYYMMDD.length < 10){
				startYYYYMMDD = '1970-01-01';
			}
			if(!endYYYYMMDD || endYYYYMMDD.length < 10){
				endYYYYMMDD = '2999-12-31';
			}
			let startDatetime = startYYYYMMDD + ' ' + startHHmmss;
			let endDatetime = endYYYYMMDD + ' ' + endHHmmss;

			//開始日時よりも終了日時が古い場合は、開始日時と終了日時を入れ替える
			let startYYYYMMDDHHmm = startDatetime.substring(0, 16);
			let endYYYYMMDDHHmm = endDatetime.substring(0, 16);
			if(endYYYYMMDDHHmm < startYYYYMMDDHHmm){
				startDatetime = endYYYYMMDDHHmm + ":00";
				endDatetime = startYYYYMMDDHHmm + ":59";
			}
			
			const orgs = this.bind_data.dpt_selected.join( ',' );

			await this.data_set( startDatetime, endDatetime, this.bind_data.summary_method, this.bind_data.state_class, this.bind_data.report_class, orgs, this.bind_data.owner_selected );

			this.loader.is_loading = false;
		},

		jumpToReportData( data ) {
			console.log( "data="+JSON.stringify( data ) );

			let param = {};
			param.dashboard_state = this.bind_data.state_class;		//状態
			param.dashboard_start_date_time = this.bind_data.between_edit_start_date + " " + this.bind_data.between_edit_start_time;	//発生日時
			param.dashboard_end_date_time = this.bind_data.between_edit_end_date + " " + this.bind_data.between_edit_end_time;		//発生日時
			param.dashboard_status = data.field.key;		//総報告件数:total //緊急報告数:emergency //緊急未完了数:correspond //通常報告数:normal
			param.dashboard_cmp_uuid = data.item.cmp_id;

			if ( data.item.mng_uuid === undefined ) {
				param.dashboard_mng_uuid = "";
			} else {
				param.dashboard_mng_uuid = data.item.mng_uuid;
			}

			console.log( "param="+JSON.stringify( param ) );
			this.$store.dispatch( "commit_dashboard_to_report_data", param );
			this.transition( '/Home/ReportingData' );

			// this.trans_forward('/Home/ReportingData', {
			//		state: this.bind_data.state_class,
			//		startDatetime: `${this.bind_data.between_edit_start_date} ${this.bind_data.between_edit_start_time}`,
			//		endDatetime: `${this.bind_data.between_edit_end_date} ${this.bind_data.between_edit_end_time}`,
			// });
		}
	}
}
