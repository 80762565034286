export default {
  name: "FormDataUtils",
  props: {
    form_info: {
      type: Object,
      default() {
        return {};
      }
    },
    form_data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      bind_data: {
        form_info: null,
        form_data: null,
      },
      state_data: {
      }
    }
  },
  mounted() {
    this.bind_data.form_info = this.form_info;
    this.bind_data.form_data = this.form_data;
  },

  // ローカル関数
  methods: {
    is_edit_item_type( item, types ){
      let type = types.split(",");
      let item_type = item.edit_item_type;
      for( let i=0;i<type.length;i++ ){
        if( item_type == type[i] ){
          return true;
        }
      }
      return false;
    },

    get_content_text( item_no ){
      for( let i=0;i<this.bind_data.form_data.item_data.length;i++ ){
        if( this.bind_data.form_data.item_data[i].item_no == item_no ){
          return this.bind_data.form_data.item_data[i].item_content;
        }
      }
      return item_no;
    },
  }
}
