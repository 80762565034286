<template>
  <div class="header">
    <div class="container" >
      <h1>{{ system_name }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  components: {
  },
  // ローカルデータ変数
  data() {
    return {
      system_name: process.env.VUE_APP_SYSTEM_NAME
    }
  }
};
</script>

<style scoped>
.header {
  background-color:#005eae;
  color:ivory;
  padding: 4px;
}
</style>