import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import validator from 'validator';
import axios from 'axios';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';

export default {
  name: 'CompanysManage', // ココを更新
  components: {
    Loading,
  },

  // 共通関数
  mixins: [common,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },

      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },

      // バインドデータ
      bind_data: {
        access_ok: false,
        // 検索条件
        search_name: "",
        search_cmp_id: "",
        search_cmp_type: "",
        // 検索結果
        result: {
          list: [],
        },
        // 会社ID
        cmp_id_options: [
        ],
        // 会社種別
        cmp_type_options: [
        ],
      },

      // ローカルデータ
      local_data: {
        //ユーザー数上限合計
        user_sum: "",
        //割当可能数
        assign_possible: "",
        // 選択行
        row: null,
        // 既存データ選択状態
        selected: false,
        add_data: {
          // 新規ボタン押下状態
          selected: false,
          cmp_id: "",
          cmp_name: "",
          cmp_type: "",
          cmp_max_users: "",
          cmp_sort_order: "",
        },
        edit_data: {
          app_cmd: "",
          cmp_id: "",
          cmp_name: "",
          cmp_type: "",
          cmp_max_users: "",
          cmp_sort_order: "",
        },
        fields: [
          { key: "cmp_id", label:"会社ID", sortable: true },
          { key: "cmp_name", label:"会社名", sortable: true },
          { key: "cmp_type_name", label:"会社種別", sortable: true },
          { key: "cmp_max_users", label:"ユーザー数上限", sortable: true },
          { key: "cmp_sort_order", label:"表示順位", sortable: true },
          { key: "app_cmd", label:"", class:"text-nowrap" },
        ],
        //会社種別
        type_options: [],
      }
    }
  },

  // 既定計算
  computed: {
    state_search_button(){
      if (!validator.isEmpty(this.local_data.cmp_name)) {
        return false;
      } else {
        return true;
      }
    },
    
    state_add_cmp_id(){
		let regex = RegExp(/^[a-z]{1,12}$/);
		return ( regex.test(this.local_data.add_data.cmp_id) );
    },
    
    state_add_cmp_name(){
      return !validator.isEmpty(this.local_data.add_data.cmp_name);
    },
    
    state_add_cmp_type() {
		return (this.local_data.add_data.cmp_type != "" && this.local_data.add_data.cmp_type != null);
	},
	
    state_add_cmp_sort_order() {
	let regex = RegExp(/^[0-9]{0,8}$/);
	return ( regex.test(this.local_data.add_data.cmp_sort_order));
	},
    
    state_add_cmp_max_users(){
      if(this.local_data.add_data.cmp_max_users < 0 || this.local_data.add_data.cmp_max_users > this.local_data.assign_possible ){
        return false;
      }else {
        return true;
      }
    },
    
    state_add_new_button(){
      if( this.state_add_cmp_id
      && this.state_add_cmp_name
      && this.local_data.add_data.cmp_type
      && this.state_add_cmp_sort_order
      && this.state_add_cmp_max_users
      ){
        return false;
      }else {
        return true;
      }
    },
    
    state_edit_cmp_name(){
      return !validator.isEmpty(this.local_data.edit_data.cmp_name);
    },
    state_edit_cmp_sort_order() {
	let regex = RegExp(/^[0-9]{0,8}$/);
	return ( regex.test(this.local_data.edit_data.cmp_sort_order));
	},
    
    state_edit_cmp_max_users(){
      if(this.local_data.edit_data.cmp_max_users < 0 || this.local_data.edit_data.cmp_max_users > this.local_data.assign_possible ){
        return false;
      }else {
        return true;
      }
    },
    state_edit_update_button(){
      if( this.state_edit_cmp_name
      && this.local_data.edit_data.cmp_type != ""
      && this.state_edit_cmp_sort_order
      && this.state_edit_cmp_max_users
      ){
        return false;
      }else {
        return true;
      }
    },
  },
  // インスタンス初期化後
  async created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "会社設定" );

    //追加、編集時の会社種別選択肢のセット
    let list = await this.get_item_list("cmptype");
    console.log(this.$route.path+":list="+JSON.stringify(list) );
    for( let i=0;i<list.length;i++ ){
      let item = {};
      item.text = list[i].itm_name;
      item.value = list[i].itm_item_key;
      this.local_data.type_options.push(item);
    }
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");
    await this.companys_pulldown_list();
    await this.companys_type_pulldown_list();
    this.bind_data.search_cmp_id = this.$store.state.users_info.usr_cmp_id; //初回はログインユーザーの会社で表示
    await this.users_sum();
    await this.search_companys_list();
  },
  // ローカル関数
  methods: {

    // 設定済ユーザー数上限合計取得
    async users_sum(){

      this.message.is_alert = false;
      // ローディング開始
      this.loader.is_loading = true;
      
      let url = process.env.VUE_APP_RESTAPI_GET_MAX_USERS_SUMMARY_INFO+"?sid="+this.$store.state.sid;
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response.data) );

        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.local_data.user_sum = response.data.users_number_sum;
          this.local_data.assign_possible = response.data.assign_possible;
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        // ローディング終了
        this.loader.is_loading = false;

      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        // ローディング終了
        this.loader.is_loading = false;
      }
    },

    // 会社プルダウンリスト用一覧取得
    async companys_pulldown_list(){
      let url = process.env.VUE_APP_RESTAPI_GET_COMPANY_LIST+"?sid="+this.$store.state.sid;
      try {
        let response = await axios.get( url );
        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.cmp_id_options = [];
          if (response.data.company_list.length > 0) {
            for (let i=0; i<response.data.company_list.length; i++) {
              let options = {};
              options.text = response.data.company_list[i].cmp_name;
              options.value = response.data.company_list[i].cmp_id;
              this.bind_data.cmp_id_options.push(options);

              if (i==0) {
                // 初期値セット
                this.bind_data.search_cmp_id = response.data.company_list[i].cmp_id;
               }
            }
          }
        }
      } catch (ex) {
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },

    // 会社種別プルダウンリスト用一覧取得
    async companys_type_pulldown_list(){
      let list = await this.get_item_list("cmptype");
      console.log(this.$route.path+":list="+JSON.stringify(list) );
      for( let i=0;i<list.length;i++ ){
        let item = {};
        item.text = list[i].itm_name;
        item.value = list[i].itm_item_key;
        this.bind_data.cmp_type_options.push(item);
      }
    },

    // 検索
    async search_companys_list(){

      this.message.is_alert = false;
      // ローディング開始
      this.loader.is_loading = true;
      if(this.bind_data.search_cmp_id == null){
        this.bind_data.search_cmp_id = "";
      }
      if(this.bind_data.search_cmp_type == null){
        this.bind_data.search_cmp_type = "";
      }
      let url = process.env.VUE_APP_RESTAPI_GET_COMPANY_MANAGE_SEARCH_LIST+"?sid="+this.$store.state.sid
      +"&cmp_name="+this.bind_data.search_name
      +"&cmp_id="+this.bind_data.search_cmp_id
      +"&cmp_type="+this.bind_data.search_cmp_type;
      try {

        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response) );

        if( response.data.result == "OK" ){
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.result.list = [];
          if( response.data.company_list.length > 0 ) {
            for ( let i=0; i<response.data.company_list.length; i++ ) {
              let row = response.data.company_list[i];
              row.cmp_type_name = await this.get_item_name("cmptype",row.cmp_type);
              console.log("row="+JSON.stringify(row) );
              this.bind_data.result.list.push(row);
            }
          } else {
            this.bind_data.result.list = [];
            this.message.is_alert = true;
            this.message.variant = "info";
            this.message.message = "登録済の会社が存在しません。";     
          }
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        if(this.bind_data.search_cmp_id == ""){
          this.bind_data.search_cmp_id = null;
        }
        if(this.bind_data.search_cmp_type == ""){
          this.bind_data.search_cmp_type = null;
        }
  
        // ローディング終了
        this.loader.is_loading = false;

      } catch( ex ){
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        // ローディング終了
        this.loader.is_loading = false;
      }
    },

    // 新規登録表示
    add_row(){
      //画面初期化
      this.local_data.add_data.cmp_id = "";
      this.local_data.add_data.cmp_name = "";
      this.local_data.add_data.cmp_type = "";
      this.local_data.add_data.cmp_max_users = "";
      this.local_data.add_data.cmp_sort_order = "";

      this.local_data.add_data.selected = true;
      if( this.local_data.selected ){
        this.local_data.row.toggleDetails();
        this.local_data.selected = false;
      }
    },
    add_cancel(){
      this.local_data.add_data.selected = false;
    },

    //編集ボタン押下
    edit_row(row){
      //他の編集が開いていたら閉じる
      if( this.local_data.row != null ){
        if( this.local_data.selected ){
          this.local_data.row.toggleDetails();
        }
      }
      //編集用に行データからコピーする
      this.local_data.edit_data.cmp_id = row.item.cmp_id;
      this.local_data.edit_data.cmp_name = row.item.cmp_name;
      this.local_data.edit_data.cmp_type = row.item.cmp_type;
      this.local_data.edit_data.cmp_max_users = row.item.cmp_max_users;
      this.local_data.edit_data.cmp_sort_order = row.item.cmp_sort_order;
      //編集が開いているフラグをON
      this.local_data.selected = true;
      row.toggleDetails();
      //行情報保管
      this.local_data.row = row;
    },

    //編集キャンセルボタン押下
    cancel_row( row ){
      //編集が開いているフラグをOFF
      this.local_data.selected = false;
      row.toggleDetails();
    },

    // 登録実行
    async add_new(){

      this.message.is_alert = false;
      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_COMPANY_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.cmp_id = this.local_data.add_data.cmp_id;
      post_data.cmp_name = this.local_data.add_data.cmp_name;
      post_data.cmp_type = this.local_data.add_data.cmp_type;
      post_data.cmp_max_users = String(this.local_data.add_data.cmp_max_users);
      post_data.cmp_sort_order = String(this.local_data.add_data.cmp_sort_order);
      try {
        let response = await axios.post( url, post_data );
        console.log(this.$route.path+":add_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){
          //選択状態解除
          this.local_data.add_data.selected = false;

          // リロード
          await this.companys_pulldown_list();
          await this.users_sum();
          this.bind_data.search_cmp_id = null;
          this.bind_data.search_cmp_type = null;
          await this.search_companys_list();

          //ローディング終了
          this.message.is_loading = false;      
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },

    // 更新実行
    async update_row(){
      
      this.message.is_alert = false;
      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_COMPANY_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.cmp_id = this.local_data.edit_data.cmp_id;
      post_data.cmp_name = this.local_data.edit_data.cmp_name; 
      post_data.cmp_type = this.local_data.edit_data.cmp_type;
      post_data.cmp_max_users = String(this.local_data.edit_data.cmp_max_users);
      post_data.cmp_sort_order = String(this.local_data.edit_data.cmp_sort_order);
      console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );
      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.local_data.selected = false;
          this.local_data.row.toggleDetails();

          // リロード
          await this.companys_pulldown_list();
          await this.users_sum();
          this.bind_data.search_cmp_id = null;
          this.bind_data.search_cmp_type = null;
          await this.search_companys_list();

          //ローディング終了
          this.loader.is_loading = false;

        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },

    // 削除実行
    delete_confirm(){
      this.$refs['confirm-modal'].show();
    },
    delete_cancel(){
      this.$refs['confirm-modal'].hide();
    },
    async delete_row(){ 
      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_COMPANY_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.cmp_id = this.local_data.edit_data.cmp_id;

      console.log(this.$route.path+":del post_data="+ JSON.stringify(post_data));
      try {
        let response = await axios.delete( url, {data: post_data} );
        console.log(this.$route.path+":del_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.local_data.selected = false;
          this.local_data.row.toggleDetails();

          // リロード
          await this.companys_pulldown_list();
          await this.users_sum();
          this.bind_data.search_cmp_id = null;
          this.bind_data.search_cmp_type = null;
          await this.search_companys_list();

          //ローディング終了
          this.loader.is_loading = false;
          
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        
        this.$refs['confirm-modal'].hide();

      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);

        this.$refs['confirm-modal'].hide();
      }
    },
  }
}
