var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ClassManage" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-2 mb-2",
              attrs: { variant: "outline-secondary" },
              on: {
                click: function($event) {
                  return _vm.back()
                }
              }
            },
            [_vm._v("戻る")]
          ),
          _vm.hasArchiveRegistPerm
            ? [
                _c(
                  "b-card",
                  {
                    staticClass: "mt-3",
                    attrs: { header: "ドキュメント登録" }
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          {
                            class: _vm.isNotMobilePhone
                              ? _vm.text_align.text_right
                              : _vm.text_align.text_left,
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "2",
                              lg: "2",
                              xl: "2"
                            }
                          },
                          [_vm._v("ドキュメント名")]
                        ),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "10",
                              lg: "10",
                              xl: "10"
                            }
                          },
                          [
                            _c("b-form-file", {
                              ref: "selectDocument",
                              attrs: {
                                placeholder:
                                  "ファイルを選択するか、ここにファイルをドロップしてください。",
                                "drop-placeholder":
                                  "ここにファイルをドロップしてください。",
                                "browse-text": "参照"
                              },
                              on: { input: _vm.chsck_document },
                              model: {
                                value: _vm.local_data.document_data,
                                callback: function($$v) {
                                  _vm.$set(_vm.local_data, "document_data", $$v)
                                },
                                expression: "local_data.document_data"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      [
                        _c("b-col", {
                          class: _vm.isNotMobilePhone
                            ? _vm.text_align.text_right
                            : _vm.text_align.text_left,
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "2",
                            lg: "2",
                            xl: "2"
                          }
                        }),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "10",
                              lg: "10",
                              xl: "10"
                            }
                          },
                          [
                            _c("div", { staticClass: "small" }, [
                              _vm._v(_vm._s(_vm.bind_data.document_placeholder))
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-1" },
                      [
                        _c(
                          "b-col",
                          {
                            class: _vm.isNotMobilePhone
                              ? _vm.text_align.text_right
                              : _vm.text_align.text_left,
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "2",
                              lg: "2",
                              xl: "2"
                            }
                          },
                          [_vm._v("共有先")]
                        ),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "10",
                              lg: "10",
                              xl: "10"
                            }
                          },
                          [
                            _vm.state_data.treeselectdialogs
                              ? [
                                  _c("treeselectdialogs", {
                                    attrs: {
                                      dpt_selected_array:
                                        _vm.bind_data.dpt_selected,
                                      multiple: true,
                                      branch_nodes: false
                                    },
                                    on: {
                                      save: function($event) {
                                        return _vm.get_dpt_info($event)
                                      }
                                    }
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mt-1" },
                      [
                        _c("b-col", {
                          class: _vm.isNotMobilePhone
                            ? _vm.text_align.text_right
                            : _vm.text_align.text_left,
                          attrs: {
                            xs: "12",
                            sm: "12",
                            md: "2",
                            lg: "2",
                            xl: "2"
                          }
                        }),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "8",
                              lg: "8",
                              xl: "8"
                            }
                          },
                          [
                            _c("div", { staticClass: "small" }, [
                              _vm._v(
                                "登録後、共有先に設定された組織に所属するユーザーに通知されます。"
                              )
                            ]),
                            _c("div", { staticClass: "small" }, [
                              _vm._v("共有先変更時も同様に通知されます。")
                            ])
                          ]
                        ),
                        _c(
                          "b-col",
                          {
                            attrs: {
                              xs: "12",
                              sm: "12",
                              md: "2",
                              lg: "2",
                              xl: "2"
                            }
                          },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-2 float-right",
                                attrs: {
                                  variant: "outline-secondary",
                                  disabled: _vm.registState
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleRegist()
                                  }
                                }
                              },
                              [_vm._v("登録")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c("b-table", {
            staticClass: "mt-4",
            attrs: {
              striped: "",
              hover: "",
              items: _vm.local_data.result.list,
              fields: _vm.local_data.result.fields,
              responsive: "sm"
            },
            scopedSlots: _vm._u(
              [
                _vm.hasArchiveRegistPerm
                  ? {
                      key: "cell(app_cmd)",
                      fn: function(row) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger", size: "sm" },
                              on: {
                                click: function($event) {
                                  return _vm.handleConfirmDeleteArchive(
                                    row.item
                                  )
                                }
                              }
                            },
                            [_vm._v("削除")]
                          )
                        ]
                      }
                    }
                  : null,
                {
                  key: "cell(share_cmd)",
                  fn: function(row) {
                    return [
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            variant: "outline-secondary float-right",
                            size: "sm"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleShare(row.item)
                            }
                          }
                        },
                        [_vm._v("シェア")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            variant: "outline-secondary float-right",
                            size: "sm"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleDownload(row.item)
                            }
                          }
                        },
                        [_vm._v("ダウンロード")]
                      ),
                      _vm.hasArchiveRegistPerm
                        ? [
                            _c(
                              "b-button",
                              {
                                staticClass: "ml-2",
                                attrs: {
                                  variant: "outline-secondary float-right",
                                  size: "sm"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.handleChangeShare(row.item)
                                  }
                                }
                              },
                              [_vm._v("共有先変更")]
                            )
                          ]
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          ref: "deleteArchive",
          attrs: { title: "ドキュメント削除" },
          on: {
            ok: function($event) {
              return _vm.handleDeleteArchive()
            }
          }
        },
        [_vm._v("選択したドキュメントを削除します。よろしいですか？")]
      ),
      _c(
        "b-modal",
        {
          ref: "treeselectDialog",
          attrs: { scrollable: "", title: "組織を選択" },
          on: {
            ok: function($event) {
              return _vm.get_dpt_info_document()
            }
          }
        },
        [
          _c("treeselect", {
            attrs: {
              multiple: "",
              "always-open": "",
              "disable-branch-nodes": "",
              searchable: "",
              options: _vm.bind_data.dpt_options,
              placeholder: "選択してください"
            },
            model: {
              value: _vm.bind_data.dpt_selected_document,
              callback: function($$v) {
                _vm.$set(_vm.bind_data, "dpt_selected_document", $$v)
              },
              expression: "bind_data.dpt_selected_document"
            }
          }),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br"),
          _c("br")
        ],
        1
      ),
      _c(
        "b-modal",
        { ref: "share", attrs: { title: "シェア", "ok-only": "" } },
        [
          _c("div", { staticClass: "ml-2 mr-2" }, [
            _vm._v(_vm._s(_vm.local_data.share_data.text))
          ]),
          _c("b-form-textarea", {
            attrs: { id: "textarea", rows: "6", "max-rows": "12" },
            model: {
              value: _vm.local_data.share_data.url,
              callback: function($$v) {
                _vm.$set(_vm.local_data.share_data, "url", $$v)
              },
              expression: "local_data.share_data.url"
            }
          })
        ],
        1
      ),
      _c("a", { ref: "documentDownload" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }