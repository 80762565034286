var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _vm.state_data.bas_info && _vm.state_data.isView
        ? [
            _c(
              "b-card",
              {
                staticClass: "mx-2 mb-4 p-0",
                attrs: { "header-bg-variant": "light" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "h4",
                            { staticClass: "m-0 p-0 font-weight-bold" },
                            [_vm._v("件名")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  2350124799
                )
              },
              [
                _vm.bind_data.bas_info.bas_rpt_type === "emergency"
                  ? _c(
                      "b-badge",
                      { staticClass: "p-2", attrs: { variant: "danger" } },
                      [_vm._v(_vm._s(_vm.bind_data.bas_info.bas_rpt_type_name))]
                    )
                  : _vm._e(),
                _vm._v(" " + _vm._s(_vm.bind_data.bas_info.bas_subject) + " ")
              ],
              1
            ),
            _c(
              "b-card",
              {
                staticClass: "mx-2 mb-4 p-0",
                attrs: { "header-bg-variant": "light" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "h4",
                            { staticClass: "m-0 p-0 font-weight-bold" },
                            [_vm._v("事案番号")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1329550554
                )
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.bind_data.bas_info.bas_case_number) + " "
                )
              ]
            ),
            _c(
              "b-card",
              {
                staticClass: "mx-2 mb-4 p-0",
                attrs: { "header-bg-variant": "light" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "h4",
                            { staticClass: "m-0 p-0 font-weight-bold" },
                            [_vm._v("発生日時")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1979174278
                )
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.bind_data.bas_info.bas_occur_date_time.substr(0, 16)
                    ) +
                    " "
                )
              ]
            ),
            _c(
              "b-card",
              {
                staticClass: "mx-2 mb-4 p-0",
                attrs: { "header-bg-variant": "light" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c(
                            "h4",
                            { staticClass: "m-0 p-0 font-weight-bold" },
                            [_vm._v("管理対象")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3016809468
                )
              },
              _vm._l(_vm.bind_data.bas_info.bas_mng_list, function(
                bas_mng_info
              ) {
                return _c(
                  "span",
                  { key: bas_mng_info.bas_mng_uuid, staticClass: "mr-2" },
                  [_vm._v(" " + _vm._s(bas_mng_info.bas_mng_uuid_name) + " ")]
                )
              }),
              0
            ),
            _vm.state_data.bas_info
              ? _c("FormDataUtils", {
                  attrs: {
                    form_info: _vm.bind_data.form_info,
                    form_data: _vm.bind_data.form_data
                  }
                })
              : _vm._e(),
            _c(
              "b-card",
              {
                staticClass: "mx-2 mb-4",
                attrs: { "header-bg-variant": "light" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("h4", { staticClass: "mb-0 font-weight-bold" }, [
                            _vm._v("図面")
                          ])
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  705054266
                )
              },
              [
                _vm.bind_data.bas_info.bas_draw_info_exist === "yes"
                  ? [
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2 mt-2 float-right",
                              attrs: { variant: "outline-secondary" },
                              on: {
                                click: function($event) {
                                  return _vm.handleToggleDrawRotate()
                                }
                              }
                            },
                            [
                              _vm._v("図面を表示 "),
                              _c("b-icon", {
                                attrs: {
                                  icon: "caret-down-fill",
                                  rotate: _vm.local_data.drawRotate
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "pt-2", staticStyle: { clear: "both" } },
                        [
                          _vm.local_data.drawRotate === 180
                            ? [
                                _vm._l(_vm.local_data.drawImage, function(
                                  draw2
                                ) {
                                  return [
                                    _c(
                                      "b-card-group",
                                      {
                                        staticClass: "mt-3",
                                        attrs: { deck: "" }
                                      },
                                      [
                                        _vm._l(draw2, function(draw) {
                                          return [
                                            _c(
                                              "b-card",
                                              {
                                                staticClass: "text-center",
                                                attrs: { header: draw.name }
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-img", {
                                                      staticClass: "w-100",
                                                      attrs: { src: draw.image }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        })
                                      ],
                                      2
                                    )
                                  ]
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm._e(),
                _vm.bind_data.bas_info.bas_draw_info_exist !== "yes"
                  ? [_vm._v(" 図面なし ")]
                  : _vm._e()
              ],
              2
            ),
            _vm.state_data.bas_info
              ? _c(
                  "div",
                  _vm._l(_vm.bind_data.bas_info.timeline_list, function(
                    tml_item
                  ) {
                    return _c(
                      "div",
                      { key: tml_item.tml_uuid, staticClass: "pt-2 mb-4" },
                      [
                        _c("div", { staticClass: "ml-2 font-weight-bold" }, [
                          _vm._v("タイムライン")
                        ]),
                        _c(
                          "b-card",
                          { staticClass: "ml-2" },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      xs: "12",
                                      sm: "12",
                                      md: "6",
                                      lg: "6",
                                      xl: "6"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { "header-bg-variant": "light" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "mb-0 font-weight-bold"
                                                    },
                                                    [_vm._v("日時")]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              tml_item.tml_rpt_date_time.substr(
                                                0,
                                                16
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  {
                                    attrs: {
                                      xs: "12",
                                      sm: "12",
                                      md: "6",
                                      lg: "6",
                                      xl: "6"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-card",
                                      {
                                        staticClass: "mb-2",
                                        attrs: { "header-bg-variant": "light" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "header",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "mb-0 font-weight-bold"
                                                    },
                                                    [_vm._v("報告者")]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(tml_item.tml_reporter_name) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-2",
                                attrs: { "header-bg-variant": "light" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "header",
                                      fn: function() {
                                        return [
                                          _c(
                                            "h4",
                                            {
                                              staticClass:
                                                "mb-0 font-weight-bold"
                                            },
                                            [_vm._v("状況（説明）")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _vm._l(
                                  _vm.statusExplains(
                                    tml_item.tml_status_explain
                                  ),
                                  function(statusExplain) {
                                    return [
                                      _vm._v(" " + _vm._s(statusExplain)),
                                      _c("br")
                                    ]
                                  }
                                )
                              ],
                              2
                            ),
                            tml_item.tml_media_type === "image"
                              ? _vm._l(tml_item.mediaList, function(
                                  media,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { staticStyle: { clear: "both" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "ml-2 mt-2",
                                              attrs: {
                                                variant: "outline-secondary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleToggleRotate(
                                                    media.fileUuid,
                                                    tml_item.tml_media_type,
                                                    media.dataUrl
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(" 画像を表示 "),
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "caret-down-fill",
                                                  rotate: _vm.rotate(
                                                    media.fileUuid
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.rotate(media.fileUuid) === 180
                                        ? _c(
                                            "div",
                                            { staticStyle: { clear: "both" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    float: "right"
                                                  }
                                                },
                                                [
                                                  _c("b-img", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      src:
                                                        _vm.local_data.imageSrc,
                                                      fluid: "",
                                                      "max-width": "800"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                })
                              : _vm._e(),
                            tml_item.tml_media_type === "video"
                              ? _vm._l(tml_item.mediaList, function(
                                  media,
                                  index
                                ) {
                                  return _c(
                                    "div",
                                    { staticStyle: { clear: "both" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "float-right" },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "ml-2 mt-2",
                                              attrs: {
                                                variant: "outline-secondary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleDownloadVideo(
                                                    tml_item.tml_save_id,
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [_vm._v(" 動画をダウンロード ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              staticClass: "ml-2 mt-2",
                                              attrs: {
                                                variant: "outline-secondary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.handleToggleRotate(
                                                    media.fileUuid,
                                                    tml_item.tml_media_type,
                                                    media.dataUrl
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(" 動画を表示 "),
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: "caret-down-fill",
                                                  rotate: _vm.rotate(
                                                    media.fileUuid
                                                  )
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _vm.rotate(media.fileUuid) === 180
                                        ? _c(
                                            "div",
                                            { staticStyle: { clear: "both" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    float: "right"
                                                  }
                                                },
                                                [
                                                  _c("video-player", {
                                                    staticClass: "mt-2",
                                                    attrs: {
                                                      options:
                                                        _vm.local_data
                                                          .video_options
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  )
                                })
                              : _vm._e()
                          ],
                          2
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm._e()
          ]
        : _vm._e(),
      _c("a", { ref: "videoDownload" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }