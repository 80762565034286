var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-navbar",
    {
      staticStyle: { "background-color": "#005eae" },
      attrs: { toggleable: "lg", type: "dark" }
    },
    [
      _c(
        "b-navbar-brand",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              return _vm.$eventHub.$emit("sidebar_menu_click")
            }
          }
        },
        [_c("b-icon", { attrs: { icon: "list" } })],
        1
      ),
      _c(
        "b-navbar-nav",
        [
          _c("b-nav-text", { staticClass: "text-left" }, [
            _c(
              "span",
              {
                staticClass: "nav-text-style mr-3",
                on: {
                  click: function($event) {
                    return _vm.link_to("/Home/Dashboard")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.system_name))]
            ),
            _c("span", { staticClass: "nav-subtext-style" }, [
              _vm._v(_vm._s(_vm.bind_data.nav_title))
            ])
          ])
        ],
        1
      ),
      _c(
        "b-navbar-nav",
        { staticClass: "ml-auto" },
        [
          _c(
            "b-nav-form",
            [
              _vm.hasNewsDisplayPerm
                ? [
                    _c(
                      "div",
                      { staticStyle: { position: "relative" } },
                      [
                        _c("b-icon", {
                          staticClass: "border rounded p-1",
                          staticStyle: {
                            "background-color": "#ffffff",
                            cursor: "pointer"
                          },
                          attrs: {
                            icon: "bell",
                            variant: "secondary",
                            "font-scale": "2"
                          },
                          on: {
                            click: function($event) {
                              return _vm.link_to("/Home/Notice")
                            }
                          }
                        }),
                        _vm.isNewArrivalNews
                          ? _c("div", { staticClass: "news_badge" }, [
                              _c("p", { staticClass: "news_count" })
                            ])
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                : _vm._e(),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "ml-2",
                  attrs: { right: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "button-content",
                      fn: function() {
                        return [
                          _vm.isNotMobilePhone
                            ? [
                                _c("em", [
                                  _vm._v(_vm._s(_vm.bind_data.usr_name))
                                ])
                              ]
                            : _vm._e(),
                          !_vm.isNotMobilePhone
                            ? [
                                _c("b-icon", {
                                  staticClass: "border rounded p-1",
                                  staticStyle: {
                                    "background-color": "#ffffff"
                                  },
                                  attrs: {
                                    icon: "person",
                                    variant: "secondary",
                                    "font-scale": "2"
                                  }
                                })
                              ]
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.link_to("/Home/AffiliationRequest")
                        }
                      }
                    },
                    [_vm._v("所属申請")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.link_to("/Home/ProfileManage")
                        }
                      }
                    },
                    [_vm._v("プロフィール表示")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.link_to("/Home/PasswordManage")
                        }
                      }
                    },
                    [_vm._v("パスワード更新")]
                  ),
                  _c(
                    "b-dropdown-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.logout()
                        }
                      }
                    },
                    [_vm._v("ログアウト")]
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }