<template>
    <div class="ListDownLoad">
        <!-- ローディング -->
        <loading :active.sync="loader.is_loading" :is-full-page="loader.is_full_page"></loading>
        <!-- メッセージ -->
        <b-alert v-model="message.is_alert" dismissible :variant="message.variant">{{ message.message }}</b-alert>
        <!-- メインコンテンツ -->
        <div class="container text-left">
            <h1>一覧ダウンロード</h1>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
//import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';

export default {
  name: 'ListDownLoad', // ココを更新
  components: {
    Loading,
  },

  // 共通関数
  mixins: [common],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
      },
      // ローカルデータ
      local_data: {
      }
    }
  },

  // 既定計算
  computed: {
  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "報告データ/一覧ダウンロード" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");
  },
  // ローカル関数
  methods: {
  }
}
</script>

<style scoped lang="scss">
</style>