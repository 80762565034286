import { render, staticRenderFns } from "./ClassManage.html?vue&type=template&id=4a403ffe&scoped=true&"
import script from "./ClassManage.js?vue&type=script&lang=javascript&"
export * from "./ClassManage.js?vue&type=script&lang=javascript&"
import style0 from "./ClassManage.scss?vue&type=style&index=0&id=4a403ffe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a403ffe",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\work\\btalert_eol_prod\\IN_SBP_OneRepo\\BT-Alert-VUE\\bt-alert-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4a403ffe')) {
      api.createRecord('4a403ffe', component.options)
    } else {
      api.reload('4a403ffe', component.options)
    }
    module.hot.accept("./ClassManage.html?vue&type=template&id=4a403ffe&scoped=true&", function () {
      api.rerender('4a403ffe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ClassManage/ClassManage.vue"
export default component.exports