import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
import moment from 'moment-timezone';
import common from '@/lib/common.js';

export default {
	name: 'AccessLogDownload', // ココを更新
	components: {
		Loading,
	},

	// 共通関数
	mixins: [
		common,
	],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				log_type: "",
				from_date: "",
				to_date: "",
				company_select_options: [],
				last_modified: "",
			},
			// ローカルデータ
			local_data: {
				log_type_options: [
					{ value:"login_log", text:"ログイン履歴"},
					{ value:"operations_log", text:"操作履歴"},
					{ value:"dbaccess_log", text:"DBアクセス履歴"}
				]
			},
			// 状態
			state_data: {
			}
		}
	},

	// 既定計算
	computed: {
		state_date: function(){
			let from_date = moment(this.bind_data.from_date);
			let to_date = moment(this.bind_data.to_date);
			if( from_date > to_date ){
				return false;
			}
			if( to_date.diff(from_date, 'days') > 6 ){
				return false;
			}
			return true;
		},
		state_download_button: function(){
			return !this.state_date;
		}
	},
	// インスタンス初期化後
	created(){
		// タイトル更新イベント
		this.$eventHub.$emit( "navbar_title", "アクセスログダウンロード" );
	},
	// インスタンス破棄後
	destroyed: function() {
	},
	// インスタンスマウント後
	async mounted(){

		// ローディング開始
		this.loader.is_loading = true;

		this.companys_list();

		this.bind_data.log_type = "login_log";
		this.bind_data.from_date = moment().add(-1,"days").format("YYYY-MM-DD");
		this.bind_data.to_date = moment().add(-1,"days").format("YYYY-MM-DD");

		// ローディング終了
		this.loader.is_loading = false;
	},
	// ローカル関数
	methods: {
		dateDisabled( ymd ){
			let today = moment().format("YYYY-MM-DD");
			if( ymd > today ){
				return true;
			} else {
				return false;
			}
		},

		// 会社一覧
		async companys_list(){

			let url = process.env.VUE_APP_RESTAPI_GET_COMPANY_LIST+"?sid="+this.$store.state.sid;
			try {
				let response = await axios.get( url );
				console.log(this.$route.path+":response="+JSON.stringify(response) );

				if( response.data.result == "OK" ){
					this.bind_data.company_select_options = [];
					if( response.data.company_list.length > 0 ) {
						for ( let i=0; i<response.data.company_list.length; i++ ) {
							let item = {}
							item.value = response.data.company_list[i].cmp_id;
							item.text = response.data.company_list[i].cmp_name;
							this.bind_data.company_select_options.push(item);
							if( i==0 ){
								this.bind_data.company_selected_id = item.value;
							}
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "検索結果がありません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch( ex ){
				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// CSVダウンロード
		async click_download(){

			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let header = {
				"x-api-key":process.env.VUE_APP_API_KEY,
			}
			let url = process.env.VUE_APP_BTALERT_GET_SUMMARIZED_LOG;
			let post_data = {};
			post_data.type = this.bind_data.log_type;
			post_data.cnt_uuid = this.bind_data.company_selected_id;
			post_data.from_date = moment(this.bind_data.from_date).format("YYYY/MM/DD");
			post_data.to_date = moment(this.bind_data.to_date).format("YYYY/MM/DD");
			console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );
			
			try {
				let response = await axios.post( url, post_data, { headers: header } );
				console.log(this.$route.path+":response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){
					// ローディング終了
					this.loader.is_loading = false;

					if( response.data.csv_log === "" ){

						this.message.is_alert = true;
						this.message.variant = "danger";
						this.message.message = "ダウンロードするデータはありませんでした。";
					} else {
						let UTF_8_BOM = new Uint8Array( [0xEF, 0xBB, 0xBF] );
						let link = document.createElement( 'a' );
						link.download = this.bind_data.log_type+"_"+this.bind_data.from_date+"_"+this.bind_data.to_date+".csv";
						let blob = new Blob( [UTF_8_BOM, response.data.csv_log ], { type: 'text/csv;charset=utf-8' } );
						link.href = window.URL.createObjectURL( blob );
						document.body.appendChild( link );
						link.click();
					}
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}

		},

		// 当日のログのサマリーデータの存在をチェックする
		/**
		async get_summarized_log_info(){

			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let header = {
				"x-api-key":process.env.VUE_APP_API_KEY,
			}
			let url = process.env.VUE_APP_BTALERT_GET_SUMMARIZED_LOG_INFO;
			let post_data = {};
			post_data.type = this.bind_data.log_type;
			post_data.cnt_uuid = this.bind_data.company_selected_id;
			post_data.date = moment(this.bind_data.to_date).format("YYYY/MM/DD");
			console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );
			
			try {
				let response = await axios.post( url, post_data, { headers: header } );
				console.log(this.$route.path+":response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){
					// ローディング終了
					this.loader.is_loading = false;

					if( response.data.info == "" ){
						this.$refs['confirm-modal-1'].show();
					}
					else {
						this.bind_data.last_modified = response.data.info;
						this.$refs['confirm-modal-2'].show();
					}

				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}

		},

		// 当日のログのサマリーデータを作成する
		async log_summarizer(){

			this.$refs['confirm-modal-1'].hide();
			this.$refs['confirm-modal-2'].hide();
			
			this.message.is_alert = true;
			this.message.variant = "info";
			this.message.message = "集計処理中です。しばらくお待ちください。";

			// ローディング開始
			this.loader.is_loading = true;

			let header = {
				"x-api-key":process.env.VUE_APP_API_KEY,
			}
			let url = process.env.VUE_APP_BTALERT_LOG_SUMMARIZER;
			let post_data = {};
			post_data.date = moment(this.bind_data.to_date).format("YYYY/MM/DD");
			console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );
			
			let response = null;
			try {
				response = await axios.post( url, post_data, { headers: header } );
				console.log(this.$route.path+":response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){
					// ローディング終了
					this.loader.is_loading = false;
					this.message.is_alert = true;
					this.message.variant = "success";
					this.message.message = "本日のログが集計されました。";

				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				//this.message.variant = "danger";
				//this.message.message = JSON.stringify(ex);
				this.message.variant = "warning";
				this.message.message = "タイムアウトが発生しました。集計処理に時間がかかっていることが予想されるため、しばらくした後に、再度、［CSVダウンロード］をクリックしてください。";
			}

		},

		cancel(){
			this.$refs['confirm-modal-1'].hide();
			this.$refs['confirm-modal-2'].hide();
		},
		**/
	}
}