var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AffiliationRequest" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-card",
            { staticClass: "card-shadow border-1 mt-2 ml-2" },
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "2" } },
                    [_vm._v("異動先部署")]
                  ),
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "8", md: "8", lg: "9", xl: "10" }
                    },
                    [
                      !_vm.bind_data.applying_flg
                        ? _c(
                            "div",
                            [
                              _c("treeselectdialogs", {
                                attrs: {
                                  dpt_info: _vm.bind_data.dpt_options,
                                  dpt_selected_string:
                                    _vm.bind_data.usr_applying_org_uuid,
                                  multiple: false,
                                  branch_nodes: true
                                },
                                on: { save: _vm.get_org_info }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.bind_data.applying_flg
                        ? _c("div", [_vm._v(_vm._s(_vm.bind_data.org_name))])
                        : _vm._e()
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { xs: "12", sm: "4", md: "4", lg: "3", xl: "2" } },
                    [_vm._v("異動適用日")]
                  ),
                  _c(
                    "b-col",
                    {
                      attrs: { xs: "12", sm: "8", md: "8", lg: "9", xl: "10" }
                    },
                    [
                      _c("b-form-datepicker", {
                        staticClass: "m-0",
                        attrs: {
                          locale: "ja",
                          "label-no-date-selected": "日付を選択してください",
                          "date-format-options": {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            weekday: "short"
                          },
                          disabled: _vm.bind_data.applying_flg
                        },
                        model: {
                          value: _vm.bind_data.transfer_date,
                          callback: function($$v) {
                            _vm.$set(_vm.bind_data, "transfer_date", $$v)
                          },
                          expression: "bind_data.transfer_date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-2" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2 float-right",
                      attrs: {
                        variant: "outline-secondary",
                        disabled: _vm.state_cancel_button
                      },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("取り消し")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-2 float-right",
                      attrs: {
                        variant: "outline-secondary",
                        disabled: _vm.state_request_button
                      },
                      on: {
                        click: function($event) {
                          return _vm.request()
                        }
                      }
                    },
                    [_vm._v("申請")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }