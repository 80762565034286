import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import validator from 'validator';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
import treeselectdialogs2 from '@/components/TreeSelectDialogs2.vue';

export default {
	name: 'OrganizationRegist', // ココを更新
	components: {
		Loading,
		treeselectdialogs,
		treeselectdialogs2,
	},

	// 共通関数
	mixins: [common,CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},
			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			// バインドデータ
			bind_data: {
				// 検索/会社選択肢
				company_select_options: [],
				// 検索/選択された会社ID
				company_selected_id: "",
				// 検索/組織名
				search_name: "",
				// 検索結果/組織一覧
				list: [],
				// 報告区分
				org_rpt_class_options: [],
				//下位組織
				lower_org_options: null,
				// 権限情報
				org_ath_uuid_options: [],
				dl_flg: false, 
				// 初期表示フラグ(一覧取得と検索が同一ロジックの為、フラグで判断する)
				first_flg: true,
				// 上司・上位確認組織
				dpt_boss_confirm_options: [],
				// 下位組織
				dpt_down_options: [],

			},
			// ローカルデータ
			local_data: {
				// 選択行
				row: null,
				// 新規用
				add_data: {
					selected: false,// 新規ボタン押下状態
					cmp_name: "", //会社名
					org_uuid: "", //組織UUID
					org_name: "", //組織名
					org_rpt_class: "", //報告区分
					org_rpt_class_name: "", //報告区分名
					org_boss_confirm_selected: [], //上司・上位確認組織
					org_down_selected: [], //下位組織
					org_ath_selected: [], //権限設定
					org_push_continue: "off",//連続通知
					org_sort_order: "",
					org_self_approval: "off",
					org_push_notif_selected: [
						'case_edit_redeliver',
						'case_consult_cancel',
						'case_treatment_complete',
						'case_treatment_complete_cancel',
						'timeline_new_regist_delivery',
						'timeline_edit_redeliver',
						'timeline_edit_complete',
						'timeline_consult_cancel',
						'timeline_comment',
						'reportdata_consult_cancel',
						'reportdata_edit_contents_confirm',
						'reportdata_report_edit_complete',
						'reportdata_library_regist',
						'reportdata_complete_cancel',
						'talkroom_post'
					],	// プッシュ通知設定
				},
				// 編集用
				edit_data: {
					selected: false,
					org_cmp_uuid: "", //会社ID
					cmp_name: "", //会社名
					org_uuid: "", //組織UUID
					org_name: "", //組織名
					org_rpt_class: "", //報告区分
					org_rpt_class_name: "", //報告区分名
					org_boss_confirm_selected: [], //上司・上位確認組織
					org_down_selected: [], //下位組織
					org_ath_selected: [], //権限設定
					org_push_continue: "",//連続通知
					org_sort_order: "",
					org_self_approval: "off",
					org_push_notif_selected: [],	// プッシュ通知設定
				},
				// 編集用
				delete_data: {
					org_name: "", //組織名
				},
				//テーブル
				fields: [
					{ key: "cmp_name", label:"会社", sortable: true },
					{ key: "org_name", label:"組織名", sortable: true },
					{ key: "org_rpt_class_name", label:"報告区分", sortable: true },
					{ key: "org_push_continue", label:"連続通知", sortable: true },
					{ key: "org_sort_order", label:"表示順位", sortable: true },
					{ key: "org_self_approval", label:"自己承認", sortable: true },
					{ key: "app_cmd", label:"", class:"text-nowrap" },
				],
				// プッシュ通知設定の種類
				pushNotificationTypeList: [
					{id: 'case_edit_redeliver', label: '事案:編集後再配信'},
					{id: 'case_consult_cancel', label: '事案:相談キャンセル'},
					{id: 'case_treatment_complete', label: '事案:処置完了'},
					{id: 'case_treatment_complete_cancel', label: '事案:処置完了解除'},
					{id: 'timeline_new_regist_delivery', label: 'タイムライン:新規登録・配信'},
					{id: 'timeline_edit_redeliver', label: 'タイムライン:編集後再配信'},
					{id: 'timeline_edit_complete', label: 'タイムライン:編集完了'},
					{id: 'timeline_consult_cancel', label: 'タイムライン:相談キャンセル'},
					{id: 'timeline_comment', label: 'タイムライン:コメント'},
					{id: 'reportdata_consult_cancel', label: '報告データ:相談キャンセル'},
					{id: 'reportdata_edit_contents_confirm', label: '報告データ:編集内容を確定する'},
					{id: 'reportdata_report_edit_complete', label: '報告データ:報告書の編集を完了する'},
					{id: 'reportdata_library_regist', label: '報告データ:書庫登録'},
					{id: 'reportdata_complete_cancel', label: '報告データ:完了解除'},
					{id: 'talkroom_post', label: 'トークルーム:投稿時'},
				],
			},
			state_data: {
				treeselectdialogs: true,
				treeselectdialogs2: true,
				isOrgAthSelectedError: false,
				isOrgPushNotifSelectedError: false,
			},
			auth_table: {
				list:[],
				fields: [
					{ key: "ath_id", label:"権限ID", sortable: true },
					{ key: "ath_name", label:"権限名", sortable: true },
					{ key: "ath_desc", label:"説明", sortable: true },
				], 
				auth_toggle: false,
				auth_toggle_rotate: "0",
			}
		}
	},

	// 既定計算
	computed: {
	
		state_add_org_name() {
			return !validator.isEmpty(this.local_data.add_data.org_name);
		},

		state_add_org_rpt_class() {
			return (this.local_data.add_data.org_rpt_class != "" && this.local_data.add_data.org_rpt_class != null);
		},
		
		state_add_org_sort_order() {
			let regex = RegExp(/^[0-9]{0,8}$/);
			return ( regex.test(this.local_data.add_data.org_sort_order));
		},
		
		state_add_new_button() {
			if( this.state_add_org_name
				&& this.state_add_org_rpt_class
				&& this.state_add_org_sort_order
				) {
				return false;
			} else {
				return true;
			}
		},
		state_edit_org_name() {
			return !validator.isEmpty(this.local_data.edit_data.org_name);
		},

		state_edit_org_rpt_class() {
			return (this.local_data.edit_data.org_rpt_class != "" && this.local_data.edit_data.org_rpt_class != null);
		},
		
		state_edit_org_sort_order() {
			let regex = RegExp(/^[0-9]{0,8}$/);
			return ( regex.test(this.local_data.edit_data.org_sort_order));
		},

		state_edit_update_button(){
			if( this.state_edit_org_name 
				&& this.state_edit_org_rpt_class 
				&& this.state_edit_org_sort_order
				) {
				return false;
			} else {
				return true;
			}
		},

		state_upload(){
			return this.bind_data.csv_file==null;
		},

		org_ath_selected_class() {
			return this.state_data.isOrgAthSelectedError ? "is-invalid" : "";
		},
		
		org_push_notif_selected_class() {
			return this.state_data.isOrgPushNotifSelectedError ? "is-invalid" : "";
		},


	},
	// インスタンス初期化後
	created(){
		// タイトル更新イベント
		this.$eventHub.$emit( "navbar_title", "組織設定" );
	},

	// インスタンス破棄後
	destroyed: function() {
	},

	// インスタンスマウント後
	async mounted(){
		console.log(this.$route.path+".vue Start");

		// ローディング開始
		this.loader.is_loading = true;

		//会社情報の取得
		await this.companys_list();

		this.bind_data.company_selected_id = this.$store.state.users_info.usr_cmp_id; // 初期表示はログインユーザーの会社

		//権限情報の取得
		await this.get_auth_list();

		// 組織情報の取得
		await this.get_organization_list();

		// 報告区分のセット
		this.bind_data.org_rpt_class_options = [];
		let list = await this.get_item_list("report");
		console.log(this.$route.path+":report="+JSON.stringify(list) );
		for( let i=0;i<list.length;i++ ){
			let item = {};
			item.text = list[i].itm_name;
			item.value = list[i].itm_item_key;
			item.order = list[i].itm_order;
			this.bind_data.org_rpt_class_options.push(item);
		}

		// ローディング終了
		this.loader.is_loading = false;
	},

	// ローカル関数
	methods: {
		// 会社一覧
		async companys_list(){
			// ALLSYS 以外は、自社の設定しかできない
			this.bind_data.usr_cmp_id = this.$store.state.users_info.usr_cmp_id;
			this.bind_data.cmp_name = this.$store.state.users_info.cmp_name;

			let url = process.env.VUE_APP_RESTAPI_GET_COMPANY_LIST+"?sid="+this.$store.state.sid;
			try {
				let response = await axios.get( url );
				console.log(this.$route.path+":response="+JSON.stringify(response) );

				if( response.data.result == "OK" ){
					this.bind_data.company_select_options = [];
					if( response.data.company_list.length > 0 ) {
						for ( let i=0; i<response.data.company_list.length; i++ ) {
							let item = {}
							item.value = response.data.company_list[i].cmp_id;
							item.text = response.data.company_list[i].cmp_name;
							this.bind_data.company_select_options.push(item);
							if( i==0 ){
								this.bind_data.company_selected_id = item.value;
								this.local_data.add_data.org_cmp_uuid = item.value;
							}
						}
					} else {
						this.bind_data.result.list = [];
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "検索結果がありません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch( ex ){
				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 組織検索
		async get_organization_list(){
			// ローディング開始
			this.loader.is_loading = true;
 
			let url = process.env.VUE_APP_RESTAPI_GET_ORG_LIST+"?sid="+this.$store.state.sid+"&cmp_id="+this.bind_data.company_selected_id;
			if( this.bind_data.search_name != "" ){
				url = url+"&org_name="+encodeURIComponent(this.bind_data.search_name);
			}
			try {
				let response = await axios.get( url );
				console.log(this.$route.path+":response="+JSON.stringify(response) );

				if( response.data.result == "OK" ){
					this.bind_data.list = [];
					if( response.data.organization_list.length > 0 ) {
						this.bind_data.list = response.data.organization_list;
						if (this.bind_data.first_flg) {
							this.bind_data.dl_flg = false;
							this.bind_data.first_flg = false;
						} else {
							// ダウンロードボタン表示
							this.bind_data.dl_flg = true;
						}
					} else {
						this.message.is_alert = true;
						this.message.variant = "info";
						this.message.message = "検索結果がありません。";
					}
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				await this.get_auth_list();

				// ローディング終了
				this.loader.is_loading = false;
			} catch( ex ){
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 権限情報を取得
		async get_auth_list(){

			let url = process.env.VUE_APP_RESTAPI_GET_AUTH_LIST+"?sid="+this.$store.state.sid+"&cmp_id="+this.bind_data.company_selected_id;
			console.log(this.$route.path+":regist:url="+url);

			try {
				let response = await axios.get( url );
				console.log(this.$route.path+":response="+JSON.stringify(response));

				if( response.data.result == "OK" ){
					
					this.bind_data.org_ath_uuid_options = [];
					for( let i=0;i<response.data.auth_list.length;i++ ){
						let item = {};
						item.id = response.data.auth_list[i].ath_id;
						item.label = response.data.auth_list[i].ath_name;
						this.bind_data.org_ath_uuid_options.push(item);
					}
					this.auth_table.list = response.data.auth_list;

				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
	
			} catch( ex ){
				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//組織の選択ダイアログを表示する
		treeselect_dialog_show(){
			this.$refs['treeselect-dialog'].show();
		},

		//新規ボタンを押下
		async add_row(){
			// 入力欄の初期化処理
			this.local_data.add_data.cmp_name = "";
			this.local_data.add_data.org_uuid = "";
			this.local_data.add_data.org_name = "";
			this.local_data.add_data.org_rpt_class = "";
			this.local_data.add_data.org_rpt_class_name = "";
			this.local_data.add_data.org_boss_confirm_selected = [];
			this.local_data.add_data.org_down_selected = [];
			this.local_data.add_data.org_ath_selected = [];
			this.local_data.add_data.org_push_continue = "";
			this.local_data.add_data.org_sort_order = "";
			this.local_data.add_data.org_self_approval = "";
			this.local_data.add_data.org_push_notif_selected = [
				'case_edit_redeliver',
				'case_consult_cancel',
				'case_treatment_complete',
				'case_treatment_complete_cancel',
				'timeline_new_regist_delivery',
				'timeline_edit_redeliver',
				'timeline_edit_complete',
				'timeline_consult_cancel',
				'timeline_comment',
				'reportdata_consult_cancel',
				'reportdata_edit_contents_confirm',
				'reportdata_report_edit_complete',
				'reportdata_library_regist',
				'reportdata_complete_cancel',
				'talkroom_post'
			];

			this.local_data.add_data.selected = true;
			this.local_data.add_data.org_cmp_uuid = this.$store.state.users_info.usr_cmp_id;
			this.bind_data.company_selected_id = this.$store.state.users_info.usr_cmp_id;

			await this.add_cmp_change();
		},

		//新規/キャンセルボタンを押下
		add_cancel(){
			this.local_data.add_data.selected = false;
		},

		//新規/登録ボタン
		async add_new(){
			this.state_data.isOrgAthSelectedError = false;
			this.state_data.isOrgPushNotifSelectedError = false;

			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = process.env.VUE_APP_RESTAPI_SET_ORG_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.org_cmp_uuid = this.local_data.add_data.org_cmp_uuid;
			post_data.org_name = this.local_data.add_data.org_name;
			post_data.org_boss_confirm_uuid = this.local_data.add_data.org_boss_confirm_selected.join(",");
			post_data.org_rpt_class = this.local_data.add_data.org_rpt_class;
			post_data.org_down_uuid = this.local_data.add_data.org_down_selected.join(",");
			post_data.org_ath_uuid = this.local_data.add_data.org_ath_selected.join(",");
			post_data.org_push_continue = this.local_data.add_data.org_push_continue;
			if(post_data.org_push_continue == "" || post_data.org_push_continue == null){
				post_data.org_push_continue = "off";
			}
			post_data.org_sort_order = String(this.local_data.add_data.org_sort_order);
			post_data.org_self_approval = this.local_data.add_data.org_self_approval;
			if(post_data.org_self_approval == "" || post_data.org_self_approval == null){
				post_data.org_self_approval = "off";
			}
			post_data.org_push_notification_type = this.local_data.add_data.org_push_notif_selected.join(",");
			console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );

			// BCP権限に関するチェック
			let selectedBcpAuthNum = 0;		// 選択されているBCP権限の数
			let isSelectedMblBcpFunction = post_data.org_ath_uuid.match( /mbl_bcp_function/g );		// 「BCP機能を使用出来る権限」設定フラグ

			if ( post_data.org_ath_uuid.match( /bcp_manager|bcp_user|bcp_viewer/g ) ) {
				selectedBcpAuthNum = post_data.org_ath_uuid.match( /bcp_manager|bcp_user|bcp_viewer/g ).length;
			}

			if ( selectedBcpAuthNum > 1 ) {
				// 選択されているBCP権限の数が1を超えた場合（BCP権限が2つ以上設定されている場合）

				// ローディング終了
				this.loader.is_loading = false;

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "BCPの権限は「BCP管理者」「BCP一般」「BCP閲覧」のいずれか1つを設定してください。";
				this.state_data.isOrgAthSelectedError = true;

				return;
			} else if ( isSelectedMblBcpFunction && selectedBcpAuthNum == 0 ) {
				// 「BCP機能を使用出来る権限」が設定されているが、BCP権限が設定されていない場合

				// ローディング終了
				this.loader.is_loading = false;

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "「BCP機能を使用出来る権限」を設定した場合「BCP管理者」「BCP一般」「BCP閲覧」のいずれか1つを設定してください。";
				this.state_data.isOrgAthSelectedError = true;
				
				return;
			}
			
			try {
				let response = await axios.post( url, post_data );
				console.log(this.$route.path+":add_response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){

					//選択状態解除
					this.local_data.add_data.selected = false;

					// 初期化
					this.local_data.add_data.cmp_name = "";
					this.local_data.add_data.org_uuid = "";
					this.local_data.add_data.org_name = "";
					this.local_data.add_data.org_rpt_class = "";
					this.local_data.add_data.org_rpt_class_name = "";
					this.local_data.add_data.org_boss_confirm_selected = [];
					this.local_data.add_data.org_down_selected = [];
					this.local_data.add_data.org_ath_selected = [];
					this.local_data.add_data.org_push_continue = "";
					this.local_data.add_data.org_sort_order = "";
					this.local_data.add_data.org_self_approval = "";
					this.local_data.add_data.org_push_notif_selected = [
						'case_edit_redeliver',
						'case_consult_cancel',
						'case_treatment_complete',
						'case_treatment_complete_cancel',
						'timeline_new_regist_delivery',
						'timeline_edit_redeliver',
						'timeline_edit_complete',
						'timeline_consult_cancel',
						'timeline_comment',
						'reportdata_consult_cancel',
						'reportdata_edit_contents_confirm',
						'reportdata_report_edit_complete',
						'reportdata_library_regist',
						'reportdata_complete_cancel',
						'talkroom_post'
					];

					this.bind_data.first_flg = true;
					// 組織情報の取得
					await this.get_organization_list();

					// // 上司・上位確認組織情報の取得
					// await this.get_dpt_boss_confirm(this.$store.state.users_info.usr_cmp_id);
			
					// // 下位組織情報の取得
					// await this.get_dpt_down(this.$store.state.users_info.usr_cmp_id);

					//ローディング終了
					this.loader.is_loading = false;
					
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		//編集ボタン
		async edit_row(row){
			this.state_data.isOrgAthSelectedError = false;
			this.state_data.isOrgPushNotifSelectedError = false;

			console.log("edit_row:row="+JSON.stringify(row) );
			if( this.local_data.row != null ){
				if( this.local_data.edit_data.selected ){
					this.local_data.row.toggleDetails();
				}
			}
			//編集用
			this.local_data.edit_data.org_cmp_uuid = row.item.org_cmp_uuid;//会社ID
			this.local_data.edit_data.cmp_name = row.item.cmp_name;//会社名
			this.local_data.edit_data.org_uuid = row.item.org_uuid;//組織UUID
			this.local_data.edit_data.org_name = row.item.org_name;//組織名
			this.local_data.edit_data.org_rpt_class = row.item.org_rpt_class;//報告区分
			this.local_data.edit_data.org_rpt_class_name = row.item.itm_name;//報告区分名
			if( row.item.org_boss_confirm_uuids != null ){
				this.local_data.edit_data.org_boss_confirm_selected = this.exclude_empty_array(row.item.org_boss_confirm_uuids.split(","));//上司・上位確認組織
			} else {
				this.local_data.edit_data.org_boss_confirm_selected = [];
			}
			if( row.item.org_down_uuids != null ){
				this.local_data.edit_data.org_down_selected = this.exclude_empty_array(row.item.org_down_uuids.split(","));//下位組織
			} else {
				this.local_data.edit_data.org_down_selected = [];
			}
			if( row.item.org_ath_uuids != null && row.item.org_ath_uuids != "" ){
				this.local_data.edit_data.org_ath_selected = row.item.org_ath_uuids.split(",");//権限設定
			} else {
				this.local_data.edit_data.org_ath_selected = [];
			}
			
			if( row.item.org_push_notification_types != null && row.item.org_push_notification_types != "" ){
				this.local_data.edit_data.org_push_notif_selected = row.item.org_push_notification_types.split(",");	// プッシュ通知設定
			} else {
				this.local_data.edit_data.org_push_notif_selected = [];
			}

			this.local_data.edit_data.org_push_continue = row.item.org_push_continue;//連続通知
			this.local_data.edit_data.org_sort_order = row.item.org_sort_order;
			this.local_data.edit_data.org_self_approval = row.item.org_self_approval;

			// // 上司・上位確認組織情報の取得
			// this.get_dpt_boss_confirm(row.item.org_cmp_uuid);
	
			// // 下位組織情報の取得
			// this.get_dpt_down(row.item.org_cmp_uuid);
			await this.edit_cmp_change();
			
			//削除用
			this.local_data.delete_data.org_name = row.item.org_name;//組織名

			this.local_data.edit_data.selected = true;
			row.toggleDetails();
			this.local_data.row = row;
		},

		//編集/キャンセルボタン
		cancel_row( row ){
			this.local_data.edit_data.selected = false;
			row.toggleDetails();
		},

		//編集/更新ボタン
		async update_row(){
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = process.env.VUE_APP_RESTAPI_SET_ORG_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.org_uuid = this.local_data.edit_data.org_uuid;
			post_data.org_name = this.local_data.edit_data.org_name;
			post_data.org_boss_confirm_uuid = this.local_data.edit_data.org_boss_confirm_selected.join(",");
			post_data.org_rpt_class = this.local_data.edit_data.org_rpt_class;
			post_data.org_down_uuid = this.local_data.edit_data.org_down_selected.join(",");
			post_data.org_ath_uuid = this.local_data.edit_data.org_ath_selected.join(",");
			post_data.org_push_continue = this.local_data.edit_data.org_push_continue;
			post_data.org_sort_order = String(this.local_data.edit_data.org_sort_order);
			post_data.org_self_approval = this.local_data.edit_data.org_self_approval;
			post_data.org_push_notification_type = this.local_data.edit_data.org_push_notif_selected.join(",");
			console.log(this.$route.path+":post_data="+JSON.stringify(post_data) );

			// BCP権限に関するチェック
			let selectedBcpAuthNum = 0;		// 選択されているBCP権限の数
			let isSelectedMblBcpFunction = post_data.org_ath_uuid.match( /mbl_bcp_function/g );		// 「BCP機能を使用出来る権限」設定フラグ

			if ( post_data.org_ath_uuid.match( /bcp_manager|bcp_user|bcp_viewer/g ) ) {
				selectedBcpAuthNum = post_data.org_ath_uuid.match( /bcp_manager|bcp_user|bcp_viewer/g ).length;
			}

			if ( selectedBcpAuthNum > 1 ) {
				// 選択されているBCP権限の数が1を超えた場合（BCP権限が2つ以上設定されている場合）

				// ローディング終了
				this.loader.is_loading = false;

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "BCPの権限は「BCP管理者」「BCP一般」「BCP閲覧」のいずれか1つを設定してください。";
				this.state_data.isOrgAthSelectedError = true;

				return;
			} else if ( isSelectedMblBcpFunction && selectedBcpAuthNum == 0 ) {
				// 「BCP機能を使用出来る権限」が設定されているが、BCP権限が設定されていない場合

				// ローディング終了
				this.loader.is_loading = false;

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "「BCP機能を使用出来る権限」を設定した場合「BCP管理者」「BCP一般」「BCP閲覧」のいずれか1つを設定してください。";
				this.state_data.isOrgAthSelectedError = true;
				
				return;
			}

			try {
				let response = await axios.put( url, post_data );
				console.log(this.$route.path+":upd_response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){

					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();
					this.bind_data.first_flg = true;

					// 組織情報の取得
					await this.get_organization_list();

					// // 上司・上位確認組織情報の取得
					// await this.get_dpt_boss_confirm(this.local_data.edit_data.org_cmp_uuid);
			
					// // 下位組織情報の取得
					// await this.get_dpt_down(this.local_data.edit_data.org_cmp_uuid);
					
					//ローディング終了
					this.loader.is_loading = false;
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					let msg = response.data.message;
					if( msg && (msg.includes("\n") || msg.includes("\u00A5n")) ){
						//文字列中の"¥n"を削除する
						msg = msg.replace("\n", "").replace("\u00A5n", "");
					}
					this.message.message = msg;
				}
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}

		},

		// 削除実行
		delete_confirm(){
			this.$refs['confirm-modal'].show();
		},

		delete_cancel(){
			this.$refs['confirm-modal'].hide();
		},

		async delete_row(){
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = process.env.VUE_APP_RESTAPI_SET_ORG_INFO;
			let post_data = {};
			post_data.sid = this.$store.state.sid;
			post_data.org_uuid = this.local_data.edit_data.org_uuid;

			console.log(this.$route.path+":del post_data="+ JSON.stringify(post_data));
			try {
				let response = await axios.delete( url, {data: post_data} );
				console.log(this.$route.path+":del_response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){

					//選択状態解除
					this.local_data.selected = false;
					this.local_data.row.toggleDetails();
					// リロード
					await this.get_organization_list();

					// // 上司・上位確認組織情報の取得
					// await this.get_dpt_boss_confirm();
			
					// // 下位組織情報の取得
					// await this.get_dpt_down();

					//ローディング終了
					this.loader.is_loading = false;
					
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				
				this.$refs['confirm-modal'].hide();
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);

				this.$refs['confirm-modal'].hide();
			}
		},

		//新規/上司・上位確認組織
		add_org_boss_confirm_info( data ){
			console.log("add_org_boss_confirm_info="+JSON.stringify(data) );
			this.local_data.add_data.org_boss_confirm_selected = data;
		},

		//新規/下位組織
		add_org_down_info( data ){
			console.log("add_org_down_info="+JSON.stringify(data) );
			this.local_data.add_data.org_down_selected = data;
		},

		//編集/上司・上位確認組織
		edit_org_boss_confirm_info( data ){
			console.log("edit_org_boss_confirm_info="+JSON.stringify(data) );
			this.local_data.edit_data.org_boss_confirm_selected = data;
		},

		//編集/下位組織
		edit_org_down_info( data ){
			console.log("edit_org_down_info="+JSON.stringify(data) );
			this.local_data.edit_data.org_down_selected = data;
		},

		async download(){
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let UTF_8_BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);

			let url = process.env.VUE_APP_RESTAPI_GET_ORG_CSV+"?sid="+this.$store.state.sid
			+"&cmp_id="+this.bind_data.company_selected_id
			+"&org_name="+this.bind_data.search_name;
			console.log(this.$route.path+":url="+url);
			try {
				let response = await axios.get( url );
				console.log(this.$route.path+":response="+JSON.stringify(response));
				if( response.data.result == "OK" ){
					let rows = [];
					for( let i=0;i<response.data.organization_list.length;i++ ){
						let cols = response.data.organization_list[i];
						rows.push('"'+cols.join('","')+'"');
					}
					let link = document.createElement('a');
					link.download = "organization_list.csv";
					let blob = new Blob([UTF_8_BOM, rows.join('\n')], { type: 'text/csv;charset=utf-8' } );
					link.href = window.URL.createObjectURL(blob);
					document.body.appendChild(link);
					link.click();
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// ローディング終了
				this.loader.is_loading = false;
			} catch ( ex ) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		async csv_upload(){
			// ローディング開始
			this.loader.is_loading = true;
			this.message.is_alert = false;

			let url = process.env.VUE_APP_RESTAPI_SET_ORG_CSV;
			let post_data = new FormData();
			post_data.append("sid",this.$store.state.sid);
			post_data.append("csv_file",this.bind_data.csv_file);
			try {
				let response = await axios.post( url, post_data);
				console.log(this.$route.path+":response="+JSON.stringify(response) );
				if( response.data.result == "OK" ){
					this.bind_data.access_ok = true;//画面を有効化する
					this.message.is_alert = true;
					this.message.variant = "info";
					this.message.message = response.data.message;
				} else {
					
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
				// リロード
				await this.get_organization_list();

				// // 上司・上位確認組織情報の取得
				// await this.get_dpt_boss_confirm();
		
				// // 下位組織情報の取得
				// await this.get_dpt_down();

				// ローディング終了
				this.loader.is_loading = false;
			} catch (ex) {
				// ローディング終了
				this.loader.is_loading = false;
				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			}
		},

		// 会社ドロップダウンリスト選択値変更（新規登録時）
		async add_cmp_change(){

			// ローディング開始
			this.loader.is_loading = true;

			this.state_data.treeselectdialogs = false;
			this.state_data.treeselectdialogs2 = false;

			// await this.set_org_map(this.local_data.add_data.org_cmp_uuid);
			// 上司・上位確認組織情報の取得
			await this.get_dpt_boss_confirm(this.local_data.add_data.org_cmp_uuid);
	
			// 下位組織情報の取得
			await this.get_dpt_down(this.local_data.add_data.org_cmp_uuid);

			this.bind_data.company_selected_id = this.local_data.add_data.org_cmp_uuid;
			await this.get_organization_list();

			this.state_data.treeselectdialogs = true;
			this.state_data.treeselectdialogs2 = true;

			// ローディング終了
			this.loader.is_loading = false;
		},	

		// 会社ドロップダウンリスト選択値変更（編集時）
		async edit_cmp_change(){
			// ローディング開始
			this.loader.is_loading = true;

			this.state_data.treeselectdialogs = false;
			this.state_data.treeselectdialogs2 = false;

			// await this.set_org_map(this.local_data.edit_data.org_cmp_uuid);
				// 上司・上位確認組織情報の取得
				await this.get_dpt_boss_confirm(this.local_data.edit_data.org_cmp_uuid);
		
				// 下位組織情報の取得
				await this.get_dpt_down(this.local_data.edit_data.org_cmp_uuid);


			this.state_data.treeselectdialogs = true;
			this.state_data.treeselectdialogs2 = true;

			// ローディング終了
			this.loader.is_loading = false;
		},	
	
		// 上司・上位確認組織取得
		async get_dpt_boss_confirm(cmp_uuid){
			this.state_data.treeselectdialogs = false;

			await this.set_org_map("cmpmst",cmp_uuid,null);
			const ret = await localStorage.getItem('organization_info');
			const orgs = JSON.parse(ret);
			for (let i=0 ; i<orgs.length ; i++) {
				this.bind_data.dpt_boss_confirm_options.push({
					id: orgs[i].org_uuid,
					value: orgs[i].org_uuid,
					label: orgs[i].org_name,
				});
			}

			this.state_data.treeselectdialogs = true;
		},

		// 下位組織取得
		async get_dpt_down(cmp_uuid){
			this.state_data.treeselectdialogs2 = false;

			await this.set_org_map2("selslf",cmp_uuid,null);
			const ret= await localStorage.getItem('organization_info2');
			const orgs = JSON.parse(ret);
			for (let i=0 ; i<orgs.length ; i++) {
				this.bind_data.dpt_down_options.push({
					id: orgs[i].org_uuid,
					value: orgs[i].org_uuid,
					label: orgs[i].org_name,
				});
			}

			this.state_data.treeselectdialogs2 = true;
		},

		// 検索条件トグル
		auth_toggle() {
			if (this.auth_table.auth_toggle) {
				this.auth_table.auth_toggle_rotate ="0";
			} else {
				this.auth_table.auth_toggle_rotate ="180";
			}
			this.auth_table.auth_toggle = !this.auth_table.auth_toggle;
		},
	}
}
