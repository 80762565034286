import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import dataset from '@/lib/dataset.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
import validator from 'validator';
import moment from 'moment-timezone';

export default {
  name: 'NoticeManage', // ココを更新
  components: {
    Loading,
    treeselectdialogs  
  },

  // 共通関数
  mixins: [common,dataset,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        dpt_selected: null,
        dpt_options: this.data_get_dpt_info(),
        access_ok: false,

        //検索結果
        result: { 
          list: [],
        },
        //検索条件（件名+現在日付）
        search_nws_subject: "",
        search_nws_start_date: moment().add(-1,'day').format('YYYY-MM-DD'),
        search_nws_end_date: moment().format('YYYY-MM-DD'),
        //between_date_from: moment().add(-1,'day').format('YYYY/MM/DD HH:mm'),
        //between_date_to: moment().format('YYYY/MM/DD HH:mm'),
        //between_init: true,
        //between_edit_start_date: moment().add(-1,'day').format('YYYY-MM-DD'),
        //between_edit_start_time: "00:00",
        //between_edit_end_date: moment().format('YYYY-MM-DD'),
        //between_edit_end_time: "23:59",

      },
      // ローカルデータ
      local_data: {

        // 検索条件
        search_nsw_name:"",

        // 新規登録
        add_data: {
          selected: false,
          nws_subject: "",
          nws_contents: "",
          nws_start_date: "",
          nws_end_date: "",
          nws_dlv_list: [],
        },
        // 編集
        edit_data: {
          app_cmd: "",
          nws_uuid:"" ,               // お知らせUUID
          nws_subject: "",            // 件名
          nws_contents: "",           // 内容
          nws_start_date:"",          // 掲載開始日 (YYYY-MM-DD)
          nws_end_date: "",           // 掲載終了日 (YYYY-MM-DD)
          nws_dlv_list: [],           // 配信先リスト カンマ区切り
        },
        fields: [
          { key: "nws_subject", label: "件名", sortable: true, class:"text-nowrap" },
          { key: "nws_start_date", label: "掲載開始日", sortable: true, class:"text-nowrap" },
          { key: "nws_end_date", label: "掲載終了日", sortable: true, class:"text-nowrap" },
          { key: "app_cmd", label: "", class:"text-nowrap"},
        ],

        row: null,
        selected: false,
      },
      state_data: {
        treeselectdialogs: true,
      },
    }
  },

  // 既定計算
  computed: {
    // 登録
    state_nws_subject(){
      return !validator.isEmpty(this.local_data.add_data.nws_subject);
    },
    state_nws_contents(){
      return !validator.isEmpty(this.local_data.add_data.nws_contents);
    },
    state_nws_start_date(){
      return !validator.isEmpty(this.local_data.add_data.nws_start_date);
    }, 
    state_nws_end_date(){
      return !validator.isEmpty(this.local_data.add_data.nws_end_date);
    }, 
    // state_nws_dlv_list(){
    //   return !validator.isEmpty(this.local_data.add_data.nws_dlv_list);
    // }, 
    state_add_new_button(){
      if (this.state_nws_subject
        && this.state_nws_contents
        && this.state_nws_start_date
        && this.state_nws_end_date
        // && this.state_nws_dlv_list
        && this.local_data.add_data.nws_start_date <= this.local_data.add_data.nws_end_date
        ) {
        return false;
      } else {
        return true;
      }
    },

    // 編集
    state_edit_nws_subject(){
      return !validator.isEmpty(this.local_data.edit_data.nws_subject);
    },
    state_edit_nws_contents(){
      return !validator.isEmpty(this.local_data.edit_data.nws_contents);
    },
    state_edit_nws_start_date(){
      return !validator.isEmpty(this.local_data.edit_data.nws_start_date);
    },    
    state_edit_nws_end_date(){
      return !validator.isEmpty(this.local_data.edit_data.nws_end_date);
    },
    // state_edit_nws_dlv_list(){
    //   return !validator.isEmpty(this.local_data.edit_data.nws_dlv_list);
    // },    

    state_edit_update_button(){
      if (this.state_edit_nws_subject
        && this.state_edit_nws_contents 
        && this.state_edit_nws_start_date
        && this.state_edit_nws_end_date
        // && this.state_edit_nws_dlv_list
        && this.local_data.edit_data.nws_start_date <= this.local_data.edit_data.nws_end_date
        ) {
        return false;
      } else {
        return true;
      }
    },
  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "お知らせ設定" );

  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){ 
    this.state_data.treeselectdialogs = false;
    await this.set_org_map("allslf",null,null);
    this.state_data.treeselectdialogs = true;
    await this.get_news_list();
    console.log(this.$route.path+".vue Start");
  },
  // ローカル関数
  methods: {
    treeselect_dialog_show(){
      this.$refs['treeselect-dialog'].show();
    },
    add(){
      // 入力欄の初期化処理
      this.local_data.add_data.nws_subject = "";
      this.local_data.add_data.nws_contents = "";
      this.local_data.add_data.nws_start_date = "";
      this.local_data.add_data.nws_end_date = "";
      this.local_data.add_data.nws_dlv_list = [];

      this.local_data.add_data.selected = true;
      if( this.local_data.selected ){
        this.local_data.row.toggleDetails();
        this.local_data.selected = false;
      }
    },
    add_cancel(){
      this.local_data.add_data.selected = false;
    },
    //登録実行
    async add_new(){
      this.local_data.add_data.selected = false;
      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_NEWS_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.nws_subject = this.local_data.add_data.nws_subject;
      post_data.nws_contents = this.local_data.add_data.nws_contents;
      post_data.nws_start_date = this.local_data.add_data.nws_start_date;
      post_data.nws_end_date = this.local_data.add_data.nws_end_date;
      post_data.nws_dlv_list = this.local_data.add_data.nws_dlv_list.join(",");
      
      console.log(this.$route.path+":post_data="+post_data );

      try {
        let response = await axios.post( url, post_data );
        console.log(this.$route.path+":add_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){
          //選択状態解除
           this.local_data.add_data.selected = false;
          // リロード
          await this.get_news_list();          
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;          
        }
        // ローディング終了
        this.loader.is_loading = false;
     } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    // add_new(){
    //   if(this.local_data.add_data.usr_dpt_uuid == 0) {
    //     this.message.is_alert = true;
    //     this.message.variant = "danger";
    //     this.message.message = "登録ユーザーの部署が未選択です。";
    //   } else {
    //     this.message.is_alert = false;
    //     this.message.variant = "";
    //     this.message.message = "";       
    //   }
    // },

    //編集ボタン
    edit_row(row){
      if( this.local_data.row != null ){
        if( this.local_data.selected ){
          this.local_data.row.toggleDetails();
        }
      }
      this.local_data.edit_data.nws_uuid = row.item.nws_uuid;
      this.local_data.edit_data.nws_subject = row.item.nws_subject;
      this.local_data.edit_data.nws_contents = row.item.nws_contents;
      this.local_data.edit_data.nws_start_date = row.item.nws_start_date;
      this.local_data.edit_data.nws_end_date = row.item.nws_end_date;
      this.local_data.edit_data.nws_dlv_list = [];
      let dlv_list = row.item.nws_dlv_list_array.split(",");
      console.log("dlv_list="+dlv_list);
      for (let i=0; i<dlv_list.length; i++) {
        this.local_data.edit_data.nws_dlv_list.push(dlv_list[i]);
      }

      this.local_data.add_data.selected = false;// 新規画面閉じる
      this.local_data.selected = true;
      row.toggleDetails();
      this.local_data.row = row;
    },

    cancel_row( row ){
      this.message.is_alert = false;
      this.message.variant = "";
      this.message.message = "";   
      this.local_data.selected = false;
      row.toggleDetails();
    },
    get_dpt_info( data ){
      console.log("get_dpt_info="+JSON.stringify(data));
    },

    // お知らせ設定一覧の取得
    async get_news_list(){

      // ローディング開始
      this.loader.is_loading = true;
      let url = process.env.VUE_APP_RESTAPI_GET_NEWS_LIST+"?sid="+this.$store.state.sid;
      console.log(this.$route.path+":url="+ url);

      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response) );
        if (response.data.result == "OK") {
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.result.list = [];
          //console.log(this.$route.path+":this.bind_data.result.list="+ this.bind_data.result.list);

          if( response.data.news_list.length > 0 ) {
            for(let i=0; i<response.data.news_list.length; i++) {
              let row = response.data.news_list[i];
              this.bind_data.result.list.push(row);
            }

          } else {
            this.bind_data.result.list = [];
            this.message.is_alert = true;
            this.message.variant = "info";
            this.message.message = "お知らせが存在しません。";     
          }
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        // ローディング終了
        this.loader.is_loading = false;     
      } catch (ex) {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        // ローディング終了
        this.loader.is_loading = false;
      }
    },


    // 検索
    async seach_notice_list(){
      // ローディング開始
      this.loader.is_loading = true;
      let url = process.env.VUE_APP_RESTAPI_GET_NEWS_LIST+"?sid="+this.$store.state.sid
      +"&nws_subject="+encodeURIComponent(this.bind_data.search_nws_subject)
      +"&nws_start_date="+this.bind_data.search_nws_start_date
      +"&nws_end_date="+this.bind_data.search_nws_end_date;
      console.log(this.$route.path+":url="+ url);
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response) );
        if (response.data.result == "OK") {
          this.bind_data.access_ok = true;//画面を有効化する
          this.bind_data.result.list = [];
          if( response.data.news_list.length > 0 ) {
            for(let i=0; i<response.data.news_list.length; i++) {
              let row = response.data.news_list[i];
              console.log("row="+JSON.stringify(row) );
              this.bind_data.result.list.push(row);
              this.message.is_alert = false;
            }
          } else {
            this.bind_data.result.list = [];
            this.message.is_alert = true;
            this.message.variant = "info";
            this.message.message = "該当する件名が存在しません。";     
          }
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        // ローディング終了
        this.loader.is_loading = false;     
      } catch (ex) {
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
        // ローディング終了
        this.loader.is_loading = false;
      }
    },


    //登録時の配信先パラメータセット
    get_dpt_info_add( data ){
      console.log("get_dpt_info="+JSON.stringify(data));
      this.local_data.add_data.nws_dlv_list = data;
    },
    //編集時の配信先パラメータセット
    get_dpt_info_edit( data ){
      console.log("get_dpt_info="+JSON.stringify(data));
      this.local_data.edit_data.nws_dlv_list = data;
    },
    // 更新実行
    async update_row(){
      // ローディング開始
      this.loader.is_loading = true;
      let url = process.env.VUE_APP_RESTAPI_SET_NEWS_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.nws_uuid = this.local_data.edit_data.nws_uuid;
      post_data.nws_subject = this.local_data.edit_data.nws_subject;
      post_data.nws_contents = this.local_data.edit_data.nws_contents;
      post_data.nws_start_date = this.local_data.edit_data.nws_start_date;
      post_data.nws_end_date = this.local_data.edit_data.nws_end_date;
      post_data.nws_dlv_list = this.local_data.edit_data.nws_dlv_list.join(",");
      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response));
        if (response.data.result == "OK") {
          //選択状態解除
          this.local_data.selected = false;
          this.local_data.row.toggleDetails();
          // リロード
          await this.get_news_list();
          //ローディング終了
          this.loader.is_loading = false;
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch(ex){
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
      this.local_data.selected = false;
      this.local_data.row.toggleDetails();
    },
    delete_confirm(){
      this.$refs['confirm-modal'].show();
    },    
    delete_cancel(){
      this.$refs['confirm-modal'].hide();
    },

    // 削除実行
    async delete_row(){
      // ローディング開始
      this.loader.is_loading = true;
      let url = process.env.VUE_APP_RESTAPI_SET_NEWS_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.nws_uuid = this.local_data.edit_data.nws_uuid;

      try {
        let response = await axios.delete( url, {data: post_data} );
        console.log(this.$route.path+":del_response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          //選択状態解除
          this.local_data.selected = false;
          this.local_data.row.toggleDetails();
          // リロード
          await this.get_news_list();
          //ローディング終了
          this.loader.is_loading = false;
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;          
        }
        this.$refs['confirm-modal'].hide();
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);

        this.$refs['confirm-modal'].hide();
      }

      this.local_data.selected = false;
      this.local_data.row.toggleDetails();
    },

  }
}