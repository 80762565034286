
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';

export default {
  name: 'ClassManage', // ココを更新
  components: {
    Loading,
  },

  // 共通関数
  mixins: [common,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        // 区分
        class_add_data: {
          selected: false,
          cls_name: "",
        },
        class_edit_data: {
          selected: false,
          cls_class_key: "",
          cls_name: "",
        },
        class_delete_data: {
          cls_name: "",
        },
        class_list: [],
        // 項目
        item_add_data: {
          selected: false,
          itm_name: "",
          itm_order: 1
        },
        item_edit_data: {
          selected: false,
          cls_class_key: "",
          itm_item_key: "",
          itm_name: "",
          itm_order: 1
        },
        item_delete_data: {
          itm_name: "",
        },
        item_list: [],
      },
      // ローカルデータ
      local_data: {
        //区分
        class_fields: [
          { key: "sel_cmd", label: ""},
          { key: "cls_name", label: "区分名", class:"text-nowrap" },
          { key: "app_cmd", label: "", class:"text-right"},
        ],
        //項目
        item_fields: [
            { key: "itm_name", label: "項目名", class:"text-nowrap" },
            { key: "itm_order", label: "表示順序", class:"text-nowrap" },
            { key: "app_cmd", label: "", class:"text-right"},
        ],
       
        class_row: null,
        class_selected: true,  //falseの時、項目の新規ボタンを有効化
        item_row: null,
        item_selected: false,
      }
    }
  },

  // 既定計算
  computed: {
  
//		state_add_itm_orderr() {
//			let regex = RegExp(/^[0-9]{0,8}$/);
//			return ( regex.test(this.local_data.item_edit_data.itm_order));
//		},
		
//		state_add_new_button() {
//			if( this.state_add_itm_orderr
//				) {
//				return false;
//			} else {
//				return true;
//			}
//		},
    state_add_itm_name() {
      return (this.bind_data.item_add_data.itm_name ? true : false);
    },
    state_edit_itm_name() {
      return (this.bind_data.item_edit_data.itm_name ? true : false);
    },
    state_item_add_new_button() {
			return (!this.bind_data.item_add_data.itm_name || !this.bind_data.item_add_data.itm_order);
		},
    state_item_update_button() {
			return (!this.bind_data.item_edit_data.itm_name || !this.bind_data.item_edit_data.itm_order);
		},
  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "区分設定" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");

    await this.init();
  },
  // ローカル関数
  methods: {

    async init(){
      // ストアされている区分情報を取り込む
      this.bind_data.class_list = [];
      let class_list = await this.get_class_list();
      //console.log(this.$route.path+":class_list="+JSON.stringify(class_list));
      for( let i=0;i<class_list.length;i++ ){
        // ユーザー設定できる情報だけを絞り込む
        if( class_list[i].cls_usr_setting_flg == "1" ){
          let item = {};
          item.cls_class_key = class_list[i].cls_class_key;
          item.cls_name = class_list[i].cls_name;
          item._rowVariant = "";  //背景色を変更する
          this.bind_data.class_list.push(item);
        }
      }

      this.bind_data.item_add_data.itm_name = "";
      this.bind_data.item_add_data.itm_order = 1;
      this.bind_data.item_edit_data.itm_name = "";
      this.bind_data.item_edit_data.itm_order = 1;
      this.bind_data.item_list = [];
      //console.log(this.$route.path+":class_list="+JSON.stringify(this.bind_data.class_list));
    },

    //--------------------------------------------------------
    // 区分/新規ボタン押下
    class_add_show(){
      // 入力欄の初期化処理
      this.bind_data.class_add_data.cls_class_name = "";

      this.bind_data.class_add_data.selected = true;
    },
    // 区分/新規/キャンセルボタン押下
    class_add_cancel(){
      this.bind_data.class_add_data.selected = false;
    },
    // 区分/新規/登録ボタン押下
    async class_add_new(){

      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_CLASS_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.cls_name = this.bind_data.class_add_data.cls_class_name;
      console.log("class_add_new:url="+url );
      console.log("class_add_new:post_data="+JSON.stringify(post_data) );

      try {
        let response = await axios.post( url, post_data );
        console.log(this.$route.path+":add_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.bind_data.class_add_data.selected = false;
          // リロード
          await this.set_class_list();
          await this.init();
          //ローディング終了
          this.loader.is_loading = false;
          
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    // 区分/名称変更ボタン押下
    class_edit_row(row){
      
      if( this.local_data.class_row != null ){
          this.local_data.class_row.toggleDetails();
      }
      
      this.bind_data.class_edit_data.cls_class_key = row.item.cls_class_key;
      this.bind_data.class_edit_data.cls_name = row.item.cls_name;
      this.bind_data.class_edit_data.selected = true;
      row.toggleDetails();
      this.local_data.class_row = row;
      
      //名前変更ボタンを押した行を選択状態にする
      this.class_select_row(row);
      let selectRows = document.getElementsByName("select-row");
      selectRows[row.index].checked = true;
    },
    // 区分/名称変更/更新ボタン押下
    async class_update_row(){

      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_CLASS_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.cls_class_key = this.bind_data.class_edit_data.cls_class_key;
      post_data.cls_name = this.bind_data.class_edit_data.cls_name;
      console.log("class_update_row:url="+url );
      console.log("class_update_row:post_data="+JSON.stringify(post_data) );

      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.bind_data.class_edit_data.selected = false;
          this.local_data.class_row.toggleDetails();
          // リロード
          await this.set_class_list();
          await this.init();
          //ローディング終了
          this.loader.is_loading = false;

        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }

    },
    // 区分/名称変更/更新ボタン押下
    class_cancel_row( row ){
      //選択状態解除
      this.bind_data.class_edit_data.selected = false;
      this.local_data.class_row = null;
      row.toggleDetails();
    },

    // 区分が選択された
    async class_select_row( row ){
      console.log("class_select_row="+JSON.stringify(row));

      //選択された行の色を替える
      for( let i=0;i<this.bind_data.class_list.length;i++ ){
        if( this.bind_data.class_list[i].cls_class_key == row.item.cls_class_key ){
          this.bind_data.class_list[i]._rowVariant = "info";
        } else {
          this.bind_data.class_list[i]._rowVariant = "";
        }
      }
      this.local_data.class_selected = false;//項目の新規ボタンを有効化
      this.bind_data.class_edit_data.cls_class_key = row.item.cls_class_key;//項目側のために保管

      //選択された区分の項目情報を取り込む
      this.bind_data.item_list = [];
      let item_list = await this.get_item_list( row.item.cls_class_key );
      for( let i=0;i<item_list.length;i++ ){
        if(item_list[i].itm_name){
          let item = {};
          item.cls_class_key = item_list[i].cls_class_key;
          item.itm_item_key = item_list[i].itm_item_key;
          item.itm_name = item_list[i].itm_name;
          item.itm_order = item_list[i].itm_order;
          this.bind_data.item_list.push(item);
        }
      }

      //表示順序で並び替え
      this.bind_data.item_list.sort(this.compare);

      console.log("class_select_row:item_list="+JSON.stringify(this.bind_data.item_list));
    },
    // 区分/削除実行
    class_delete_confirm( row ){
      this.bind_data.class_delete_data.cls_name = row.item.cls_name;
      this.$refs['class-confirm-modal'].show();
    },
    class_delete_cancel(){
      this.$refs['class-confirm-modal'].hide();
    },
    async class_delete_row(){

      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_CLASS_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.cls_class_key = this.bind_data.class_edit_data.cls_class_key;
      console.log("class_delete_row:url="+url );
      console.log("class_delete_row:post_data="+JSON.stringify(post_data) );

      try {
        let response = await axios.delete( url, {data: post_data} );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.bind_data.class_edit_data.selected = false;
          this.local_data.class_row.toggleDetails();
          // リロード
          await this.set_class_list();
          await this.init();
          //ローディング終了
          this.loader.is_loading = false;

        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
      this.$refs['class-confirm-modal'].hide();

      this.local_data.class_selected = true;
      this.bind_data.item_add_data.selected = false;
    },

    //--------------------------------------------------------
    // 項目/新規ボタンボタン押下
    item_add_show(){
      // 入力欄の初期化処理
      this.bind_data.item_add_data.itm_name = "";
      this.bind_data.item_add_data.itm_order = 1;

      this.bind_data.item_add_data.selected = true;
    },
    ///項目/新規/キャンセルボタン押下
    item_add_cancel(){
      this.bind_data.item_add_data.selected = false;

      this.bind_data.item_add_data.itm_name = "";
      this.bind_data.item_add_data.itm_order = 1;
    },
    ///項目/新規/登録キャンセルボタン押下
    async item_add_new(){
      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_ITEM_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.itm_class_key = this.bind_data.class_edit_data.cls_class_key;
      post_data.itm_name = this.bind_data.item_add_data.itm_name;
      post_data.itm_order = this.bind_data.item_add_data.itm_order;
      console.log("item_add_new:url="+url );
      console.log("item_add_new:post_data="+JSON.stringify(post_data) );

      try {
        let response = await axios.post( url, post_data );
        console.log(this.$route.path+":add_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.bind_data.item_add_data.selected = false;
          // リロード
          await this.set_class_list();
          await this.init();
          //ローディング終了
          this.loader.is_loading = false;
          
        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },

    ///項目/編集ボタン押下
    item_edit_row(row){
      if( this.local_data.item_row != null ){
          this.local_data.item_row.toggleDetails();
      }
      console.log("item_edit_row:row="+JSON.stringify(row) );
      this.bind_data.item_edit_data.cls_class_key = row.item.cls_class_key;
      this.bind_data.item_edit_data.itm_item_key = row.item.itm_item_key;
      this.bind_data.item_edit_data.itm_name = row.item.itm_name;
      this.bind_data.item_edit_data.itm_order = row.item.itm_order;
      this.bind_data.item_edit_data.selected = true;
      row.toggleDetails();
      this.local_data.item_row = row;
    },

    ///項目/更新ボタン押下
    async item_update_row(){
      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_ITEM_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.itm_class_key = this.bind_data.item_edit_data.cls_class_key;
      post_data.itm_item_key = this.bind_data.item_edit_data.itm_item_key;
      post_data.itm_name = this.bind_data.item_edit_data.itm_name;
      post_data.itm_order = this.bind_data.item_edit_data.itm_order;
      console.log("class_update_row:url="+url );
      console.log("class_update_row:post_data="+JSON.stringify(post_data) );

      try {
        let response = await axios.put( url, post_data );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.bind_data.item_edit_data.selected = false;
          this.local_data.item_row.toggleDetails();
          // リロード
          await this.set_class_list();
          await this.init();
          await this.class_select_row(this.local_data.item_row);
          //ローディング終了
          this.loader.is_loading = false;

        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
    ///項目/更新ボタン押下
    item_cancel_row( row ){
      this.bind_data.item_edit_data.selected = false;
      this.local_data.item_row = null;
      row.toggleDetails();
    },
    // 項目/削除実行
    item_delete_confirm(row){
      this.bind_data.item_delete_data.itm_name = row.item.itm_name;
      this.$refs['item-confirm-modal'].show();
    },
    item_delete_cancel(){
      this.$refs['item-confirm-modal'].hide();
    },
    async item_delete_row(){

      // ローディング開始
      this.loader.is_loading = true;

      let url = process.env.VUE_APP_RESTAPI_SET_ITEM_INFO;
      let post_data = {};
      post_data.sid = this.$store.state.sid;
      post_data.itm_class_key = this.bind_data.item_edit_data.cls_class_key;
      post_data.itm_item_key = this.bind_data.item_edit_data.itm_item_key;
      console.log("class_delete_row:url="+url );
      console.log("class_delete_row:post_data="+JSON.stringify(post_data) );

      try {
        let response = await axios.delete( url, {data: post_data} );
        console.log(this.$route.path+":upd_response="+JSON.stringify(response) );
        if( response.data.result == "OK" ){

          //選択状態解除
          this.bind_data.item_edit_data.selected = false;
          this.local_data.item_row.toggleDetails();
          // リロード
          await this.set_class_list();
          await this.init();
          //ローディング終了
          this.loader.is_loading = false;

        } else {
          // ローディング終了
          this.loader.is_loading = false;

          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        
      } catch (ex) {
        // ローディング終了
        this.loader.is_loading = false;

        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
      this.$refs['item-confirm-modal'].hide();
    },
    class_row_clicked(item, index){
      if(!item || index < 0){
        return;
      }
      
      let row = {
        item: item,
      };

      //クリックした行を選択状態にする
      this.class_select_row(row);
      let selectRows = document.getElementsByName("select-row");
      selectRows[index].checked = true;      
    },
    //ソート関数
    compare( a, b ) {
      let itm_order_a = a.itm_order;
      let itm_order_b = b.itm_order;
    
      let comparison = 0;
      if (itm_order_a > itm_order_b) {
        comparison = 1;
      } else if (itm_order_a < itm_order_b) {
        comparison = -1;
      }
      return comparison;
    },
    //表示順序の入力制限
    formatOrder(e){
      return String(e).substring(0, 8);
    }
  }
}
