var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ProfileChange" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-card",
            { staticClass: "border-1 mt-2 ml-2" },
            [
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "3" } },
                    [_vm._v("ユーザー名")]
                  ),
                  _c("b-col", [_vm._v(_vm._s(_vm.bind_data.usr_name))])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "3" } },
                    [_vm._v("会社名")]
                  ),
                  _c("b-col", [_vm._v(_vm._s(_vm.bind_data.cmp_name))])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "3" } },
                    [_vm._v("所属部署")]
                  ),
                  _c("b-col", [_vm._v(_vm._s(_vm.bind_data.long_name) + " ")])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { cols: "3" } },
                    [_vm._v("メールアドレス")]
                  ),
                  _c("b-col", [_vm._v(_vm._s(_vm.bind_data.usr_mail_address))])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }