import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import validator from 'validator';
import common from '@/lib/common.js';
import draggable from 'vuedraggable';
import CommonDataSet from '@/lib/CommonDataSet.js';

export default {
	name: 'FormManage', // ココを更新
	components: {
		Loading,
		draggable,
	},

	// 共通関数
	mixins: [common,CommonDataSet],

	// ローカルデータ変数
	data() {
		return {
			// ローディング
			loader: {
				is_loading: false,
				is_full_page: true,
			},

			// メッセージ
			message: {
				is_alert: false,
				variant: "",
				message: "",
			},
			
			// バインドデータ
			bind_data: {
				class_selected: "",
				class_options: [],
				row_data: [],
				frm_explanation: "",
			},
			
			// ローカルデータ
			local_data: {
				item_title: "",
				item_examine: "",
				item_must: "off",
				item_type: "text",
				item_type_options: [
					{ text: "短文テキスト（50文字）", value: "text" },
					{ text: "長文テキスト（500文字）", value: "texts" },
					{ text: "数値", value: "numeric" },
					{ text: "日付", value: "date" },//テキスト（定型）
					{ text: "時刻", value: "time" },//テキスト（定型）
					{ text: "日時", value: "datetime" },//テキスト（定型）
					{ text: "現在日時", value: "now" },//テキスト（定型）
					{ text: "単一選択", value: "radio" },
					{ text: "複数選択", value: "checkbox" },
					{ text: "単一選択（区分）", value: "pulldown" },
					{ text: "チェック", value: "toggle" },
					{ text: "メールアドレス", value: "mail_address" },//テキスト（定型）
				],
				item_numeric_unit: "",
				item_numeric_upper: "",
				item_numeric_lower: "",
				item_radio_options: ["サンプルデータ"],
				item_checkbox_options: ["サンプルデータ"],
				// 項目名編集
				item_title_edit_id: 0,
				item_title_edit: "",
				// 説明編集
				item_examine_edit_id: 0,
				item_examine_edit: "",
				// 必須編集
				item_must_edit_id: 0,
				item_must_edit: "",
				// オプション
				item_option: null,
				// 付記情報
				frm_explanation: "",
				// 更新日時
				frm_upd_date_time: "",
				new_item_card_visivle: false,
			},
			
			// 状態データ
			state_data: {
				template_table: true,
				frm_explanation: null,
			}
		}
	},

	// 既定計算
	computed: {
		//フォームを更新するボタン
		state_regist_template_button(){
			// if( this.state_template_title && this.bind_data.row_data.length > 0 ){
			//	 return false;
			// } else {
			//	 return true;
			// }
			return false;
		},

		//項目のタイトル
		state_item_title(){
			if( this.local_data.item_title === undefined ){
				return false;
			}

			return !validator.isEmpty(this.local_data.item_title);
		},

		//項目を登録するボタン
		state_item_add_button(){
			if( this.state_item_title ){
				return false;
			} else {
				return true;
			}
		},

		// 項目行のドラッグ
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},
	},

	// インスタンス初期化後
	async created(){
		this.$on( 'bv::collapse::state', ( event ) => {
			console.log( event );
		} );

		// タイトル更新イベント
		this.$eventHub.$emit( "navbar_title", "フォーム編集" );

		let class_list = await this.get_class_list();

		console.log(this.$route.path+":class_list="+JSON.stringify(class_list));
		
		for( let i=0;i<class_list.length;i++ ){
			// ユーザー設定できる情報だけを絞り込む
			if( class_list[i].cls_usr_setting_flg == "1" ){
				let item = {};

				item.value = class_list[i].cls_class_key;
				item.text = class_list[i].cls_name;
				this.bind_data.class_options.push(item);
			}
		}

		this.local_data.new_item_card_visivle = false;
	},

	// インスタンス破棄後
	destroyed: function() {},

	// インスタンスマウント後
	async mounted(){
		console.log(this.$route.path+".vue Start");

		this.imports();
		this.local_data.new_item_card_visivle = false;
	},

	// ローカル関数
	methods: {
		//
		// 項目登録
		//
		add: function( toast ){
			let item = {};
			
			item.id = this.bind_data.row_data.length+1;
			
			//表示用
			item.item_title = this.local_data.item_title;
			item.item_examine = this.local_data.item_examine;
			item.item_must = this.local_data.item_must;
			item.item_must_name = this.get_item_must_name(this.local_data.item_must);
			item.item_type = this.local_data.item_type;
			item.item_type_name = this.get_item_type_name(this.local_data.item_type);
			item.item_type_option_examine = this.make_option_examine();
			
			//登録用
			item.item_data = this.make_row_data( toast );
			
			console.log("item.item_data="+JSON.stringify(item.item_data));
			
			this.bind_data.row_data.push(item);

			if( toast ){
				this.$bvToast.toast(`${this.local_data.item_title} を登録しました`, {
					title: "項目登録",
					autoHideDelay: 2000,
					appendToast: true,
					variant: "success",
					solid: true
				});
			}

			this.local_data.item_title = "";
			this.local_data.item_examine = "";
			this.local_data.item_must = "off";
			this.local_data.item_must_name = this.get_item_must_name( this.local_data.item_must );
			this.local_data.item_type = "text";
			this.local_data.item_type_name = this.get_item_type_name( this.local_data.item_type );
			this.local_data.item_type_option_examine = this.make_option_examine();
		},

		// 行データ作成
		make_row_data: function( toast ){
			let work_row_data = {};
			
			// 入力方法
			console.log("item_type="+this.local_data.item_type);
			
			work_row_data.edit_item_type = this.local_data.item_type;
			work_row_data.edit_item_must = this.local_data.item_must;
			work_row_data.edit_item_desc = this.local_data.item_examine;
			work_row_data.edit_item_name = this.local_data.item_title;

			if( toast ){
				work_row_data.edit_item_option = {};
				
				//短文テキスト（50文字）
				if( work_row_data.edit_item_type == "text" ){
					work_row_data.edit_item_option.max_length = 50;
				}
				
				//長文テキスト（500文字）
				if( work_row_data.edit_item_type == "texts" ){
					work_row_data.edit_item_option.max_length = 500;
				}
				
				//数値
				if( work_row_data.edit_item_type == "numeric" ){
					work_row_data.edit_item_option.numeric_unit = this.local_data.item_numeric_unit;
					work_row_data.edit_item_option.numeric_upper = this.local_data.item_numeric_upper;
					work_row_data.edit_item_option.numeric_lower = this.local_data.item_numeric_lower;
				}
				
				//日付
				//時刻
				//日時
				//現在日時
				//単一選択
				if( work_row_data.edit_item_type == "radio" ){
					work_row_data.edit_item_option.select_option = [];

					for( let i=0;i<this.local_data.item_radio_options.length;i++ ){
						let option = this.local_data.item_radio_options[i];
						
						work_row_data.edit_item_option.select_option.push(option);
					}
				}
				
				//複数選択
				if( work_row_data.edit_item_type == "checkbox" ){
					work_row_data.edit_item_option.select_option = [];
					
					for( let i=0;i<this.local_data.item_checkbox_options.length;i++ ){
						let option = this.local_data.item_checkbox_options[i];
						
						work_row_data.edit_item_option.select_option.push(option);
					}
				}
				
				//単一選択（区分）
				if( work_row_data.edit_item_type == "pulldown" ){
					work_row_data.edit_item_option.class_key = this.bind_data.class_selected;
					work_row_data.edit_item_option.select_option = [];
				}
				
				//チェック
				//メールアドレス
			} else {
				work_row_data.edit_item_option = this.local_data.item_option;
			}
			//console.log(JSON.stringify(work_row_data));
			
			return work_row_data;
		},

		make_option_examine: function(){
			let option_examine = "";
			
			// 数値
			if( this.local_data.item_type == "numeric" ){
				option_examine = "単位："+this.local_data.item_numeric_unit+"　上限値："+this.local_data.item_numeric_upper+"　下限値："+this.local_data.item_numeric_lower;
			}
			
			// 単一選択
			if( this.local_data.item_type == "radio" ){
				option_examine = this.local_data.item_radio_options.join(",");
			}
			
			// 複数選択
			if( this.local_data.item_type == "checkbox" ){
				option_examine = this.local_data.item_checkbox_options.join(",");
			}

			return option_examine;
		},

		//
		// 項目削除
		//
		remove: function( item ){
			this.bind_data.row_data.splice(item.id-1,1);
			this.id_reset();
		},

		// 行編集
		row_edit: function( item ){
			console.log(JSON.stringify(item));

			this.local_data.item_title_edit_id = item.id-1;
			this.local_data.item_title_edit = item.item_title;
			this.local_data.item_examine_edit_id = item.id-1;
			this.local_data.item_examine_edit = item.item_examine;
			this.local_data.item_must_edit_id = item.id-1;
			this.local_data.item_must_edit = item.item_must;
			this.$refs['row-edit-modal'].show();
		},

		row_edit_ok: function(){
			this.bind_data.row_data[this.local_data.item_title_edit_id].item_title = this.local_data.item_title_edit;
			this.bind_data.row_data[this.local_data.item_title_edit_id].item_data.edit_item_name = this.local_data.item_title_edit;
			this.bind_data.row_data[this.local_data.item_examine_edit_id].item_examine = this.local_data.item_examine_edit;
			this.bind_data.row_data[this.local_data.item_examine_edit_id].item_data.edit_item_examine = this.local_data.item_examine_edit;
			this.bind_data.row_data[this.local_data.item_must_edit_id].item_must = this.local_data.item_must_edit;
			this.bind_data.row_data[this.local_data.item_must_edit_id].item_must_name = this.get_item_must_name(this.local_data.item_must_edit);
			this.bind_data.row_data[this.local_data.item_must_edit_id].item_data.edit_item_must = this.local_data.item_must_edit;
			this.$refs['row-edit-modal'].hide();
		},

		// IDのリセット
		id_reset: function(){
			//console.log("id_reset");
			for( let i=0;i<this.bind_data.row_data.length;i++ ){
				let row = this.bind_data.row_data[i];

				row.id = i + 1;
			}
		},

		// 日本語タイプ名の取得
		get_item_type_name: function( item_type ){
			for( let i=0;i<this.local_data.item_type_options.length;i++ ){
				if( this.local_data.item_type_options[i].value == item_type ){
					return this.local_data.item_type_options[i].text;
				}
			}

			return "";
		},

		// 日本語必須の取得
		get_item_must_name: function( item_must ){
			//console.log("item_must="+item_must);
			if( item_must == "on" ){
				return "必須";
			}

			return "";
		},

		//
		// 単一選択
		//
		add_radio_item: function(){
			this.local_data.item_radio_options.push("");
		},

		remove_radio_item: function( idx ){
			this.local_data.item_radio_options.splice(idx,1);
		},

		radio_options_plus_state: function( idx ){
			//console.log("idx="+idx);
			if( this.local_data.item_radio_options.length == idx ){
				return true;
			} else {
				return false;
			}
		},

		radio_options_trash_state: function( idx ){
			//console.log("idx="+idx);
			if( this.local_data.item_radio_options.length == 1 ){
				return false;
			} else {
				if( this.local_data.item_radio_options.length != idx ){
					return true;
				} else {
					return false;
				}
			}
		},

		//
		// 複数選択
		//
		add_checkbox_item: function(){
			this.local_data.item_checkbox_options.push("");
		},

		remove_checkbox_item: function( idx ){
			this.local_data.item_checkbox_options.splice(idx,1);
		},

		checkbox_options_plus_state: function( idx ){
			if( this.local_data.item_checkbox_options.length == idx ){
				return true;
			} else {
				return false;
			}
		},

		checkbox_options_trash_state: function( idx ){
			if( this.local_data.item_checkbox_options.length == 1 ){
				return false;
			} else {
				if( this.local_data.item_checkbox_options.length != idx ){
					return true;
				} else {
					return false;
				}
			}
		},

		//
		// テンプレートを登録する
		//
		regist_confirm(){
			this.$refs['confirm-modal'].show();

			// 入力欄の初期化処理
			this.bind_data.frm_explanation = "";
		},

		regist_cancel(){
			this.$refs['confirm-modal'].hide();
		},

		async regist_template(){
			this.state_data.frm_explanation = null;

			if ( this.bind_data.frm_explanation == "" ) {
				this.state_data.frm_explanation = false;
				
				return;
			}

			// ローディング開始
			this.loader.is_loading = true;

			//項目
			var item_info = [];

			for( let i=0;i<this.bind_data.row_data.length;i++ ){
				let row = this.bind_data.row_data[i].item_data;
				row.edit_item_no = String(i+1);
				item_info.push(row);
			}
			//console.log("item_info="+JSON.stringify(item_info));

			var data = {};
			
			data.version = "1.0";
			data.edit_form_name = "BT-Alert"
			data.edit_item_data = item_info;
			
			console.log("data="+JSON.stringify(data));

			let url = process.env.VUE_APP_RESTAPI_SET_INPUT_FORM
			let post_data = {};

			post_data.sid = this.$store.state.sid;
			post_data.frm_value = JSON.stringify(data);
			post_data.frm_explanation = this.bind_data.frm_explanation;

			try {
				let response = await axios.post( url, post_data );
				
				console.log(this.$route.path+":add_response="+JSON.stringify(response));

				if( response.data.result == "OK" ){
					//ローディング終了
					this.loader.is_loading = false;
					this.imports();
				} else {
					// ローディング終了
					this.loader.is_loading = false;

					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}
			} catch(ex){
				// ローディング終了
				this.loader.is_loading = false;

				console.log(this.$route.path+":ex="+JSON.stringify(ex) );

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
			} finally {
				this.$refs['confirm-modal'].hide();
			}
		},

		zeroPadding: function(num,length){
			return ( Array(length).join('0') + num ).slice(-length);
		},

		//
		//インポート
		//
		imports: async function(){
			this.bind_data.row_data = [];

			let form_info = await this.data_set();

			console.log("imports:form_info="+JSON.stringify(form_info));
			
			let item_info = form_info.frm_value.edit_item_data;
			//console.log("imports:item_info="+JSON.stringify(item_info));
			
			for( let i=0;i<item_info.length;i++ ){
				let item = item_info[i];

				console.log("imports:item="+JSON.stringify(item));

				this.local_data.item_title = item.edit_item_name;
				this.local_data.item_examine = item.edit_item_desc;
				this.local_data.item_must = item.edit_item_must;
				this.local_data.item_type = item.edit_item_type;
				this.local_data.item_option = item.edit_item_option;
				this.add( false );
			}

			this.local_data.frm_explanation = form_info.frm_explanation;
			this.local_data.frm_upd_date_time = form_info.frm_upd_date_time.replace( /:\d{2}/, "" );		// 秒を除去
		},

		async data_set(){
			// ローディング開始
			this.loader.is_loading = true;

			let url = process.env.VUE_APP_RESTAPI_GET_INPUT_FORM+"?sid="+this.$store.state.sid;
			let form_info = {};

			try {
				let response = await axios.get( url );
				console.log(this.$route.path+":response="+JSON.stringify(response) );

				if( response.data.result == "OK" ){
					// console.log(this.$route.path+":frm_value="+response.data.frm_value );
					form_info.frm_value = JSON.parse(response.data.frm_value);
					form_info.frm_explanation = response.data.frm_explanation;
					form_info.frm_upd_date_time = response.data.frm_upd_date_time;
					// console.log(this.$route.path+":form_info="+JSON.stringify(form_info) );

				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;
				}

				// ローディング終了
				this.loader.is_loading = false;
			} catch( ex ){
				console.log(this.$route.path+":ex="+JSON.stringify(ex) );
				
				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = JSON.stringify(ex);
				
				// ローディング終了
				this.loader.is_loading = false;
			}

			console.log(this.$route.path+":form_info="+JSON.stringify(form_info) );

			return form_info;
		},

		newItemCardShow() {
			this.local_data.item_title = "";
			this.local_data.item_examine = "";
			this.local_data.item_must = "off";
			this.local_data.item_must_name = this.get_item_must_name( this.local_data.item_must );
			this.local_data.item_type = "text";
			this.local_data.item_type_name = this.get_item_type_name( this.local_data.item_type );
			this.local_data.item_type_option_examine = this.make_option_examine();
		}
	}
}