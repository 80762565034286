
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
import Header from '@/components/Header';
import validator from 'validator';

export default {
  name: 'Init', // ココを更新
  components: {
    Loading,
    Header
  },

  // 共通関数
  mixins: [common,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
      },
      // ローカルデータ
      local_data: {
      }
    }
  },

  // 既定計算
  computed: {
  },
  // インスタンス初期化後
  created(){
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");
    // ローディング開始
    this.loader.is_loading = true;

    // sid存在確認
    if( this.$route.query.sid !== undefined
      && this.$route.query.sid != null
      && this.$route.query.sid != "" ){

      let sid = this.$route.query.sid.replace(/\s+/g, "+");

      let mode = "";
      if( this.$route.query.mode !== undefined && this.$route.query.mode != null && this.$route.query.mode != "" ){
        mode = this.$route.query.mode;
        // グローバル変数（store）に格納
        this.$store.dispatch("commit_set_mode",mode);
      }
      let bas_uuid = "";
      if( this.$route.query.bas_uuid !== undefined && this.$route.query.bas_uuid != null && this.$route.query.bas_uuid != "" ){
        bas_uuid = this.$route.query.bas_uuid;
        // グローバル変数（store）に格納
        this.$store.dispatch("commit_set_bas_uuid",bas_uuid);
      }
      let fbs_uuid = "";
      if( this.$route.query.fbs_uuid !== undefined && this.$route.query.fbs_uuid != null && this.$route.query.fbs_uuid != "" ){
        fbs_uuid = this.$route.query.fbs_uuid;
        // グローバル変数（store）に格納
        this.$store.dispatch("commit_set_fbs_uuid",fbs_uuid);
      }

      this.init( sid, mode );
    } else {
      let sid = this.$cookies.get("sid");
      if( sid == null || sid == "" ){

        this.transition("Login");
        // ローディング終了
        this.loader.is_loading = false;        
      } else {
        this.init( sid );
      }
    }
  },
  // ローカル関数
  methods: {
    async init( sid, mode = '' ){
		console.log( 'init in.', sid, mode );

		if ( !validator.isEmpty( mode ) ) {
			if ( mode.includes( 'report_' ) ) {
				mode = 'basics';
			}

			let ret = await this.check_sid( sid, mode );

			if ( !ret ) {
				// ローディング終了
				this.loader.is_loading = false;

				return;
			}
		}

      let url = process.env.VUE_APP_RESTAPI_LOGIN_AUTO;
      let post_data = {};
      post_data.sid = sid;

      try {
        let response = await axios.post( url, post_data );
        console.log(this.$route.path+":response="+JSON.stringify(response) );
        if ( response.data.result == "OK" ) {
          // グローバル変数（store）に格納
          this.$store.dispatch("commit_set_sid",response.data.sid);

          // ユーザー情報をグローバル変数に格納
          let user_info = response.data.user_info;
          user_info.organization_list = response.data.organization_list;
          this.$store.dispatch("commit_set_users_info",user_info);
          this.$store.dispatch("commit_set_system_info",response.data.system_param);

          // システムパラメータを取得しグローバル変数に格納
          // let result1 = await this.set_system_info();
          // if( !result1 ){
          //   // ローディング終了
          //   this.loader.is_loading = false;
          //   return;
          // }

          // 区分情報を取得しグローバル変数に格納
          let result2 = await this.set_class_list();
          if( !result2 ){
            // ローディング終了
            this.loader.is_loading = false;
            return;
          }

          // 組織情報を取得しローカルストレージに格納
          let result3 = await this.set_org_map();
          if( !result3 ){
            // ローディング終了
            this.loader.is_loading = false;
            return;
          }

          // クッキーに保存(仮)
          this.$cookies.config('72h');
          this.$cookies.set('sid',response.data.sid);

          //画面遷移
          this.transition("Home");
        } else {

          //画面遷移
          this.transition("Login");
        }

      } catch(ex) {
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        // ローディング終了
        this.loader.is_loading = false;     
      }
    },

		check_sid: async function( sid, mode = '' ) {
			let url = process.env.VUE_APP_RESTAPI_CHECK_SID + "?sid=" + sid;

			if ( mode != '' ) {
				url += '&mode=' + mode;
			}

			console.log( this.$route.path + ":regist:url=" + url );

			try {
				let response = await axios.get( url );

				console.log( this.$route.path + ":response=" + JSON.stringify( response.data ) );
				
				if ( response.data.result == "OK" ) {
					return true;
				} else {
					this.message.is_alert = true;
					this.message.variant = "danger";
					this.message.message = response.data.message;

					return false;
				}
			} catch( ex ) {
				console.log( this.$route.path + ":ex=" + JSON.stringify( ex ) );

				this.message.is_alert = true;
				this.message.variant = "danger";
				this.message.message = "エラーが発生しました。" + JSON.stringify( ex );
				
				return false;
			}
		},
  },
}
