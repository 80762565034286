var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "CaseDetails" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-2 mb-2",
              attrs: { variant: "outline-secondary" },
              on: {
                click: function($event) {
                  return _vm.back()
                }
              }
            },
            [_vm._v("戻る")]
          ),
          _vm.state_data.bas_info
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { "header-bg-variant": "light" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bold" },
                                  [_vm._v("件名")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1781290544
                      )
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.bind_data.basics_info.bas_subject_disp) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "b-card",
                    {
                      staticClass: "mt-2 mb-2",
                      attrs: { "header-bg-variant": "light" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function() {
                              return [
                                _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bold" },
                                  [_vm._v("事案番号")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        1358819253
                      )
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.bind_data.basics_info.bas_case_number_disp
                          ) +
                          " "
                      )
                    ]
                  ),
                  _vm.state_data.form_info_load
                    ? [
                        _c("b-table", {
                          staticClass: "mt-3",
                          attrs: {
                            hover: "",
                            items: _vm.bind_data.fbs_lists,
                            fields: _vm.local_data.fbs_fields,
                            responsive: "sm"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "head(fbs_report_title)",
                                fn: function(scope) {
                                  return [
                                    _c("div", { staticClass: "text-nowrap" }, [
                                      _vm._v("報告書名称")
                                    ])
                                  ]
                                }
                              },
                              {
                                key: "cell(fbs_status)",
                                fn: function(row) {
                                  return [
                                    _vm.isConsulted(row.item) == 1
                                      ? [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "float-left p-2",
                                              attrs: { variant: "primary" }
                                            },
                                            [_vm._v("相談あり")]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm.isConsulted(row.item) == 2
                                      ? [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "float-left p-2",
                                              attrs: { variant: "primary" }
                                            },
                                            [_vm._v("相談中")]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm.isConsulted(row.item) == 3
                                      ? [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "float-left p-2",
                                              attrs: { variant: "primary" }
                                            },
                                            [_vm._v("回答済み")]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm.isConsulted(row.item) == 4
                                      ? [
                                          _c(
                                            "b-badge",
                                            {
                                              staticClass: "float-left p-2",
                                              attrs: { variant: "primary" }
                                            },
                                            [_vm._v("回答あり")]
                                          )
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "cell(app_cmd)",
                                fn: function(row) {
                                  return [
                                    _vm.hasReportBrowsingPerm
                                      ? [
                                          _vm.buttonMode(row.item) == "view"
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "float-right m-1",
                                                  attrs: {
                                                    variant:
                                                      "outline-secondary",
                                                    size: "sm"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.auth(row.item)
                                                    }
                                                  }
                                                },
                                                [_vm._v("閲覧")]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e(),
                                    _vm.hasReportEditPerm
                                      ? [
                                          _vm.buttonMode(row.item) == "edit"
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "float-right m-1",
                                                  attrs: {
                                                    variant:
                                                      "outline-secondary",
                                                    size: "sm"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.edit(row.item)
                                                    }
                                                  }
                                                },
                                                [_vm._v("編集")]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e(),
                                    _vm.buttonMode(row.item) == "check"
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "float-right m-1",
                                            attrs: {
                                              variant: "outline-secondary",
                                              size: "sm"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.editConfirm(row.item)
                                              }
                                            }
                                          },
                                          [_vm._v("確認")]
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            3289124389
                          )
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }