<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
	mounted: async function() {
		this.$router.afterEach( async ( to, from, next ) => {
			console.log( 'router.afterEach:', to, from, next );

			await this.getNewsList( {_this: this} );
		} );
	},

	methods: {
		...mapActions( 'api', [
			'getNewsList',
		] ),
	}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.report-comment {
  word-wrap: normal;
  word-break: keep-all;
  white-space: pre-wrap;
}
</style>

<style lang="scss">
div.vue-treeselect {
	&.is-invalid {
		border: 1px solid #dc3545;

		&.vue-treeselect--focused:not(.vue-treeselect--open){
			box-shadow: 0 0 0 4px rgba(229, 11, 3, 0.1);

			.vue-treeselect__control {
				border-color: #cfcfcf;
			}
		}
	}

	.vue-treeselect__multi-value {
		padding-left: 5px;
	}
}
</style>
