import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
// import validator from 'validator';
import axios from 'axios';
//import moment from 'moment-timezone';
import common from '@/lib/common.js';
import CommonDataSet from '@/lib/CommonDataSet.js';
// import treeselectdialogs from '@/components/TreeSelectDialogs.vue';
// import treeselectdialogs2 from '@/components/TreeSelectDialogs2.vue';

export default {
  name: 'CommentHistory', // ココを更新
  components: {
    Loading,
    // treeselectdialogs,
    // treeselectdialogs2,
  },

  // 共通関数
  mixins: [common,CommonDataSet],

  // ローカルデータ変数
  data() {
    return {
      // ローディング
      loader: {
        is_loading: false,
        is_full_page: true,
      },
      // メッセージ
      message: {
        is_alert: false,
        variant: "",
        message: "",
      },
      // バインドデータ
      bind_data: {
        // 検索条件表示トグル
        search_toggle: false,
        search_toggle_rotate:"0",
        
        // 検索結果/組織一覧
        list: [],

      },
      // ローカルデータ
      local_data: {
        //検索/事案番号
        bas_case_number: "",
        //検索/事案名
        search_subject: "",
        // 検索条件：日付
        search_date: "",
        between_edit_start_time: null,
        search_date_end: null,
        between_edit_end_time: null,

        // 選択行
        row: null,
        fields: [
          { key: "bas_case_number", label:"事案番号", sortable: true },
          { key: "bas_subject", label:"事案名", sortable: true },
          { key: "bas_occur_date_time", label:"発生日時", sortable: true },
          { key: "talk_room", label:"トークルーム" },
          { key: "time_line", label:"タイムライン" },
          { key: "basics_comment", label:"相談(事案)" },
          { key: "form_basics_comment", label:"相談(報告)" },
          { key: "app_cmd", label:"", class:"text-nowrap" },
        ],    
      },
      state_data: {
        // treeselectdialogs: true,
        // treeselectdialogs2: true,
        
        // 検索条件：日付
        search_date: null,
        between_edit_start_time: null,
        search_date_end: null,
        between_edit_end_time: null,
      },
    }
  },

  // 既定計算
  computed: {
    state_search_button(){
      return !this.validSearch();
    },
  },
  // インスタンス初期化後
  created(){
    // タイトル更新イベント
    this.$eventHub.$emit( "navbar_title", "コメント履歴" );
  },
  // インスタンス破棄後
  destroyed: function() {
  },
  // インスタンスマウント後
  async mounted(){
    console.log(this.$route.path+".vue Start");

    // ローディング開始
    this.loader.is_loading = true;

    await this.get_comment_list();

    // ローディング終了
    this.loader.is_loading = false;
  },
  // ローカル関数
  methods: {
    // 検索条件トグル
    search_toggle() {
      if (this.bind_data.search_toggle) {
        this.bind_data.search_toggle_rotate ="0";
      } else {
        this.bind_data.search_toggle_rotate ="180";
      }
      this.bind_data.search_toggle = !this.bind_data.search_toggle;
    },
    
    /**
     * 検索条件チェック
     * 発生日時はセットで指定されないとエラーにする
     */
     validSearch() {
      this.state_data.between_edit_start_time = null;
      this.state_data.search_date = null;
      this.state_data.between_edit_end_time = null;
      this.state_data.search_date_end = null;

      let result = true;
      if (this.local_data.search_date && !this.local_data.between_edit_start_time) {
        result = false;
        this.state_data.between_edit_start_time = false;
      }
      else if (!this.local_data.search_date && this.local_data.between_edit_start_time) {
        result = false;
        this.state_data.search_date = false;
      }
      if (this.local_data.search_date_end && !this.local_data.between_edit_end_time) {
        result = false;
        this.state_data.between_edit_end_time = false;
      }
      else if (!this.local_data.search_date_end && this.local_data.between_edit_end_time) {
        result = false;
        this.state_data.search_date_end = false;
      }

      return result;
    },

    //コメント履歴一覧の取得
    async get_comment_list() {
      //日付の入力が不正なら検索を行わない
      if (!this.validSearch()) {
        return;
      }

      // ローディング開始
      this.loader.is_loading = true;
      
      let startDatetime = '';
      if (this.local_data.search_date && this.local_data.between_edit_start_time) {
        startDatetime = `${this.local_data.search_date} ${this.local_data.between_edit_start_time}`;
      }
      let endDatetime = '';
      if (this.local_data.search_date_end && this.local_data.between_edit_end_time) {
        endDatetime = `${this.local_data.search_date_end} ${this.local_data.between_edit_end_time}`;
      }

      let url = process.env.VUE_APP_GET_COMMENT_HISTORY_LIST+"?sid="+this.$store.state.sid
      +"&bas_subject="+encodeURIComponent(this.local_data.search_subject)
      +"&bas_case_number="+encodeURIComponent(this.local_data.bas_case_number)
      +"&bas_occur_start_date_time="+startDatetime
      +"&bas_occur_end_date_time="+endDatetime;
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response) );

        this.bind_data.list = [];

        if( response.data.result == "OK" ){
          if( response.data.comment_history_list.length > 0 ) {
            this.bind_data.list = response.data.comment_history_list;
          } else {
            this.message.is_alert = true;
            this.message.variant = "info";
            this.message.message = "検索結果がありません。";     
          }
        } else {
          console.log("その他のエラー")
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        // ローディング終了
        this.loader.is_loading = false;

      } catch( ex ){
        // ローディング終了
        this.loader.is_loading = false;
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },

    //トークルーム出力
    async output_talkroom(row) {
      console.log("トークルーム出力");
      console.log(row);

        // ローディング開始
        this.loader.is_loading = true;
        this.message.is_alert = false;
  
        let UTF_8_BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);
  
        let url = process.env.VUE_APP_BTALERT_CHAT_GET_ALL+"?cnt_uuid="+"btalert"
        +"&chat_send_uuid="+String(row.item.bas_chat_group_uuid)
        +"&chat_type="+"group";

        let header = {
          "x-api-key":process.env.VUE_APP_API_KEY,
        }

        console.log(this.$route.path+":url="+url);
        try {
          let response = await axios.get( url, {headers:header} );
          console.log(this.$route.path+":response="+JSON.stringify(response));
          if( response.data.result == "OK" ){
            let bas_case_number = row.item.bas_case_number;
            let bas_subject = row.item.bas_subject;
            let rows = [];
            rows.push('"事案番号","件名","発信日時","発信者","メッセージ","写真","位置情報","ドキュメント"')
            for( let i=0;i<response.data.chat_info.length;i++ ){
              let cols = '"'+bas_case_number
                      +'","'+bas_subject
                      +'","'+response.data.chat_info[i].chat_time
                      +'","'+response.data.chat_info[i].chat_sent_name
                      +'","'+response.data.chat_info[i].chat_message+'"';
              rows.push(cols);
            }
            let link = document.createElement('a');
            // link.download = "talkroom_comment_history.csv";
            link.download = "トークルームコメント履歴.csv";
            let blob = new Blob([UTF_8_BOM, rows.join('\n')], { type: 'text/csv;charset=utf-8' } );
            link.href = window.URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();
          } else {
            this.message.is_alert = true;
            this.message.variant = "danger";
            this.message.message = response.data.message;
          }
          // ローディング終了
          this.loader.is_loading = false;
  
        } catch ( ex ) {
          // ローディング終了
          this.loader.is_loading = false;
          console.log(this.$route.path+":ex="+JSON.stringify(ex) );
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = JSON.stringify(ex);        
        }
    },
    
    //タイムライン出力
    async output_timeline(row) {
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;

      let UTF_8_BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);

      let url = process.env.VUE_APP_GET_TIMELINES_COMMENT_CSV+"?sid="+this.$store.state.sid
      +"&bas_uuid="+String(row.item.bas_uuid);
      console.log(this.$route.path+":url="+url);
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          let rows = [];
          for( let i=0;i<response.data.timelines_comment_list.length;i++ ){
            let cols = response.data.timelines_comment_list[i];
            rows.push('"'+cols.join('","')+'"');
          }
          let link = document.createElement('a');
          // link.download = "timelines_comment_history.csv";
          link.download = "タイムラインコメント履歴.csv";
          let blob = new Blob([UTF_8_BOM, rows.join('\n')], { type: 'text/csv;charset=utf-8' } );
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        // ローディング終了
        this.loader.is_loading = false;

      } catch ( ex ) {
        // ローディング終了
        this.loader.is_loading = false;
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);        
      }
    },

    //上司相談（事案）出力
    async output_case(row) {
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;

      let UTF_8_BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);

      let url = process.env.VUE_APP_GET_CONSULTATION_BASICS_COMMENT_CSV+"?sid="+this.$store.state.sid
      +"&bas_uuid="+String(row.item.bas_uuid);
      console.log(this.$route.path+":url="+url);
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          let rows = [];
          for( let i=0;i<response.data.consultation_basics_comment_list.length;i++ ){
            let cols = response.data.consultation_basics_comment_list[i];
            rows.push('"'+cols.join('","')+'"');
          }
          let link = document.createElement('a');
          // link.download = "consultation_basics_comment.csv";
          link.download = "相談事案履歴.csv";
          let blob = new Blob([UTF_8_BOM, rows.join('\n')], { type: 'text/csv;charset=utf-8' } );
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        // ローディング終了
        this.loader.is_loading = false;

      } catch ( ex ) {
        // ローディング終了
        this.loader.is_loading = false;
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },

    //上司相談（帳票出力時）出力
    async output_form(row) {
      // ローディング開始
      this.loader.is_loading = true;
      this.message.is_alert = false;

      let UTF_8_BOM = new Uint8Array([0xEF, 0xBB, 0xBF]);

      let url = process.env.VUE_APP_GET_CONSULTATION_FORM_BASICS_COMMENT_CSV+"?sid="+this.$store.state.sid
      +"&bas_uuid="+String(row.item.bas_uuid);
      console.log(this.$route.path+":url="+url);
      try {
        let response = await axios.get( url );
        console.log(this.$route.path+":response="+JSON.stringify(response));
        if( response.data.result == "OK" ){
          let rows = [];
          for( let i=0;i<response.data.consultation_form_basics_comment_list.length;i++ ){
            let cols = response.data.consultation_form_basics_comment_list[i];
            rows.push('"'+cols.join('","')+'"');
          }
          let link = document.createElement('a');
          // link.download = "consultation_form_basics_comment.csv";
          link.download = "相談報告履歴.csv";
          let blob = new Blob([UTF_8_BOM, rows.join('\n')], { type: 'text/csv;charset=utf-8' } );
          link.href = window.URL.createObjectURL(blob);
          document.body.appendChild(link);
          link.click();
        } else {
          this.message.is_alert = true;
          this.message.variant = "danger";
          this.message.message = response.data.message;
        }
        // ローディング終了
        this.loader.is_loading = false;

      } catch ( ex ) {
        // ローディング終了
        this.loader.is_loading = false;
        console.log(this.$route.path+":ex="+JSON.stringify(ex) );
        this.message.is_alert = true;
        this.message.variant = "danger";
        this.message.message = JSON.stringify(ex);
      }
    },
  }
}
