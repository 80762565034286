var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Dashboard" },
    [
      _c("loading", {
        attrs: {
          active: _vm.loader.is_loading,
          "is-full-page": _vm.loader.is_full_page
        },
        on: {
          "update:active": function($event) {
            return _vm.$set(_vm.loader, "is_loading", $event)
          }
        }
      }),
      _c(
        "b-alert",
        {
          attrs: { dismissible: "", variant: _vm.message.variant },
          model: {
            value: _vm.message.is_alert,
            callback: function($$v) {
              _vm.$set(_vm.message, "is_alert", $$v)
            },
            expression: "message.is_alert"
          }
        },
        [_vm._v(_vm._s(_vm.message.message))]
      ),
      _c(
        "div",
        { staticClass: "container text-left" },
        [
          _c(
            "b-button",
            {
              staticClass: "m-1",
              attrs: { variant: "secondary" },
              on: {
                click: function($event) {
                  return _vm.link_to("back")
                }
              }
            },
            [_vm._v("戻る")]
          ),
          _c("h2", [_vm._v(_vm._s(_vm.bind_data.case_title))]),
          _vm._l(_vm.bind_data.download_lists, function(download_list) {
            return _c(
              "div",
              { key: download_list.id, staticClass: "border-top pt-2 mt-2" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      {
                        attrs: { xs: "12", sm: "4", md: "2", lg: "1", xl: "1" }
                      },
                      [
                        download_list.fileflg
                          ? _c(
                              "b-badge",
                              {
                                staticClass: "p-3",
                                attrs: { variant: "light" }
                              },
                              [_vm._v("報告書")]
                            )
                          : _c(
                              "b-badge",
                              {
                                staticClass: "p-3",
                                attrs: { variant: "light" }
                              },
                              [_vm._v("画像データ")]
                            )
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-left",
                        attrs: { xs: "12", sm: "8", md: "6", lg: "7", xl: "8" }
                      },
                      [_vm._v(" " + _vm._s(download_list.fileNm) + " ")]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass: "text-right",
                        attrs: { xs: "12", sm: "12", md: "4", lg: "4", xl: "3" }
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "m-1",
                            attrs: { variant: "secondary" }
                          },
                          [_vm._v("ダウンロード")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }